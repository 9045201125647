import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react"
import { Button, Col, Form, FormFeedback, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import { useFormik } from "formik";
import * as Yup from "yup";
import { reqCreatePartner, reqUpdatePartner } from "helpers/fakebackend_helper";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { dateToDB, htmlDecode } from "helpers/utils";

const EditPartner = (props) => {
    const { onSuccess, partner, isShow, toggle } = props
    const [otherErrorMessage, setOtherErrorMessage] = useState("")
    const isEdit = useRef(false)
    const [partnerInfo, setPartnerInfo] = useState({
        id: "",
        imageInvoice: "",
        purchaseDate: "",
        warranty: "",
        providerName: "",
        bankAccountNumber: "",
        cardName: "",
        bankName: "",
        address: "",
        facebookLink: "",
        phoneNumber: "",
        phoneNumber2: "",
        description: "",
        notes: [],
    })

    const handleUpdateState = () => {
        const partnerId = partner._id || ""
        isEdit.current = (partnerId !== null && partnerId !== "")
        setPartnerInfo({
            id: partnerId,
            imageInvoice: htmlDecode(partner.imageInvoice) || "",
            purchaseDate: dateToDB(partner.purchaseDate),
            warranty: partner.warranty || "",
            providerName: htmlDecode(partner.providerName) || "",
            bankAccountNumber: htmlDecode(partner.bankAccountNumber) || "",
            cardName: htmlDecode(partner.cardName) || "",
            bankName: htmlDecode(partner.bankName) || "",
            address: htmlDecode(partner.address) || "",
            facebookLink: htmlDecode(partner.facebookLink) || "",
            phoneNumber: htmlDecode(partner.phoneNumber) || "",
            phoneNumber2: htmlDecode(partner.phoneNumber2) || "",
            description: htmlDecode(partner.description) || "",
            notes: partner.notes || [],
        })
    }

    useEffect(() => {
        handleUpdateState()
    }, [partner])

    useEffect(() => {
        handleUpdateState()
    }, [])

    const validationInitValue = {
        id: (partnerInfo && partnerInfo.id) || '',
        imageInvoice: (partnerInfo && partnerInfo.imageInvoice) || '',
        purchaseDate: (partnerInfo && partnerInfo.purchaseDate) || '',
        warranty: (partnerInfo && partnerInfo.warranty) || '',
        providerName: (partnerInfo && partnerInfo.providerName) || '',
        bankAccountNumber: (partnerInfo && partnerInfo.bankAccountNumber) || '',
        cardName: (partnerInfo && partnerInfo.cardName) || '',
        bankName: (partnerInfo && partnerInfo.bankName) || '',
        address: (partnerInfo && partnerInfo.address) || '',
        facebookLink: (partnerInfo && partnerInfo.facebookLink) || '',
        phoneNumber: (partnerInfo && partnerInfo.phoneNumber) || '',
        phoneNumber2: (partnerInfo && partnerInfo.phoneNumber2) || '',
        description: (partnerInfo && partnerInfo.description) || '',
        notes: (partnerInfo && partnerInfo.notes) || [],
    }

    const validationRule = {
        // purchaseDate: Yup.string().required("Purchase Date is required"),
        // warranty: Yup.string().required("Warranty is required"),
        providerName: Yup.string().required("Provider Name is required"),
        // bankAccountNumber: Yup.string().required("Bank Account Number is required"),
        phoneNumber: Yup.string().required("Phone Number is required"),
        // notes: Yup.array().of(
        //     Yup.object().shape({
        //         date: Yup.date().required("Date of note is required"),
        //         note: Yup.string().required("Note is required"),
        //     })
        // ).notRequired()
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: validationInitValue,
        validationSchema: Yup.object(validationRule),
        onSubmit: async (values) => {
            let data = {
                id: values.id,
                imageInvoice: values.imageInvoice,
                purchaseDate: values.purchaseDate,
                warranty: values.warranty,
                providerName: values.providerName,
                bankAccountNumber: values.bankAccountNumber,
                cardName: values.cardName,
                bankName: values.bankName,
                address: values.address,
                facebookLink: values.facebookLink,
                phoneNumber: values.phoneNumber,
                phoneNumber2: values.phoneNumber2,
                description: values.description,
                notes: values.notes,
            };

            try {
                if (isEdit.current) {
                    await reqUpdatePartner(data)
                }
                else {
                    await reqCreatePartner(data)
                }
                await onSuccess()
                validation.resetForm();
                toggle();
            } catch (error) {
                return setOtherErrorMessage(error.message || error)
            }
        },
    });

    const onToggle = () => {
        toggle()
    }

    const handleAddRowNested = () => {
        const newRow = { date: "", note: "" }
        const notes = partnerInfo.notes
        if (!notes.length) {
            notes.push({ ...newRow })
        }
        notes.push({ ...newRow })
        setPartnerInfo({
            ...partnerInfo,
            notes: notes
        })
    }

    const handleRemoveRowNested = (e, idx) => {
        const notes = partnerInfo.notes
        const newNotes = notes.filter((value, index) => index !== idx)
        setPartnerInfo({
            ...partnerInfo,
            notes: newNotes
        })
    }

    const renderElementRowNotes = () => {
        const noteErrors = validation.errors?.notes ?? []
        if (partnerInfo.notes.length <= 1) {
            return (
                <React.Fragment>
                    <tr id="addrMain" key="">
                        <td>
                            <Row className="inner mb-3 ">
                                <Col md="10" className="col-8">
                                    <Row>
                                        <Col className="col-6">
                                            <InputGroup>
                                                <Flatpickr
                                                    className="form-control d-block"
                                                    placeholder="Date Of Note"
                                                    options={{
                                                        altInput: true,
                                                        altFormat: "F j, Y",
                                                        dateFormat: "Y-m-d"
                                                    }}
                                                    onChange={(date, dateString) => {
                                                        let note = partnerInfo.notes.length ? partnerInfo.notes[0] : { date: "", note: "" }
                                                        note.date = dateString
                                                        setPartnerInfo({
                                                            ...partnerInfo,
                                                            notes: [note]
                                                        })
                                                        // validation.validateField("notes")
                                                    }}
                                                    name="date"
                                                    value={partnerInfo.notes[0]?.date ?? ""}
                                                />
                                            </InputGroup>
                                            <Input type="hidden" invalid={noteErrors.length && noteErrors[0]?.date ? true : false} />
                                            {noteErrors.length && noteErrors[0]?.date ? (
                                                <FormFeedback type="invalid">{noteErrors.length && noteErrors[0]?.date}</FormFeedback>
                                            ) : null}
                                        </Col>
                                        <Col className="col-6">
                                            <Input
                                                type="textarea"
                                                rows="3"
                                                placeholder="Note content"
                                                onChange={(e) => {
                                                    let note = partnerInfo.notes.length ? partnerInfo.notes[0] : { date: "", note: "" }
                                                    note.note = e.target.value
                                                    setPartnerInfo({
                                                        ...partnerInfo,
                                                        notes: [note]
                                                    })
                                                    // validation.validateField("notes")
                                                }}
                                                value={partnerInfo.notes[0]?.note ?? ""}
                                                invalid={noteErrors.length && noteErrors[0]?.note ? true : false}
                                            />
                                            {noteErrors.length && noteErrors[0]?.note ? (
                                                <FormFeedback type="invalid">{noteErrors.length && noteErrors[0]?.note}</FormFeedback>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="2" className="col-4">
                                    <Button
                                        disabled
                                        color="primary"
                                        className="btn-block inner"
                                        style={{ width: "100%" }}
                                    >
                                        {" "}
                                        Delete
                                    </Button>
                                </Col>
                            </Row>
                        </td>
                    </tr>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                {
                    partnerInfo.notes.map((item1, idx) => (
                        <tr id={"nested" + idx} key={idx}>
                            <td>
                                <Row className="inner mb-3">
                                    <Col md="10" className="col-8">
                                        <Row>
                                            <Col className="col-6">
                                                <InputGroup>
                                                    <Flatpickr
                                                        className="form-control d-block"
                                                        placeholder="Date Of Note"
                                                        options={{
                                                            altInput: true,
                                                            altFormat: "F j, Y",
                                                            dateFormat: "Y-m-d"
                                                        }}
                                                        onChange={(date, dateString) => {
                                                            let notes = [...partnerInfo.notes]
                                                            notes[idx].date = dateString
                                                            setPartnerInfo({
                                                                ...partnerInfo,
                                                                notes
                                                            })
                                                            // validation.validateField("notes")
                                                        }}
                                                        name="date"
                                                        value={partnerInfo.notes[idx].date}
                                                    />
                                                </InputGroup>
                                                <Input type="hidden" invalid={noteErrors.length && noteErrors[idx]?.date ? true : false} />
                                                {noteErrors.length && noteErrors[idx]?.date ? (
                                                    <FormFeedback type="invalid">{noteErrors.length && noteErrors[idx]?.date}</FormFeedback>
                                                ) : null}
                                            </Col>
                                            <Col className="col-6">
                                                <Input
                                                    type="textarea"
                                                    rows="3"
                                                    value={partnerInfo.notes[idx]?.note}
                                                    onChange={(e) => {
                                                        let notes = [...partnerInfo.notes]
                                                        notes[idx].note = e.target.value
                                                        setPartnerInfo({
                                                            ...partnerInfo,
                                                            notes
                                                        })
                                                        // validation.validateField("notes")
                                                    }}
                                                    placeholder="Note content"
                                                    invalid={noteErrors.length && noteErrors[idx]?.note ? true : false}
                                                />
                                                {noteErrors.length && noteErrors[idx]?.note ? (
                                                    <FormFeedback type="invalid">{noteErrors.length && noteErrors[idx]?.note}</FormFeedback>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="2" className="col-4">
                                        <Button
                                            onClick={e =>
                                                handleRemoveRowNested(e, idx)
                                            }
                                            color="primary"
                                            className="btn-block inner"
                                            style={{ width: "100%" }}
                                        >
                                            {" "}
                                            Delete
                                        </Button>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                    ))
                }
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Modal isOpen={isShow} toggle={onToggle} size="lg">
                <ModalHeader toggle={onToggle} tag="h4">{isEdit.current ? "Edit Partner" : "Create Partner"}</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row form>
                            <Col className="col-12">
                                <div className="mb-3">
                                    {otherErrorMessage ? (<p className="alert alert-danger">{otherErrorMessage}</p>) : null}
                                </div>
                            </Col>
                            <Row className="mb-3">
                                <Col className="col-md-6">
                                    <Label className="form-label">Provider Name</Label>
                                    <Input
                                        name="providerName"
                                        type="text"
                                        value={validation.values.providerName || ""}
                                        invalid={validation.errors.providerName ? true : false}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setPartnerInfo({
                                                ...partnerInfo,
                                                providerName: e.target.value
                                            })
                                        }}
                                    />
                                    {validation.errors.providerName ? (
                                        <FormFeedback type="invalid">{validation.errors.providerName}</FormFeedback>
                                    ) : null}
                                </Col>
                                <Col className="col-md-6">
                                    <Label className="form-label">Card Name</Label>
                                    <Input
                                        name="cardName"
                                        type="text"
                                        value={validation.values.cardName || ""}
                                        invalid={validation.errors.cardName ? true : false}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setPartnerInfo({
                                                ...partnerInfo,
                                                cardName: e.target.value
                                            })
                                        }}
                                    />
                                    {validation.errors.cardName ? (
                                        <FormFeedback type="invalid">{validation.errors.cardName}</FormFeedback>
                                    ) : null}
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col className="col-md-6">
                                    <Label className="form-label">Bank Name</Label>
                                    <Input
                                        name="bankName"
                                        type="text"
                                        value={validation.values.bankName || ""}
                                        invalid={validation.errors.bankName ? true : false}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setPartnerInfo({
                                                ...partnerInfo,
                                                bankName: e.target.value
                                            })
                                        }}
                                    />
                                    {validation.errors.bankName ? (
                                        <FormFeedback type="invalid">{validation.errors.bankName}</FormFeedback>
                                    ) : null}
                                </Col>
                                <Col className="col-md-6">
                                    <Label className="form-label">Address</Label>
                                    <Input
                                        name="address"
                                        type="text"
                                        value={validation.values.address || ""}
                                        invalid={validation.errors.address ? true : false}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setPartnerInfo({
                                                ...partnerInfo,
                                                address: e.target.value
                                            })
                                        }}
                                    />
                                    {validation.errors.address ? (
                                        <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                    ) : null}
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col className="col-md-6">
                                    <Label className="form-label">Facebook Link</Label>
                                    <Input
                                        name="facebookLink"
                                        type="text"
                                        value={validation.values.facebookLink || ""}
                                        invalid={validation.errors.facebookLink ? true : false}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setPartnerInfo({
                                                ...partnerInfo,
                                                facebookLink: e.target.value
                                            })
                                        }}
                                    />
                                    {validation.errors.facebookLink ? (
                                        <FormFeedback type="invalid">{validation.errors.facebookLink}</FormFeedback>
                                    ) : null}
                                </Col>
                                <Col className="col-md-6">
                                    <Label className="form-label">Phone Number</Label>
                                    <Input
                                        name="phoneNumber"
                                        type="text"
                                        value={validation.values.phoneNumber || ""}
                                        invalid={validation.errors.phoneNumber ? true : false}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setPartnerInfo({
                                                ...partnerInfo,
                                                phoneNumber: e.target.value
                                            })
                                        }}
                                    />
                                    {validation.errors.phoneNumber ? (
                                        <FormFeedback type="invalid">{validation.errors.phoneNumber}</FormFeedback>
                                    ) : null}
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col className="col-md-6">
                                    <Label className="form-label">Phone Number 2</Label>
                                    <Input
                                        name="phoneNumber2"
                                        type="text"
                                        value={validation.values.phoneNumber2 || ""}
                                        invalid={validation.errors.phoneNumber2 ? true : false}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setPartnerInfo({
                                                ...partnerInfo,
                                                phoneNumber2: e.target.value
                                            })
                                        }}
                                    />
                                    {validation.errors.phoneNumber2 ? (
                                        <FormFeedback type="invalid">{validation.errors.phoneNumber2}</FormFeedback>
                                    ) : null}
                                </Col>
                                <Col className="col-md-6">
                                    
                                </Col>
                            </Row>
                            <Col className="col-12 mb-3">
                                <Label className="form-label">Description</Label>
                                <Input
                                    name="description"
                                    type="textarea"
                                    value={validation.values.description || ""}
                                    invalid={validation.errors.description ? true : false}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        setPartnerInfo({
                                            ...partnerInfo,
                                            description: e.target.value
                                        })
                                    }}
                                    rows="3"
                                />
                                {validation.errors.description ? (
                                    <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                ) : null}
                            </Col>
                            <Col className="col-12">
                                <div className="inner-repeater mb-3">
                                    <Label>Note :</Label>
                                    <table style={{ width: "100%" }}>
                                        <tbody>
                                            {renderElementRowNotes()}
                                        </tbody>
                                    </table>
                                    <Button
                                        onClick={handleAddRowNested}
                                        color="success"
                                    >
                                        Add Note
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn btn-success save-user"
                                    >
                                        {isEdit.current ? "Update" : "Create"}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

EditPartner.propTypes = {
    partner: PropTypes.object.isRequired,
    onSuccess: PropTypes.func,
    isShow: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired
}

export default EditPartner