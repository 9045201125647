import ButtonExport from "components/ultils/ButtonExport"
import PropTypes from "prop-types"
import React, {useState} from "react"
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap"
import { canAccessList } from "./Permission"
import SearchDate from "../../components/ultils/SearchDate";
import PropType from "prop-types";
import ImportWorkingTimes from "./component/ImportWorkingTime";
import DeleteWorkingTimes from "./component/DeleteWorkingTime";
import SelectSite from "./component/SelectSite";
import SelectDepartment from "../DepartmentManagerment/components/SelectDepartment";
import SelectEmployee from "../EmployeeManagerment/components/SelectEmployee";

const SearchWorkingTime = (props) => {
    const { onSearch, onError, onExport, fromDate, endDate, updateEndDate, updateFromDate } = props

    const [formSearch, setDataForm] = useState({
        employeeObject: null,
        site: "",
        departmentObject: null
    })

    const onChangeFromDate = (value) => {
        updateFromDate(value)
    }

    const onChangeToDate = (value) => {
       updateEndDate(value)
    }

    //Search
    const onClickSearchBtn = () => {
        onSearch({
            employee: formSearch.employeeObject?.value ?? "",
            fromDate: fromDate.value,
            endDate: endDate.value,
            site: formSearch.site,
            departmentId: formSearch.departmentObject?.value ?? ""
        })
    }

    //Import
    const [isShowModalImport, setIsShowModalImport] = useState(false)
    const toggleModalImport = () => {
        setIsShowModalImport(!isShowModalImport)
    }

    //Import
    const [isShowModalDelete, setIsShowModalDelete] = useState(false)
    const toggleModalDelete = () => {
        setIsShowModalDelete(!isShowModalDelete)
    }

    //Site
    const onChangeSite = (site) => {
        setDataForm({
            ...formSearch,
            site
        })
    }

    const onChangeDepartment = (departmentObject) => {
        setDataForm({
            ...formSearch,
            departmentObject
        })
    }

    return (
        <React.Fragment>
            <ImportWorkingTimes isShow={isShowModalImport} toggle={toggleModalImport} />
            <DeleteWorkingTimes isShow={isShowModalDelete} toggle={toggleModalDelete} />
            <Row>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Assigned to :</Label>
                        <SelectEmployee value={formSearch.employeeObject} onChange={(employeeObject) => {
                            setDataForm({
                                ...formSearch,
                                employeeObject: employeeObject
                            })
                        }} isClearable={true} />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Site :</Label>
                        <SelectSite value={formSearch.site} onChange={onChangeSite} />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Department :</Label>
                        <SelectDepartment value={formSearch.departmentObject} onChange={onChangeDepartment} />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>From Date :</Label>
                        <SearchDate onChange={onChangeFromDate} currentValue={fromDate} onlyDate={true} />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>To Date :</Label>
                        <SearchDate onChange={onChangeToDate} currentValue={endDate} onlyDate={true} />
                    </FormGroup>
                </div>
            </Row>
            <Row className="mb-2">
                <Col>
                    <div>
                        <Button
                            type="button"
                            color="info"
                            className="btn-rounded  mb-2 me-2"
                            onClick={onClickSearchBtn}
                        >
                            <i className="bx bx-search-alt me-1" />
                            Search
                        </Button>
                        <Button
                            type="button"
                            color="warning"
                            className="btn-rounded mb-2 me-2"
                            onClick={toggleModalImport}
                        >
                            <i className="mdi mdi-inbox-arrow-up me-1" />
                            Import
                        </Button>
                        <Button
                            type="button"
                            color="danger"
                            className="btn-rounded mb-2 me-2"
                            onClick={toggleModalDelete}
                        >
                            <i className="mdi mdi-delete me-1" />
                            Delete
                        </Button>
                        {canAccessList() ? <ButtonExport onClick={async (e) => {
                                await onExport()
                            }} /> : null}
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

SearchWorkingTime.propTypes = {
    onSearch: PropTypes.func.isRequired,
    onError: PropTypes.func,
    onExport: PropTypes.func,
    updateFromDate: PropTypes.func.isRequired,
    updateEndDate: PropTypes.func.isRequired,
    endDate: PropTypes.shape({
        type: PropType.oneOf(["day"]).isRequired,
        value: PropType.string.isRequired
    }),
    fromDate: PropTypes.shape({
        type: PropType.oneOf(["day"]).isRequired,
        value: PropType.string.isRequired
    }),
}

export default SearchWorkingTime