import CustomPagination from "components/ultils/CustomPagination"
import React, { useEffect, useRef, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { MetaTags } from "react-meta-tags"
import { Link, withRouter } from "react-router-dom"
import { Badge, Card, CardBody, Col, Container, Row, Spinner, Toast, ToastBody, ToastHeader, UncontrolledTooltip } from "reactstrap"
import SearchHistory from "./SearchHistory"
import Breadcrumbs from "components/Common/Breadcrumb"
import { reqExportAssetHistory, reqGetListHistories } from "helpers/fakebackend_helper"
import moment from "moment"
import EditHistory from "./EditHistory"
import { dateToString, htmlDecode } from "helpers/utils"
import { canAccessEdit } from "./Permission"

const HistoryManagerment = (props) => {
  const refPagination = useRef()
  let FLAG_FIRT_LOAD = useRef(false)
  const [historiesListConfig, setHistoriesListConfig] = useState({
    page: 1,
    limit: 20,
    search: {
      assetId: "",
      employeeId: "",
      returned: ""
    }
  });

  const [histories, setHistories] = useState({
    data: [],
    totalItems: 0
  })

  const [history, setHistory] = useState({})

  const historyTableColumn = [
    {
      dataField: "employeeId",
      text: "Assigned to",
      formatter: (rowConten, row) => {
        if (row.employeeId?.code) {
          return `#${htmlDecode(row.employeeId?.code)} - ${htmlDecode(row.employeeId?.fullname)}`
        }
        return htmlDecode(row.employeeId?.fullname)
      }
    },
    {
      dataField: "assetId",
      text: "Asset",
      formatter: (rowConten, row) => {
        if (row.assetId?.code) {
          return `#${htmlDecode(row.assetId?.code)} - ${htmlDecode(row.assetId?.name)}`
        }
        return htmlDecode(row.assetId?.name)
      }
    },
    {
      dataField: "returned",
      text: "Status",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        const status = row.returned === true || row.returned === "true"
        const className = status ? "success" : "primary";
        const label = status ? "Returned" : "Using";
        return (
          <Badge
            className={"font-size-12 badge-soft-" + className}
            color={className}
            pill
          >{label}</Badge>
        )
      },
    },
    {
      dataField: "startUsingDate",
      text: "Start using date",
      formatter: (rowConten, row) => {
        return dateToString(row.startUsingDate)
      }
    },
    {
      dataField: "returnedDate",
      text: "Return date",
      formatter: (rowConten, row) => {
        return dateToString(row.returnedDate)
      }
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3">
            {canAccessEdit() ? <Link
              to="#"
              className="text-success"
              onClick={() => onClickEdit(row)}
            >
              <i className="mdi mdi-pencil font-size-18" id={`edittooltip${row._id}`} />
              <UncontrolledTooltip placement="top" target={`edittooltip${row._id}`}>Edit</UncontrolledTooltip>
            </Link> : null}
          </div>
        </>
      ),
    },
  ]

  const [toast, setToast] = useState({
    message: "",
    isOpen: false
  })

  const autoCloseToast = (message) => {
    setToast({
      message: message,
      isOpen: true
    })
    setTimeout(() => {
      setToast({
        message: "",
        isOpen: false
      })
    }, 5000);
  }

  const pageOptions = {
    sizePerPage: historiesListConfig.limit,
    totalSize: histories.totalItems, // replace later with size(histories),
    custom: true,
  };

  const onChangePageNumber = (pageNumber) => {
    setHistoriesListConfig({
      ...historiesListConfig,
      page: parseInt(pageNumber)
    })
  }

  const onSearch = (data) => {
    setHistoriesListConfig({
      ...historiesListConfig,
      search: {
        ...historiesListConfig.search,
        ...data
      },
      page: 1
    })

    refPagination.current.refreshCurrentPage()
  }

  const onSearchError = (message) => {
    autoCloseToast(message)
  }

  const getListHistories = async () => {
    try {
      const filter = {
        params: {
          page: historiesListConfig.page,
          assetId: historiesListConfig.search.assetId || "",
          employeeId: historiesListConfig.search.employeeId || "",
          returned: historiesListConfig.search.returned || "",
        }
      }

      const result = await reqGetListHistories(filter)
      FLAG_FIRT_LOAD.current = true

      setHistories({
        ...histories,
        data: result.items,
        totalItems: result.totalItems
      })

    } catch (error) {
      autoCloseToast(error.message || error)
    }
  }

  useEffect(async () => {
    await getListHistories()
  }, [])

  useEffect(async () => {
    if(FLAG_FIRT_LOAD.current){
      await getListHistories()
    }
  }, [historiesListConfig])

  //Edit history
  const [modalEdit, setModalEdit] = useState(false)
  const toggleModal = () => {
    setModalEdit(!modalEdit)
  }
  const onClickEdit = (history) => {
    setHistory(history)
    setModalEdit(true)
  }

  const onSuccessEdit = async () => {
    await getListHistories()
  }

  //Export
  const clickToExport = async () => {
    try {
      const filter = {
        params: {
          page: historiesListConfig.page,
          assetId: historiesListConfig.search.assetId || "",
          employeeId: historiesListConfig.search.employeeId || "",
          returned: historiesListConfig.search.returned || "",
        }
      }

      const headers = {
        responseType: 'blob'
      }
      const result = await reqExportAssetHistory({
        params: filter,
        headers
      })

      if (result) {
        const API_URL = process.env.REACT_APP_API_URL;
        const splitURL = result.split("/")
        const fileName = splitURL[splitURL.length - 1]
        saveAs(API_URL + result, fileName)
      }
    } catch (error) {
      setToast({
        message: error.message || error,
        isOpen: true
      })

      autoCloseToast()
    }
  }

  return (
    <React.Fragment>
      <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: "1005" }}>
        <Toast isOpen={toast.isOpen}>
          <ToastHeader icon={<Spinner type="grow" size="sm" color="danger" />} toggle={() => {
            setToast({ ...toast, isOpen: !toast.isOpen })
          }}>Error</ToastHeader>
          <ToastBody>{toast.message}</ToastBody>
        </Toast>
      </div>
      <EditHistory history={history} toggle={toggleModal} onSuccess={onSuccessEdit} isShow={modalEdit} />
      <div className="page-content">
        <MetaTags>
          <title>History Assign Asset Managerment</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="History Assign Asset Managerment" breadcrumbItem="" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="_id"
                    columns={historyTableColumn}
                    data={histories.data}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={histories.data}
                        columns={historyTableColumn}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <SearchHistory onSeach={onSearch} onError={onSearchError} onExport={clickToExport}/>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    basseted={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>

                              </Col>
                            </Row>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <CustomPagination ref={refPagination} onChangePage={onChangePageNumber} sizeOfPerPage={pageOptions.sizePerPage} totalItem={pageOptions.totalSize} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(HistoryManagerment)