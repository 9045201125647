import moment from "moment"
import { decode } from 'html-entities';

export const dateToString = (date) => {
    const DATE_FORMAT = "DD-MM-YYYY"
    const newDate = date ?? ""
    const momentDate = newDate instanceof moment ? newDate : moment(newDate)
    return newDate && momentDate.isValid() ? momentDate.format(DATE_FORMAT) : ""
}

export const dateTimeToString = (date) => {
    const DATE_FORMAT = "HH:mm:ss DD-MM-YYYY"
    const newDate = date ?? ""
    const momentDate = newDate instanceof moment ? newDate : moment(newDate)
    return newDate && momentDate.isValid() ? momentDate.format(DATE_FORMAT) : ""
}

export const dateToDB = (date) => {
    const DB_DATE_FORMAT = "YYYY-MM-DD"
    const newDate = date ?? ""
    const momentDate = newDate instanceof moment ? newDate : moment(newDate)
    return newDate && momentDate.isValid() ? momentDate.format(DB_DATE_FORMAT) : ""
}

export const rebuildConfigSearchParams = (params = {}) => {
    let newParams = {}
    for (const param in params) {
        if (Object.hasOwnProperty.call(params, param)) {
            const value = params[param];
            if (value !== null && value !== "" && typeof value !== "undefined") {
                newParams[param] = value
            }
        }
    }

    return newParams
}

export const htmlDecode = (string = "") => {
    return decode(string)
}

export const decodeStringOnObject = (object = {}) => {
    let newObject = {}
    const getNewValue = (value) => {
        if(typeof value === "string") {
            return htmlDecode(value)
        }
        
        return value
    }

    if(Array.isArray(object)){
        newObject = []

        object.map(value => {
            newObject.push(getNewValue(value))
        })
    }
    else{
        for (const key in object) {
            if (Object.hasOwnProperty.call(object, key)) {
                const element = object[key];
                newObject[key] = getNewValue(element)
            }
        }
    }

    
}

export const getExchangeRate = (unitsOptions = [], exchangeRate = {}, selectedUnit) => {
    let _exchangeRate = 1

    for (let i = 0; i < unitsOptions.length; i++) {
        const unit = unitsOptions[i].value
        if(selectedUnit === unit) {
            let rate = 1
            if(exchangeRate.hasOwnProperty(unit)) {
                rate = exchangeRate[unit.toString().toLowerCase()]
            }
            else if(exchangeRate.hasOwnProperty(unit.toString().toLowerCase())) {
                rate = exchangeRate[unit.toString().toLowerCase()]
            }
            else if(exchangeRate.hasOwnProperty(unit.toString().toUpperCase())) {
                rate = exchangeRate[unit.toString().toUpperCase()]
            }

            _exchangeRate = Number(rate)
            break
        }
    }

    return _exchangeRate
}