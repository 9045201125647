import PropTypes from "prop-types"
import React, {useEffect, useRef, useState} from "react"
import {Form, Row, Modal, ModalBody, ModalHeader, Input, Button, Label, FormFeedback} from "reactstrap"
import ReactSelect from "react-select";
import * as Yup from "yup";
import {useFormik} from "formik";
import {reqImportWorkingTimes} from "../../../helpers/fakebackend_helper";

const ImportWorkingTimes = (props) => {
    const {toggle, isShow} = props
    const options = [{label: "Login", value: "login"}, {label: "Logout", value: "logout"}]
    const [optionState, setOptionState] = useState("")
    const [importFile, setImportFile] = useState(null)
    const [otherMess, setOtherMess] = useState({
        mess: "",
        status: 0
    })
    const setToDefaultOtherMess = () => {
        setOtherMess({
            mess: "",
            status: 0
        })
    }
    useEffect(setToDefaultOtherMess, [isShow])
    useEffect(setToDefaultOtherMess, [importFile])
    useEffect(setToDefaultOtherMess, [optionState])

    const validationInitValue = {
        option: optionState ? optionState : "",
        importFile: importFile ? {...importFile} : ""
    }
    const validationRule = {
        option: Yup.string().required("Please Choose Type"),
        importFile: Yup.mixed()
            .required('A file is required')
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: validationInitValue,
        validationSchema: Yup.object(validationRule),
        onSubmit: async (values) => {
            isLoading.current = true
            setReFresh(true)
            const isOpenDoor = values.option === "login" ? 1 : 0
            const formData = new FormData()
            formData.append("importFile", importFile, importFile.name)
            formData.append("isOpenDoor", isOpenDoor.toString())
            try {
                const res = await reqImportWorkingTimes(formData)
                if (res !== "Successfully!") {
                    throw res
                }
                setOtherMess({
                    mess: "Import Successfully!",
                    status: 1
                })
            } catch (error) {
                setOtherMess({
                    mess: error.message || error,
                    status: 0
                })
            }
            isLoading.current = false
            setReFresh(false)
        },
    });
    const onFileChange = (e) => {
        setImportFile(e.target.files[0])
        validation.validateField("importFile")
    }

    const isLoading = useRef(false)
    const [refresh, setReFresh] = useState(false)

    return (
        <React.Fragment>
            <Modal isOpen={isShow} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle} tag="h4">Audit payment history</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row form>
                            {
                                otherMess.mess ? (<div
                                    className={otherMess.status ? "mb-2 p-1 bg-success text-white" : "mb-2 p-1 bg-danger text-white"}>{otherMess.mess}</div>) : null
                            }

                            <div className="mb-3">
                                <Label className="form-label">Type</Label>
                                <ReactSelect onChange={(option) => {
                                    setOptionState(option?.value ?? null)
                                    validation.validateField("option")
                                }}
                                             options={options}
                                             value={options.filter(option => option.value === optionState)}
                                />
                                <Input type="hidden"
                                       invalid={validation.touched.option && validation.errors.option ? true : false}/>
                                {validation.touched.option && validation.errors.option ? (
                                    <FormFeedback type="invalid">{validation.errors.option}</FormFeedback>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="formFile" className="form-label">File</Label>
                                <Input className="form-control" type="file" id="formFile"
                                       accept=".dat"
                                       onChange={onFileChange}
                                       invalid={validation.touched.importFile && validation.errors.importFile ? true : false}/>
                                {validation.touched.importFile && validation.errors.importFile ? (
                                    <FormFeedback type="invalid">{validation.errors.importFile}</FormFeedback>
                                ) : null}
                            </div>

                            <Button disabled={isLoading.current} type="submit">{isLoading.current ?
                                <i className="mdi mdi-spin mdi-loading me-1"/> : ""} Import</Button>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

ImportWorkingTimes.propTypes = {
    isShow: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
}

export default ImportWorkingTimes