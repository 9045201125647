import { ceil, throttle } from "lodash";
import ReactSelect from "react-select";

import React, { forwardRef, useImperativeHandle, useState, useRef, useEffect } from "react"
import * as PropTypes from "prop-types"
import { FormFeedback, Input } from "reactstrap";
import { reqGetRoleOptions } from "helpers/fakebackend_helper";

const SelectRoles = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {

        }
    })

    const [otherErrorMessage, setOtherErrorMessage] = useState("")

    const {onBlur, onChange, isClear} = props
    const isClearable = isClear ?? false

    const [optionRoles, setOptionRoles] = useState({
        isLoading: false,
        options: [],
        textSearch: "",
        totalItems: 0
    })

    const menuIsOpen = useRef(false)
    const PAGE = useRef(1);
    const [textSearch, changeTextSearch] = useState("")

    const getRoles = async (textSearch = "") => {
        if (!optionRoles.isLoading && menuIsOpen.current) {
            setOptionRoles({
                ...optionRoles,
                isLoading: true
            })
            const config = {
                params: {
                    name: textSearch,
                    page: PAGE.current
                }
            }

            const options = PAGE.current > 1 ? optionRoles.options : []
            let totalItems = 0
            try {
                const res = await reqGetRoleOptions(config)
                totalItems = res.totalItems || 0
                res?.items.map((val) => {
                    const { _id: value, roleName } = val
                    options.push({ value, label: roleName })
                })
            } catch (error) {
                setOtherErrorMessage(error.message || error)
            }

            setOptionRoles({
                ...optionRoles,
                options,
                isLoading: false,
                totalItems
            })
        }
    }

    const throttled = useRef(throttle(async (newValue) => {
        PAGE.current = 1
        await getRoles(newValue)
    }, 1000))

    useEffect(() => {
        throttled.current(textSearch)
    }, [textSearch])

    const handleOnScrollToBottom = async () => {
        if (!optionRoles.isLoading) {
            const { totalItems } = optionRoles
            const LIMIT = 20
            const totalPage = ceil(totalItems / LIMIT)
            const newPageNumber = (PAGE.current < totalPage) ? (PAGE + 1) : PAGE.current
            if (newPageNumber != PAGE.current) {
                PAGE.current = newPageNumber
                await getRoles()
            }
        }
    }

    return (
        <React.Fragment>
            <ReactSelect
                blurInputOnSelect={false}
                isLoading={optionRoles.isLoading}
                onInputChange={(text) => {
                    changeTextSearch(text)
                }}
                onChange={(options) => {
                    typeof onChange === "function" ? onChange(options) : null
                }}
                onMenuOpen={async () => {
                    menuIsOpen.current = true
                    await getRoles()
                }}
                options={optionRoles.options}
                name="type"
                onBlur={(e) => {
                    menuIsOpen.current = false
                    typeof onBlur === "function" ? onBlur(e) : null
                }}
                value={props.value}
                isMulti
                onMenuScrollToBottom={async () => {
                    await handleOnScrollToBottom()
                }}
                isClearable={isClearable}
            />
            <Input type="hidden" invalid={otherErrorMessage ? true : false} />
            <FormFeedback>{otherErrorMessage}</FormFeedback>
        </React.Fragment>
    )

})

SelectRoles.propTypes = {
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string
        }).isRequired
    ),
    isClear: PropTypes.bool
}

export default SelectRoles