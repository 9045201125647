import React, { useEffect, useState, useRef, useMemo } from "react";
import { withRouter, Link, useLocation, useParams } from "react-router-dom";

import Breadcrumbs from "components/Common/Breadcrumb";
import { reqGetDatailAsset } from "helpers/fakebackend_helper";
import { MetaTags } from "react-meta-tags";
import { Badge, Card, CardBody, CardTitle, Col, Container, Input, Label, Row } from "reactstrap";
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { dateToString, htmlDecode } from "helpers/utils";

const AssetHistory = props => {
  const { id: assetId } = useParams()
  const [asset, setAsset] = useState({})
  const [messageError, setMessageError] = useState("")
  const getDetailAsset = async () => {
    try {
      const res = await reqGetDatailAsset(assetId)
      setAsset(res)
    }
    catch (error) {
      setMessageError(error.message || error)
    }
  }

  useMemo(async () => {
    await getDetailAsset()
  }, [])

  const HistoryColumns = () => [
    {
      dataField: "employeeId",
      text: "Assigned to",
      formatter: (rowConten, row) => {
        if (row.employeeId?.code) {
          return `#${htmlDecode(row.employeeId?.code)} - ${htmlDecode(row.employeeId?.fullname)}`
        }
        return htmlDecode(row.employeeId?.fullname)
      }
    },
    {
      dataField: "returned",
      text: "Status",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        const className = row.returned ? "success" : "primary";
        const label = row.returned ? "Returned" : "Using";
        return (
          <Badge
            className={"font-size-12 badge-soft-" + className}
            color={className}
            pill
          >{label}</Badge>
        )
      },
    },
    {
      dataField: "startUsingDate",
      text: "Start using date",
      formatter: (rowConten, row) => {
        return dateToString(row.startUsingDate)
      }
    },
    {
      dataField: "returnedDate",
      text: "Return date",
      formatter: (rowConten, row) => {
        return dateToString(row.returnedDate)
      }
    }
  ];

  const pageOptions = {
    sizePerPage: 20,
    totalSize: asset.histories?.length || 0, // replace later with size(assets),
    custom: true,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Asset Detail</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Asset Detail" breadcrumbItem={"#" + asset.code || ""} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {messageError ? (<Row>
                    <Col><p className="alert alert-danger">{messageError}</p></Col>
                  </Row>) : null}
                  <Row>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">Asset name</Label>
                        <Input
                          type="text"
                          disabled={true}
                          value={htmlDecode(asset.name) || ""}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">Asset code(serial)</Label>
                        <Input
                          type="text"
                          disabled={true}
                          value={htmlDecode(asset.code) || ""}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">Status</Label>
                        <Input
                          type="text"
                          disabled={true}
                          value={(asset.available === "true" || asset.available === true) ? "Availble" : "Unavailble"}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">Partner</Label>
                        <Input
                          type="text"
                          disabled={true}
                          value={htmlDecode(asset.partner?.providerName) || ""}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">Purchase Date</Label>
                        <Input
                          type="text"
                          disabled={true}
                          value={dateToString(asset.purchaseDate)}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">Warranty</Label>
                        <Input
                          type="text"
                          disabled={true}
                          value={asset.warranty || ""}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">Assigned to</Label>
                        <Input
                          type="text"
                          disabled={true}
                          value={asset.employeeId?.fullname || ""}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">Price</Label>
                        <Input
                          type="text"
                          disabled={true}
                          value={asset.price || ""}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">Description</Label>
                        <Input
                          type="textarea"
                          disabled={true}
                          value={htmlDecode(asset.description) || ""}
                          rows="3"
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="_id"
            columns={HistoryColumns()}
            data={asset.histories || []}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="_id"
                data={asset.histories || []}
                columns={HistoryColumns()}
                bootstrap4
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col xl="12">
                            <CardTitle className="mb-3">Asset histoies</CardTitle>
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField="_id"
                                responsive
                                basseted={false}
                                striped={false}
                                defaultSorted={[]}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>

                          </Col>
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AssetHistory);

