import React, {useEffect, useRef, useState} from "react";
import {htmlDecode} from "../../helpers/utils";
import {canAccessDelete, canAccessEdit} from "../PartnerManagerment/Permission";
import {Link, useHistory} from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    UncontrolledTooltip
} from "reactstrap";
import {getListBatch, reqDeleteBatch} from "../../helpers/fakebackend_helper";
import DeleteModal from "../../components/Common/DeleteModal";
import {MetaTags} from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import paginationFactory, {PaginationProvider} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import CustomPagination from "../../components/ultils/CustomPagination";
import Search from "./components/Search";
import CustomToast from "../../components/ultils/CustomToast";
import {BATCH_STATUS, BATCH_STATUS_CODE} from "./constant";

const BatchManagement = () => {
    const refPagination = useRef()
    let FLAG_FIRT_LOAD = useRef(false)
    const [batchListConf, setBatchListConf] = useState({
        page: 1,
        limit: 20,
        search: {
            code: "",
            status: [],
            partner: null,
        }
    });

    const [batches, setBatches] = useState({
        data: [],
        totalItems: 0
    })

    const [batch, setBatch] = useState({})

    const batchTableColumn = [
        {
            dataField: "code",
            text: "Code",
            formatter: (rowContent) => {
                return htmlDecode(rowContent)
            }
        },
        {
            dataField: "shippingFee",
            text: "Shipping fee",
            formatter: (rowContent, row) => {
                const unit = row?.shippingFeeUnit
                const fee = rowContent === null || typeof rowContent === "undefined" ? "" : Number(rowContent).toFixed(2).toLocaleString()
                return fee ? `${fee} (${unit})` : ""
            }
        },
        {
            dataField: "partner",
            text: "Partner",
            formatter: (rowContent, row) => {
                return htmlDecode(row?.partner?.providerName) || ""
            }
        },
        {
            dataField: "status",
            text: "Status",
            formatter: (rowContent, row) => {
                for (const STATUS_CODE_KEY in BATCH_STATUS_CODE) {
                    if(BATCH_STATUS_CODE[STATUS_CODE_KEY] === row.status) {
                        return BATCH_STATUS[STATUS_CODE_KEY]
                    }
                }
                return "N/A"
            }
        },
        {
            dataField: "description",
            text: "Description",
            formatter: (rowContent) => {
                return htmlDecode(rowContent)
            }
        },
        {
            dataField: "action",
            isDummyField: true,
            text: "Action",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <>
                    <div className="d-flex gap-3">
                        {canAccessEdit() ? <Link
                            to={`/batch/edit/${row._id}`}
                            className="text-primary"
                        >
                            <i className="mdi mdi-pencil font-size-18" id={`edittooltip${row._id}`} />
                            <UncontrolledTooltip placement="top" target={`edittooltip${row._id}`}>Edit</UncontrolledTooltip>
                        </Link>: null}
                        {canAccessDelete() ? <Link
                            to="#"
                            className="text-danger"
                            onClick={() => onDelete(row)}
                        >
                            <i className="mdi mdi-delete font-size-18" id={`deletetooltip${row._id}`} />
                            <UncontrolledTooltip placement="top" target={`deletetooltip${row._id}`}>Delete</UncontrolledTooltip>
                        </Link> : null}
                    </div>
                </>
            ),
        },
    ]

    const [toast, setToast] = useState({
        message: "",
        isOpen: false,
        title: "Error",
        closeAfter: 5000
    })

    const autoCloseToast = (message) => {
        setToast({
            message: message,
            isOpen: true
        })
        setTimeout(() => {
            setToast({
                message: "",
                isOpen: false
            })
        }, 5000);
    }

    const pageOptions = {
        sizePerPage: batchListConf.limit,
        totalSize: batches.totalItems, // replace later with size(partners),
        custom: true,
    };

    const onChangePageNumber = (pageNumber) => {
        setBatchListConf({
            ...batchListConf,
            page: parseInt(pageNumber)
        })
    }

    const onSearch = (data) => {
        setBatchListConf({
            ...batchListConf,
            search: {
                ...batchListConf.search,
                ...data
            },
            page: 1
        })

        refPagination.current.refreshCurrentPage()
    }

    const onSearchError = (message) => {
        autoCloseToast(message)
    }

    const getListBatches = async () => {
        try {
            const filter = {
                params: {
                    page: batchListConf.page,
                    code: batchListConf.search.code || "",
                    partner: batchListConf.search.partner?.value ?? "",
                    status: batchListConf.search.status || [],
                }
            }

            const result = await getListBatch(filter)
            FLAG_FIRT_LOAD.current = true

            setBatches({
                ...batches,
                data: result.items,
                totalItems: result.totalItems
            })

            setBatchListConf({
                ...batchListConf,
                page: result?.page ?? 1,
                limit: result?.limit ?? 20
            })
        } catch (error) {
            autoCloseToast(error.message || error)
        }
    }

    useEffect(async () => {
        await getListBatches()
    }, [])

    useEffect(async () => {
        if (FLAG_FIRT_LOAD.current) {
            await getListBatches()
        }
    }, [batchListConf.search, batchListConf.page])


    const navigate = useHistory()
    const onCreateClick = () => {
        navigate.push("/batch/create")
    }

    //delete
    const [deleteModal, setDeleteModal] = useState(false)
    const onDelete = async (batch) => {
        setBatch(batch)
        setDeleteModal(true)
    }

    const handleDelete = async () => {
        try {
            await reqDeleteBatch(batch?._id)
            await getListBatches()
            setDeleteModal(false)
        } catch (error) {
            autoCloseToast(error.message || error)
            setDeleteModal(false)
        }
    }

    return (
        <React.Fragment>
            <CustomToast message={toast.message} onToggle={() => setToast({...toast, isOpen: !toast.isOpen})} title={toast.title} color="danger" isOpen={toast.isOpen} closeAfterMilSec={toast.closeAfter} />
            {deleteModal ? <DeleteModal show={deleteModal} onDeleteClick={handleDelete} onCloseClick={() => {
                setDeleteModal(false)
                setBatch({})
            }}
            /> : null}
            <div className="page-content">
                <MetaTags>
                    <title>Batch Management</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Batch Management" breadcrumbItem="" />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField="_id"
                                        columns={batchTableColumn}
                                        data={batches.data}
                                    >
                                        {({ paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField="_id"
                                                data={batches.data}
                                                columns={batchTableColumn}
                                                bootstrap4
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Search onSearch={onSearch} onError={onSearchError} onCreate={onCreateClick} />
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        keyField="id"
                                                                        responsive
                                                                        basseted={false}
                                                                        striped={false}
                                                                        classes={"table align-middle table-nowrap table-check"}
                                                                        headerWrapperClasses={"table-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>

                                                            </Col>
                                                        </Row>
                                                        <div className="pagination pagination-rounded justify-content-end mb-2">
                                                            <CustomPagination ref={refPagination} onChangePage={onChangePageNumber} sizeOfPerPage={pageOptions.sizePerPage} totalItem={pageOptions.totalSize} />
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default BatchManagement