import axios from "axios";
import {del, get, post, postFormData, put} from "./api_helper";
import { getUserRefreshToken, handleAfterLofin } from "./Authentication";
import * as url from "./url_helper";
import {PRODUCT_MANAGEMENT_OPTION} from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postJwtLogin = async data => {
  try {
    const res = await post(url.POST_FAKE_JWT_LOGIN, data)
    handleAfterLofin(res)
    return res
  } catch (error) {
    throw error?.message ?? error
  }
};

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data);


//FOR APP
const reqRefreshToken = async () => {
  const res = await post(url.POST_REFRESH_TOKEN, {
    refreshToken: getUserRefreshToken()
  })
  handleAfterLofin(res)
}

const requestDeleteAsset = async (id) => {
  const res = await del(url.DEL_ASSET, { params: { id } })

  return res.data
}

const reqGetListAsset = async (data) => {
  const res = await get(url.GET_ASSETS, { params: data })

  return res.data
}

const reqAddAsset = async (data) => {
  const res = await post(url.ADD_ASSET, data)
  return res.data
}

const reqUpdateAsset = async (data) => {
  const res = await post(url.UPDATE_ASSET, data)
  return res.data
}

const reqGetListOptionEmployees = async (data) => {
  const res = await get(url.GET_OPTIONS_EMPLOYEES, data)
  return res.data
}

const reqGetListEmployees = async (data) => {
  const res = await get(url.GET_EMPLOYEES, data)
  return res.data
}

const reqAssignAsset = async (data) => {
  const res = await post(url.ASSIGN_ASSET, data)
  return res.data
}

const reqGetHistoryByAssetId = async (data) => {
  const res = await get(url.GET_HISTORY_BY_ASSET_ID, data)
  return res.data
}

const reqReturnAsset = async (data) => {
  const res = await post(url.RETURN_ASSET, data)
  return res.data
}

const reqGetDatailAsset = async (id) => {
  const res = await get(url.DETAIL_ASSET, { params: { id } })
  return res.data
}
const reqGetOptionAsset = async (data) => {
  const res = await get(url.OPTION_ASSET, data)
  return res.data
}

const reqGetListHistories = async (data) => {
  const res = await get(url.GET_HISTORIES, data)
  return res.data
}

const reqUpdateHistory = async (data) => {
  const res = await post(url.UPDATE_HISTORY, data)
  return res.data
}

const reqGetListDepartment = async (config) => {
  const res = await get(url.GET_LIST_DEPARTMENT, config)
  return res.data
}

const reqCreateDepartment = async (data) => {
  const res = await post(url.CREATE_DEPARTMENT, data)
  return res.data
}

const reqUpdateDepartment = async (data) => {
  const res = await post(url.UPDATE_DEPARTMENT, data)
  return res.data
}

const reqGetDetailDepartment = async (id) => {
  const res = await get(url.DETAIL_DEPARTMENT, { params: { id } })
  return res.data
}

const reqDeleteDepartment = async (id) => {
  const res = await del(url.DELETE_DEPARTMENT, { params: { id } })
  return res.data
}

const reqGetOptionsDepartment = async (config = {}) => {
  const res = await get(url.OPTION_DEPARTMENT, config)
  return res.data
}

const reqCreateEmployee = async (data) => {
  const res = await post(url.CREATE_EMPLOYEE, data)
  return res.data
}

const reqUpdateEmployee = async (data) => {
  const res = await post(url.UPDATE_EMPLOYEE, data)
  return res.data
}

const reqGetDetailEmployee = async (id) => {
  const res = await get(url.DETAIL_EMPLOYEE, { params: { id } })
  return res.data
}

const reqDeleteEmployee = async (id) => {
  const res = await del(url.DELETE_EMPLOYEE, { params: { id } })
  return res.data
}

const reqImportEmployee = async (formData) => {
  const res = await postFormData(url.IMPORT_EMPLOYEE, formData)
  return res.data
}

const reqGetListUsers = async (config) => {
  const res = await get(url.GET_LIST_USERS, config)
  return res.data
}

const reqGetListProduct = async (config) => {
  const res = await get(url.GET_LIST_PRODUCT, config)
  console.log(res.data)
  return res.data
}

const reqCreateUser = async (data) => {
  const res = await post(url.CREATE_USER, data)
  return res.data
}

const reqUpdateUser = async (data) => {
  const res = await post(url.POST_UPDATE_USER, data)
  return res.data
}
const reqDeleteUser = async (userName) => {
  const res = await del(url.REMOVE_USER, { params: { userName } })
  return res.data
}

const reqChangePasswordUser = async (data) => {
  const res = await post(url.CHANGE_PASSWORD, data)
  return res.data
}

const reqGetAssetTypeOptions = async (config) => {
  const res = await get(url.ASSET_TYPE_OPTIONS, config)
  return res.data
}

const reqGetListAssetType = async (config) => {
  const res = await get(url.ASSET_TYPES, config)
  return res.data
}

const reqCreateAssetType = async (data) => {
  const res = await post(url.ASSET_TYPES_CREATE, data)
  return res.data
}

const reqUpdateAssetType = async (data) => {
  const res = await post(url.ASSET_TYPES_UPDATE, data)
  return res.data
}

const reqDeleteAssetType = async (id) => {
  const res = await del(url.ASSET_TYPES_DELETE, { params: { id } })
  return res.data
}

const reqGetListPartner = async (config) => {
  const res = await get(url.PARTNER, config)
  return res.data
}
const reqGetListPartnerOptions = async (config) => {
  const res = await get(url.PARTNER_OPTIONS, config)
  return res.data
}
const reqGetDetailPartner = async (id) => {
  const res = await get(url.PARTNER_DETAIL, { params: { id } })
  return res.data
}
const reqUpdatePartner = async (data) => {
  const res = await post(url.PARTNER_UPDATE, data)
  return res.data
}
const reqCreatePartner = async (data) => {
  const res = await post(url.PARTNER_CREATE, data)
  return res.data
}
const reqDeletePartner = async (id) => {
  const res = await del(url.PARTNER_DELETE, { params: { id } })
  return res.data
}

const reqExportAsset = async (config) => {
  const res = await get(url.EXPORT_ASSET, config)

  return res.data
}

const reqExportAssetHistory = async (config) => {
  const res = await get(url.EXPORT_ASSET_HISTORY, config)

  return res.data
}

const reqGetDetailAssetType = async (id) => {
  const res = await get(url.ASSET_TYPES_DETAIL, { params: { id } })
  return res.data
}

const reqGetListPaymentType = async (config) => {
  const res = await get(url.PAYMENT_TYPE, config)
  return res.data
}

const reqGetOtionPaymentType = async (config) => {
  const res = await get(url.PAYMENT_TYPE_OPTION, config)
  return res.data
}

const reqDeletePaymentType = async (id) => {
  const res = await del(url.PAYMENT_TYPE_DELETE, { params: { id } })
  return res.data
}

const reqUpdatePaymentType = async (data) => {
  const res = await post(url.PAYMENT_TYPE_UPDATE, data)
  return res.data
}

const reqCreatePaymentType = async (data) => {
  const res = await post(url.PAYMENT_TYPE_CREATE, data)
  return res.data
}

const reqGetListPayment = async (config) => {
  const res = await get(url.PAYMENT, config)
  return res.data
}

const reqDeletePayment = async (id) => {
  const res = await del(url.PAYMENT_DELETE, { params: { id } })
  return res.data
}

const reqUpdatePayment = async (data) => {
  const res = await post(url.PAYMENT_UPDATE, data)
  return res.data
}

const reqCreatePayment = async (data) => {
  const res = await post(url.PAYMENT_CREATE, data)
  return res.data
}

const reqGetListRoles = async (config) => {
  const res = await get(url.ROLE, config)
  return res.data
}

const reqDeleteRole = async (id) => {
  const res = await del(url.ROLE_DELETE, { params: { id } })
  return res.data
}

const reqUpdateRole = async (data) => {
  const res = await post(url.ROLE_UPDATE, data)
  return res.data
}

const reqCreateRole = async (data) => {
  const res = await post(url.ROLE_CREATE, data)
  return res.data
}

const reqGetRoleConfig = async () => {
  const res = await get(url.ROLE_CONFIG)
  return res.data
}
const reqGetRoleDetail = async (id) => {
  const res = await get(url.ROLE_DETAIL, {params: {id}})
  return res.data
}

const reqGetRoleOptions = async (config) => {
  const res = await get(url.ROLE_OPTIONS, config)
  return res.data
}

const reqGetWorkingTimes = async (config) => {
  const res = await get(url.WORKING_TIME, config)
  return res.data
}

const reqImportWorkingTimes = async (formData) => {
  const res = await postFormData(url.WORKING_TIME_IMPORT, formData)
  return res.data
}

const reqDeleteWorkingTimes = async (configs) => {
  const res = await del(url.DELETE_WORKING_TIME, {params: configs})
  return res.data
}

const reqGetSiteEmployee = async () => {
  const res = await get(url.SITE_EMPLOYEE)
  return res.data
}

const reqExportWorkingTime = async (config) => {
  const res = await get(url.EXPORT_WORKING_TIME, config)

  return res.data
}

//Group Category
const getListCategory = async (config) => {
  const res = await get(url.GROUP_CATEGORY_MANAGEMENT, config)

  return res.data
}

const reqCreateCategory = async (data) => {
  const res = await post(url.GROUP_CATEGORY_MANAGEMENT_CREATE, data)
  return res.data
}
const reqUpdateCategory = async (data) => {
  const res = await post(url.GROUP_CATEGORY_MANAGEMENT_UPDATE, data)
  return res.data
}
const reqDeleteCategory = async (id) => {
  const res = await del(url.GROUP_CATEGORY_MANAGEMENT_DELETE, {params: {id}})
  return res.data
}
const reqUpdateProduct = async (data) => {
  const res = await put(url.POST_UPDATE_PRODUCT, data)
  return res.data
}
const reqUpdateProductInfo = async (data) => {
  const res = await put(url.POST_UPDATE_PRODUCT_INFO, data)
  return res.data
}

const reqAddStocks = async (data) => {
  const res = await put(url.POST_ADD_STOCKS, data)
  return res.data
}

//Batch
const getListBatch = async (config) => {
  const res = await get(url.BATCH_MANAGEMENT, config)
  return res.data
}

const reqCreateBatch = async (data) => {
  const res = await post(url.BATCH_MANAGEMENT_CREATE, data)
  return res.data
}
const reqUpdateBatch = async (data) => {
  const res = await post(url.BATCH_MANAGEMENT_UPDATE, data)
  return res.data
}
const reqDeleteBatch = async (id) => {
  const res = await del(url.BATCH_MANAGEMENT_DELETE, {params: {id}})
  return res.data
}
const reqDetailBatch = async (id) => {
  const res = await get(url.BATCH_MANAGEMENT_DETAIL, {params: {id}})
  return res.data
}
const reqGetUnitBatch = async () => {
  const res = await get(url.BATCH_MANAGEMENT_UNIT)
  return res.data
}
const reqGetExchangeRateBatch = async () => {
  const res = await get(url.BATCH_MANAGEMENT_EXCHANGE_RATE)
  return res.data
}

const reqGetProductsOption = async (config) => {
  const res = await get(url.PRODUCT_MANAGEMENT_OPTION, config)
  return res.data
}

//Payout
const getListPayout = async (config) => {
  const res = await get(url.PAYOUT_MANAGEMENT, config)
  return res.data
}

const getListPayoutById = async (config) => {
  const res = await get(url.PAYOUT_MANAGEMENT + "/getPayoutById", config)
  return res.data
}

const getBankCodes = async () => {
  const res = await post(url.PAYOUT_MANAGEMENT + "/getBankCode")
  return res.data
}

const reqCreatePayout = async (data) => {
  const res = await post(url.PAYOUT_MANAGEMENT_CREATE, data)
  return res.data
}

const reqUpdatePayout = async (data) => {
  const res = await post(url.PAYOUT_MANAGEMENT_UPDATE, data)
  return res.data
}

//Gaming Payout
const getListGamingPayout = async (config) => {
  const res = await get(url.GAMING_PAYOUT_MANAGEMENT, config)
  return res.data
}

const getListGamingPayoutById = async (config) => {
  const res = await get(url.GAMING_PAYOUT_MANAGEMENT + "/getPayoutById", config)
  return res.data
}

const getGamingBankCodes = async () => {
  const res = await post(url.GAMING_PAYOUT_MANAGEMENT + "/getBankCode")
  return res.data
}

const reqCreateGamingPayout = async (data) => {
  const res = await post(url.GAMING_PAYOUT_MANAGEMENT_CREATE, data)
  return res.data
}

const reqUpdateGamingPayout = async (data) => {
  const res = await post(url.GAMING_PAYOUT_MANAGEMENT_UPDATE, data)
  return res.data
}

//END FOR APP

export {
  getLoggedInUser,
  isUserAuthenticated,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,

  requestDeleteAsset,
  reqGetListAsset,
  reqRefreshToken,
  reqAddAsset,
  reqUpdateAsset,
  reqGetListOptionEmployees,
  reqAssignAsset,
  reqGetHistoryByAssetId,
  reqReturnAsset,
  reqGetDatailAsset,
  reqGetOptionAsset,
  reqGetListHistories,
  reqUpdateHistory,
  reqGetListDepartment,
  reqUpdateDepartment,
  reqCreateDepartment,
  reqGetDetailDepartment,
  reqGetListEmployees,
  reqCreateEmployee,
  reqUpdateEmployee,
  reqGetOptionsDepartment,
  reqGetDetailEmployee,
  reqDeleteEmployee,
  reqDeleteDepartment,
  reqGetListUsers,
  reqCreateUser,
  reqUpdateUser,
  reqDeleteUser,
  reqChangePasswordUser,
  reqGetAssetTypeOptions,
  reqGetListAssetType,
  reqCreateAssetType,
  reqUpdateAssetType,
  reqDeleteAssetType,
  reqGetListPartner,
  reqUpdatePartner,
  reqCreatePartner,
  reqDeletePartner,
  reqGetDetailPartner,
  reqGetListPartnerOptions,
  reqExportAsset,
  reqExportAssetHistory,
  reqGetDetailAssetType,
  reqGetListPaymentType,
  reqDeletePaymentType,
  reqGetOtionPaymentType,
  reqUpdatePaymentType,
  reqCreatePaymentType,
  reqGetListPayment,
  reqDeletePayment,
  reqUpdatePayment,
  reqCreatePayment,
  reqGetListRoles,
  reqDeleteRole,
  reqUpdateRole,
  reqCreateRole,
  reqGetRoleConfig,
  reqGetRoleDetail,
  reqGetRoleOptions,
  reqGetWorkingTimes,
  reqImportWorkingTimes,
  reqImportEmployee,
  reqDeleteWorkingTimes,
  reqGetSiteEmployee,
  reqExportWorkingTime,
  reqGetListProduct,
  reqUpdateProduct,
  reqUpdateProductInfo,
  getListCategory,
  reqCreateCategory,
  reqUpdateCategory,
  reqDeleteCategory,
  reqAddStocks,
  getListBatch,
  reqCreateBatch,
  reqUpdateBatch,
  reqDeleteBatch,
  reqDetailBatch,
  reqGetUnitBatch,
  reqGetExchangeRateBatch,
  reqGetProductsOption,
  getListPayout,
  getListPayoutById,
  getBankCodes,
  reqCreatePayout,
  reqUpdatePayout,
  getListGamingPayout,
  getListGamingPayoutById,
  getGamingBankCodes,
  reqCreateGamingPayout,
  reqUpdateGamingPayout,
};
