import CustomPagination from "components/ultils/CustomPagination"
import React, {useEffect, useMemo, useRef, useState} from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {PaginationProvider} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import {MetaTags} from "react-meta-tags"
import {Link, withRouter} from "react-router-dom"
import {
    Badge, Card, CardBody, Col, Container, Row, Spinner, Toast, ToastBody, ToastHeader, UncontrolledTooltip
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import moment from "moment";
import {dateToDB, dateToString, rebuildConfigSearchParams} from "../../helpers/utils";
import {reqExportWorkingTime, reqGetWorkingTimes} from "../../helpers/fakebackend_helper";
import SearchWorkingTime from "./SearchWorkingTime";
import DetailWorkingTimes from "./component/DetailWorkingTimes";

const WorkingTimeManagerment = (props) => {
    const refPagination = useRef()
    let FLAG_FIRT_LOAD = useRef(false)
    const [workingTimeListConf, setWorkingTimeListConf] = useState({
        page: 1, limit: 20, search: {
            employee: "", fromDate: "", endDate: "",
            site: "",
            departmentId: ""
        }
    });

    const [searchDateRange, setSearchRange] = useState({
        fromDate: {type: "day", value: dateToDB(moment().startOf("month"))},
        endDate: {type: "day", value: dateToDB(moment())}
    })

    const [workingTimes, setWorkingTimes] = useState({
        data: [], totalItems: 0
    })

    const [workingTime, setWorkingTime] = useState({})

    const [toast, setToast] = useState({
        message: "", isOpen: false
    })

    const pageOptions = {
        sizePerPage: workingTimeListConf.limit, totalSize: workingTimes.totalItems, // replace later with size(workingTimes),
        custom: true,
    };

    const onChangePageNumber = (pageNumber) => {
        setWorkingTimeListConf({
            ...workingTimeListConf, page: parseInt(pageNumber)
        })
    }

    const onSearch = (data) => {
        setWorkingTimeListConf({
            ...workingTimeListConf, search: {
                ...workingTimeListConf.search, ...data
            }, page: 1
        })

        refPagination.current.refreshCurrentPage()
    }

    const getListWorkingTimes = async () => {
        try {
            const filter = {
                params: {
                    page: workingTimeListConf.page,
                    employee: workingTimeListConf.search.employee || "",
                    fromDate: workingTimeListConf.search.fromDate || "",
                    endDate: workingTimeListConf.search.endDate || "",
                    site: workingTimeListConf.search.site || "",
                    departmentId: workingTimeListConf.search.departmentId || "",
                }
            }

            const result = await reqGetWorkingTimes(rebuildConfigSearchParams(filter))
            FLAG_FIRT_LOAD.current = true

            setWorkingTimes({
                ...workingTimes, data: result.data, totalItems: result.totalItems
            })

        } catch (error) {
            autoCloseToast(error.message || error)
        }
    }

    useMemo(async () => {
        await getListWorkingTimes()
    }, [])

    useEffect(async () => {
        if (FLAG_FIRT_LOAD.current) {
            await getListWorkingTimes()
        }
    }, [workingTimeListConf])

    const renderRow = employeeWorkingTimes => {
        const {workingTimes} = employeeWorkingTimes
        const workingTimesLen = Object.keys(workingTimes).length
        let {fullName, idNumber, departmentId, site} = employeeWorkingTimes

        let departmentName = departmentId?.name ?? ""
        const _idNumber = idNumber
        let index = 0
        const rows = []

        const onlyTime = (dateTime) => {
            if (!dateTime) {
                return ""
            }
            const momentDate = moment.utc(dateTime)
            return dateTime && momentDate.isValid() ? momentDate.format("HH:mm:ss") : ""
        }

        const formatDate = (date) => {
            if (!date) {
                return ""
            }
            const momentDate = moment(date)
            return date && momentDate.isValid() ? momentDate.format("dddd DD-MM-YYYY") : ""
        }

        const pushData = (workingTimeOfDay, onDate,  index, mergeRow = 0) => {
            const lenWorkingTimeOfDay = workingTimeOfDay.length
            let firstTimeLogin = ""
            let lastTimeLogout = ""
            if (lenWorkingTimeOfDay) {
                const firstElement = workingTimeOfDay[0]
                const lastElement = workingTimeOfDay[lenWorkingTimeOfDay - 1]

                const {in: login} = firstElement
                const {out: logout} = lastElement

                const {time: timeLogin} = login
                const {time: timeLgout} = logout

                firstTimeLogin = onlyTime(timeLogin)
                lastTimeLogout = onlyTime(timeLgout)
            }

            if (index > 0) {
                fullName = ""
                idNumber = ""
                departmentName = ""
            }

            const momentOnDate = moment(onDate)
            const endOfWeek = moment(onDate).endOf("week")

            const mergeCell = (value) => {
                if(mergeRow && index === 0) {
                    return (<td rowSpan={mergeRow} style={{verticalAlign: "text-top"}}>{value}</td>)
                }
                if(mergeRow && index > 0){
                    return null
                }
                return ( <td>{value}</td>)
            }

            return (<tr key={"working-time-row-" + _idNumber + index}>
                {mergeCell(idNumber)}
                {mergeCell(fullName)}
                {mergeCell(site)}
                {mergeCell(departmentName)}
                <td className={momentOnDate.isSame(endOfWeek, "date") ? "text text-danger": ""}>{formatDate(onDate)}</td>
                <td>{firstTimeLogin}</td>
                <td>{lastTimeLogout}</td>
                <td>
                    <div className="d-flex gap-3">
                        <Link
                            to="#"
                            className="text-info"
                            onClick={() => {
                                onClickShowDetail(workingTimeOfDay)
                            }}
                        >
                            <i className="mdi mdi-eye font-size-18" id={`detailooltip${index}${_idNumber}`}/>
                            <UncontrolledTooltip placement="top"
                                                 target={`detailooltip${index}${_idNumber}`}>Detail</UncontrolledTooltip>
                        </Link>
                    </div>
                </td>
            </tr>)
        }

        for (const key in workingTimes) {
            if (!workingTimes.hasOwnProperty(key)) {
                continue
            }
            const workingTimeOfDay = workingTimes[key]
            rows.push(pushData(workingTimeOfDay, key, index, workingTimesLen))
            index++
        }

        if(!workingTimesLen) {
            rows.push(pushData([], index))
        }

        return rows
    }

    const renderRows = useMemo(() => {
        const {data} = workingTimes
        return data.map(row => renderRow(row))
    }, [workingTimes])

    const toastSpinerColler = useRef("danger")

    const autoCloseToast = (message) => {
        setToast({
            message: message,
            isOpen: true
        })
        setTimeout(() => {
            toastSpinerColler.current = "danger"
            setToast({
                message: "",
                isOpen: false
            })
        }, 5000);
    }

    const onSearchError = (mess) => {
        autoCloseToast(mess)
    }

    const updateFromDate = (value) => {
        setSearchRange({
            ...searchDateRange,
            fromDate: value
        })
    }
    const updateEndDate = (value) => {
        setSearchRange({
            ...searchDateRange,
            endDate: value
        })
    }

    //Detail
    const [workingTimesOnDay, setWorkingTimeOnDay] = useState([])
    const [isShowModalDetail, setIsShowModalDetail] = useState(false)
    const toggleModalDetail = () => {
        setIsShowModalDetail(!isShowModalDetail)
    }
    const onClickShowDetail = (row) => {
        setWorkingTimeOnDay(row)
        toggleModalDetail()
    }

    //Export
    const clickToExport = async () => {
        try {
            const filter = {
                page: workingTimeListConf.page,
                employee: workingTimeListConf.search.employee || "",
                fromDate: workingTimeListConf.search.fromDate || "",
                endDate: workingTimeListConf.search.endDate || "",
                site: workingTimeListConf.search.site || "",
                departmentId: workingTimeListConf.search.departmentId || "",
            }
            const headers = {
                responseType: 'blob'
            }
            const result = await reqExportWorkingTime({
                params: rebuildConfigSearchParams(filter),
                headers
            })

            if (result) {
                const API_URL = process.env.REACT_APP_API_URL;
                const splitURL = result.split("/")
                const fileName = splitURL[splitURL.length - 1]
                saveAs(API_URL + result, fileName)
            }
        } catch (error) {
            autoCloseToast(error.message || error,)
        }
    }

    return (<React.Fragment>
        <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: "1005" }}>
            <Toast isOpen={toast.isOpen}>
                <ToastHeader icon={<Spinner type="grow" size="sm" color={toastSpinerColler.current} />} toggle={() => {
                    setToast({ ...toast, isOpen: !toast.isOpen })
                }}>{toastSpinerColler.current === "danger" ? "Error" : "Message"}</ToastHeader>
                <ToastBody>{toast.message}</ToastBody>
            </Toast>
        </div>
        <DetailWorkingTimes toggle={toggleModalDetail} isShow={isShowModalDetail} workingTimes={workingTimesOnDay} />
        <div className="page-content">
            <MetaTags>
                <title>Users Managerment</title>
            </MetaTags>
            <Container fluid>
                <Breadcrumbs title="Users Managerment" breadcrumbItem=""/>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <SearchWorkingTime onSearch={onSearch} onError={onSearchError}
                                                   onExport={clickToExport}
                                                   fromDate={searchDateRange.fromDate}
                                                   endDate={searchDateRange.endDate}
                                                   updateFromDate={updateFromDate}
                                                   updateEndDate={updateEndDate}
                                />
                                <div className="table-responsive">
                                    <div className="react-bootstrap-table">
                                        <table
                                            className="table table-bordered table align-middle table-nowrap table-check">
                                            <thead className="table-light">
                                            <tr>
                                                <th tabIndex="0">ID Number</th>
                                                <th tabIndex="0">Name</th>
                                                <th tabIndex="0">Site</th>
                                                <th tabIndex="0">Department</th>
                                                <th tabIndex="0">Date</th>
                                                <th tabIndex="0">Login</th>
                                                <th tabIndex="0">Logout</th>
                                                <th tabIndex="0">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {renderRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                    <CustomPagination ref={refPagination} onChangePage={onChangePageNumber}
                                                      sizeOfPerPage={workingTimeListConf.limit}
                                                      totalItem={workingTimes.totalItems}/>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    </React.Fragment>)
}

export default withRouter(WorkingTimeManagerment)