const LOCAL_STORAGE_AUTH_USER = "authUser"
const LOCAL_STORAGE_INFO_USER = "infoUser"
const LOCAL_STORAGE_PERMISSION_MODULE = "permissionModule"

const handleAfterLofin = (response) => {
    const {data} = response
    const {token, configRoleModules} = data

    const splitToken = token.split(".")
    const userInfo = splitToken[1]
    const buffer = Buffer.from(userInfo, "base64")
    let stringUserInfo = buffer.toString("ascii")
    saveAuthUser(JSON.stringify(data))
    saveUserInfo(stringUserInfo)
    saveConfigPermissionModule(JSON.stringify(configRoleModules))
}

const saveAuthUser = (data) => {
    localStorage.setItem(LOCAL_STORAGE_AUTH_USER, data);
}

const removeAuthUser = () => {
    localStorage.removeItem(LOCAL_STORAGE_AUTH_USER)
    localStorage.removeItem(LOCAL_STORAGE_INFO_USER)
}

const getAuthUser = () => {
    try {
        const value = localStorage.getItem(LOCAL_STORAGE_AUTH_USER)
        return value ? JSON.parse(value) : null
    } catch (error) {
        return null
    }
}

const saveUserInfo = (stringInfo) => {
    localStorage.setItem(LOCAL_STORAGE_INFO_USER, stringInfo);
}

const getUserInfo = () => {
    const value = localStorage.getItem(LOCAL_STORAGE_INFO_USER)
    return value ? JSON.parse(value) : null
}

const timestampToDate = (timestamp) => {
    return timestamp ? new Date(timestamp * 1000) :  new Date()
}

const isExpiredToken = () => {
    const userInfo = getUserInfo()
    const {exp} = userInfo ?? {}
    return timestampToDate(exp || 0).getTime() <= new Date().getTime()
}

const getUserToken = () => {
    const userInfo = getAuthUser()
    return userInfo ? userInfo.token : ""
}

const getUserRefreshToken = () => {
    const userInfo = getAuthUser()
    return userInfo ? userInfo.refreshToken : ""
}

const saveConfigPermissionModule = (value) => {
    localStorage.setItem(LOCAL_STORAGE_PERMISSION_MODULE, value);
}

const getConfigPermissionModule = () => {
    try {
        const value = localStorage.getItem(LOCAL_STORAGE_PERMISSION_MODULE);
        return value ? JSON.parse(value) : []
    } catch (error) {
        return []
    }
}

module.exports = {
    handleAfterLofin,
    getUserInfo,
    getAuthUser,
    getUserToken,
    isExpiredToken,
    getUserRefreshToken,
    removeAuthUser,
    getConfigPermissionModule
}