import React, { useState, useRef, useMemo, useEffect } from "react"
import { Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import { useFormik } from "formik";
import * as Yup from "yup";
import { reqCreateRole, reqGetRoleConfig, reqGetRoleDetail, reqUpdateRole } from "helpers/fakebackend_helper";
import ReactSelect from "react-select";
import { onlyAminAccess } from "helpers/Permission";
import { MetaTags } from "react-meta-tags";
import Breadcrumbs from "components/Common/Breadcrumb"
import { useHistory, useParams, withRouter } from "react-router-dom";
import { htmlDecode } from "helpers/utils";

const EditRole = (props) => {
    const [otherMessage, setOtherMessage] = useState({
        message: "",
        isSuccess: false
    })
    const history = useHistory()

    const { id: roleId } = useParams()

    const configRole = useRef({
        moduleName: [],
        moduleCode: [],
        actionCode: [],
        actionName: [],
        moduleOnlyAdmin: []
    })

    const isEdit = useRef(false)
    const tableRoleModule = useRef(null)

    const [roleInfo, setRoleInfo] = useState({
        id: "",
        roleName: ""
    })

    const [stateRoleModules, setStateRoleModule] = useState([])

    const formatCurrentRoleModules = (currentRoleModules = []) => {
        let output = {}

        currentRoleModules.forEach(module => {
            const moduleCode = module?.moduleCode ?? ""
            if (moduleCode) {
                const actions = module?.actions ?? []
                let outputAction = {}
                actions.forEach(action => {
                    const actionCode = action.actionCode
                    outputAction[actionCode] = { ...action }
                })
                module.actions = outputAction
                output[moduleCode] = { ...module }
            }
        })

        return output
    }

    const handleActionsCode = (module, actionsCode = {}) => {
        const actions = []
        for (const indexActionCode in actionsCode) {
            if (Object.hasOwnProperty.call(actionsCode, indexActionCode)) {
                const actionCode = actionsCode[indexActionCode];
                const _actions = module["actions"] ?? {}
                const action = _actions[actionCode] ?? {}
                const allow = action?.allow ?? false

                actions.push({
                    actionCode,
                    allow
                })
            }
        }
        return actions
    }

    const defaultRoleModules = (config = {}, currentRoleModules = []) => {
        const modulesCode = config?.moduleCode ?? {}
        const actionsCode = config?.actionCode ?? {}

        const formatCurrentConfig = formatCurrentRoleModules(currentRoleModules)
        const defaultRoleModules = []

        for (const moduleCodeIndex in modulesCode) {
            if (Object.hasOwnProperty.call(modulesCode, moduleCodeIndex)) {
                const moduleCode = modulesCode[moduleCodeIndex];
                const module = formatCurrentConfig[moduleCode] ?? []
                const actions = handleActionsCode(module, actionsCode)

                defaultRoleModules.push({
                    moduleCode,
                    actions
                })
            }
        }
        defaultRoleModules.sort((a, b) => {
            const codeA = a.moduleCode
            const codeB = b.moduleCode

            if (codeA > codeB) {
                return 1
            }

            if (codeA < codeB) {
                return -1
            }

            return 0
        })

        defaultRoleModules.sort((a, b) => { //module only admin allway on last of list
            const codeA = a.moduleCode
            const codeB = b.moduleCode

            const temp1 = onlyAminAccess(codeA)
            const temp2 = onlyAminAccess(codeB)

            if (temp1 === false && temp2 === true) {
                return -1
            }
            if (temp1 === true && temp2 === false) {
                return 1
            }

            return 0
        })
        return defaultRoleModules
    }

    const validationInitValue = {
        id: (roleInfo && roleInfo.id) || '',
        roleName: (roleInfo && roleInfo.roleName) || '',
        roleModules: stateRoleModules && stateRoleModules.length ? stateRoleModules : [],
    }

    const validationRule = {
        roleName: Yup.string().required("Name is required"),
        roleModules: Yup.array().of(
            Yup.object().shape({
                moduleCode: Yup.string().required("Module code is required"),
                actions: Yup.array().of(
                    Yup.object().shape({
                        actionCode: Yup.string().required("Action is required"),
                        allow: Yup.boolean().required("Allow is require")
                    })
                )
            })
        ),
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: validationInitValue,
        validationSchema: Yup.object(validationRule),
        onSubmit: async (values) => {
            const onSuccess = (response) => {
                if (response._id) {
                    setOtherMessage({
                        isSuccess: true,
                        message: `Role ${roleInfo.roleName} has been ${isEdit.current ? "updated" : "created"}. Redirect to list Role after 3s`
                    })
                    setTimeout(() => {
                        history.push("/role")
                    }, 3000);
                }
            }

            let data = {
                id: values.id,
                roleName: values.roleName,
                roleModules: values.roleModules,
            };

            try {
                if (isEdit.current) {
                    data["id"] = values.id
                    const response = await reqUpdateRole(data)
                    onSuccess(response)
                }
                else {
                    const response = await reqCreateRole(data)
                    onSuccess(response)
                }
                validation.resetForm();
            } catch (error) {
                return setOtherMessage({
                    message: error.message || error,
                    isSuccess: false
                })
            }
        },
    });

    const onChangeAction = (value, moduleCode, actionCode) => {
        const currentRoleModules = stateRoleModules

        const newState = currentRoleModules.map(module => {
            if (module.moduleCode === moduleCode) {
                const actions = module.actions
                const newActions = actions.map(action => {

                    if (action.actionCode === actionCode) {
                        action.allow = value
                    }

                    return action
                })
                module.actions = newActions
            }

            return module
        })

        return newState
    }

    const allowSelect = (allow = false, moduleCode, actionCode) => {
        const options = [
            { value: true, label: "Yes" },
            { value: false, label: "No" },
        ]

        const disabled = onlyAminAccess(moduleCode)

        return <ReactSelect
            options={options}
            onChange={(option) => {
                const newState = onChangeAction(option?.value ?? false, moduleCode, actionCode)
                setStateRoleModule(newState)

                validation.validateField("roleModules")
            }}
            value={options.filter(option => disabled ? false === option.value : allow === option.value)}
            isDisabled={disabled}
        />
    }

    const renderTableRoleModules = () => {
        const moduleNames = configRole.current.moduleName ?? []
        const ACTION_COL_INDEX = ["LIST", "CREATE", "EDIT", "DETAIL", "DELETE"]

        const renderActions = (module, moduleCode) => {
            const actions = module.actions
            return ACTION_COL_INDEX.map(COL_INDEX => {
                let allow = false
                for (let actionIndex = 0; actionIndex < actions.length; actionIndex++) {
                    const action = actions[actionIndex];
                    if (action.actionCode === COL_INDEX) {
                        allow = action.allow ?? false
                    }
                }

                return (
                    <td key={`${moduleCode}_${COL_INDEX}`}>{allowSelect(allow, moduleCode, COL_INDEX)}</td>
                )
            })
        }

        return stateRoleModules.map((module, index) => {
            const moduleCode = module.moduleCode ?? ""
            const moduleName = moduleNames[moduleCode]
            return (
                <tr key={moduleCode + index}>
                    <td>{moduleName}</td>
                    {renderActions(module, moduleCode)}
                </tr>
            )
        })
    }

    useMemo(async () => {
        let roleModules = []
        if (roleId) {
            const [config, roleDetail] = await Promise.all([reqGetRoleConfig(), reqGetRoleDetail(roleId)])
            configRole.current = config
            const currentRoleModules = roleDetail.roleModules ? [...roleDetail.roleModules] : []
            roleModules = defaultRoleModules(configRole.current, currentRoleModules)
            setRoleInfo({
                roleName: htmlDecode(roleDetail.roleName),
                id: roleDetail._id
            })
            isEdit.current = true
        }
        else {
            isEdit.current = false
            const config = await reqGetRoleConfig()
            configRole.current = config
            roleModules = defaultRoleModules(configRole.current)
        }

        setStateRoleModule(roleModules)
    }, [])

    useEffect(() => {
        tableRoleModule.current = renderTableRoleModules()
    }, [stateRoleModules])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Edit Role</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Edit Role" breadcrumbItem="" />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        {otherMessage.message ? (<Row>
                                            <Col><p className={`alert alert-${otherMessage.isSuccess ? "success" : "danger"}`}>{otherMessage.message}</p></Col>
                                        </Row>) : null}
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label">Name</Label>
                                                    <Input
                                                        type="text"
                                                        invalid={validation.touched.roleName && validation.errors.roleName}
                                                        value={validation.values.roleName || ""}
                                                        onChange={(e) => {
                                                            setRoleInfo({
                                                                ...roleInfo,
                                                                roleName: e.target.value
                                                            })
                                                        }}
                                                    />
                                                    {validation.touched.roleName && validation.errors.roleName ? (
                                                        <FormFeedback type="invalid">{validation.errors.roleName}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" style={{ color: "transparent", display: "block" }}>NONE</Label>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success save-user"
                                                    >
                                                        {isEdit.current ? "Update" : "Create"}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <div className="table-responsive">
                        <div className="react-bootstrap-table">
                            <table className="table table-bordered table align-middle table-nowrap table-check">
                                <thead className="table-light">
                                    <tr>
                                        <th>Module</th>
                                        <th>List</th>
                                        <th>Create</th>
                                        <th>Edit</th>
                                        <th>Detail</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableRoleModule.current}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(EditRole)