import React, { useState, useMemo } from "react";
import { withRouter, useParams } from "react-router-dom";

import Breadcrumbs from "components/Common/Breadcrumb";
import { reqGetDatailAsset, reqGetDetailDepartment } from "helpers/fakebackend_helper";
import { MetaTags } from "react-meta-tags";
import { Badge, Card, CardBody, CardTitle, Col, Container, Input, Label, Row } from "reactstrap";
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { htmlDecode } from "helpers/utils";

const DepartmentDetail = props => {
  const { id: departmentId } = useParams()
  const [department, setDepartment] = useState({})
  const [messageError, setMessageError] = useState("")
  const getDepartment = async () => {
    try {
      const res = await reqGetDetailDepartment(departmentId)
      setDepartment(res)
    }
    catch (error) {
      setMessageError(error.message || error)
    }
  }

  useMemo(async () => {
    await getDepartment()
  }, [])

  const employeeColumn = () => [
    {
      dataField: "fullname",
      text: "Fullname",
      formatter: (rowContent, row) => {
        return htmlDecode(rowContent)
      }
    },
    {
      dataField: "status",
      text: "Status",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        const className = row.returned ? "success" : "primary";
        const label = row.returned ? "Returned" : "Using";
        return (
          <Badge
            className={"font-size-12 badge-soft-" + className}
            color={className}
            pill
          >{label}</Badge>
        )
      },
    },
    {
      dataField: "code",
      text: "Code",
      formatter: (rowContent, row) => {
        return htmlDecode(rowContent)
      }
    },
    {
      dataField: "passportOrIDNumber",
      text: "Passport or ID",
      formatter: (rowContent, row) => {
        return htmlDecode(rowContent)
      }
    }
  ];

  const pageOptions = {
    sizePerPage: 20,
    totalSize: department.employees?.length || 0, // replace later with size(assets),
    custom: true,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Department Detail</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Department Detail" breadcrumbItem={"#" + department.code || ""} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {messageError ? (<Row>
                    <Col><p className="alert alert-danger">{messageError}</p></Col>
                  </Row>) : null}
                  <Row>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">Name</Label>
                        <Input
                          type="text"
                          disabled={true}
                          value={htmlDecode(department.name) || ""}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">Code</Label>
                        <Input
                          type="text"
                          disabled={true}
                          value={htmlDecode(department.code) || ""}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="_id"
            columns={employeeColumn()}
            data={department.employees || []}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="_id"
                data={department.employees || []}
                columns={employeeColumn()}
                bootstrap4
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col xl="12">
                            <CardTitle className="mb-3">Employees</CardTitle>
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField="_id"
                                responsive
                                basseted={false}
                                striped={false}
                                defaultSorted={[]}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>

                          </Col>
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(DepartmentDetail);

