import {ceil, throttle} from "lodash";
import ReactSelect from "react-select";

import React, {forwardRef, useState, useRef, useEffect, useImperativeHandle} from "react"
import * as PropTypes from "prop-types"
import {FormFeedback, Input} from "reactstrap";
import css from "./SelectProduct.module.scss"
import {reqGetProductsOption} from "../../../helpers/fakebackend_helper";

const SelectProduct = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {

        }
    })

    const [otherErrorMessage, setOtherErrorMessage] = useState("")

    const {onBlur, onChange, isClear, isDisabled, isMulti} = props
    const isClearable = isClear ?? false
    const _isDisabled = isDisabled ?? false
    const _isMulti = isMulti ?? false

    const [optionProducts, setOptionProducts] = useState({
        isLoading: false,
        options: [],
        textSearch: "",
        totalItems: 0
    })

    const menuIsOpen = useRef(false)
    const PAGE = useRef(1);
    const [textSearch, changeTextSearch] = useState("")

    const getProducts = async (textSearch = "") => {
        if (!optionProducts.isLoading && menuIsOpen.current) {
            setOptionProducts({
                ...optionProducts,
                isLoading: true
            })
            const config = {
                params: {
                    name: textSearch,
                    page: PAGE.current,
                }
            }

            const options = PAGE.current > 1 ? optionProducts.options : []
            let totalItems = 0
            try {
                const res = await reqGetProductsOption(config)
                const items = res.items ?? []
                totalItems = res?.totalItems ?? 0
                items.forEach((val) => {
                    const {_id: value, PRODUCTNAME} = val
                    options.push({value, label: PRODUCTNAME?.EN ?? "", PRODUCTNAME})
                })

                if(otherErrorMessage) {
                    setOtherErrorMessage("")
                }
            } catch (error) {
                setOtherErrorMessage(error.message || error)
            }

            setOptionProducts({
                ...optionProducts,
                options,
                isLoading: false,
                totalItems
            })
        }
    }

    const throttled = useRef(throttle(async (newValue) => {
        PAGE.current = 1
        await getProducts(newValue)
    }, 1000))

    useEffect(() => {
        throttled.current(textSearch)
    }, [textSearch])

    const handleOnScrollToBottom = async () => {
        if (!optionProducts.isLoading) {
            const {totalItems} = optionProducts
            const LIMIT = 20
            const totalPage = ceil(totalItems / LIMIT)
            const newPageNumber = (PAGE.current < totalPage) ? (PAGE.current + 1) : PAGE.current
            if (newPageNumber !== PAGE.current) {
                PAGE.current = newPageNumber
                await getProducts()
            }
        }
    }

    const formatOptionLabel = (data) => {
        const {PRODUCTNAME} = data ?? {}
        const {EN, VN} = PRODUCTNAME ?? {}

        return <div className={css.optionContainer}>
            <span className={css.optionLabelVN}>VN: {VN}</span>
            <span className={css.optionLabelEN}>EN: {EN}</span>
        </div>
    }

    const filterOption = (option) => {
       return option
    }

    const getOptions = () => {
        const values = props.value ?? []
        return values.map(value => {
            if(typeof value.label === "string") {
                const PRODUCTNAME =  value?.PRODUCTNAME ?? {}
                return {value: value.value, label: value.label, PRODUCTNAME}
            }
            else {
                const PRODUCTNAME =  value?.label ?? {}
                return {value: value.value, label: PRODUCTNAME?.EN ?? "", PRODUCTNAME}
            }
        })
    }

    return (
        <React.Fragment>
            <ReactSelect
                blurInputOnSelect={false}
                isLoading={optionProducts.isLoading}
                onInputChange={(text) => {
                    changeTextSearch(text)
                }}
                onChange={(options) => {
                    let _options
                    if(_isMulti) {
                        _options = options.map(option => {
                            return {
                                value: option.value,
                                label: option?.PRODUCTNAME ?? {}
                            }
                        })
                    }
                    else {
                        _options = {
                            value: options.value,
                            label: options?.PRODUCTNAME ?? {}
                        }
                    }
                    typeof onChange === "function" ? onChange(_options) : null
                }}
                onMenuOpen={async () => {
                    menuIsOpen.current = true
                    await getProducts()
                }}
                options={optionProducts.options}
                name="type"
                onBlur={(e) => {
                    menuIsOpen.current = false
                    typeof onBlur === "function" ? onBlur(e) : null
                }}
                value={getOptions()}
                isMulti={_isMulti}
                onMenuScrollToBottom={async () => {
                    await handleOnScrollToBottom()
                }}
                isClearable={isClearable}
                isDisabled={_isDisabled}
                formatOptionLabel={formatOptionLabel}
                styles={{
                    option: (base) => ({
                        ...base,
                        borderBottom: "1px solid #f8f8fb",
                    }),
                }}
                filterOption={filterOption}
            />
            <Input type="hidden" invalid={!!otherErrorMessage}/>
            <FormFeedback>{otherErrorMessage}</FormFeedback>
        </React.Fragment>
    )

})

SelectProduct.propTypes = {
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object
            ])
        }).isRequired
    ),
    isClear: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isMulti: PropTypes.bool,
}

export default SelectProduct