import PropTypes from "prop-types"
import React, { useState } from "react"
import ReactSelect from "react-select"
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap"

const SearchUser = (props) => {
    const {onCreate, onSearch} = props
    const [formSearch, setDataForm] = useState({
        userName: "",
        firstName: "",
        lastName: "",
        email: "",
        status: ""
    })
    const statusOptions = [
        { value: "", label: "Choose status" },
        { value: "true", label: "Active" },
        { value: "false", label: "Inactive" }
    ]

    return (
        <React.Fragment>
            <Row>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Username :</Label>
                        <Input
                            name="userName"
                            type="text"
                            value={formSearch.userName}
                            autoComplete="off"
                            onChange={(e) => {
                                setDataForm({
                                    ...formSearch,
                                    userName: e.target.value
                                })
                            }}
                        />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>First Name :</Label>
                        <Input
                            name="firstName"
                            type="text"
                            value={formSearch.firstName}
                            autoComplete="off"
                            onChange={(e) => {
                                setDataForm({
                                    ...formSearch,
                                    firstName: e.target.value
                                })
                            }}
                        />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Last Name :</Label>
                        <Input
                            name="lastName"
                            type="text"
                            value={formSearch.lastName}
                            autoComplete="off"
                            onChange={(e) => {
                                setDataForm({
                                    ...formSearch,
                                    lastName: e.target.value
                                })
                            }}
                        />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Email :</Label>
                        <Input
                            name="email"
                            type="email"
                            value={formSearch.email}
                            autoComplete="off"
                            onChange={(e) => {
                                setDataForm({
                                    ...formSearch,
                                    email: e.target.value
                                })
                            }}
                        />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Status :</Label>
                        <ReactSelect
                            options={statusOptions}
                            onChange={(option) => {
                                setDataForm({
                                    ...formSearch,
                                    status: option.value
                                })
                            }}
                            value={statusOptions.filter(option => option.value === formSearch.status)}
                        />
                    </FormGroup>
                </div>
            </Row>
            <Row className="mb-2">
                <Col>
                    <div>
                        <Button
                            type="button"
                            color="info"
                            className="btn-rounded  mb-2 me-2"
                            onClick={(e) => {
                                onSearch(formSearch)
                            }}
                        >
                            <i className="bx bx-search-alt me-1" />
                            Search
                        </Button>
                        <Button
                            type="button"
                            color="success"
                            className="btn-rounded  mb-2 me-2"
                            onClick={onCreate}
                        >
                            <i className="mdi mdi-plus me-1" />
                            Add New User
                        </Button>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

SearchUser.propTypes = {
    onSearch: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
}

export default SearchUser