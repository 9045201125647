import ButtonExport from "components/ultils/ButtonExport"
import { reqGetListOptionEmployees, reqGetOptionAsset } from "helpers/fakebackend_helper"
import { ceil, throttle } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import ReactSelect from "react-select"
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap"
import { canAccessList } from "./Permission"
import SelectEmployee from "../EmployeeManagerment/components/SelectEmployee";

const SearchHistory = (props) => {
    const { onSeach, onError, onExport } = props

    const [formSearch, setDataForm] = useState({
        employeeObject: null,
        assetId: "",
        returned: ""
    })
    const statusOptions = [
        { value: "", label: "Choose status" },
        { value: "true", label: "Returned" },
        { value: "false", label: "Using" }
    ]

    //Dropdow Asset
    const throttled = useRef(throttle(async (callback) => {
        await callback()
    }, 1000))
    const page = useRef(1);
    const menuIsOpen = useRef(false)

    const [optionAsset, setOptionAsset] = useState({
        isLoading: false,
        options: [],
        textSearch: "",
        totalItems: 0
    })

    const [textSearchAsset, changeTextSearchAsset] = useState("")

    const getOptionAsset = async (textSearch = "") => {
        if (!optionAsset.isLoading && menuIsOpen.current) {
            setOptionAsset({
                ...optionAsset,
                isLoading: true
            })

            const config = {
                params: {
                    name: textSearch,
                    page: page.current
                }
            }

            const options = page.current > 1 ? optionAsset.options : []
            let totalItems = 0
            try {
                const res = await reqGetOptionAsset(config)
                totalItems = res.totalItems || 0
                res?.items.map((val) => {
                    const { _id: value, fullname: label, code } = val
                    options.push({ value, label: concatNameAndCode(code, label) })
                })
            } catch (error) {
                onError(error.message || error)
            }

            setOptionAsset({
                ...optionAsset,
                options,
                isLoading: false,
                totalItems
            })
        }
    }

    useEffect(() => {
        throttled.current(async () => {
            page.current = 1
            await getOptionAsset(textSearchAsset)
        })
    }, [textSearchAsset])

    const handleOnScrollToBottomAsset = async () => {
        if (!optionAsset.isLoading) {
            const { totalItems } = optionAsset
            const LIMIT = 20
            const totalPage = ceil(totalItems / LIMIT)
            const newPageNumber = (page.current < totalPage) ? (page.current + 1) : page.current
            if(newPageNumber != page.current){
                page.current = newPageNumber
                await getOptionAsset()
            }
        }
    }

    return (
        <React.Fragment>
            <Row>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Asset name :</Label>
                        <ReactSelect
                            isLoading={optionAsset.isLoading}
                            onInputChange={(text) => {
                                changeTextSearchAsset(text)
                            }}
                            onChange={(option) => {
                                setDataForm({
                                    ...formSearch,
                                    assetId: option?.value,
                                })
                            }}
                            onMenuOpen={async () => {
                                page.current = 1
                                menuIsOpen.current = true
                                await getOptionAsset()
                            }}
                            options={optionAsset.options}
                            onBlur={(e) => {
                                menuIsOpen.current = false
                            }}
                            value={optionAsset.options.filter(val => val.value == formSearch.assetId)}
                            onMenuScrollToBottom={async (e) => {
                                await handleOnScrollToBottomAsset()
                            }}
                            isClearable
                        />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Assigned to :</Label>
                        <SelectEmployee value={formSearch.employeeObject} isClearable={true} onChange={(employeeObject) => {
                            setDataForm({
                                ...formSearch,
                                employeeObject: employeeObject
                            })
                        }} />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Status :</Label>
                        <ReactSelect
                            options={statusOptions}
                            onChange={(option) => {
                                setDataForm({
                                    ...formSearch,
                                    returned: option.value
                                })
                            }}
                            value={statusOptions.filter(option => option.value === formSearch.returned)}
                        />
                    </FormGroup>
                </div>
            </Row>
            <Row className="mb-2">
                <Col>
                    <div>
                        <Button
                            type="button"
                            color="info"
                            className="btn-rounded  mb-2 me-2"
                            onClick={(e) => {
                                onSeach({
                                    ...formSearch,
                                    employeeId: formSearch.employeeObject?.value ?? ""
                                })
                            }}
                        >
                            <i className="bx bx-search-alt me-1" />
                            Search
                        </Button>
                        {canAccessList() ? <ButtonExport onClick={async (e) => {
                                await onExport()
                            }} /> : null}
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

SearchHistory.propTypes = {
    onSeach: PropTypes.func.isRequired,
    onError: PropTypes.func,
    onExport: PropTypes.func,
}

export default SearchHistory