import PropTypes from "prop-types"
import React, { useState, useEffect, useRef, useMemo } from "react"
import { Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import { useFormik } from "formik";
import * as Yup from "yup";
import { reqChangePasswordUser } from "helpers/fakebackend_helper";
import ReactSelect from "react-select";

const ChangePassword = (props) => {
    const { onSuccess, user, isShow, toggle } = props
    const [otherErrorMessage, setOtherErrorMessage] = useState("")

    const [userInfo, setUserInfo] = useState({
        id: "",
        password: "",
        confirm_password: ""
    })

    const handleUpdateState = () => {
        const userId = user._id || ""
        setUserInfo({
            id: userId,
        })
    }

    useEffect(() => {
        handleUpdateState()
    }, [user])

    useMemo(() => {
        handleUpdateState()
    }, [])

    let validationInitValue = {
        id: (userInfo && userInfo.id) || '',
        password: (userInfo && userInfo.password) || '',
        confirm_password: (userInfo && userInfo.confirm_password) || '',
    }

    let validationRule = {
        password: Yup.string().required("Password is required")
            .min(6, "Password length must be on range 6 to 256 character")
            .max(255, "Password length must be on range 6 to 256 character")
            .oneOf([Yup.ref('confirm_password'), null], "Password confirm do not match")
    }
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: validationInitValue,
        validationSchema: Yup.object(validationRule),
        onSubmit: async (values) => {
            let data = {
                id: values.id,
                password: values.password || "",
                confirm_password: values.confirm_password || "",
            };

            try {
                await reqChangePasswordUser(data)
                await onSuccess("Password is changed")
                validation.resetForm();
                toggle();
            } catch (error) {
                return setOtherErrorMessage(error.message || error)
            }
        },
    });

    const onToggle = () => {
        toggle()
    }

    //Status
    const optionsStatus = [
        { value: "true", label: "Active" },
        { value: "false", label: "Inactive" },
    ]
    return (
        <React.Fragment>
            <Modal isOpen={isShow} toggle={onToggle}>
                <ModalHeader toggle={onToggle} tag="h4">Change Password</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row form>
                            <Col className="col-12">
                                <div className="mb-3">
                                    {otherErrorMessage ? (<p className="alert alert-danger">{otherErrorMessage}</p>) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Password</Label>
                                    <Input
                                        name="password"
                                        type="password"
                                        value={validation.values.password || ""}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setUserInfo({
                                                ...userInfo,
                                                password: e.target.value
                                            })
                                        }}
                                        invalid={validation.errors.password ? true : false}
                                    />
                                    {validation.errors.password ? (
                                        <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Confirm Password</Label>
                                    <Input
                                        name="confirm_password"
                                        type="password"
                                        value={validation.values.confirm_password || ""}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setUserInfo({
                                                ...userInfo,
                                                confirm_password: e.target.value
                                            })
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn btn-success save-user"
                                    >
                                        Change
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

ChangePassword.propTypes = {
    user: PropTypes.object.isRequired,
    onSuccess: PropTypes.func,
    isShow: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired
}

export default ChangePassword