import {Button, Col, Row} from "reactstrap";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {TEMPLATE_NEW_PRODUCT} from "../constant";
import Product from "./Product";
import css from "./Product.module.scss"
import {getExchangeRate} from "../../../helpers/utils";
const EditProducts = ({batch, onChange, validate, batchStatus, exchangeRate, units}) => {
    const products = batch?.products ?? []
    const addRow = () => {
        const _products = [...products]
        _products.unshift(TEMPLATE_NEW_PRODUCT)
        onChange(_products)
    }

    const onCancel = (index) => {
        let _products = [...products]
        let product = {..._products[index]}
        product.isCanceled = 1
        product.canRevertCancel = true
        _products[index] = product
        onChange(_products)
    }

    const onRevertCancel = (index) => {
        let _products = [...products]
        let product = {..._products[index]}
        product.isCanceled = 0
        product.canRevertCancel = false
        _products[index] = product
        onChange(_products)
    }

    const onDelete = (index) => {
        let _products = products.filter((product, _index) => {
            return index!== _index
        })

        if(!_products.length) {
            _products.push(TEMPLATE_NEW_PRODUCT)
        }

        onChange(_products)
    }

    useEffect(() => {
        if(!products.length) {
            addRow()
        }
    }, [])

    const onChangeProduct = (product, index) => {
        let _products = [...products]
        _products[index] = product
        onChange(_products)
    }

    const validateErrorProducts = validate?.errors?.products ?? []

    //covert shipping fee to peso and calc shipping fee per product
    const splitShippingFee = () => {
        const listProducts = batch?.products ?? []
        let totalProductQuantity = 0
        listProducts.forEach((product) => {
            const importQuantity = product?.import?.quantity ?? 0
            return totalProductQuantity +=  Number(importQuantity)
        })
        const _exchangeRate = getExchangeRate(units, exchangeRate, batch?.shippingFeeUnit)
        if(totalProductQuantity === 0) {
            return 0
        }
        return (_exchangeRate * batch?.shippingFee ?? 0) / totalProductQuantity
    }

    return <React.Fragment>
        <Row>
            <Col sm="9">
                <p className="fs-5 fw-bold">Products</p>
            </Col>
            <Col sm="3" className={css.textAlignRight}>
                <Button onClick={addRow} color="success">Add Product</Button>
            </Col>
        </Row>
        {
            products.map((product, index) => {
                const hasError = validateErrorProducts[index] !== null && typeof validateErrorProducts[index] !== "undefined" ? validateErrorProducts[index] : {}

                return <Product key={`batch_product_${index}_${product?._id ?? ""}`}
                                product={product}
                                onDelete={() => onDelete(index)}
                                onCancel={() => onCancel(index)}
                                onRevertCancel={() => onRevertCancel(index)}
                                onChange={(_product) => onChangeProduct(_product, index)}
                                validate={hasError ?? {}}
                                status={batchStatus}
                                shippingFeePerProduct={splitShippingFee()}
                                units={units}
                                exchangeRate={exchangeRate}
                />
            })
        }
    </React.Fragment>
}

EditProducts.propTypes = {
    onChange: PropTypes.func,
    validate: PropTypes.object,
    batch: PropTypes.object,
    exchangeRate:PropTypes.object.isRequired,
    batchStatus: PropTypes.number.isRequired,
    units: PropTypes.arrayOf(PropTypes.object).isRequired,
}

EditProducts.defaultProps = {
    onChange: () => {},
    batch: null,
}

export default EditProducts