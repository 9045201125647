import CustomPagination from "components/ultils/CustomPagination"
import React, { useEffect, useRef, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { MetaTags } from "react-meta-tags"
import { Link, withRouter } from "react-router-dom"
import { Badge, Card, CardBody, Col, Container, Row, Spinner, Toast, ToastBody, ToastHeader, UncontrolledTooltip } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { reqDeleteRole, reqGetListRoles } from "helpers/fakebackend_helper"
import DeleteModal from "components/Common/DeleteModal"
import SearchRole from "./SearchRole"
import { htmlDecode } from "helpers/utils"

const RoleManagerment = (props) => {
  const refPagination = useRef()
  let FLAG_FIRT_LOAD = useRef(false)
  const [roleListConf, setRoleListConf] = useState({
    page: 1,
    limit: 20,
    search: {
        roleName: "",
    }
  });

  const [roles, setRoles] = useState({
    data: [],
    totalItems: 0
  })

  const [role, setRole] = useState({})

  const historyTableColumn = [
    {
      dataField: "roleName",
      text: "Name",
      formatter: (rowContent, row) => {
        return htmlDecode(rowContent)
      }
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3"><Link
              to={`/role/edit/${row._id}`}
              className="text-success"
            >
              <i className="mdi mdi-pencil font-size-18" id={`edittooltip${row._id}`} />
              <UncontrolledTooltip placement="top" target={`edittooltip${row._id}`}>Edit</UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onDelete(row)}
            >
              <i className="mdi mdi-delete font-size-18" id={`deletetooltip${row._id}`} />
              <UncontrolledTooltip placement="top" target={`deletetooltip${row._id}`}>Delete</UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ]

  const [toast, setToast] = useState({
    message: "",
    isOpen: false
  })

  const autoCloseToast = (message) => {
    setToast({
      message: message,
      isOpen: true
    })
    setTimeout(() => {
      setToast({
        message: "",
        isOpen: false
      })
    }, 5000);
  }

  const pageOptions = {
    sizePerPage: roleListConf.limit,
    totalSize: roles.totalItems, // replace later with size(roles),
    custom: true,
  };

  const onChangePageNumber = (pageNumber) => {
    setRoleListConf({
      ...roleListConf,
      page: parseInt(pageNumber)
    })
  }

  const onSearch = (data) => {
    setRoleListConf({
      ...roleListConf,
      search: {
        ...roleListConf.search,
        ...data
      },
      page: 1
    })

    refPagination.current.refreshCurrentPage()
  }

  const onSearchError = (message) => {
    autoCloseToast(message)
  }

  const getListRoles = async () => {
    try {
      const filter = {
        params: {
          page: roleListConf.page,
          roleName: roleListConf.search.roleName || "",
        }
      }

      const result = await reqGetListRoles(filter)
      FLAG_FIRT_LOAD.current = true

      setRoles({

        ...roles,
        data: result.items,
        totalItems: result.totalItems
      })

    } catch (error) {
      autoCloseToast(error.message || error)
    }
  }

  useEffect(async () => {
    await getListRoles()
  }, [])

  useEffect(async () => {
    if (FLAG_FIRT_LOAD.current) {
      await getListRoles()
    }
  }, [roleListConf])

  //delete
  const [deleteModal, setDeleteModal] = useState(false)
  const onDelete = async (role) => {
    setRole(role)
    setDeleteModal(true)
  }

  const handleDelete = async () => {
    try {
      await reqDeleteRole(role?._id)
      await getListRoles()
      setDeleteModal(false)
    } catch (error) {
      autoCloseToast(error.message || error)
      setDeleteModal(false)
    }
  }

  return (
    <React.Fragment>
      <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: "1005" }}>
        <Toast isOpen={toast.isOpen}>
          <ToastHeader icon={<Spinner type="grow" size="sm" color="danger" />} toggle={() => {
            setToast({ ...toast, isOpen: !toast.isOpen })
          }}>Error</ToastHeader>
          <ToastBody>{toast.message}</ToastBody>
        </Toast>
      </div>
      {deleteModal ? <DeleteModal show={deleteModal} onDeleteClick={handleDelete} onCloseClick={() => {
          setDeleteModal(false)
          setRole({})
        }}
      /> : null}
      <div className="page-content">
        <MetaTags>
          <title>Role Managerment</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Role Managerment" breadcrumbItem="" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="_id"
                    columns={historyTableColumn}
                    data={roles.data}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={roles.data}
                        columns={historyTableColumn}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <SearchRole onSearch={onSearch} onError={onSearchError}/>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    basseted={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>

                              </Col>
                            </Row>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <CustomPagination ref={refPagination} onChangePage={onChangePageNumber} sizeOfPerPage={pageOptions.sizePerPage} totalItem={pageOptions.totalSize} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(RoleManagerment)