import { canAccess, getCodeActionCreate, getCodeActionDelete, getCodeActionDetail, getCodeActionEdit, getCodeActionList, getCodeModulePayment } from "helpers/Permission"

const moduleCode = getCodeModulePayment()

export const canAccessEdit = () => {
    const actionCode = getCodeActionEdit()
    return canAccess(moduleCode, actionCode)
}

export const canAccessCreate = () => {
    const actionCode = getCodeActionCreate()
    return canAccess(moduleCode, actionCode)
}

export const canAccessDetail = () => {
    const actionCode = getCodeActionDetail()
    return canAccess(moduleCode, actionCode)
}

export const canAccessDelete = () => {
    const actionCode = getCodeActionDelete()
    return canAccess(moduleCode, actionCode)
}
export const canAccessList = () => {
    const actionCode = getCodeActionList()
    return canAccess(moduleCode, actionCode)
}

