import React, { useEffect, useRef, useState } from "react"
import { htmlDecode } from "../../helpers/utils"
import { canAccessDelete, canAccessEdit } from "./Permission"
import { Link, useHistory } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Badge,
  Spinner,
} from "reactstrap"
import {
  getListPayout,
  getListPayoutById,
  getBankCodes,
} from "../../helpers/fakebackend_helper"
import moment from "moment"
import DeleteModal from "../../components/Common/DeleteModal"
import { MetaTags } from "react-meta-tags"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import CustomPagination from "../../components/ultils/CustomPagination"
import Search from "./components/Search"
import CustomToast from "../../components/ultils/CustomToast"
import CreateModal from "./components/CreateModal"

const PayoutManagement = () => {
  const refPagination = useRef()
  let FLAG_FIRT_LOAD = useRef(false)
  const [payoutFilter, setPayoutFilter] = useState({
    page: 1,
    limit: 15,
    search: {
      tranId: "",
      bankCode: "",
      receiverName: "",
      receiverCardNumber: "",
      status: "",
      fromDate: null,
      toDate: null,
    },
  })
  const [showModal, setShowModal] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [selectedTrans, setSelectedTrans] = useState({})
  const [refresh, setRefresh] = useState(false)

  const [payouts, setPayouts] = useState({
    data: [],
    totalItems: 0,
  })

  const [payout, setPayout] = useState({})
  const toggleModal = () => setShowModal(!showModal)

  const getPayoutById = async id => {
    const filter = {
      params: {
        id: id,
      },
    }
    const result = await getListPayoutById(filter)
    setSelectedTrans(result?.items)
    // console.log(result)
  }

  const payoutTableColumn = [
    {
      dataField: "tranId",
      text: "Transaction Id",
    },
    {
      dataField: "receiverName",
      text: "Receiver Name",
    },
    {
      dataField: "receiverCardNumber",
      text: "Receiver Card Number",
    },
    {
      dataField: "amount",
      text: "Amount",
    },
    {
      dataField: "bankCode",
      text: "Bank",
    },
    {
      dataField: "partnerTranId",
      text: "Partner Tran Id",
    },
    {
      dataField: "circlePayMessage",
      text: "Circlepay Message",
    },
    {
      dataField: "status",
      text: "Status",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Badge
          className={`font-size-12 badge-soft-${
            row.status == "00" || row.status == "05"
              ? "success"
              : row.status == "04"
              ? "warning"
              : "danger"
          }`}
          pill
        >
          {row.status == "00"
            ? "Success"
            : row.status == "01"
            ? "Processing"
            : row.status == "02"
            ? "Refunded"
            : row.status == "04"
            ? "Pending"
            : "Accepted"}
        </Badge>
      ),
    },
    {
      dataField: "createdAt",
      text: "Date Created",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <React.Fragment>
          {moment(row.createdAt).format("YYYY-MM-DD hh:mm")}
        </React.Fragment>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3">
            {canAccessEdit() ? (
              row.status === "04" ?
              <Link
                to="#"
                className="text-primary"
                onClick={() => {
                  getPayoutById(row._id)
                  setIsUpdate(true)
                  toggleModal()
                }}
              >
                <i
                  className="mdi mdi-pencil font-size-18"
                  id={`edittooltip${row._id}`}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`edittooltip${row._id}`}
                >
                  Edit
                </UncontrolledTooltip>
              </Link>
              : null
            ) : null}
          </div>
        </>
      ),
    },
  ]

  const [toast, setToast] = useState({
    message: "",
    isOpen: false,
    title: "Error",
    closeAfter: 5000,
  })

  const autoCloseToast = message => {
    setToast({
      message: message,
      isOpen: true,
    })
    setTimeout(() => {
      setToast({
        message: "",
        isOpen: false,
      })
    }, 5000)
  }

  const pageOptions = {
    sizePerPage: payoutFilter.limit,
    totalSize: payouts.totalItems, // replace later with size(partners),
    custom: true,
  }

  const onChangePageNumber = pageNumber => {
    setPayoutFilter({
      ...payoutFilter,
      page: parseInt(pageNumber),
    })
  }

  const onSearch = data => {
    setPayoutFilter({
      ...payoutFilter,
      search: {
        ...payoutFilter.search,
        ...data,
      },
      page: 1,
    })

    refPagination.current.refreshCurrentPage()
  }

  const onSearchError = message => {
    autoCloseToast(message)
  }

  const [bankCodes, setBankCodes] = useState([])
  const getBankCodeList = async () => {
    const result = await getBankCodes()
    let ibarr = []
    if (result?.data?.error_code == "00") {
      Object.entries(result?.data?.result).forEach(([key, val]) => {
        ibarr.push({
          label: key,
          value: val,
        })
      })
    }
    setBankCodes(ibarr)
  }

  const getListPayouts = async () => {
    try {
      const filter = {
        params: {
          page: payoutFilter.page,
          tranId: payoutFilter.search.tranId || "",
          bankCode: payoutFilter.search.bankCode || "",
          receiverName: payoutFilter.search.receiverName || "",
          receiverCardNumber: payoutFilter.search.receiverCardNumber || "",
          status: payoutFilter.search.status || "",
          fromDate: payoutFilter.search.fromDate || null,
          toDate: payoutFilter.search.toDate || null,
        },
      }

      const result = await getListPayout(filter)
      FLAG_FIRT_LOAD.current = true

      setPayouts({
        ...payouts,
        data: result.items,
        totalItems: result.totalItems,
      })

      setPayoutFilter({
        ...payoutFilter,
        page: result?.page ?? 1,
      })
    } catch (error) {
      autoCloseToast(error.message || error)
    }
  }

  useEffect(async () => {
    await getListPayouts()
    getBankCodeList()
  }, [])

  useEffect(async () => {
    if (FLAG_FIRT_LOAD.current) {
      await getListPayouts()
    }
  }, [payoutFilter.search, payoutFilter.page, refresh])

  const navigate = useHistory()
  const onCreateClick = () => {
    setIsUpdate(false)
    toggleModal()
  }

  //delete
  const [deleteModal, setDeleteModal] = useState(false)
  const onDelete = async data => {
    setPayout(data)
    setDeleteModal(true)
  }

  const handleDelete = async () => {
    try {
      await reqDeleteBatch(payout?._id)
      await getListPayouts()
      setDeleteModal(false)
    } catch (error) {
      autoCloseToast(error.message || error)
      setDeleteModal(false)
    }
  }

  let loading = <div className="loader">No data found.</div>

  return (
    <React.Fragment>
      <CustomToast
        message={toast.message}
        onToggle={() => setToast({ ...toast, isOpen: !toast.isOpen })}
        title={toast.title}
        color="danger"
        isOpen={toast.isOpen}
        closeAfterMilSec={toast.closeAfter}
      />
      {deleteModal ? (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => {
            setDeleteModal(false)
            setPayout({})
          }}
        />
      ) : null}
      <div className="page-content">
        <MetaTags>
          <title>Payout Management</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Payout Manager"
            breadcrumbItem="Transactions"
          />
          <CreateModal
            isOpen={showModal}
            toggle={() => {
              toggleModal()
              setRefresh(!refresh)
            }}
            title={isUpdate ? "Update Transaction" : "Create New Transaction"}
            isUpdate={isUpdate}
            selected={selectedTrans}
            bankCodes={bankCodes}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="_id"
                    columns={payoutTableColumn}
                    data={payouts.data || []}
                  >
                    {({ paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={payouts.data}
                        columns={payoutTableColumn}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Search
                              onSearch={onSearch}
                              onError={onSearchError}
                              onCreate={onCreateClick}
                              bankCodes={bankCodes}
                            />
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    basseted={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    noDataIndication={() => {
                                      return loading
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <CustomPagination
                                ref={refPagination}
                                onChangePage={onChangePageNumber}
                                sizeOfPerPage={pageOptions.sizePerPage}
                                totalItem={pageOptions.totalSize}
                              />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PayoutManagement
