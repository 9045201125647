import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap"
import { canAccessCreate } from "./Permission"

const SearchPartner = (props) => {
    const { onSearch, onError, onCreate } = props

    const [formSearch, setDataForm] = useState({
        providerName: "",
        bankAccountNumber: "",
        phoneNumber: "",
        description: "",
    })

    return (
        <React.Fragment>
            <Row>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Provider Name :</Label>
                        <Input
                            name="providerName"
                            type="text"
                            value={formSearch.providerName || ""}
                            onChange={(e) => setDataForm({...formSearch, providerName: e.target.value})}
                            autoComplete="off"
                        />
                    </FormGroup>
                </div>
                {/* <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Bank Account Number :</Label>
                        <Input
                            name="bankAccountNumber"
                            type="text"
                            value={formSearch.bankAccountNumber || ""}
                            onChange={(e) => setDataForm({...formSearch, bankAccountNumber: e.target.value})}
                            autoComplete="off"
                        />
                    </FormGroup>
                </div> */}
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Phone Number :</Label>
                        <Input
                            name="phoneNumber"
                            type="text"
                            value={formSearch.phoneNumber || ""}
                            onChange={(e) => setDataForm({...formSearch, phoneNumber: e.target.value})}
                            autoComplete="off"
                        />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Description :</Label>
                        <Input
                            name="description"
                            type="text"
                            value={formSearch.description || ""}
                            onChange={(e) => setDataForm({...formSearch, description: e.target.value})}
                            autoComplete="off"
                        />
                    </FormGroup>
                </div>
            </Row>
            <Row className="mb-2">
                <Col>
                    <div>
                        <Button
                            type="button"
                            color="info"
                            className="btn-rounded  mb-2 me-2"
                            onClick={(e) => {
                                onSearch(formSearch)
                            }}
                        >
                            <i className="bx bx-search-alt me-1" />
                            Search
                        </Button>
                        {canAccessCreate() ? <Button
                            type="button"
                            color="success"
                            className="btn-rounded  mb-2 me-2"
                            onClick={onCreate}
                        >
                            <i className="mdi mdi-plus me-1" />
                            Add New Partner
                        </Button> : null}
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

SearchPartner.propTypes = {
    onSearch: PropTypes.func.isRequired,
    onError: PropTypes.func,
    onCreate: PropTypes.func
}

export default SearchPartner