import PropTypes from "prop-types"
import React, { useState } from "react"
import ReactSelect from "react-select"
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap"
import { canAccessCreate } from "./Permission"
import ImportEmployee from "./components/ImportEmployee";

const SearchEmployee = (props) => {
    const {onCreate, onSearch} = props
    const [formSearch, setDataForm] = useState({
        firstName: "",
        lastName: "",
        idNumber: "",
        code: "",
        status: ""
    })
    const statusOptions = [
        { value: "", label: "Choose status" },
        { value: "true", label: "Active" },
        { value: "false", label: "Inactive" }
    ]

    const [isShowModalImport, setIsShowModalImport] = useState(false)
    const toggleModalImport = () => {
        setIsShowModalImport(!isShowModalImport)
    }

    return (
        <React.Fragment>
            <ImportEmployee isShow={isShowModalImport} toggle={toggleModalImport} />
            <Row>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>First name :</Label>
                        <Input
                            name="firstName"
                            type="text"
                            value={formSearch.firstName}
                            autoComplete="off"
                            onChange={(e) => {
                                setDataForm({
                                    ...formSearch,
                                    firstName: e.target.value
                                })
                            }}
                        />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Last name :</Label>
                        <Input
                            name="lastName"
                            type="text"
                            value={formSearch.lastName}
                            autoComplete="off"
                            onChange={(e) => {
                                setDataForm({
                                    ...formSearch,
                                    lastName: e.target.value
                                })
                            }}
                        />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>ID Number :</Label>
                        <Input
                            name="idNumber"
                            type="text"
                            value={formSearch.idNumber}
                            autoComplete="off"
                            onChange={(e) => {
                                setDataForm({
                                    ...formSearch,
                                    idNumber: e.target.value
                                })
                            }}
                        />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Code :</Label>
                        <Input
                            name="code"
                            type="text"
                            value={formSearch.code}
                            autoComplete="off"
                            onChange={(e) => {
                                setDataForm({
                                    ...formSearch,
                                    code: e.target.value
                                })
                            }}
                        />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Status :</Label>
                        <ReactSelect
                            options={statusOptions}
                            onChange={(option) => {
                                setDataForm({
                                    ...formSearch,
                                    status: option.value
                                })
                            }}
                            value={statusOptions.filter(option => option.value === formSearch.status)}
                        />
                    </FormGroup>
                </div>
            </Row>
            <Row className="mb-2">
                <Col>
                    <div>
                        <Button
                            type="button"
                            color="info"
                            className="btn-rounded  mb-2 me-2"
                            onClick={(e) => {
                                onSearch(formSearch)
                            }}
                        >
                            <i className="bx bx-search-alt me-1" />
                            Search
                        </Button>
                        {canAccessCreate() ? <Button
                            type="button"
                            color="success"
                            className="btn-rounded  mb-2 me-2"
                            onClick={onCreate}
                        >
                            <i className="mdi mdi-plus me-1" />
                            Add New Asset
                        </Button> : null}
                        {canAccessCreate() ? <Button
                            type="button"
                            color="warning"
                            className="btn-rounded mb-2 me-2"
                            onClick={toggleModalImport}
                        >
                            <i className="mdi mdi-inbox-arrow-up me-1" />
                            Import
                        </Button> : null }
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

SearchEmployee.propTypes = {
    onSearch: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
}

export default SearchEmployee