import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import {
  canAccessToModule,
  getCodeModuleAsset,
  getCodeModuleAssetHistory,
  getCodeModuleAssetType,
  getCodeModuleDepartment,
  getCodeModuleEmployee,
  getCodeModulePartner,
  getCodeModulePayment,
  getCodeModulePaymentType,
  getCodeModuleRole,
  getCodeModuleUser,
  getCodeModuleWorkingTimes,
  getCodeModulePayout,
  getCodeModuleGame,
  getCodeModuleImport_Category, getCodeModuleBATCH,
} from "helpers/Permission";

const SidebarContent = props => {
  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;
    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }

      if (!matchingMenuItem) {
        //check contain
        let pathLength = 0
        for (let i = 0; i < items.length; ++i) {
          if (pathName.includes(items[i].pathname)) {
            const temp = items[i].pathname.length
            if (temp > pathLength) {
              pathLength = temp
              matchingMenuItem = items[i];
            }
          }
        }
      }

      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  const renderAssetManager = () => {
    const assetModuleCode = getCodeModuleAsset()
    const assetTypeModuleCode = getCodeModuleAssetType()
    const assetHistoryModuleCode = getCodeModuleAssetHistory()
    const partnerModuleCode = getCodeModulePartner()

    const canAccessAsset = canAccessToModule(assetModuleCode)
    const canAccessAssetType = canAccessToModule(assetTypeModuleCode)
    const canAccessAssetHistory = canAccessToModule(assetHistoryModuleCode)
    const canAccessPartner = canAccessToModule(partnerModuleCode)

    const canAccessOneOf = canAccessAsset || canAccessAssetType || canAccessAssetHistory || canAccessPartner

    if (!canAccessOneOf) {
      return null
    }

    return (
      <React.Fragment>
        <li>
          <Link to="/#asset" className="has-arrow">
            <i className="bx bx-file"></i>
            <span>Asset Manager</span>
          </Link>
          <ul className="sub-menu">
            {
              canAccessAsset ? (
                <li>
                  <Link to="/asset" className="">
                    <i className="bx bx-file"></i>
                    <span>Assets List</span>
                  </Link>
                </li>
              ) : null
            }
            {
              canAccessAssetType ? (
                <li>
                  <Link to="/asset-type" className="">
                    <i className="mdi mdi-format-list-bulleted-type"></i>
                    <span>Asset Types</span>
                  </Link>
                </li>
              ) : null
            }
            {
              canAccessAssetHistory ? (
                <li>
                  <Link to="/history" className="">
                    <i className="bx bx-history"></i>
                    <span>Assigned Asset Histories</span>
                  </Link>
                </li>
              ) : null
            }
            {
              canAccessPartner ? (
                <li>
                  <Link to="/asset-partner" className="">
                    <i className="bx bx-archive"></i>
                    <span>Partners</span>
                  </Link>
                </li>
              ) : null
            }
          </ul>
        </li>
      </React.Fragment>
    )
  }

  const renderEmployeeManager = () => {
    const employeeModuleCode = getCodeModuleEmployee()
    const departmentTypeModuleCode = getCodeModuleDepartment()
    const workingTimeModuleCode = getCodeModuleWorkingTimes()

    const canAccessEmployee = canAccessToModule(employeeModuleCode)
    const canAccessDepartment = canAccessToModule(departmentTypeModuleCode)
    const canAccessWorkingTime = canAccessToModule(workingTimeModuleCode)

    const canAccessOneOf = canAccessEmployee || canAccessDepartment || canAccessWorkingTime

    if (!canAccessOneOf) {
      return null
    }

    return (
      <React.Fragment>
        <li>
          <Link to="/#employee" className="has-arrow ">
            <i className="mdi mdi-domain"></i>
            <span>Employees Manager</span>
          </Link>
          <ul className="sub-menu">
            {canAccessEmployee ? (<li>
              <Link to="/department" className="">
                <i className="mdi mdi-domain"></i>
                <span>Departments</span>
              </Link>
            </li>) : null}
            {canAccessDepartment ? (<li>
              <Link to="/employee" className="">
                <i className="mdi mdi-account-group"></i>
                <span>Employees</span>
              </Link>
            </li>) : null}
            {canAccessWorkingTime ? (<li>
              <Link to="/working-time" className="">
                <i className="mdi mdi-clock"></i>
                <span>Working time</span>
              </Link>
            </li>) : null}

          </ul>
        </li>
      </React.Fragment>
    )
  }
  const renderPaymentManager = () => {
    const codeModulePayment = getCodeModulePayment()
    const codeModulePaymentType = getCodeModulePaymentType()

    const canAccessPayment = canAccessToModule(codeModulePayment)
    const canAccessPaymentType = canAccessToModule(codeModulePaymentType)

    const canAccessOneOf = canAccessPayment || canAccessPaymentType

    if (!canAccessOneOf) {
      return null
    }

    return (
      <React.Fragment>
        <li>
          <Link to="/#payemt" className="has-arrow ">
            <i className="mdi mdi-cash-check"></i>
            <span>Payment Manager</span>
          </Link>
          <ul className="sub-menu">
            {canAccessPayment ? (<li>
              <Link to="/payment" className="">
                <i className="mdi mdi-cash-check"></i>
                <span>Payment</span>
              </Link>
            </li>) : null}
            {canAccessPaymentType ? (<li>
              <Link to="/payment-type" className="">
                <i className="mdi mdi-cash-plus"></i>
                <span>Payment type</span>
              </Link>
            </li>) : null}

          </ul>
        </li>
      </React.Fragment>
    )
  }
  
  const renderUserManager = () => {
    const codeModuleUser = getCodeModuleUser()
    const codeModuleRole = getCodeModuleRole()

    const canAccessUser = canAccessToModule(codeModuleUser)
    const canAccessRole = canAccessToModule(codeModuleRole)

    const canAccessOneOf = canAccessUser || canAccessRole

    if (!canAccessOneOf) {
      return null
    }

    return (
      <React.Fragment>
        <li>
          <Link to="/#user" className="has-arrow ">
            <i className="bx bx-user"></i>
            <span>User Manager</span>
          </Link>
          <ul className="sub-menu">
            {canAccessUser ? (<li>
              <Link to="/user" className="">
                <i className="bx bx-user"></i>
                <span>Users</span>
              </Link>
            </li>) : null}
            {canAccessRole ? (<li>
              <Link to="/role" className="">
                <i className="mdi mdi-shuffle-variant"></i>
                <span>Roles</span>
              </Link>
            </li>) : null}

          </ul>
        </li>
      </React.Fragment>
    )
  }

  const renderProductManager = () => {
    const codeModuleCategory = getCodeModuleImport_Category()

    const canAccessCategory = canAccessToModule(codeModuleCategory)

    const codeModuleUser = getCodeModuleUser()
    const codeModuleRole = getCodeModuleRole()

    const canAccessUser = canAccessToModule(codeModuleUser)
    const canAccessRole = canAccessToModule(codeModuleRole)

    const canAccessOneOf = canAccessUser || canAccessRole

    if (!canAccessOneOf) {
      return null
    }

    return (
        <React.Fragment>
          <li>
            <Link to="/#product-manager" className="has-arrow ">
              <i className="bx bx-user"></i>
              <span>Product Manager</span>
            </Link>
            <ul className="sub-menu">
              {canAccessUser ? (<li>
                <Link to="/product" className="">
                  <i className="bx bx-user"></i>
                  <span>Products</span>
                </Link>
              </li>) : null}
              {canAccessRole ? (<li>
                <Link to="/category" className="">
                  <i className="mdi mdi-shuffle-variant"></i>
                  <span>Category</span>
                </Link>
              </li>) : null}
              {canAccessCategory ? (<li>
                <Link to="/group-category" className="has-arrow">
                  <i className="mdi mdi-domain"></i>
                  <span>Group Category Manager</span>
                </Link>
              </li>) : null}
            </ul>
          </li>
        </React.Fragment>
    )
  }

  const renderImportManager = () => {
    const codeModuleBatch = getCodeModuleBATCH()
    const canAccessBatch = canAccessToModule(codeModuleBatch)

    const canAccessOneOf = canAccessBatch

    if (!canAccessOneOf) {
      return null
    }

    return (
        <React.Fragment>
          <li>
            <Link to="/#product-manager" className="has-arrow ">
              <i className="bx bx-anchor"></i>
              <span>Batch Manager</span>
            </Link>
            <ul className="sub-menu">
              {canAccessBatch ? (<li>
                <Link to="/batch" className="">
                  <i className="bx bx-anchor"></i>
                  <span>Batches</span>
                </Link>
              </li>) : null}
            </ul>
          </li>
        </React.Fragment>
    )
  }

  const renderPayoutManager = () => {
    const codeModulePayout = getCodeModulePayout()
    const codeModuleGaming = getCodeModuleGame()

    const canAccessPayout = canAccessToModule(codeModulePayout)
    const canAccessGaming = canAccessToModule(codeModuleGaming)

    const canAccessOneOf = canAccessPayout || canAccessGaming

    if (!canAccessOneOf) {
      return null
    }

    return (
        <React.Fragment>
          <li>
            <Link to="/#payout-manager" className="has-arrow ">
              <i className="bx bx-money"></i>
              <span>Payout Manager</span>
            </Link>
            <ul className="sub-menu">
              {canAccessPayout ? (<li>
                <Link to="/payout" className="">
                  <i className="bx bx-money"></i>
                  <span>Payout Transactions</span>
                </Link>
              </li>) : null}
              {canAccessGaming ? (<li>
                <Link to="/gaming-payout" className="">
                  <i className="mdi mdi-slot-machine-outline"></i>
                  <span>Gaming Payouts</span>
                </Link>
              </li>) : null}
            </ul>
          </li>
        </React.Fragment>
    )
  }
  

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {renderAssetManager()}
            {renderEmployeeManager()}
            {renderPaymentManager()}
            {renderProductManager()}
            {renderImportManager()}
            {renderUserManager()}
            {renderPayoutManager()}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
