import PropTypes from "prop-types"
import React, { useState } from "react"
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap"
import { canAccessCreate } from "../Permission"
import ReactSelect from "react-select"
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Swal from "sweetalert2"
import moment from "moment"

const Search = props => {
  const { onSearch, onCreate, bankCodes } = props

  const [formSearch, setDataForm] = useState({
    tranId: "",
    bankCode: "",
    receiverName: "",
    receiverCardNumber: "",
    status: "",
    fromDate: null,
    toDate: null,
  })
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const optionsStatus = [
    { value: "", label: "Choose status" },
    { value: "00", label: "Success" },
    { value: "01", label: "Processing" },
    { value: "02", label: "Refunded" },
    { value: "04", label: "Pending" },
    { value: "05", label: "Accepted" },
  ]

  const customOptions = [
    {
      value: "",
      label: "",
    },
    ...bankCodes,
  ]

  const handleStartdate = date => {
    if (!endDate) {
      setStartDate(date)
    } else {
      if (date > endDate && date !== "") {
        Swal.fire({
          title: "Warning",
          text: "Date From should not be greater than Date To",
          icon: "warning",
          confirmButtonText: "Okay",
        })
        // console.log("Date From should not be greater than Date To");
      } else {
        const start = moment(date)
        const end = moment(endDate)
        const daysRange = end.diff(start, "days")
        if (daysRange <= 32) {
          setStartDate(date)
          setDataForm({
            ...formSearch,
            fromDate: moment(date).format("YYYY-MM-DD"),
            toDate: moment(endDate).format("YYYY-MM-DD"),
          })
          
        } else {
          Swal.fire({
            title: "Warning",
            text: "Date range is until 30 days only",
            icon: "warning",
            confirmButtonText: "Okay",
          })
        }
      }
    }
  }

  const handleEnddate = date => {
    if (!startDate) {
      setEndDate(date)
    } else {
      if (date < startDate) {
        Swal.fire({
          title: "Warning",
          text: "Date To should not be less than Date From",
          icon: "warning",
          confirmButtonText: "Okay",
        })
      } else {
        const start = moment(startDate)
        const end = moment(date)
        const daysRange = end.diff(start, "days")
        if (daysRange <= 32) {
          setEndDate(date)
          setDataForm({
            ...formSearch,
            fromDate: moment(startDate).format("YYYY-MM-DD"),
            toDate: moment(date).format("YYYY-MM-DD"),
          })
          
        } else {
          Swal.fire({
            title: "Warning",
            text: "Max date range is 30 days only",
            icon: "warning",
            confirmButtonText: "Okay",
          })
        }
      }
    }
  }

  const resetFilter = () => {
    setDataForm({
      tranId: "",
      bankCode: "",
      receiverName: "",
      receiverCardNumber: "",
      status: "",
      fromDate: null,
      toDate: null,
    })
    setStartDate("")
    setEndDate("")
    onSearch({
      tranId: "",
      bankCode: "",
      receiverName: "",
      receiverCardNumber: "",
      status: "",
      fromDate: null,
      toDate: null,
    })
    
  }

  return (
    <React.Fragment>
      <Row>
        <div className="col-xl col-sm-6">
          <FormGroup className="mt-3 mb-0">
            <Label>Transaction Id</Label>
            <Input
              name="tranId"
              type="text"
              value={formSearch.tranId || ""}
              onChange={e =>
                setDataForm({ ...formSearch, tranId: e.target.value })
              }
              autoComplete="off"
            />
          </FormGroup>
        </div>
        <div className="col-xl col-sm-6">
          <FormGroup className="mt-3 mb-0">
            <Label>Receiver Name</Label>
            <Input
              name="receiverName"
              type="text"
              value={formSearch.receiverName || ""}
              onChange={e =>
                setDataForm({ ...formSearch, receiverName: e.target.value })
              }
              autoComplete="off"
            />
          </FormGroup>
        </div>
        <div className="col-xl col-sm-6">
          <FormGroup className="mt-3 mb-0">
            <Label>Receiver Card Number</Label>
            <Input
              name="receiverCardNumber"
              type="text"
              value={formSearch.receiverCardNumber || ""}
              onChange={e =>
                setDataForm({
                  ...formSearch,
                  receiverCardNumber: e.target.value,
                })
              }
              autoComplete="off"
            />
          </FormGroup>
        </div>

        <div className="col-xl col-sm-6">
          <FormGroup className="mt-3 mb-0">
            <Label>From :</Label>
            <ReactDatePicker
              selected={startDate}
              onChange={handleStartdate}
              className="form-control"
              placeholderText="Select date"
            />
          </FormGroup>
        </div>
        <div className="col-xl col-sm-6">
        <FormGroup className="mt-3 mb-0">
            <Label> To :</Label>
            <ReactDatePicker
              selected={endDate}
              onChange={handleEnddate}
              className="form-control"
              placeholderText="Select date"
            />
          </FormGroup>
        </div>
        <div className="col-xl col-sm-6">
          <FormGroup className="mt-3 mb-0">
            <Label>Status </Label>
            <ReactSelect
              options={optionsStatus}
              onChange={options => {
                setDataForm({
                  ...formSearch,
                  status: options?.value,
                })
              }}
              value={optionsStatus.filter(
                option => option?.value === formSearch.status
              )}
            />
          </FormGroup>
        </div>
        <div className="col-xl col-sm-6">
          <FormGroup className="mt-3 mb-0">
            <Label>Banks </Label>
            <ReactSelect
              defaultValue={customOptions[0]}
              options={customOptions}
              onChange={options => {
                setDataForm({
                  ...formSearch,
                  bankCode: options?.label,
                })
              }}
              value={bankCodes.filter(
                option => option?.label === formSearch.bankCode
              )}
            />
          </FormGroup>
        </div>
      </Row>
      <Row className="mb-2">
        <Col>
          <div>
            <Button
              type="button"
              color="info"
              className="btn-rounded  mb-2 me-2"
              onClick={() => {
                onSearch(formSearch)
              }}
            >
              <i className="bx bx-search-alt me-1" />
              Search
            </Button>
            <Button
              type="button"
              color="info"
              className="btn-rounded  mb-2 me-2"
              onClick={() => {
                resetFilter()
              }}
            >
              <i className="mdi mdi-rotate-right me-1" />
              Reset Filter
            </Button>
            {canAccessCreate() ? (
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={onCreate}
              >
                <i className="mdi mdi-plus me-1" />
                Create new payout
              </Button>
            ) : null}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

Search.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onError: PropTypes.func,
  onCreate: PropTypes.func,
  bankCodes: PropTypes.array,
}

export default Search
