import PropTypes from "prop-types"
import React, { useState, useEffect, useRef, useMemo } from "react"
import { Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, ModalFooter, Row, Spinner, Button } from "reactstrap"
import { useFormik } from "formik";
import * as Yup from "yup";
import { reqAddStocks } from "helpers/fakebackend_helper";
import ReactSelect from "react-select"; 
import Swal from "sweetalert2";
const AddStocks = (props) => {
    
    const { onSuccess, products, isShow, toggle, onClose } = props
    const [isLoading, setIsLoading] = useState(false);

    const swalSuccess = data => {
        Swal.fire({
          title: "Success",
          text: data?.mess,
          icon: "success",
          confirmButtonText: "Okay",
        }).then(res => {
          if (res.isConfirmed) {
            onToggle();
          }
        });
      };
    
      const swalError = data => {
        Swal.fire({
          title: "Error",
          text: data?.data || data?.mess,
          icon: "error",
          confirmButtonText: "Okay",
        }).then(res => {
          if (res?.isConfirmed) {
            console.log(data?.mess);
          }
        });
      };

    // console.log(products)
    const [otherErrorMessage, setOtherErrorMessage] = useState("")
    const isEdit = useRef(false)

    const [productInfo, setProductInfo] = useState({
        id: "",
        userName: "",
        website: "",
        branch: "",
        sku: "",
        quantity: 0,
        sendQuantity: 0
    })

    const handleUpdateState = () => {
        const productId = products._id || "" 
        isEdit.current = (productId !== null && productId !== "") 

        setProductInfo({
            id: productId || "",
            userName: localStorage.getItem("user") || "",
            sku: products.SKU || "",
            quantity: products.QUANTITY || 0,
            sendQuantity: products.sendQuantity || 0,
            website: products.website || "",
            branch: products.branch || ""
        })
    }

    useEffect(() => {
        handleUpdateState()
    }, [products])

    useMemo(() => {
        handleUpdateState()
    }, [])

    let validationInitValue = {
        id: (productInfo && productInfo.id) || '',
        userName: (productInfo && productInfo.userName) || '',
        sku: (productInfo && productInfo.sku) || '',
        quantity: (productInfo && productInfo.quantity) || 0,
        sendQuantity: (productInfo && productInfo.sendQuantity) || 0, 
        website: (productInfo && productInfo.website) || '',
        branch: (productInfo && productInfo.branch) || ''
    }

    let validationRule = { 
        quantity: Yup.number().moreThan(0).required("In-store quantity is required"), 
        sendQuantity: Yup.number().moreThan(0).max(Yup.ref("quantity")).required("sending quantity is required"), 
        website: Yup.string().required("Website is required"),
        branch: Yup.string().required("Branch is required")
       
    }

    const AddStocks = async values => {
        const data = await reqAddStocks(values) 
        if (data?.status == "fail") {
          swalError(data);
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        } else {
          swalSuccess(data);
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        }
    };
 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: validationInitValue,
        validationSchema: Yup.object(validationRule),
        onSubmit: async (values) => { 
            
            let data = {
                id: values.id,
                website: values.website,
                username: values.userName,
                branchid: values.branch, 
                sku: values.sku,
                quantity: values.quantity, 
                transferStock: parseInt(values.sendQuantity)
            };
            console.log("sending: ", data)

            try { 
                setIsLoading(true);
                await AddStocks(data) 
                await onSuccess()
                validation.resetForm();
                toggle();
            } catch (error) {
                setTimeout(() => {
                    setIsLoading(false);
                  }, 300);
                return setOtherErrorMessage(error.message || error)
            }
        },
    });

    const onToggle = () => {
        toggle()
    }
  
    //Selection
    let websiteOption = [
        { value: "VMART", label: "VMART" },
        { value: "ANNAM", label: "ANNAM" },
    ]

    let BranchOption = []

    if(productInfo.website == "VMART"){
        BranchOption = [
            { value: "5f7ff944bb947b79c6410a19", label: "Yakal Vmart" },
            { value: "60640d7a258389420901e239", label: "Sherwood Vmart" }
        ]
    }else if(productInfo.website == "ANNAM"){
        BranchOption = [
            { value: "61a86499586bcd26f54b8a67", label: "Yakal Annam" }
        ]
    }

    return (
        <React.Fragment>
            <Modal isOpen={isShow} toggle={onToggle}>
                <ModalHeader toggle={onToggle} tag="h4">Add Stocks</ModalHeader> 
                    <ModalBody> 
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        >
                            <Row form>
                                <Col className="col-12">
                                    <div className="mb-3">
                                        {otherErrorMessage ? (<p className="alert alert-danger">{otherErrorMessage}</p>) : null}
                                    </div>
                                    <div className="mb-3">
                                        <Label className="form-label">Website</Label>
                                        <ReactSelect
                                            options={websiteOption}
                                            onChange={(option) => {
                                                setProductInfo({
                                                    ...productInfo,
                                                    website: option.value
                                                })

                                                validation.validateField("website")
                                            }}
                                            onBlur={validation.handleBlur}
                                            value={websiteOption.filter(option => {
                                                const website = productInfo.website
                                                return website === option.value
                                            })}
                                        />

                                        <Input type="hidden" invalid={validation.errors.website ? true : false} />
                                        {validation.touched.website && validation.errors.website ? (
                                            <FormFeedback type="invalid">{validation.errors.website}</FormFeedback>
                                        ) : null}
                                    </div>
                                    <div className="mb-3">
                                        <Label className="form-label">Branches</Label>
                                        <ReactSelect
                                            options={BranchOption}
                                            onChange={(option) => {
                                                setProductInfo({
                                                    ...productInfo,
                                                    branch: option.value
                                                })

                                                validation.validateField("branch")
                                            }}
                                            onBlur={validation.handleBlur}
                                            value={BranchOption.filter(option => {
                                                const branch = productInfo.branch
                                                return branch === option.value
                                            })}
                                        />

                                        <Input type="hidden" invalid={validation.errors.branch ? true : false} />
                                        {validation.touched.branch && validation.errors.branch ? (
                                            <FormFeedback type="invalid">{validation.errors.branch}</FormFeedback>
                                        ) : null}
                                    </div>
                                    <div className="mb-3">
                                        <Label className="form-label">SKU</Label>
                                        <Input
                                            name="sku"
                                            type="text"
                                            value={validation.values.sku || ""}
                                            invalid={validation.errors.sku && validation.touched.sku ? true : false}
                                            autoComplete="off"
                                            onChange={(e) => {
                                                setProductInfo({
                                                    ...productInfo,
                                                    sku: e.target.value
                                                })
                                            }}
                                            disabled={isEdit.current}
                                        />
                                        {validation.errors.sku ? (
                                            <FormFeedback type="invalid">{validation.errors.sku}</FormFeedback>
                                        ) : null}
                                    </div> 
                                    <div className="mb-3">
                                        <Label className="form-label">In Store Quantity</Label>
                                        <Input
                                            name="quantity"
                                            type="number"
                                            value={validation.values.quantity || 0}
                                            invalid={validation.errors.quantity && validation.touched.quantity ? true : false}
                                            autoComplete="off"
                                            onChange={(e) => {
                                                setProductInfo({
                                                    ...productInfo,
                                                    quantity: e.target.value
                                                })
                                            }}
                                            disabled={isEdit.current}
                                        />
                                        {validation.errors.quantity ? (
                                            <FormFeedback type="invalid">{validation.errors.quantity}</FormFeedback>
                                        ) : null}
                                    </div>
                                    <div className="mb-3">
                                        <Label className="form-label">Send to Vmart / Annam</Label>
                                        <Input
                                            name="sendQuantity"
                                            type="number"
                                            value={validation.values.sendQuantity || ""}
                                            invalid={validation.errors.sendQuantity && validation.touched.sendQuantity ? true : false}
                                            autoComplete="off"
                                            step="1"
                                            onChange={(e) => { 
                                                setProductInfo({
                                                    ...productInfo,
                                                    sendQuantity: e.target.value
                                                })
                                            }}
                                        />
                                        {validation.errors.sendQuantity ? (
                                            <FormFeedback type="invalid">{validation.errors.sendQuantity}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>  
                            <Row>
                                <Col>
                                    <div className="text-start">
                                        <Button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={onClose}
                                        >
                                            Cancel {"  "}
                                            <i className="bx bx-window-close me-2"></i>
                                        </Button> 
                                    </div>
                                </Col>
                                <Col>
                                    <div className="text-end">  
                                        <Button type="submit" color="success" className="btn">
                                            Save {"  "}
                                            <i className="bx bx-save me-2"></i>
                                            {isLoading && <Spinner size="sm" />}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>          
            </Modal>
        </React.Fragment>
    )
}

AddStocks.propTypes = {
    products: PropTypes.any,
    onSuccess: PropTypes.func,
    isShow: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
}

export default AddStocks