import PropTypes from "prop-types";
import React, {useEffect, useMemo, useRef, useState} from "react";
import ReactSelect from "react-select";
import {reqGetSiteEmployee} from "../../../helpers/fakebackend_helper";

const SelectSite = (props) => {
    const {value, onChange} = props
    //Site
    const LIST_SITE = useRef([])
    const [otherErrorMessage, setOtherErrorMessage] = useState("")
    const selectedSite = () => {
        const siteSelected = LIST_SITE.current.filter(option =>  option.value === value)
        setSiteSelected(siteSelected ? siteSelected : null)
    }

    useMemo(async () => {
        try{
            const listSite = await reqGetSiteEmployee()
            for (const key in listSite) {
                LIST_SITE.current.push({value: key, label: listSite[key]})
            }

            selectedSite()
        }
        catch (e) {
            setOtherErrorMessage(e.message || e)
        }
    }, [])

    const [siteSelected, setSiteSelected] = useState(null)

    useEffect(selectedSite, [value])

    return (
        <React.Fragment>
            <ReactSelect
                onChange={(option) => {
                    onChange(option.value)
                }}
                options={LIST_SITE.current}
                name="site"
                value={siteSelected}
                isClearable
            />
            {otherErrorMessage ? (<p className="text text-danger" style={{marginTop: "5px"}}>{otherErrorMessage}</p>) : null}
        </React.Fragment>
    )
}

SelectSite.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onChange: PropTypes.func
}

export default SelectSite