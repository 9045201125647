import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react"
import ReactSelect from "react-select"
import { Col, Form, FormFeedback, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { useFormik } from "formik";
import * as Yup from "yup";
import { reqAssignAsset, reqGetListOptionEmployees } from "helpers/fakebackend_helper";
import { ceil, throttle } from "lodash";
import { dateToDB } from "helpers/utils";

let menuIsOpen = false
let PAGE = 1 //??? change state not working with property page

const AssetAsignment = (props) => {
    const { onSuccess, onError, asset, isShow, toggle } = props
    const [otherErrorMessage, setOtherErrorMessage] = useState("")
    const { employee } = asset
    const { id: employeeID, fullname: employeeName, code: employeeCode } = employee || {}

    const concatNameAndCode = (code, name) => {
        if (name && code) {
            return code ? `#${code} - ${name}` : name
        }
        if (name && !code) {
            return name
        }

        if (!name && code) {
            return `#${code}`
        }

        return ""
    }

    const defaultEmployeeOption = {
        value: employeeID || "",
        label: concatNameAndCode(employeeCode, employeeName) || ""
    }

    const defaultAssignInfo = {
        startUsingDate: dateToDB(new Date()),
        returnedDate: "",
        employee: defaultEmployeeOption.value,
        employeeObject: { ...defaultEmployeeOption }
    }
    const [assignInfo, setAssignInfo] = useState({ ...defaultAssignInfo })

    const validationInitValue = {
        id: (asset && asset.id) || '',
        name: (asset && asset.name) || '',
        startUsingDate: (assignInfo && assignInfo.startUsingDate) || '',
        returnedDate: (assignInfo && assignInfo.returnedDate) || '',
        employee: (assignInfo && assignInfo.employee) ? assignInfo.employee : "",
    }

    const validationRule = {
        id: Yup.string().required("Asset ID is required"),
        startUsingDate: Yup.date().required("Start using date is required"),
        returnedDate: Yup.date(),
        employee: Yup.string().required("Employee is required"),
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: validationInitValue,
        validationSchema: Yup.object(validationRule),
        onSubmit: async (values) => {
            let data = {
                id: values.id,
                startUsingDate: values.startUsingDate,
                employee: values.employee,
                returnedDate: values.returnedDate,
            };

            try {
                await reqAssignAsset(data)
                await onSuccess()
                validation.resetForm();
                toggle();
            } catch (error) {
                return setOtherErrorMessage(error.message || error)
            }
        },
    });

    const onToggle = () => {
        toggle()
    }

    const [optionEmployee, setEmployeeLoading] = useState({
        isLoading: false,
        options: [],
        textSearch: "",
        totalItems: 0
    })

    const [textSearch, changeTextSearch] = useState("")

    const getListEmployees = async (textSearch = "") => {
        if (!optionEmployee.isLoading && menuIsOpen) {
            setEmployeeLoading({
                ...optionEmployee,
                isLoading: true
            })
            const config = {
                params: {
                    name: textSearch,
                    page: PAGE
                }
            }

            const options = PAGE > 1 ? optionEmployee.options : []
            let totalItems = 0
            try {
                const res = await reqGetListOptionEmployees(config)
                totalItems = res.totalItems || 0
                res?.items.map((val) => {
                    const { _id: value, fullname: label, code } = val
                    options.push({ value, label: concatNameAndCode(code, label) })
                })
            } catch (error) {
                onError(error.message || error)
            }

            setEmployeeLoading({
                ...optionEmployee,
                options,
                isLoading: false,
                totalItems
            })
        }
    }

    const throttled = useRef(throttle(async (newValue) => {
        PAGE = 1
        await getListEmployees(newValue)
    }, 1000))

    useEffect(() => {
        throttled.current(textSearch)
    }, [textSearch])

    const handleOnScrollToBottom = async () => {
        if (!optionEmployee.isLoading) {
            const { totalItems } = optionEmployee
            const LIMIT = 20
            const totalPage = ceil(totalItems / LIMIT)
            const newPageNumber = (PAGE.current < totalPage ) ? (PAGE.current + 1) : PAGE.current
            if(newPageNumber != PAGE.current){
                PAGE.current = newPageNumber
                await getListEmployees()
            }
        }
    }

    const onMenuScrollToBottom = () => {
        handleOnScrollToBottom()
    }

    return (
        <React.Fragment>
            <Modal isOpen={isShow} toggle={onToggle}>
                <ModalHeader toggle={onToggle} tag="h4">Asset assignment</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row form>
                            <Col className="col-12">
                                <div className="mb-3">
                                    {otherErrorMessage ? (<p className="alert alert-danger">{otherErrorMessage}</p>) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Asset name</Label>
                                    <Input
                                        name="name"
                                        type="text"
                                        disabled={true}
                                        value={validation.values.name || ""}
                                        invalid={validation.errors.id ? true : false}
                                    />
                                    {validation.errors.id ? (
                                        <FormFeedback type="invalid">{validation.errors.id}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Assign to</Label>
                                    <ReactSelect
                                        // components={{Option: customOptionComponent}}
                                        blurInputOnSelect={false}
                                        isLoading={optionEmployee.isLoading}
                                        onInputChange={(text) => {
                                            changeTextSearch(text)
                                        }}
                                        onChange={(option) => {
                                            setAssignInfo({
                                                ...assignInfo,
                                                employee: option.value,
                                                employeeObject: option
                                            })
                                        }}
                                        onMenuOpen={async () => {
                                            menuIsOpen = true
                                            await getListEmployees()
                                        }}
                                        options={optionEmployee.options}
                                        name="employee"
                                        onBlur={(e) => {
                                            menuIsOpen = false
                                            validation.handleBlur(e)
                                        }}
                                        value={assignInfo.employeeObject}
                                        onMenuScrollToBottom={async () => {
                                            await onMenuScrollToBottom()
                                        }}
                                    />
                                    <Input type="hidden" invalid={validation.errors.employee ? true : false} />
                                    {validation.errors.employee ? (
                                        <FormFeedback type="invalid">{validation.errors.employee}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Start using date</Label>
                                    <InputGroup>
                                        <Flatpickr
                                            className="form-control d-block"
                                            placeholder="YYYY-MM-DD"
                                            options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                            }}
                                            onChange={(date, dateString) => {
                                                setAssignInfo({
                                                    ...assignInfo,
                                                    startUsingDate: dateString
                                                })
                                                validation.validateField("startUsingDate")
                                            }}
                                            name="startUsingDate"
                                            onBlur={validation.handleBlur}
                                            value={assignInfo.startUsingDate}
                                        />
                                    </InputGroup>
                                    <Input type="hidden" invalid={validation.touched.startUsingDate && validation.errors.startUsingDate ? true : false} />
                                    {validation.touched.startUsingDate && validation.errors.startUsingDate ? (
                                        <FormFeedback type="invalid">{validation.errors.startUsingDate}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Returned date</Label>
                                    <InputGroup>
                                        <Flatpickr
                                            className="form-control d-block"
                                            placeholder="YYYY-MM-DD"
                                            options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                            }}
                                            onChange={(date, dateString) => {
                                                setAssignInfo({
                                                    ...assignInfo,
                                                    returnedDate: dateString
                                                })
                                                validation.validateField("returnedDate")
                                            }}
                                            name="returnedDate"
                                            value={assignInfo.returnedDate}

                                        />
                                    </InputGroup>
                                    <Input type="hidden" invalid={validation.touched.returnedDate && validation.errors.returnedDate ? true : false} />
                                    {validation.touched.returnedDate && validation.errors.returnedDate ? (
                                        <FormFeedback type="invalid">{validation.errors.returnedDate}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn btn-success save-user"
                                    >
                                        Assign
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

AssetAsignment.propTypes = {
    asset: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        employee: PropTypes.object
    }).isRequired,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    isShow: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired
}

export default AssetAsignment