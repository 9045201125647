import React, {useEffect, useRef, useState} from "react";
import {ceil, throttle} from "lodash";
import {reqGetListOptionEmployees} from "../../../helpers/fakebackend_helper";
import PropTypes from "prop-types";
import SelectDepartment from "../../DepartmentManagerment/components/SelectDepartment";
import ReactSelect from "react-select";

const SelectEmployee = (props) => {
    const {value, onChange, isClearable} = props

    const page = useRef(1);
    const menuIsOpen = useRef(false)

    const throttled = useRef(throttle(async (callback) => {
        await callback()
    }, 1000))

    //Dropdow Assigned To
    const concatNameAndCode = (code, name) => {
        if (name && code) {
            return code ? `#${code} - ${name}` : name
        }
        if (name && !code) {
            return name
        }

        if (!name && code) {
            return `#${code}`
        }

        return ""
    }

    const [optionEmployee, setOptionEmployee] = useState({
        isLoading: false,
        options: [],
        textSearch: "",
        totalItems: 0
    })

    const [textSearch, changeTextSearch] = useState("")

    const getListEmployees = async (textSearch = "") => {
        if (!optionEmployee.isLoading && menuIsOpen.current) {
            setOptionEmployee({
                ...optionEmployee,
                isLoading: true
            })
            const config = {
                params: {
                    name: textSearch,
                    page: page.current
                }
            }

            const options = page.current > 1 ? optionEmployee.options : []
            let totalItems = 0
            try {
                const res = await reqGetListOptionEmployees(config)
                totalItems = res.totalItems || 0
                res?.items.map((val) => {
                    const {_id: value, fullname: label, code} = val
                    options.push({value, label: concatNameAndCode(code, label)})
                })
            } catch (error) {
                setOtherErrorMessage(error.message || error)
            }

            setOptionEmployee({
                ...optionEmployee,
                options,
                isLoading: false,
                totalItems
            })
        }
    }

    useEffect(() => {
        throttled.current(async () => {
            page.current = 1
            await getListEmployees(textSearch)
        })
    }, [textSearch])

    const handleOnScrollToBottom = async () => {
        if (!optionEmployee.isLoading) {
            const {totalItems} = optionEmployee
            const LIMIT = 20
            const totalPage = ceil(totalItems / LIMIT)
            const newPageNumber = (page.current < totalPage) ? (page.current + 1) : page.current
            if (newPageNumber != page.current) {
                page.current = newPageNumber
                await getListEmployees()
            }
        }
    }

    const [otherErrorMessage, setOtherErrorMessage] = useState("")

    return (
        <>
            <ReactSelect
                blurInputOnSelect={false}
                isLoading={optionEmployee.isLoading}
                onInputChange={(text) => {
                    changeTextSearch(text)
                }}
                onChange={(option) => {
                    onChange(option)
                }}
                onMenuOpen={async () => {
                    page.current = 1
                    menuIsOpen.current = true
                    await getListEmployees()
                }}
                options={optionEmployee.options}
                onBlur={(e) => {
                    menuIsOpen.current = false
                }}
                value={value}
                onMenuScrollToBottom={async (e) => {
                    await handleOnScrollToBottom()
                }}
                isClearable={isClearable ? isClearable : false}
            />
            {otherErrorMessage ? (
                <p className="text text-danger" style={{marginTop: "5px"}}>{otherErrorMessage}</p>) : null}
        </>
    )
}

SelectEmployee.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
        PropTypes.oneOf([null])
    ]),
    onChange: PropTypes.func,
    isClearable: PropTypes.bool
}

export default SelectEmployee