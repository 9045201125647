import PropTypes from "prop-types"
import React, { useState, useEffect, useRef, useMemo } from "react"
import { Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import { useFormik } from "formik";
import * as Yup from "yup";
import {  reqUpdateProductInfo } from "helpers/fakebackend_helper";
const EditProduct = (props) => {
    const { onSuccess, product, isShow, toggle } = props
    const [otherErrorMessage, setOtherErrorMessage] = useState("")
    const isEdit = useRef(false)
    //console.log(product)
    const [productInfo, setProductInfo] = useState({
        id: "",
        userName: "",
        productName:"",
        sku: "",
        barCode:"",
        entryPrice: "",
        quantity:"",
        regularPrice:""
    })

    const handleUpdateState = () => {

        const productId = product._id || ""
        const status = product.status ?? "true"
        isEdit.current = (productId !== null && productId !== "")

        setProductInfo({
            id: productId || "",
            userName: localStorage.getItem("user") || "",
            productName: product.PRODUCTNAME.VN || "",
            sku: product.SKU || "",
            barCode: product.BARCODE || "",
            entryPrice: product.ENTRYPRICE || 0,
            regularPrice: product.REGULARPRICE || 0,
            quantity: product.QUANTITY || 0
        })
    }

    useEffect(() => {
        handleUpdateState()
    }, [product])

    useMemo(() => {
        handleUpdateState()
    }, [])

    let validationInitValue = {
        id: (productInfo && productInfo.id) || '',
        productName: (productInfo && productInfo.productName) || '',
        sku: (productInfo && productInfo.sku) || '',
        barCode: (productInfo && productInfo.barCode) || '',
        quantity: (productInfo && productInfo.quantity) || '',
        entryPrice: (productInfo && productInfo.entryPrice) || '',

    }

    let validationRule = {
        entryPrice: Yup.string().required("Entry Price is required"),
        quantity: Yup.string().required("Quantity is required"),


    }
    //console.log(validationInitValue)
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: validationInitValue,
        validationSchema: Yup.object(validationRule),
        onSubmit: async (values) => {
            const roles = values.roles ?? []

            let data = {
                id: values.id,
                QUANTITY: values.quantity,
                ENTRYPRICE: values.entryPrice,
            };

            try {

                await reqUpdateProductInfo(data)
                await onSuccess()
                validation.resetForm();
                toggle();
            } catch (error) {
                return setOtherErrorMessage(error.message || error)
            }
        },
    });

    const onToggle = () => {
        toggle()
    }

    //Status
    const optionsStatus = [
        { value: "true", label: "Active" },
        { value: "false", label: "Inactive" },
    ]

    const systemAminOption = [
        { value: false, label: "No" },
        { value: true, label: "Yes" },
    ]

    return (
        <React.Fragment>
            <Modal isOpen={isShow} toggle={onToggle}>
                <ModalHeader toggle={onToggle} tag="h4">{"Edit Product"}</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row form>
                            <Col className="col-12">
                                <div className="mb-3">
                                    {otherErrorMessage ? (<p className="alert alert-danger">{otherErrorMessage}</p>) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Product Name</Label>
                                    <Input
                                        name="productName"
                                        type="text"
                                        disabled={true}
                                        value={validation.values.productName || ""}
                                        autoComplete="off"
                                    />
                                    {validation.errors.sku ? (
                                        <FormFeedback type="invalid">{validation.errors.sku}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">SKU</Label>
                                    <Input
                                        name="productName"
                                        type="text"
                                        value={validation.values.sku || ""}
                                        invalid={validation.errors.sku && validation.touched.sku ? true : false}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setProductInfo({
                                                ...productInfo,
                                                sku: e.target.value
                                            })
                                        }}
                                        disabled={true}
                                    />
                                    {validation.errors.sku ? (
                                        <FormFeedback type="invalid">{validation.errors.sku}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">BARCODE</Label>
                                    <Input
                                        name="barcode"
                                        type="text"
                                        value={validation.values.barCode || ""}
                                        invalid={validation.errors.barCode ? true : false}
                                        autoComplete="off"
                                        disabled={true}
                                        onChange={(e) => {
                                            setproductInfo({
                                                ...productInfo,
                                                barCode: e.target.value
                                            })
                                        }}
                                    />
                                    {validation.errors.barCode ? (
                                        <FormFeedback type="invalid">{validation.errors.barCode}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Quantity</Label>
                                    <Input
                                        name="lastName"
                                        type="text"
                                        value={validation.values.quantity || 0}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setProductInfo({
                                                ...productInfo,
                                                quantity: e.target.value
                                            })
                                        }}
                                        invalid={validation.errors.quantity ? true : false}
                                    />
                                    {validation.errors.quantity ? (
                                        <FormFeedback type="invalid">{validation.errors.quantity}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Entry Price</Label>
                                    <Input
                                        name="email"
                                        type="text"
                                        value={validation.values.entryPrice || 0}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setProductInfo({
                                                ...productInfo,
                                                entryPrice: e.target.value
                                            })
                                        }}
                                        invalid={validation.errors.entryPrice ? true : false}
                                    />
                                    {validation.errors.entryPrice ? (
                                        <FormFeedback type="invalid">{validation.errors.entryPrice}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn btn-success save-user"
                                    >
                                        {isEdit.current ? "Update" : "Create"}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

EditProduct.propTypes = {
    product: PropTypes.object.isRequired,
    isShow: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onSuccess: PropTypes.func
}

export default EditProduct