import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import AssetManagerment from "pages/AssetManagerment"
import AssetDetail from "pages/AssetManagerment/AssetDetail"
import HistoryManagerment from "pages/HistoryManagerment"
import DepartmentManagerment from "pages/DepartmentManagerment"
import DepartmentDetail from "pages/DepartmentManagerment/DepartmentDetail"
import EmployeeManagerment from "pages/EmployeeManagerment"
import EmployeeDetail from "pages/EmployeeManagerment/EmployeeDetail"
import UserManagerment from "pages/UserManagerment"
import AssetTypeManagerment from "pages/AssetTypeManagerment"
import PartnerManagerment from "pages/PartnerManagerment"
import DetailPartner from "pages/PartnerManagerment/DetailPartner"
import DetailAssetType from "pages/AssetTypeManagerment/DetailAssetType"
import PaymentType from "pages/Payment/PaymentType"
import Payment from "pages/Payment/PaymentManagerment"
import Error401 from "pages/Errors/Error401"
import RoleManagerment from "pages/RoleManagerment"
import EditRole from "pages/RoleManagerment/EditRole"
import WorkingTimeManagerment from "../pages/WorkingTime";
import ProductManagerment from "../pages/ProductManagerment";
import CategoryManagement from "../pages/ProductManagerment/GroupCategoryManagement";
import BatchManagement from "../pages/BatchManagement";
import BatchEdit from "../pages/BatchManagement/Edit";
import PayoutManagement from "pages/PayoutManagement";
import GameTransactions from "pages/GameTransactions";

const authProtectedRoutes = [
  { path: "/asset", component: AssetManagerment },
  { path: "/asset/detail/:id", component: AssetDetail },

  { path: "/history", component: HistoryManagerment },
  { path: "/department", component: DepartmentManagerment },
  { path: "/department/detail/:id", component: DepartmentDetail },
  { path: "/employee/", component: EmployeeManagerment },
  { path: "/employee/detail/:id", component: EmployeeDetail },
  { path: "/user", component: UserManagerment },

  { path: "/asset-type", component: AssetTypeManagerment },
  { path: "/asset-type/detail/:id", component: DetailAssetType },
  { path: "/asset-partner", component: PartnerManagerment },
  { path: "/asset-partner/detail/:id", component: DetailPartner },

  { path: "/payment-type", component: PaymentType },
  { path: "/payment", component: Payment },
  { path: "/role", component: RoleManagerment },
  { path: "/role/edit/:id", component: EditRole },
  { path: "/role/create", component: EditRole },

  { path: "/working-time", component: WorkingTimeManagerment },

  { path: "/product", component: ProductManagerment },

  { path: "/group-category", component: CategoryManagement },

  { path: "/batch", component: BatchManagement },
  { path: "/batch/create", component: BatchEdit },
  { path: "/batch/edit/:id", component: BatchEdit },

  { path: "/payout", component: PayoutManagement },
  { path: "/gaming-payout", component: GameTransactions },

  //Permission
  { path: "/error/401", component: Error401 },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/asset" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
]

export { authProtectedRoutes, publicRoutes }
