import PropTypes from "prop-types"
import React, { useState } from "react"
import ReactSelect from "react-select"
import { Button, Col, FormGroup, Input, Label, Row, InputGroup } from "reactstrap"
import SelectPaymentType from "./component/SelectPaymentType"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import SearchDate from "components/ultils/SearchDate"
import { canAccessCreate } from "./Permission"

const SearchPayment = (props) => {
    const { onCreate, onSearch } = props
    const [formSearch, setDataForm] = useState({
        name: "",
        type: "",
        paymentDate: "",
        resovled: "",
        missTime: "",
        paymentDateType: ""
    })
    const resovledOptions = [
        { value: 1, label: "Yes" },
        { value: 0, label: "No" }
    ]

    return (
        <React.Fragment>
            <Row>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Payment name :</Label>
                        <Input
                            name="name"
                            type="text"
                            value={formSearch.name}
                            autoComplete="off"
                            onChange={(e) => {
                                setDataForm({
                                    ...formSearch,
                                    name: e.target.value
                                })
                            }}
                        />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Payment Type :</Label>
                        <SelectPaymentType isClear={true} value={formSearch.typeObject} onChange={(option) => {
                            setDataForm({
                                ...formSearch,
                                type: option ? option?.value : "",
                                typeObject: option ? { value: option?.value, label: option?.label } : null
                            })
                        }} />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Payment Date :</Label>
                        <SearchDate 
                            onChange={(object) => {
                                setDataForm({
                                    ...formSearch,
                                    paymentDateType: object.type,
                                    paymentDate: object.value
                                })
                            }} 
                            currentValue={{type: formSearch.paymentDateType, value: formSearch.paymentDate}}
                        />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Resovled :</Label>
                        <ReactSelect
                            onChange={(option) => {
                                setDataForm({
                                    ...formSearch,
                                    resovled: option?.value ?? ""
                                })
                            }}
                            options={resovledOptions}
                            name="resovled"
                            value={resovledOptions.filter(option => option.value === formSearch.resovled)}
                            isClearable={true}
                        />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Miss Time :</Label>
                        <ReactSelect
                            onChange={(option) => {
                                setDataForm({
                                    ...formSearch,
                                    missTime: option?.value ?? ""
                                })
                            }}
                            options={resovledOptions}
                            name="missTime"
                            value={resovledOptions.filter(option => option.value === formSearch.missTime)}
                            isClearable={true}
                        />
                    </FormGroup>
                </div>
            </Row>
            <Row className="mb-2">
                <Col>
                    <div>
                        <Button
                            type="button"
                            color="info"
                            className="btn-rounded mb-2 me-2"
                            onClick={(e) => {
                                onSearch(formSearch)
                            }}
                        >
                            <i className="bx bx-search-alt me-1" />
                            Search
                        </Button>
                        {canAccessCreate() ? <Button
                            type="button"
                            color="success"
                            className="btn-rounded  mb-2 me-2"
                            onClick={onCreate}
                        >
                            <i className="mdi mdi-plus me-1" />
                            Add New Payment
                        </Button> : null}
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

SearchPayment.propTypes = {
    onSearch: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
}

export default SearchPayment