import PropType from "prop-types"
import React, { useEffect, useState } from "react"
import { Col, Input, InputGroup, Row } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";

const SearchDate = (props) => {
    const { onChange, currentValue, onlyDate } = props
    const [filter, setFilter] = useState({
        type: "",
        value: ""
    })

    const defaultSpecialValue = {
        day: "",
        month: "01",
        year: ""
    }
    const [specialValue, setSpecialValue] = useState({...defaultSpecialValue})

    useEffect(() => {
        const type = onlyDate === true ? "day" : (currentValue?.type ?? "")
        const value = currentValue?.value ?? ""
        setFilter({
            type: type,
            value: value
        })

        if(type === "month" && value) {
            const momentVal = moment(value, "YYYY-MM-DD")
            const month = momentVal.get("month") + 1
            const year = momentVal.get("years")
            const split = value ? value.split("-") : []
            let year2 = split[0] ? split[0] : "" //check case moment return 22 to 2022 for year
            year2 = year2 ? parseInt(year2) : ""
            year2 = (year2 < 1000 || year2 === "") ? year2 : year

            if(momentVal.isValid() && (month != parseInt(specialValue.month) || year2 != parseInt(specialValue.year))) {
                setSpecialValue({
                    ...specialValue,
                    month: month < 10 ?  "0"+month : month,
                    year: year2 < 1000 ? year2 : year
                })
            }
        }
    }, [currentValue])

    useEffect(() => {
        if (specialValue.month && specialValue.year) {
            onChangeValue(`${specialValue.year}-${specialValue.month}-01`)
        }
    }, [specialValue])

    const onChangeSearchType = (e) => {
        const newFilter = {
            ...filter,
            type: e.target.value,
            value: ""
        }
        setFilter(newFilter)
        setSpecialValue({...defaultSpecialValue})
        onChange(newFilter)
    }

    const typeIsDay = () => {
        return (
            <React.Fragment>
                <InputGroup>
                    <Flatpickr
                        className="form-control d-block"
                        placeholder="YYYY-MM-DD"
                        options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                        }}
                        onChange={(date, dateString) => {
                            onChangeValue(dateString)
                        }}
                        value={filter.value}
                    />
                </InputGroup>
            </React.Fragment>
        )
    }

    const onChangeValue = (value) => {
        const newFilter = {
            ...filter,
            value: value
        }
        setFilter(newFilter)
        onChange(newFilter)
    }

    const typeIsMonth = () => {
        return (
            <React.Fragment>
                <Row>
                    <Col md="5" sm="6" style={{paddingRight: "0"}}>
                        <select value={specialValue.month} className="form-select" onChange={(e) => {
                            setSpecialValue({
                                ...specialValue,
                                month: e.target.value
                            })
                        }}
                        >
                            <option value="01">1</option>
                            <option value="02">2</option>
                            <option value="03">3</option>
                            <option value="04">4</option>
                            <option value="05">5</option>
                            <option value="06">6</option>
                            <option value="07">7</option>
                            <option value="08">8</option>
                            <option value="09">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                    </Col>
                    <Col md="7" sm="6">
                        <Input
                            disabled={!specialValue.month}
                            value={specialValue.year}
                            onChange={(e) => {
                                let value = e.target.value
                                setSpecialValue({
                                    ...specialValue,
                                    year: value
                                })
                            }}
                            placeholder="Year"
                            type="number"
                        />
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

    const typeIsYear = () => {
        return (
            <React.Fragment>
                <Input
                    disabled={!filter.type}
                    value={filter.value}
                    onChange={(e) => {
                        let value = e.target.value
                        onChangeValue(value)
                    }}
                    type="number"
                    placeholder="Input Year"
                    onBlur={(e) => {
                        if (!/^\d+$/.test(filter.value)) {
                            onChangeValue("")
                        }
                    }}
                    min={2000}
                    max={2100}
                />
            </React.Fragment>
        )
    }

    const renderInput = () => {
        const { type } = filter
        switch (type) {
            case "day":
                return typeIsDay()
                break;
            case "month":
                return typeIsMonth()
                break;
            case "typeIsYear": {
                return typeIsYear()
                break;
            }
            default:
                return typeIsYear()
                break;
        }
    }

    return (
        <React.Fragment>
            <Row>
                {
                    onlyDate === true ? <Col sm="12">{renderInput()}</Col> : (
                        <React.Fragment>
                            <Col md="5" sm="6" style={{paddingRight: "0"}}>
                                <select onChange={onChangeSearchType} value={filter.type} className="form-select">
                                    <option value="">Filter By</option>
                                    <option value="day">Day</option>
                                    <option value="month">Month</option>
                                    <option value="year">Year</option>
                                </select>
                            </Col>
                            <Col md="7" sm="6">{renderInput()}</Col>
                        </React.Fragment>
                    )
                }


            </Row>
        </React.Fragment>
    )
}

SearchDate.propTypes = {
    onChange: PropType.func.isRequired,
    currentValue: PropType.shape({
        type: PropType.oneOf(["", "day", "month", "year"]).isRequired,
        value: PropType.string.isRequired
    }),
    onlyDate: PropType.bool
}

export default SearchDate