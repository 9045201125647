import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
  Spinner,
} from "reactstrap"
//SweetAlert
import Swal from "sweetalert2"
//Action
import { reqCreateGamingPayout, reqUpdateGamingPayout } from "helpers/fakebackend_helper"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import _, { trim } from "lodash"

const CreateModal = props => {
  const { isOpen, toggle, title, isUpdate, selected, bankCodes } = props
  const [isError, setIsError] = useState([])
  const [isSuccess, setIsSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    console.log(selected)

    return () => {}
  }, [])

  const swalSuccess = data => {
    Swal.fire({
      title: "Success",
      text: data?.mess,
      icon: "success",
      confirmButtonText: "Okay",
    }).then(res => {
      if (res.isConfirmed) {
        toggleModal()
      }
    })
  }

  const swalError = data => {
    Swal.fire({
      title: "Error",
      text: data?.error_message,
      icon: "error",
      confirmButtonText: "Okay",
    }).then(res => {
      if (res.isConfirmed) {
        console.log(data?.mess)
      }
    })
  }

  const generateRandomString = () => {
    var chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
    var charLength = chars.length
    var result = ""
    for (var i = 0; i < 16; i++) {
      result += chars.charAt(Math.floor(Math.random() * charLength))
    }
    return result
  }

  const createPayout = async values => {
    const partnerTranId = generateRandomString()

    const newValues = {
      totalAmount: values.totalAmount,
      bankCode: values.bankCode,
      receiverName: trim(values.receiverName),
      receiverCardNumber: trim(values.receiverCardNumber),
      reason: values.reason,
      partnerTranId: partnerTranId,
    }
    const data = await reqCreateGamingPayout(newValues)
    
    if (data?.error_code == "00") {
      swalSuccess(data)
      setTimeout(() => {
        setIsLoading(false)
      }, 300)
    } else {
      swalError(data)
      setTimeout(() => {
        setIsLoading(false)
      }, 300)
    }
  }
  const updatePayout = async values => {
    const newValues = {
      id: selected._id,
      totalAmount: values.totalAmount,
      bankCode: values.bankCode,
      receiverName: values.receiverName,
      receiverCardNumber: values.receiverCardNumber,
      reason: values.reason,
      partnerTranId: selected.partnerTranId,
      token: selected.token,
      status: values.status,
    }
    const data = await reqUpdateGamingPayout(newValues)
    if (data?.error_code == "00") {
      swalSuccess(data)
      setTimeout(() => {
        setIsLoading(false)
      }, 300)
    } else {
      swalError(data)
      setTimeout(() => {
        setIsLoading(false)
      }, 300)
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      totalAmount: isUpdate ? selected.amount : 0,
      bankCode: isUpdate ? selected.bankCode : "",
      receiverName: isUpdate ? selected.receiverName : "",
      receiverCardNumber: isUpdate ? selected.receiverCardNumber : "",
      reason: isUpdate ? selected.reason : "",
      status: isUpdate ? selected.status : "04",
    },
    validationSchema: Yup.object({
      totalAmount: Yup.string().required("Please enter amount first"),
      bankCode: Yup.string().required("Please select bank"),
      receiverCardNumber: Yup.string().required("Please enter card number"),
      receiverName: Yup.string().required("Please enter card name"),
    }),
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true)
      if (isUpdate) {
        updatePayout(values)
      } else {
        createPayout(values)
      }
      resetForm()
    },
  })

  const convertToBool = values => {
    if (values == "true" || values == true) return true
    if (values == "false" || values == false) return false
  }

  const toggleModal = () => {
    toggle()
    setIsError([])
    setIsSuccess(false)
    validation.resetForm()
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} centered backdrop="static">
      <div className="modal-content">
        <ModalHeader toggle={toggleModal}> {title} </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label">Bank Account Name</Label>
                  <Input
                    name="receiverName"
                    className="form-control"
                    placeholder="Enter receiver bank name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.receiverName || ""}
                    invalid={
                      validation.touched.receiverName &&
                      validation.errors.receiverName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.receiverName &&
                  validation.errors.receiverName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.receiverName}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label"> Bank Account Number</Label>
                  <Input
                    name="receiverCardNumber"
                    className="form-control"
                    placeholder="Enter bank account number"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.receiverCardNumber || ""}
                    invalid={
                      validation.touched.receiverCardNumber &&
                      validation.errors.receiverCardNumber
                        ? true
                        : false
                    }
                  />
                  {validation.touched.receiverCardNumber &&
                  validation.errors.receiverCardNumber ? (
                    <FormFeedback type="invalid">
                      {validation.errors.receiverCardNumber}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label">Bank Name</Label>
                  <Input
                    name="bankCode"
                    className="form-control"
                    type="select"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.bankCode || ""}
                    invalid={
                      validation.touched.bankCode && validation.errors.bankCode
                        ? true
                        : false
                    }
                  >
                    <option defaultValue value="">
                      Choose a bank
                    </option>
                    {bankCodes &&
                      bankCodes.map((code, index) => {
                        return (
                          <option key={index} value={code.label}>
                            {code.value}
                          </option>
                        )
                      })}
                  </Input>
                  {validation.touched.bankCode && validation.errors.bankCode ? (
                    <FormFeedback type="invalid">
                      {validation.errors.bankCode}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label"> Reason</Label>
                  <Input
                    name="reason"
                    className="form-control"
                    placeholder="Enter reason"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.reason || ""}
                    invalid={
                      validation.touched.reason && validation.errors.reason
                        ? true
                        : false
                    }
                  />
                  {validation.touched.reason && validation.errors.reason ? (
                    <FormFeedback type="invalid">
                      {validation.errors.reason}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label">Amount</Label>
                  <Input
                    name="totalAmount"
                    className="form-control"
                    type="number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.totalAmount || 0}
                    invalid={
                      validation.touched.totalAmount &&
                      validation.errors.totalAmount
                        ? true
                        : false
                    }
                  ></Input>
                  {validation.touched.totalAmount &&
                  validation.errors.totalAmount ? (
                    <FormFeedback type="invalid">
                      {validation.errors.totalAmount}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              {isUpdate && (
                <Col md={6}>
                  <div className="mb-3">
                    <Label className="form-label">Status</Label>
                    <Input
                      name="status"
                      className="form-control"
                      type="select"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.status || "04"}
                      invalid={
                        validation.touched.status && validation.errors.status
                          ? true
                          : false
                      }
                    >
                      <option value="00">SUCCESS</option>
                      <option value="01">PROCESSING</option>
                      <option value="02">REFUNDED</option>
                      <option value="04">PENDING</option>
                      <option value="05">ACCEPTED</option>
                    </Input>
                    {validation.touched.status && validation.errors.status ? (
                      <FormFeedback type="invalid">
                        {validation.errors.status}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <Button
                    onClick={toggleModal}
                    color="secondary"
                    className="btn me-2"
                  >
                    Cancel {"  "}
                  </Button>
                  <Button type="submit" color="success" className="btn">
                    Save {"  "}
                    <i className="bx bx-save me-1"></i>
                    {isLoading && <Spinner size="sm" />}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        {/* <ModalFooter>
          <Button onClick={toggleModal} color="secondary" className="btn">
            Cancel {"  "}
          </Button>
        </ModalFooter> */}
      </div>
    </Modal>
  )
}

CreateModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  isUpdate: PropTypes.bool,
  selected: PropTypes.object,
  bankCodes: PropTypes.array,
}

export default CreateModal
