import PropTypes from "prop-types"
import React, { useState, useEffect, useRef, useMemo } from "react"
import { Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import { useFormik } from "formik";
import * as Yup from "yup";
import { reqCreateEmployee, reqUpdateEmployee } from "helpers/fakebackend_helper";
import ReactSelect from "react-select";
import { htmlDecode } from "helpers/utils";
import SelectDepartment from "../DepartmentManagerment/components/SelectDepartment";
import SelectSite from "../WorkingTime/component/SelectSite";

const EditEmployee = (props) => {
    const { onSuccess, employee, isShow, toggle } = props
    const [otherErrorMessage, setOtherErrorMessage] = useState("")
    const isEdit = useRef(false)
    const concatNameAndCode = (code, name) => {
        code = htmlDecode(code)
        name = htmlDecode(name)
        if (name && code) {
            return code ? `#${code} - ${name}` : name
        }
        if (name && !code) {
            return name
        }

        if (!name && code) {
            return `#${code}`
        }

        return ""
    }
    const [employeeInfo, setEmployeeInfo] = useState({
        id: "",
        firstName: "",
        lastName: "",
        middleName: "",
        idNumber: "",
        bioNumber: "",
        site: "",
        code: "",
        passportOrIDNumber: "",
        status: "true",
        departmentObject: { value: "", label: "" }
    })

    const handleUpdateState = () => {
        const employeeId = employee._id || ""
        isEdit.current = (employeeId !== null && employeeId !== "")
        setEmployeeInfo({
            id: employeeId || "",
            firstName: htmlDecode(employee.firstName) || "",
            lastName: htmlDecode(employee.lastName) || "",
            middleName: htmlDecode(employee.middleName) || "",
            idNumber: htmlDecode(employee.idNumber) || "",
            bioNumber: htmlDecode(employee.bioNumber) || "",
            site: htmlDecode(employee.site) || "",
            code: htmlDecode(employee.code) || "",
            passportOrIDNumber: htmlDecode(employee.passportOrIDNumber) || "",
            status: htmlDecode(employee.status) || "true",
            departmentObject: employee.departmentId?._id ? { value: employee.departmentId?._id, label: concatNameAndCode(employee.departmentId?.code, employee.departmentId?.name) } : null
        })
    }

    useEffect(() => {
        handleUpdateState()
    }, [employee])

    useMemo(() => {
        handleUpdateState()
    }, [])

    const validationInitValue = {
        id: (employeeInfo && employeeInfo.id) || '',
        firstName: (employeeInfo && employeeInfo.firstName) || '',
        lastName: (employeeInfo && employeeInfo.lastName) || '',
        middleName: (employeeInfo && employeeInfo.middleName) || '',
        idNumber: (employeeInfo && employeeInfo.idNumber) || '',
        bioNumber: (employeeInfo && employeeInfo.bioNumber) || '',
        site: (employeeInfo && employeeInfo.site) || '',
        code: (employeeInfo && employeeInfo.code) || '',
        passportOrIDNumber: (employeeInfo && employeeInfo.passportOrIDNumber) || '',
        departmentId: (employeeInfo && employeeInfo.departmentObject?.value) || '',
        status: (employeeInfo && employeeInfo.status) || '',
    }

    const validationRule = {
        lastName: Yup.string().required("Last Name is required"),
        idNumber: Yup.string().required("ID Number is required"),
        status: Yup.string().required("Code is required"),
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: validationInitValue,
        validationSchema: Yup.object(validationRule),
        onSubmit: async (values) => {
            let data = {
                id: values.id,
                firstName: values.firstName,
                lastName: values.lastName,
                middleName: values.middleName,
                idNumber: values.idNumber,
                bioNumber: values.bioNumber,
                site: values.site,
                code: values.code,
                passportOrIDNumber: values.passportOrIDNumber,
                departmentId: values.departmentId,
                status: values.status
            };

            try {
                if (isEdit.current) {
                    await reqUpdateEmployee(data)
                }
                else {
                    await reqCreateEmployee(data)
                }
                await onSuccess()
                validation.resetForm();
                toggle();
            } catch (error) {
                return setOtherErrorMessage(error.message || error)
            }
        },
    });

    const onToggle = () => {
        toggle()
    }

    //Status
    const optionsStatus = [
        { value: "true", label: "Active" },
        { value: "false", label: "Inactive" },
    ]

    return (
        <React.Fragment>
            <Modal isOpen={isShow} toggle={onToggle}>
                <ModalHeader toggle={onToggle} tag="h4">{isEdit.current ? "Edit employee" : "Create Employee"}</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row form>
                            <Col className="col-12">
                                <div className="mb-3">
                                    {otherErrorMessage ? (<p className="alert alert-danger">{otherErrorMessage}</p>) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Code</Label>
                                    <Input
                                        name="code"
                                        type="text"
                                        value={validation.values.code || ""}
                                        invalid={validation.errors.code ? true : false}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setEmployeeInfo({
                                                ...employeeInfo,
                                                code: e.target.value
                                            })
                                        }}
                                    />
                                    {validation.errors.code ? (
                                        <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">First name</Label>
                                    <Input
                                        name="firstName"
                                        type="text"
                                        value={validation.values.firstName || ""}
                                        invalid={validation.errors.firstName && validation.touched.firstName ? true : false}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setEmployeeInfo({
                                                ...employeeInfo,
                                                firstName: e.target.value
                                            })
                                        }}
                                    />
                                    {validation.errors.firstName ? (
                                        <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Middle name</Label>
                                    <Input
                                        name="middleName"
                                        type="text"
                                        value={validation.values.middleName || ""}
                                        invalid={validation.errors.middleName && validation.touched.middleName ? true : false}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setEmployeeInfo({
                                                ...employeeInfo,
                                                middleName: e.target.value
                                            })
                                        }}
                                    />
                                    {validation.errors.middleName ? (
                                        <FormFeedback type="invalid">{validation.errors.middleName}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Last name</Label>
                                    <Input
                                        name="lastName"
                                        type="text"
                                        value={validation.values.lastName || ""}
                                        invalid={validation.errors.lastName && validation.touched.lastName ? true : false}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setEmployeeInfo({
                                                ...employeeInfo,
                                                lastName: e.target.value
                                            })
                                        }}
                                    />
                                    {validation.errors.lastName ? (
                                        <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">ID Number</Label>
                                    <Input
                                        name="idNumber"
                                        type="text"
                                        value={validation.values.idNumber || ""}
                                        invalid={validation.errors.idNumber ? true : false}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setEmployeeInfo({
                                                ...employeeInfo,
                                                idNumber: e.target.value
                                            })
                                        }}
                                    />
                                    {validation.errors.idNumber ? (
                                        <FormFeedback type="invalid">{validation.errors.idNumber}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">BIO Number</Label>
                                    <Input
                                        name="bioNumber"
                                        type="text"
                                        value={validation.values.bioNumber || ""}
                                        invalid={validation.errors.bioNumber ? true : false}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setEmployeeInfo({
                                                ...employeeInfo,
                                                bioNumber: e.target.value
                                            })
                                        }}
                                    />
                                    {validation.errors.bioNumber ? (
                                        <FormFeedback type="invalid">{validation.errors.bioNumber}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Passport Or ID</Label>
                                    <Input
                                        name="passportOrIDNumber"
                                        type="text"
                                        value={validation.values.passportOrIDNumber || ""}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setEmployeeInfo({
                                                ...employeeInfo,
                                                passportOrIDNumber: e.target.value
                                            })
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Status</Label>
                                    <ReactSelect
                                        options={optionsStatus}
                                        onChange={(option) => {
                                            setEmployeeInfo({
                                                ...employeeInfo,
                                                status: option.value
                                            })

                                            validation.validateField("status")
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={optionsStatus.filter(option => {
                                            const status = employeeInfo.status === "true" || employeeInfo.status === true ? "true" : "false"
                                            return status === option.value
                                        })}
                                    />

                                    <Input type="hidden" invalid={validation.errors.status ? true : false} />
                                    {validation.touched.status && validation.errors.status ? (
                                        <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Department</Label>
                                    <SelectDepartment value={employeeInfo.departmentObject} onChange={(departmentObject) => {
                                        setEmployeeInfo({
                                            ...employeeInfo,
                                            departmentObject: departmentObject,
                                        })
                                    }} />
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Site</Label>
                                    <SelectSite value={validation.values.site || ""} onChange={(site) => {
                                        setEmployeeInfo({
                                            ...employeeInfo,
                                            site
                                        })
                                    }} />
                                    <Input
                                        type="hidden"
                                        value={validation.values.site || ""}
                                        invalid={validation.errors.site ? true : false}
                                        autoComplete="off"
                                    />
                                    {validation.errors.site ? (
                                        <FormFeedback type="invalid">{validation.errors.site}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn btn-success save-user"
                                    >
                                        {isEdit.current ? "Update" : "Create"}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

EditEmployee.propTypes = {
    employee: PropTypes.object.isRequired,
    onSuccess: PropTypes.func,
    isShow: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired
}

export default EditEmployee