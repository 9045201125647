import CustomPagination from "components/ultils/CustomPagination"
import React, { useEffect, useRef, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { MetaTags } from "react-meta-tags"
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Spinner, Toast, ToastBody, ToastHeader, UncontrolledTooltip } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import {getListCategory, reqDeleteCategory} from "helpers/fakebackend_helper"
import DeleteModal from "components/Common/DeleteModal"
import { htmlDecode } from "helpers/utils"
import Search from "./components/Search";
import Edit from "./components/Edit";

const CategoryManagement  = () => {
    const refPagination = useRef()
    let FLAG_FIRST_LOAD = useRef(false)

    const [categoryListConf, setCategoryListConf] = useState({
        page: 1,
        limit: 20,
        search: {
            name: "",
            isActive: ""
        }
    });

    const [categories, setCategories] = useState({
        data: [],
        totalItems: 0
    })

    const [category, setCategory] = useState({})

    const renderName = (names = {}) => {
        const output = []
        for (const key in names) {
            const name = names[key]
            if (name) {
                output.push(<div className="group-name">
                    <span className="label">{htmlDecode(key.toString().trim().toUpperCase())}: </span>
                    <span className="text">{htmlDecode(name)}</span>
                </div>)
            }
            else {
                output.push(<div className="group-name">
                    <span className="label">{htmlDecode(key.toString().trim().toUpperCase())}: </span>
                    <span className="text"></span>
                </div>)
            }
        }

        return output
    }

    const onClickEdit = (category) => {
        setCategory(category)
        setIsShowEdit(true)
    }

    const historyTableColumn = [
        {
            dataField: "name",
            text: "Name",
            formatter: (rowContent, row) => {
                return renderName(row["name"] ?? {})
            }
        },
        {
            dataField: "description",
            text: "Description",
            formatter: (rowContent, row) => {
                const description = row["description"] ?? {}
                return <>
                    <div className="group-name">
                        <span className="label">VN: </span>
                        <span className="text">{description?.VN ?? ""}</span>
                    </div>
                    <div className="group-name">
                        <span className="label">EN: </span>
                        <span className="text">{description?.EN ?? ""}</span>
                    </div>
                </>
            }
        },
        {
            dataField: "isActive",
            text: "Active",
            formatter: (rowContent, row) => {
                const IsActive = row["IsActive"] ?? false
                return IsActive ? "Yes" : "No"
            }
        },
        {
            dataField: "formula",
            text: "Formula",
            formatter: (rowContent) => {
                return htmlDecode(rowContent)
            }
        },
        {
            dataField: "action",
            isDummyField: true,
            text: "Action",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <>
                    <div className="d-flex gap-3"><Link
                        to={`#`}
                        className="text-success"
                        onClick={() => {onClickEdit(row)}}
                    >
                        <i className="mdi mdi-pencil font-size-18" id={`edittooltip${row._id}`} />
                        <UncontrolledTooltip placement="top" target={`edittooltip${row._id}`}>Edit</UncontrolledTooltip>
                    </Link>
                        <Link
                            to="#"
                            className="text-danger"
                            onClick={() => onDelete(row)}
                        >
                            <i className="mdi mdi-delete font-size-18" id={`deletetooltip${row._id}`} />
                            <UncontrolledTooltip placement="top" target={`deletetooltip${row._id}`}>Delete</UncontrolledTooltip>
                        </Link>
                    </div>
                </>
            ),
        },
    ]

    const [toast, setToast] = useState({
        message: "",
        isOpen: false
    })

    const autoCloseToast = (message) => {
        setToast({
            message: message,
            isOpen: true
        })
        setTimeout(() => {
            setToast({
                message: "",
                isOpen: false
            })
        }, 5000);
    }

    const pageOptions = {
        sizePerPage: categoryListConf.limit,
        totalSize: categories.totalItems, // replace later with size(roles),
        custom: true,
    };

    const onChangePageNumber = (pageNumber) => {
        setCategoryListConf({
            ...categoryListConf,
            page: parseInt(pageNumber)
        })
    }

    const onSearch = (data) => {
        setCategoryListConf({
            ...categoryListConf,
            search: {
                ...categoryListConf.search,
                ...data
            },
            page: 1
        })

        refPagination.current.refreshCurrentPage()
    }

    const onSearchError = (message) => {
        autoCloseToast(message)
    }

    const getListCategories = async () => {
        try {
            const filter = {
                params: {
                    page: categoryListConf.page,
                    name: categoryListConf.search.name ?? "",
                    isActive: categoryListConf.search.isActive ?? "",
                }
            }

            const result = await getListCategory(filter)
            FLAG_FIRST_LOAD.current = true
            setCategories({
                ...categories,
                data: result.items,
                totalItems: result.totalItems
            })

        } catch (error) {
            autoCloseToast(error.message || error)
        }
    }

    useEffect(async () => {
        await getListCategories()
    }, [])

    useEffect(async () => {
        if (FLAG_FIRST_LOAD.current) {
            await getListCategories()
        }
    }, [categoryListConf])

    //delete
    const [deleteModal, setDeleteModal] = useState(false)
    const onDelete = async (category) => {
        setCategory(category)
        setDeleteModal(true)
    }

    const handleDelete = async () => {
        try {
            await reqDeleteCategory(category?._id)
            await getListCategories()
            setDeleteModal(false)
        } catch (error) {
            autoCloseToast(error.message || error)
            setDeleteModal(false)
        }
    }

    //create / edit
    const [isShowEdit, setIsShowEdit] = useState(false)
    const toggleEdit = () => setIsShowEdit(!isShowEdit)
    const onEditSuccess = async () => {
        await getListCategories()
        setIsShowEdit(false)
    }
    return (
        <React.Fragment>
            <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: "1005" }}>
                <Toast isOpen={toast.isOpen}>
                    <ToastHeader icon={<Spinner type="grow" size="sm" color="danger" />} toggle={() => {
                        setToast({ ...toast, isOpen: !toast.isOpen })
                    }}>Error</ToastHeader>
                    <ToastBody>{toast.message}</ToastBody>
                </Toast>
            </div>
            {deleteModal ? <DeleteModal show={deleteModal} onDeleteClick={handleDelete} onCloseClick={() => {
                setDeleteModal(false)
                // setRole({})
            }}
            /> : null}
            <Edit category={category} isShow={isShowEdit} toggle={toggleEdit} onSuccess={onEditSuccess} />
            <div className="page-content">
                <MetaTags>
                    <title>Category Management</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Category Management" breadcrumbItem="" />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField="_id"
                                        columns={historyTableColumn}
                                        data={categories.data}
                                    >
                                        {({ paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField="_id"
                                                data={categories.data}
                                                columns={historyTableColumn}
                                                bootstrap4
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Search onSearch={onSearch} onError={onSearchError} onCreate={toggleEdit}/>
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        keyField="id"
                                                                        responsive
                                                                        basseted={false}
                                                                        striped={false}
                                                                        classes={
                                                                            "table align-middle table-nowrap table-check"
                                                                        }
                                                                        headerWrapperClasses={"table-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>

                                                            </Col>
                                                        </Row>
                                                        <div className="pagination pagination-rounded justify-content-end mb-2">
                                                            <CustomPagination ref={refPagination} onChangePage={onChangePageNumber} sizeOfPerPage={pageOptions.sizePerPage} totalItem={pageOptions.totalSize} />
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(CategoryManagement)