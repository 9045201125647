import {useHistory, useParams} from "react-router-dom";
import {
    reqCreateBatch,
    reqDetailBatch,
    reqGetExchangeRateBatch,
    reqGetUnitBatch, reqUpdateBatch,
} from "../../helpers/fakebackend_helper";
import React, {useEffect, useState} from "react";
import {MetaTags} from "react-meta-tags";
import {Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {htmlDecode} from "../../helpers/utils";
import ReactSelect from "react-select";
import SelectPartner from "../AssetManagerment/components/SelectPartner";
import {BATCH_STATUS, BATCH_STATUS_CODE} from "./constant";
import EditProducts from "./components/EditProducts";
import css from "./Edit.module.scss"
import * as Yup from "yup";
import {useFormik} from "formik";
import {value} from "lodash/seq";
import CustomToast from "../../components/ultils/CustomToast";
import Audit from "./components/Audit";

const Edit = () => {
    const {id: batchId} = useParams()
    const isEditMode = batchId !== null && typeof batchId !== "undefined"
    const [batch, setBatch] = useState({})
    const [batchStatus, setBatchStatus] = useState(BATCH_STATUS_CODE.pending)

    useEffect(() => {
        if(typeof batch.status !== "undefined" && batch.status !== null) {
            setBatchStatus(batch.status)
        }
    }, [batch.status])

    const getDetailBatch = async () => {
        try {
            const batch = await reqDetailBatch(batchId)
            setBatch(batch)
            setExchangeRate({
                vnd: batch?.exchangeRate?.vnd,
                usd: batch?.exchangeRate?.usd,
            })
        } catch (error) {
            setToast({
                ...toast,
                isOpen: true,
                message: error?.message ?? error,
                color: "danger",
                title: "Error",
            })
        }
    }

    const [toast, setToast] = useState({
        message: "",
        isOpen: false,
        title: "Error",
        closeAfter: 3000,
        color: "danger"
    })

    useEffect(async () => {
        if (batchId) {

            await getDetailBatch()
            const _units = await reqGetUnitBatch()
            setUnits(_units)
        } else {
            const [_units, _exchangeRate] = await Promise.all([
                reqGetUnitBatch(),
                reqGetExchangeRateBatch()
            ]).catch(err => {
                setToast({
                    ...toast,
                    isOpen: true,
                    message: err?.message ?? err,
                    title: "Error",
                    color: "danger"
                })
            })
            setExchangeRate(_exchangeRate)
            setUnits(_units)
        }
    }, [])

    const [validateMessage, setValidateMessage] = useState([])

    const optionsStatus = [
        {
            value: BATCH_STATUS_CODE.pending,
            label: BATCH_STATUS.pending,
            isDisabled: BATCH_STATUS_CODE.pending < batch?.status
        },
        {
            value: BATCH_STATUS_CODE.approved,
            label: BATCH_STATUS.approved,
            isDisabled: BATCH_STATUS_CODE.approved < batch?.status
        },
        {
            value: BATCH_STATUS_CODE.canceled,
            label: BATCH_STATUS.canceled,
            isDisabled: BATCH_STATUS_CODE.canceled < batch?.status || BATCH_STATUS_CODE.done === batch?.status
        },
        {
            value: BATCH_STATUS_CODE.buying,
            label: BATCH_STATUS.buying,
            isDisabled: BATCH_STATUS_CODE.buying < batch?.status
        },
        {
            value: BATCH_STATUS_CODE.shipping,
            label: BATCH_STATUS.shipping,
            isDisabled: BATCH_STATUS_CODE.shipping < batch?.status
        },
        {
            value: BATCH_STATUS_CODE.done,
            label: BATCH_STATUS.done,
            isDisabled: BATCH_STATUS_CODE.done < batch?.status
        },
    ]

    const getPartner = () => {
        return batch?.partner?._id ? {value: batch?.partner?._id, label: batch?.partner?.providerName} : null
    }

    const onChangeBatch = (key, value) => {
        setBatch({
            ...batch,
            [key]: value
        })
    }

    const [exchangeRate, setExchangeRate] = useState({
        vnd: 1,
        usd: 1
    })

    const [units, setUnits] = useState([])

    const onChangeInput = (e) => {
        const {name, value} = e.target
        onChangeBatch(name, value)
    }

    const onChangeProducts = (products = []) => {
        setBatch({
            ...batch,
            products: products
        })
    }

    const validationInitValue = {
        id: batch && batch?._id ? batch?._id : "",
        code: batch && batch?.code ? batch?.code : "",
        status: batchStatus,
        shippingFee: batch && batch?.shippingFee !== null ? batch?.shippingFee : 0,
        shippingFeeUnit: batch && batch?.shippingFeeUnit ? batch?.shippingFeeUnit : "",
        partner: batch && batch?.partner ? batch?.partner : "",
        products: batch && batch?.products ? batch?.products : [],
        description: batch && batch?.description ? batch?.description : "",
    }

    const setValidationRule = () => {
        const isCanceled = batch?.isCanceled === true
        const requireWhenShipping = batchStatus >= BATCH_STATUS_CODE.shipping && batchStatus !== BATCH_STATUS_CODE.canceled && !isCanceled
        const requireWhenDone = batchStatus >= BATCH_STATUS_CODE.done && batchStatus !== BATCH_STATUS_CODE.canceled && !isCanceled

        let status = Yup.number()
        if (isCanceled) {
            status = status.required("Status is required")
        }

        let shippingFee = Yup.number().min(0).label("Shipping Fee")
        if (requireWhenShipping) {
            shippingFee = shippingFee.required("Shipping Fee is required")
        }

        let shippingFeeUnit = Yup.string().label("Unit")
        if (requireWhenShipping) {
            shippingFeeUnit = shippingFeeUnit.required("Unit is required")
        }

        let partner = Yup.object().label("Partner")
        if (requireWhenShipping) {
            partner = partner.shape(
                {_id: Yup.string().required("Partner is required")}
            ).required("Partner is required")
        } else {
            partner = partner.shape(
                {_id: Yup.string().nullable(true)}
            ).notRequired().nullable(true)
        }

        const product = Yup.object().label("Product").shape({
            _id: Yup.string().label("Product").required("Product is required"),
        })

        const requested = Yup.object().shape({
            quantity: Yup.number().label("Requested quantity").required("Requested quantity is required").min(0),
            unit: Yup.string().label("Product unit").required("Product unity is required"),
        }).required("Requested is required")

        let exportInfo = Yup.object()
        if (requireWhenShipping) {
            exportInfo = exportInfo.shape({
                quantity: Yup.number().label("Export quantity").required("Export quantity is required").min(0),
                price: Yup.number().label("Export price").required("Export price is required").min(0),
                priceUnit: Yup.string().label("Export price unit").required("Export price unit required"),
                note: Yup.string().label("Note").notRequired().nullable(true),
            })
        } else {
            exportInfo = exportInfo.shape({
                quantity: Yup.number().label("Export quantity").notRequired().nullable(true).min(0),
                price: Yup.number().label("Export price").notRequired().nullable(true).min(0),
                note: Yup.string().label("Note").notRequired().nullable(true),
                priceUnit: Yup.string().label("Export price unit").nullable(true).typeError('Invalid value'),
            })
        }

        let importInfo = Yup.object()
        if (requireWhenDone) {
            importInfo = importInfo.shape({
                quantity: Yup.number().label("Import quantity").required("Import quantity is required").min(0),
                note: Yup.string().label("Import note").notRequired().nullable(true),
            })
        } else {
            importInfo = importInfo.shape({
                quantity: Yup.number().label("Import quantity").notRequired().nullable(true).min(0),
                note: Yup.string().label("Import note").notRequired().nullable(true),
            })
        }

        const description = Yup.string().label("Description")

        return {
            status,
            shippingFee,
            shippingFeeUnit,
            partner,
            products: Yup.array().of(
                Yup.object().shape({
                    product,
                    requested,
                    export: exportInfo,
                    import: importInfo,
                })
            ),
            description
        }
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: validationInitValue,
        validationSchema: Yup.object(setValidationRule()),
        onSubmit: async (values) => {
            const products = values?.products ?? []
            let data = {
                id: values.id,
                status: values.status,
                shippingFee: values.shippingFee,
                shippingFeeUnit: values.shippingFeeUnit,
                partner: values?.partner?._id ?? "",
                description: values?.description ?? "",
                products: products.map(product => {
                    const _product = product?.product?._id ?? ""
                    return {
                        ...product,
                        product: _product
                    }
                })
            };

            try {
                let record
                if (!isEditMode) {
                    record = await reqCreateBatch(data)
                    if(record?._id) {
                        navigate.push("/batch/edit/"+record?._id)
                    }
                } else {
                    await reqUpdateBatch(data)
                    await getDetailBatch()
                }
                setToast({
                    ...toast,
                    isOpen: true,
                    message: "Update success.",
                    color: "success",
                    title: "Successfully!",
                })
                validation.resetForm();

            } catch (error) {
                const messages = (error.message || error).split("<br>")
                return setValidateMessage(messages.map((message, index) => {
                    return <p key={`error_mess_${index}`}>{message}</p>
                }))
            }
        },
    });

    const navigate = useHistory()
    const onCancel = () => {
        navigate.back()
    }

    const [showAudit, setShowAudit] = useState(false)

    const renderActions = () => {
        return <Card>
            <CardBody className={css.buttons}>
                <Button color="success" type="submit">Save</Button>
                <Button onClick={onCancel}>Cancel</Button>
                {isEditMode ? <Button onClick={() => setShowAudit(!showAudit)} color="info">Audit</Button> : null}
            </CardBody>
        </Card>
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{isEditMode ? "Edit Batch" : "Create Batch"}</title>
                </MetaTags>
                <Container fluid>
                    <Audit toggle={() => {setShowAudit(!showAudit)}} isShow={showAudit} batch={batch} />
                    <CustomToast message={toast.message} onToggle={() => setToast({...toast, isOpen: !toast.isOpen})}
                                 title={toast.title} color={toast.color} isOpen={toast.isOpen}
                                 closeAfterMilSec={toast.closeAfter}/>
                    <Breadcrumbs title={isEditMode ? "Edit Batch" : "Create Batch"}
                                 breadcrumbItem={"#" + (batch?.code ?? "")}/>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        <Row>
                            <Col xs="12">
                                {renderActions()}
                                <Card className="mt-3">
                                    <CardBody>
                                        {validateMessage.length ?
                                            <Row>
                                                <Col className={`alert alert-danger ${css.messgaeError}`}>
                                                    {validateMessage}
                                                </Col>
                                            </Row>
                                            : null}
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label">Code</Label>
                                                    <Input
                                                        type="text"
                                                        disabled={true}
                                                        value={htmlDecode(validation.values.code) || ""}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label">Status</Label>
                                                    <ReactSelect options={optionsStatus}
                                                                 onChange={(option) => {
                                                                     setBatchStatus(option.value)
                                                                 }}
                                                                 isDisabled={!isEditMode}
                                                                 value={optionsStatus.filter(option => {
                                                                     if (!isEditMode) {
                                                                         return BATCH_STATUS_CODE.pending === option.value
                                                                     }

                                                                     return batchStatus === option.value
                                                                 })}
                                                    />
                                                    <Input
                                                        type="hidden"
                                                        disabled={true}
                                                        invalid={!!validation.errors.status}
                                                    />
                                                    {validation.errors.status ? <FormFeedback
                                                        type="invalid">{validation.errors.status}</FormFeedback> : null}
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <Row>
                                                    <Col md="9">
                                                        <div className="mb-3">
                                                            <Label className="form-label">Shipping Fee</Label>
                                                            <Input
                                                                type="number"
                                                                value={htmlDecode(batch.shippingFee) || ""}
                                                                onChange={onChangeInput}
                                                                name="shippingFee"
                                                                invalid={!!validation.errors.shippingFee}
                                                            />
                                                            {validation.errors.shippingFee ? <FormFeedback
                                                                type="invalid">{validation.errors.shippingFee}</FormFeedback> : null}
                                                        </div>
                                                    </Col>
                                                    <Col md="3">
                                                        <div className="mb-3">
                                                            <Label className="form-label">Unit</Label>
                                                            <ReactSelect
                                                                value={units.filter(unit => unit.value === batch?.shippingFeeUnit)}
                                                                onChange={(option) => onChangeBatch("shippingFeeUnit", option.value)}
                                                                options={units}/>
                                                            <Input
                                                                type="hidden"
                                                                disabled={true}
                                                                invalid={!!validation.errors.shippingFeeUnit}
                                                            />
                                                            {validation.errors.shippingFeeUnit ? <FormFeedback
                                                                type="invalid">{validation.errors.shippingFeeUnit}</FormFeedback> : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="6">
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label className="form-label">Exchange Rate(VND)</Label>
                                                            <Input
                                                                type="text"
                                                                disabled={true}
                                                                value={exchangeRate.vnd}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label className="form-label">Exchange Rate(USD)</Label>
                                                            <Input
                                                                type="text"
                                                                disabled={true}
                                                                value={exchangeRate.usd}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label">Partner</Label>
                                                    <SelectPartner value={getPartner()}
                                                                   onChange={(option) => onChangeBatch("partner", {
                                                                       _id: option.value,
                                                                       providerName: option.label
                                                                   })}/>
                                                    <Input
                                                        type="hidden"
                                                        disabled={true}
                                                        invalid={!!validation.errors.partner?._id}
                                                    />
                                                    {validation.errors.partner?._id ? <FormFeedback
                                                        type="invalid">{validation.errors.partner?._id}</FormFeedback> : null}
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label">Description</Label>
                                                    <Input
                                                        type="textarea"
                                                        invalid={!!validation.errors.description}
                                                        value={htmlDecode(validation.values.description) || ""}
                                                        name="description"
                                                        onChange={onChangeInput}
                                                    />
                                                    {validation.errors.description ? <FormFeedback
                                                        type="invalid">{validation.errors.description}</FormFeedback> : null}
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card className="mt-3 mb-2">
                                    <CardBody>
                                        <EditProducts batch={batch}
                                                      onChange={onChangeProducts}
                                                      validate={validation}
                                                      batchStatus={validation.values.status ?? BATCH_STATUS_CODE.pending}
                                                      exchangeRate={exchangeRate}
                                                      units={units}
                                        />
                                    </CardBody>
                                </Card>
                                {renderActions()}
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Edit