//ASSET
export const GET_ASSETS = "/asset"
export const DEL_ASSET = "/asset/delete"
export const ADD_ASSET = "/asset/create"
export const UPDATE_ASSET = "/asset/update"
export const ASSIGN_ASSET = "/asset/assign"
export const DETAIL_ASSET = "/asset/detail"
export const OPTION_ASSET = "/asset/optionAsset"
export const EXPORT_ASSET = "/asset/export"

//Employee
export const GET_EMPLOYEES = "/employee"
export const GET_OPTIONS_EMPLOYEES = "/employee/option"
export const CREATE_EMPLOYEE = "/employee/create"
export const UPDATE_EMPLOYEE = "/employee/update"
export const DETAIL_EMPLOYEE = "/employee/detail"
export const DELETE_EMPLOYEE = "/employee/delete"
export const IMPORT_EMPLOYEE = "/employee/import"
export const SITE_EMPLOYEE = "/employee/list_site"

//History
export const GET_HISTORY_BY_ASSET_ID = "/history/getHistoryByAssetId"
export const RETURN_ASSET = "/history/returnASSet"
export const GET_HISTORIES = "/history"
export const UPDATE_HISTORY = "/history/update"
export const EXPORT_ASSET_HISTORY = "/history/export"

//Department
export const GET_LIST_DEPARTMENT = "/department"
export const CREATE_DEPARTMENT = "/department/create"
export const UPDATE_DEPARTMENT = "/department/update"
export const DETAIL_DEPARTMENT = "/department/detail"
export const OPTION_DEPARTMENT = "/department/option"
export const DELETE_DEPARTMENT = "/department/delete"

//Users
export const GET_LIST_USERS = "/user"
export const CREATE_USER = "/user/create"
export const POST_UPDATE_USER = "/user/update"
export const REMOVE_USER = "/user/delete"
export const CHANGE_PASSWORD = "/user/change-password"

//Asset type
export const ASSET_TYPES = "/asset-type"
export const ASSET_TYPES_DETAIL = "/asset-type/detail"
export const ASSET_TYPES_CREATE = "/asset-type/create"
export const ASSET_TYPES_UPDATE = "/asset-type/update"
export const ASSET_TYPES_DELETE = "/asset-type/delete"
export const ASSET_TYPE_OPTIONS = "/asset-type/options"

//Partner
export const PARTNER = "/partner"
export const PARTNER_OPTIONS = "/partner/options"
export const PARTNER_CREATE = "/partner/create"
export const PARTNER_UPDATE = "/partner/update"
export const PARTNER_DELETE = "/partner/delete"
export const PARTNER_DETAIL = "/partner/detail"

//Payment type
export const PAYMENT_TYPE = "/payment-type"
export const PAYMENT_TYPE_CREATE = "/payment-type/create"
export const PAYMENT_TYPE_UPDATE = "/payment-type/update"
export const PAYMENT_TYPE_DELETE = "/payment-type/delete"
export const PAYMENT_TYPE_OPTION = "/payment-type/option"

//Payment type
export const PAYMENT = "/payment"
export const PAYMENT_CREATE = "/payment/create"
export const PAYMENT_UPDATE = "/payment/update"
export const PAYMENT_DELETE = "/payment/delete"

//Roles
export const ROLE = "/role"
export const ROLE_CONFIG = "/role/config"
export const ROLE_DETAIL = "/role/detail"
export const ROLE_CREATE = "/role/create"
export const ROLE_UPDATE = "/role/update"
export const ROLE_DELETE = "/role/delete"
export const ROLE_OPTIONS = "/role/options"

//WORKING TIME
export const WORKING_TIME = "/working-time"
export const WORKING_TIME_IMPORT = "/working-time/import"
export const DELETE_WORKING_TIME = "/working-time/delete"
export const EXPORT_WORKING_TIME = "/working-time/export"

//Group Category management
export const GROUP_CATEGORY_MANAGEMENT = "/group-category"
export const GROUP_CATEGORY_MANAGEMENT_CREATE = "/group-category/create"
export const GROUP_CATEGORY_MANAGEMENT_UPDATE = "/group-category/update"
export const GROUP_CATEGORY_MANAGEMENT_DELETE = "/group-category/delete"

//Batch management
export const BATCH_MANAGEMENT = "/batch"
export const BATCH_MANAGEMENT_CREATE = "/batch/create"
export const BATCH_MANAGEMENT_UPDATE = "/batch/update"
export const BATCH_MANAGEMENT_DELETE = "/batch/delete"
export const BATCH_MANAGEMENT_DETAIL = "/batch/detail"
export const BATCH_MANAGEMENT_UNIT = "/batch/unit"
export const BATCH_MANAGEMENT_EXCHANGE_RATE = "/batch/exchange-rate"

//Users
export const GET_LIST_PRODUCT = "/product"
export const PRODUCT_MANAGEMENT_OPTION = "/product/options"

//Payout
export const PAYOUT_MANAGEMENT = "/payout"
export const PAYOUT_MANAGEMENT_CREATE = "/payout/create"
export const PAYOUT_MANAGEMENT_UPDATE = "/payout/update"
export const PAYOUT_MANAGEMENT_DELETE = "/payout/delete"

//GamingPayout
export const GAMING_PAYOUT_MANAGEMENT = "/gaming-payout"
export const GAMING_PAYOUT_MANAGEMENT_CREATE = "/gaming-payout/create"
export const GAMING_PAYOUT_MANAGEMENT_UPDATE = "/gaming-payout/update"
export const GAMING_PAYOUT_MANAGEMENT_DELETE = "/gaming-payout/delete"

//AUTH
export const POST_FAKE_JWT_LOGIN = "/auth/login";
export const POST_REFRESH_TOKEN = "/auth/refreshToken";

//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//Product 
export const POST_UPDATE_PRODUCT_INFO = "/product/update"
export const POST_UPDATE_PRODUCT = "/vmart/updateproductprice"
export const POST_ADD_STOCKS = "/vmart/transferstocks"