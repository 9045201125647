import ReactSelect from "react-select";
import React, {useEffect, useRef, useState} from "react";
import {reqGetOptionsDepartment} from "../../../helpers/fakebackend_helper";
import {ceil, throttle} from "lodash";
import {htmlDecode} from "../../../helpers/utils";
import PropTypes from "prop-types";

const SelectDepartment = (props) => {
    const {value, onChange} = props

    const menuIsOpen = useRef(false)
    const PAGE = useRef(1)

    const [optionDepartment, setOptionDepartment] = useState({
        isLoading: false,
        options: [],
        textSearch: "",
        totalItems: 0
    })

    const [textSearch, changeTextSearch] = useState("")
    const [otherErrorMessage, setOtherErrorMessage] = useState("")

    const concatNameAndCode = (code, name) => {
        code = htmlDecode(code)
        name = htmlDecode(name)
        if (name && code) {
            return code ? `#${code} - ${name}` : name
        }
        if (name && !code) {
            return name
        }

        if (!name && code) {
            return `#${code}`
        }

        return ""
    }

    const getListOptionDepartment = async (textSearch = "") => {
        if (!optionDepartment.isLoading && menuIsOpen.current) {
            setOptionDepartment({
                ...optionDepartment,
                isLoading: true
            })

            const payload = {
                params: {
                    name: textSearch,
                    page: PAGE.current
                }
            }

            const options = PAGE.current > 1 ? optionDepartment.options : []
            let totalItems = 0
            try {
                const res = await reqGetOptionsDepartment(payload)
                totalItems = res.totalItems || 0
                res?.items.map((val) => {
                    const {_id: value, name: label, code} = val
                    options.push({value, label: concatNameAndCode(code, label)})
                })
            } catch (error) {
                setOtherErrorMessage(error.message || error)
            }

            setOptionDepartment({
                ...optionDepartment,
                options,
                isLoading: false,
                totalItems
            })
        }
    }

    const throttled = useRef(throttle(async (newValue) => {
        PAGE.current = 1
        await getListOptionDepartment(newValue)
    }, 1000))

    useEffect(() => {
        throttled.current(textSearch)
    }, [textSearch])

    const handleOnScrollToBottom = async () => {
        if (!optionDepartment.isLoading) {
            const {totalItems} = optionDepartment
            const LIMIT = 20
            const totalPage = ceil(totalItems / LIMIT)
            const newPageNumber = (PAGE.current < totalPage) ? (PAGE.current + 1) : PAGE.current
            if (newPageNumber != PAGE.current) {
                PAGE.current = newPageNumber
                await getListOptionDepartment()
            }
        }
    }

    return (
        <React.Fragment>
            <ReactSelect
                blurInputOnSelect={false}
                isLoading={optionDepartment.isLoading}
                onInputChange={(text) => {
                    changeTextSearch(text)
                }}
                onChange={(option) => {
                    onChange(option)
                }}
                onMenuOpen={async () => {
                    menuIsOpen.current = true
                    await getListOptionDepartment()
                }}
                options={optionDepartment.options}
                name="department"
                onBlur={(e) => {
                    menuIsOpen.current = false
                }}
                value={value}
                onMenuScrollToBottom={async (e) => {
                    await handleOnScrollToBottom()
                }}
            />
            {otherErrorMessage ? (<p className="text text-danger" style={{marginTop: "5px"}}>{otherErrorMessage}</p>) : null}
        </React.Fragment>
    )
}

SelectDepartment.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
        PropTypes.oneOf([null])
    ]),
    onChange: PropTypes.func
}

export default SelectDepartment