import PropTypes from "prop-types"
import React, { useState, useEffect, useRef, useMemo } from "react"
import ReactSelect from "react-select"
import { Col, Form, FormFeedback, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { useFormik } from "formik";
import * as Yup from "yup";
import { reqGetHistoryByAssetId, reqReturnAsset } from "helpers/fakebackend_helper";

const AssetReturn = (props) => {
    const { onSuccess, onError, assetId, isShow, toggle } = props
    const [otherErrorMessage, setOtherErrorMessage] = useState("")

    const defaultReturnAssetInfo = {
        startUsingDate: "",
        returnedDate: "",
        employeeObject: {
            id: "",
            name: ""
        },
        returned: "true",
        id: ""
    }

    const [returnAssetInfo, setReturnAssetInfo] = useState({ ...defaultReturnAssetInfo })

    const getHistoryOfAsset = async () => {
        try {
            const res = await reqGetHistoryByAssetId({ params: { id: assetId } })
            setReturnAssetInfo({
                ...returnAssetInfo,
                id: res._id,
                returnedDate: res.returnedDate,
                startUsingDate: res.startUsingDate,
                // returned: (res.returned === "true" || res.returned === true) ? "true" : "false",
                returned: "true" //hardcode vs case return asset

            })
        }
        catch (error) {
            onError(error.message || error)
            onToggle()
        }
    }

    useMemo(async () => {
        await getHistoryOfAsset()
    }, [])

    const validationInitValue = {
        id: (returnAssetInfo && returnAssetInfo.id) || '',
        startUsingDate: (returnAssetInfo && returnAssetInfo.startUsingDate) || '',
        returnedDate: (returnAssetInfo && returnAssetInfo.returnedDate) || '',
        returned: (returnAssetInfo && returnAssetInfo.returned) || "true",
    }

    const validationRule = {
        id: Yup.string().required("Asset ID is required"),
        returned: Yup.string().required("Status is required"),
        startUsingDate: Yup.date().required("Start using date is required"),
        returnedDate: Yup.date().required("Return date is required"),
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: validationInitValue,
        validationSchema: Yup.object(validationRule),
        onSubmit: async (values) => {
            let data = {
                id: values.id,
                startUsingDate: values.startUsingDate,
                returned: values.returned,
                returnedDate: values.returnedDate,
            };

            try {
                await reqReturnAsset(data)
                await onSuccess()
                validation.resetForm();
                toggle();
            } catch (error) {
                return setOtherErrorMessage(error.message || error)
            }
        },
    });

    const onToggle = () => {
        toggle()
    }

    const statusOptions = [
        { value: "true", label: "Returned" },
        { value: "false", label: "Using" },
    ]

    return (
        <React.Fragment>
            <Modal isOpen={isShow} toggle={onToggle}>
                <ModalHeader toggle={onToggle} tag="h4">Return Asset</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row form>
                            <Col className="col-12">
                                <div className="mb-3">
                                    {otherErrorMessage ? (<p className="alert alert-danger">{otherErrorMessage}</p>) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Status</Label>
                                    <ReactSelect
                                        defaultValue="true"
                                        options={statusOptions}
                                        onChange={(option) => {
                                            setReturnAssetInfo({
                                                ...returnAssetInfo,
                                                returned: option.value
                                            })

                                            validation.validateField("returned")
                                        }}
                                        isDisabled
                                        onBlur={validation.handleBlur}
                                        value={statusOptions.filter(option => {
                                            const status = "true";
                                            return status === option.value
                                        })}
                                    />

                                    <Input type="hidden" invalid={validation.errors.returned ? true : false} />
                                    {validation.touched.returned && validation.errors.returned ? (
                                        <FormFeedback type="invalid">{validation.errors.returned}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Start using date</Label>
                                    <InputGroup>
                                        <Flatpickr
                                            className="form-control d-block"
                                            placeholder="YYYY-MM-DD"
                                            options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                            }}
                                            onChange={(date, dateString) => {
                                                setReturnAssetInfo({
                                                    ...returnAssetInfo,
                                                    startUsingDate: dateString
                                                })
                                                validation.validateField("startUsingDate")
                                            }}
                                            name="startUsingDate"
                                            onBlur={validation.handleBlur}
                                            value={returnAssetInfo.startUsingDate}
                                            disabled={true}
                                        />
                                    </InputGroup>
                                    <Input type="hidden" invalid={validation.errors.startUsingDate ? true : false} />
                                    {validation.errors.startUsingDate ? (
                                        <FormFeedback type="invalid">{validation.errors.startUsingDate}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Returned date</Label>
                                    <InputGroup>
                                        <Flatpickr
                                            className="form-control d-block"
                                            placeholder="YYYY-MM-DD"
                                            options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                            }}
                                            onChange={(date, dateString) => {
                                                setReturnAssetInfo({
                                                    ...returnAssetInfo,
                                                    returnedDate: dateString
                                                })
                                                validation.validateField("returnedDate")
                                            }}
                                            name="returnedDate"
                                            value={returnAssetInfo.returnedDate}

                                        />
                                    </InputGroup>
                                    <Input type="hidden" invalid={validation.touched.returnedDate && validation.errors.returnedDate ? true : false} />
                                    {validation.touched.returnedDate && validation.errors.returnedDate ? (
                                        <FormFeedback type="invalid">{validation.errors.returnedDate}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn btn-success save-user"
                                    >
                                        Save
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

AssetReturn.propTypes = {
    assetId: PropTypes.string.isRequired,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    isShow: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired
}

export default AssetReturn