import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Card, CardBody, Modal, ModalBody, ModalHeader } from "reactstrap"
import { dateTimeToString, htmlDecode } from "helpers/utils";
import moment from "moment";

const AuditPayment = (props) => {
    const { payment, toggle, isShow } = props
    const [audit, setAudit] = useState([])

    const sortAuditByTime = (audit) => {
        return audit.sort((audit1, audit2) => {
            const time1 = audit1.time
            const time2 = audit2.time

            const unix1 = moment(time1).utc().unix()
            const unix2 = moment(time2).utc().unix()
            return unix2 - unix1
        })
    }

    useEffect(() => {
        const audit = payment?.audit ?? []
        const auditSorted = sortAuditByTime(audit)

        setAudit(auditSorted)
    }, [props.payment])

    const mapFieldName = (fieldName) => {
        const listField = {
            name: "Name",
            type: "Type",
            paymentDate: "Payment Date",
            accountReceived: "Account Received",
            paymentAccount: "Payment Account",
            transactionAddress: "Transaction Address",
            note: "Note",
            partner: "Partner",
            resovled: "Resovled",
            amount: "Amount",
            registedEmail: "Registed Email",
            registedPhone: "Registed Phone",
            resovledDate: "Last Payment Date",
            repeatType: "Repeat Type",
            repeatNumber: "Repeat",
        }

        return listField[fieldName] ?? ""
    }

    const renderAudit = (audit, key) => {
        let modifiedBy = audit?.userChange ? audit.userChange : {}
        modifiedBy = `${modifiedBy?.firstName} ${modifiedBy?.lastName}(${modifiedBy?.userName})`
        const time = audit?.time ? dateTimeToString(audit.time) : ""
        const changes = audit.changes ?? []
        return (
            <React.Fragment key={`block_audit_${key}`}>
                <Card style={{border: "1px solid #eee", borderRadius: "5px"}}>
                    <CardBody>
                        <div>
                            <p className="mb-b2 text-primary"><i className="mdi mdi-account" /> {modifiedBy}</p>
                            <p className="mb-b4 text-danger"><i className="mdi mdi-clock" /> {time}</p>
                            <div className="table-responsive" style={{maxWidth: "100%"}}>
                                <table className="table align-middle table-nowrap table">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{width: "200px"}}>Field Name</th>
                                            <th scope="col">Old Value</th>
                                            <th scope="col">New Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            changes.map((change, index) => {
                                                return (
                                                    <React.Fragment key={`audit_change_row_${index}`}>
                                                        <tr>
                                                            <th scope="row">{mapFieldName(change.field)}</th>
                                                            <td className="text-danger">{htmlDecode(change.oldValue)}</td>
                                                            <td className="text-success">{htmlDecode(change.newValue)}</td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        )
    }

    const renderAuditList = () => {
        return audit.map((value, index) => renderAudit(value, index))
    }

    return (
        <React.Fragment>
            <Modal isOpen={isShow} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle} tag="h4">Audit payment history</ModalHeader>
                <ModalBody>
                    {renderAuditList()}
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

AuditPayment.propTypes = {
    payment: PropTypes.object.isRequired,
    isShow: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
}

export default AuditPayment