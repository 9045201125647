import { ceil, round } from "lodash";
import React, { useState, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types"

const CustomPagination = forwardRef((props, ref) => {
    const RENDER_ITEMS = 5
    const [currentPage, setCurrentPage] = useState({
        currentPageNumber: 1,
        currentPageText: '1'
    });

    const handleClick = async (event, pageNumber) => {
        event.preventDefault()
        await props.onChangePage(pageNumber)
        setCurrentPage({
            ...currentPage, currentPageNumber: pageNumber
        })
    }

    const getLoopFrom = (totalPages = 0, currentPage) => {
        let from = totalPages <= RENDER_ITEMS ? 1 : currentPage
        if (totalPages > RENDER_ITEMS && ((totalPages - RENDER_ITEMS) < currentPage)) {
            from = totalPages - RENDER_ITEMS
        }

        return from
    }

    const getLoopEnd = (totalPages = 0, currentPage) => {
        let to = totalPages <= RENDER_ITEMS ? totalPages : (currentPage + RENDER_ITEMS)
        if (totalPages > RENDER_ITEMS && ((currentPage + RENDER_ITEMS) >= totalPages)) {
            to = totalPages
        }

        return to
    }

    const renderItem = () => {
        const { sizeOfPerPage, totalItem } = props
        const pages = ceil(totalItem / sizeOfPerPage)
        const items = []

        if (!totalItem) {
            return null
        }

        const { currentPageNumber } = currentPage

        const loopFrom = getLoopFrom(pages, currentPageNumber)
        const loopTo = getLoopEnd(pages, currentPageNumber)

        if (currentPageNumber > 1 && RENDER_ITEMS < pages) {
            items.push(<li className="page-item" key="pagination-custom-first" title="first page"><a href="#" className="page-link" onClick={(e) => handleClick(e, 1)}>&lt;&lt;</a></li>)
        }

        if (currentPageNumber > 1) {
            items.push(<li className="page-item" key="pagination-custom-prev" title="previous page"><a href="#" className="page-link" onClick={(e) => handleClick(e, currentPageNumber - 1)}>&lt;</a></li>)
        }


        for (let index = loopFrom; index <= loopTo; index++) {
            items.push(<li key={"pagination-custom-first" + index} className={`page-item ${currentPageNumber === index ? "active" : ""}`}>
                <a href="#" onClick={(e) => {
                    e.preventDefault()
                    if(currentPageNumber !== index){
                        handleClick(e, index)
                    }
                }} className="page-link">{index}</a>
            </li>)
        }

        if (currentPageNumber <= pages - 1) {
            items.push(<li className="page-item" key="pagination-custom-next" title="next page"><a href="#" className="page-link" onClick={(e) => handleClick(e, currentPageNumber + 1)}>&gt;</a></li>)
        }

        if (currentPageNumber <= pages - RENDER_ITEMS) {
            items.push(<li className="page-item" key="pagination-custom-last" title="last page"><a href="#" className="page-link" onClick={(e) => handleClick(e, pages)}>&gt;&gt;</a></li>)
        }

        return items
    }

    useImperativeHandle(ref, () => ({
        refreshCurrentPage: () => {
            setCurrentPage({
                ...currentPage,
                currentPageNumber: 1
            })
        }
    }))

    return (
        <React.Fragment>
            <ul className="pagination react-bootstrap-table-page-btns-ul">
                {renderItem()}
            </ul>
        </React.Fragment>
    );
})

CustomPagination.propTypes = {
    sizeOfPerPage: PropTypes.number,
    totalItem: PropTypes.number,
    onChangePage: PropTypes.func,
};
export default CustomPagination;
