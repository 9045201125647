import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import moment from "moment";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip,
  Toast,
  ToastHeader,
  ToastBody,
  Spinner
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { reqExportAsset, reqGetListAsset, requestDeleteAsset, } from "helpers/fakebackend_helper";
import AssetAsignment from "./AssetAsign";
import AssetReturn from "./AssetReturn";
import CustomPagination from "components/ultils/CustomPagination";
import SearchAsset from "./SearchAsset";
import DeleteModal from "components/Common/DeleteModal";
import AssetEdit from "./AssetEdit";
import { dateToDB, dateToString, htmlDecode } from "helpers/utils";
import { saveAs } from "file-saver";
import { canAccessDelete, canAccessDetail, canAccessEdit } from "./Permission";

let FLAG_FIRT_LOAD = false
const AssetManagerment = props => {
  const newAsset = {
    id: "",
    name: "",
    code: "",
    price: "",
    available: "true",
    description: "",
    warranty: "",
    purchaseDate: "",
    type: "",
    typeObject: null
  }

  const refPagination = useRef()
  const [assetListConfig, setAssetListConfig] = useState({
    page: 1,
    limit: 20,
    search: {
      available: "",
      name: "",
      code: "",
      type: "",
    }
  });

  const [assets, setAssets] = useState({
    data: [],
    totalItems: 0
  })
  const [asset, setAsset] = useState({ ...newAsset });

  const getListAsset = async () => {
    try {
      const filter = {
        page: assetListConfig.page,
        name: assetListConfig.search.name || "",
        code: assetListConfig.search.code || "",
        available: assetListConfig.search.available || "",
        type: assetListConfig.search.type || "",
      }
      const result = await reqGetListAsset(filter)
      FLAG_FIRT_LOAD = true
      setAssets({
        ...assets,
        data: result.items,
        totalItems: result.totalItems
      })

    } catch (error) {
      setToast({
        message: error.message || error,
        isOpen: true
      })

      autoCloseToast()
    }
  }

  useEffect(async () => {
    await getListAsset()
  }, [])

  useEffect(async () => {
    if (FLAG_FIRT_LOAD) {
      await getListAsset()
    }
  }, [assetListConfig])

  const onChangePageNumber = (pageNumber) => {
    setAssetListConfig({
      ...assetListConfig,
      page: parseInt(pageNumber)
    })
  }

  const onSearch = (data) => {
    setAssetListConfig({
      ...assetListConfig,
      search: {
        ...assetListConfig.search,
        ...data
      },
      page: 1
    })
    refPagination.current.refreshCurrentPage()
  }

  const [toast, setToast] = useState({
    message: "",
    isOpen: false
  })

  const autoCloseToast = () => {
    setTimeout(() => {
      setToast({
        message: "",
        isOpen: false
      })
    }, 5000);
  }

  // Create/Edit
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const onEditSuccess = async () => {
    await getListAsset()
    toggle()
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
      setAsset({ ...newAsset });
    } else {
      setModal(true);
    }
  };

  const handleAssetClick = asset => {
    const purchaseDate = moment(asset.purchaseDate)
    setAsset({
      id: asset._id || "",
      name: htmlDecode(asset.name) || "",
      code: htmlDecode(asset.code) || "",
      price: asset.price || "",
      available: (asset.available === true || asset.available == "true") ? "true" : "false",
      description: htmlDecode(asset.description) || "",
      type: asset?.type?._id,
      typeObject: asset.type ? { value: asset?.type?._id, label: htmlDecode(asset?.type?.name) } : null,
      purchaseDate: dateToDB(asset.purchaseDate),
      warranty: asset.warranty || "",
      partner: asset.partner?._id || "",
      partnerObject: asset.partner?._id ? { value: asset.partner._id, label: htmlDecode(asset.partner.providerName) } : null
    });
    setIsEdit(true);
    toggle();
  };

  const handleAddAssetClicks = () => {
    setIsEdit(false);
    setAsset({ ...newAsset });
    toggle();
  };

  //pagination customization
  const pageOptions = {
    sizePerPage: assetListConfig.limit,
    totalSize: assets.totalItems, // replace later with size(assets),
    custom: true,
  };

  const EcommerceAssetColumns = toggleModal => [
    {
      dataField: "name",
      text: "Asset Name",
      formatter: (rowContent, row) => {
        return htmlDecode(rowContent)
      }
    },
    {
      dataField: "code",
      text: "Asset code(serial)",
      formatter: (rowContent, row) => {
        return htmlDecode(rowContent)
      }
    },
    {
      dataField: "type",
      text: "Type",
      formatter: (rowContent, row) => {
        return htmlDecode(row?.type?.name)
      }
    },
    {
      dataField: "available",
      text: "Status",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        const className = row.available ? "success" : "primary";
        const label = row.available ? "Available" : "Unavaiable";
        return (
          <Badge
            className={"font-size-12 badge-soft-" + className}
            color={className}
            pill
          >{label}</Badge>
        )
      },
    },
    {
      dataField: "price",
      text: "Price"
    },
    {
      dataField: "purchaseDate",
      text: "Purchase date",
      formatter: (rowContent, row) => {
        return dateToString(row.purchaseDate)
      }
    },
    {
      dataField: "warranty",
      text: "Warranty"
    },
    {
      dataField: "employeeId",
      text: "Assigned to",
      formatter: (cellContent, asset) => htmlDecode(asset.employeeId?.fullname)
    },
    {
      dataField: "partner",
      text: "Partner",
      formatter: (cellContent, asset) => htmlDecode(asset.partner?.providerName)
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, asset) => (
        <>
          <div className="d-flex gap-3">
            {
              canAccessDetail() ? <Link
                to={`/asset/detail/${asset._id}`}
                className="text-warning"
              >
                <i className="mdi mdi-eye font-size-18" id={`detailtooltip${asset._id}`} />
                <UncontrolledTooltip placement="top" target={`detailtooltip${asset._id}`}>
                  View Detail
                </UncontrolledTooltip>
              </Link> : null
            }
            {canAccessEdit() ? (
              <React.Fragment>
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => handleAssetClick(asset)}
                >
                  <i className="mdi mdi-pencil font-size-18" id={`edittooltip${asset._id}`} />
                  <UncontrolledTooltip placement="top" target={`edittooltip${asset._id}`}>
                    Edit
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className={(asset.available === true || asset.available === "true") ? "text-primary" : " text-secondary"}
                  onClick={(asset.available === true || asset.available === "true") ? () => onClickAssign(asset) : () => { }}
                >
                  <i className="mdi mdi-account-reactivate font-size-18" id={`assignTooltip${asset._id}`} />
                  <UncontrolledTooltip placement="top" target={`assignTooltip${asset._id}`}>Assign</UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className={((asset.available === false || asset.available === "false") && asset.employeeId?._id) ? "text-info" : " text-secondary"}
                  onClick={((asset.available === false || asset.available === "false") && asset.employeeId?._id) ? () => onReturnAsset(asset) : () => { }}
                >
                  <i className="mdi mdi-keyboard-return font-size-18" id={`returnTooltip${asset._id}`} />
                  <UncontrolledTooltip placement="top" target={`returnTooltip${asset._id}`}>Return Asset</UncontrolledTooltip>
                </Link>
              </React.Fragment>
            )
              : null
            }
            {
              canAccessDelete() ? <Link
                to="#"
                className="text-danger"
                onClick={() => onClickDelete(asset)}
              >
                <i className="mdi mdi-delete font-size-18" id={`deletetooltip${asset._id}`} />
                <UncontrolledTooltip placement="top" target={`deletetooltip${asset._id}`}>
                  Delete
                </UncontrolledTooltip>
              </Link>
                : null
            }

          </div>
        </>
      ),
    },
  ];

  //delete asset
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (asset) => {
    setAsset({
      id: asset._id || "",
      name: htmlDecode(asset.name || ""),
      code: htmlDecode(asset.code || ""),
      price: asset.price || "",
      available: (asset.available === true || asset.available == "true") ? "true" : "false",
      description: htmlDecode(asset.description || ""),
    });
    setDeleteModal(true);
  };

  const onDeleteAsset = async () => {
    try {
      const res = await requestDeleteAsset(asset.id)
      if (res.status === "success") {
        await getListAsset()
      }
      else {
        throw res.message
      }
    } catch (error) {
      setToast({
        isOpen: true,
        message: error.message
      })

      autoCloseToast()
    }
  }

  const handleDeleteAsset = async () => {
    if (asset.id) {
      await onDeleteAsset(asset)
      await getListAsset()

      refPagination.current.refreshCurrentPage()
      setDeleteModal(false)
    }
  };

  //Handle Assign Asset
  const [showAssignModal, setShowAssginModal] = useState(false)
  const toggleModalAssign = () => {
    setShowAssginModal(!showAssignModal)
  }

  const onClickAssign = (asset) => {
    if (asset && (asset.available === true || asset.available === "true")) {
      const employee = asset.employeeId || {}
      const { _id, fullname, code } = employee
      setAsset({
        id: asset._id || "",
        name: asset.name || "",
        employee: {
          id: _id || "",
          fullname: fullname || "",
          code: code || ""
        }
      });

      toggleModalAssign()
    }
  }

  const onErrorAssign = (message) => {
    setShowAssginModal(false)
    setToast({
      message: message,
      isOpen: true
    })

    autoCloseToast()
  }

  const onSuccessAssign = async () => {
    await getListAsset()
  }

  //Return asset
  const [showReturnAssetModal, setShowReturnAssetModal] = useState(false)
  const toggleModalReturn = () => {
    setShowReturnAssetModal(!showReturnAssetModal)
  }

  const onReturnAsset = async (asset) => {
    if (asset && (asset.available === false || asset.available === "false") && asset.employeeId?._id) {
      const employee = asset.employeeId || {}
      const { _id, fullname, code } = employee
      setAsset({
        id: asset._id || "",
        name: asset.name || "",
        employee: {
          id: _id || "",
          fullname: fullname || "",
          code: code || ""
        }
      });

      toggleModalReturn()
    }
  }

  const onSuccessReturnAsset = async () => {
    await getListAsset()
  }

  const onErrorReturnAsset = (message) => {
    onErrorAssign(message)
  }

  //Export
  const clickToExport = async () => {
    try {
      const filter = {
        page: assetListConfig.page,
        name: assetListConfig.search.name || "",
        code: assetListConfig.search.code || "",
        available: assetListConfig.search.available || "",
        type: assetListConfig.search.type || "",
      }
      const headers = {
        responseType: 'blob'
      }
      const result = await reqExportAsset({
        params: filter,
        headers
      })

      if (result) {
        const API_URL = process.env.REACT_APP_API_URL;
        const splitURL = result.split("/")
        const fileName = splitURL[splitURL.length - 1]
        saveAs(API_URL + result, fileName)
      }
    } catch (error) {
      setToast({
        message: error.message || error,
        isOpen: true
      })

      autoCloseToast()
    }
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteAsset}
        onCloseClick={() => {
          setDeleteModal(false)
          setAsset(newAsset)
        }}
      />
      {showAssignModal ? (
        <AssetAsignment
          asset={asset}
          onSuccess={onSuccessAssign}
          onError={onErrorAssign}
          isShow={showAssignModal}
          toggle={toggleModalAssign}
        />
      ) : null}
      {
        showReturnAssetModal ? (
          <AssetReturn
            assetId={asset.id}
            onSuccess={onSuccessReturnAsset}
            onError={onErrorReturnAsset}
            isShow={showReturnAssetModal}
            toggle={toggleModalReturn}
          />
        ) : null
      }
      {
        modal ? <AssetEdit asset={asset} isEdit={isEdit} isShow={modal} toggle={toggle} onSuccess={onEditSuccess} /> : null
      }
      <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: "1005" }}>
        <Toast isOpen={toast.isOpen}>
          <ToastHeader icon={<Spinner type="grow" size="sm" color="danger" />} toggle={() => {
            setToast({ ...toast, isOpen: !toast.isOpen })
          }}>Error</ToastHeader>
          <ToastBody>{toast.message}</ToastBody>
        </Toast>
      </div>
      <div className="page-content">
        <MetaTags>
          <title>Assets Managerment</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Asset Managerment" breadcrumbItem="Assets" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="_id"
                    columns={EcommerceAssetColumns(toggle)}
                    data={assets.data}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={assets.data}
                        columns={EcommerceAssetColumns(toggle)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <SearchAsset
                              onSeach={onSearch}
                              onCreate={handleAddAssetClicks}
                              onExport={clickToExport}
                            />
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    basseted={false}
                                    striped={false}
                                    defaultSorted={[]}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>

                              </Col>
                            </Row>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <CustomPagination ref={refPagination} onChangePage={onChangePageNumber} sizeOfPerPage={pageOptions.sizePerPage} totalItem={pageOptions.totalSize} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AssetManagerment);

