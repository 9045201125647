import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap"

const SearchRole = (props) => {
    const { onSearch, onError } = props
    const [formSearch, setDataForm] = useState({
        roleName: "",
    })

    const history = useHistory()

    return (
        <React.Fragment>
            <Row className="mb-2">
                <Col sm="6">
                    <FormGroup>
                        <Label>Name :</Label>
                        <Input
                            name="roleName"
                            type="text"
                            value={formSearch.roleName || ""}
                            onChange={(e) => setDataForm({ ...formSearch, roleName: e.target.value })}
                            autoComplete="off"
                        />
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <FormGroup>
                        <Label style={{ display: "block", color: "transparent" }}>NONE</Label>
                        <Button
                            type="button"
                            color="info"
                            className="btn-rounded  mb-2 me-2"
                            onClick={(e) => {
                                onSearch(formSearch)
                            }}
                        >
                            <i className="bx bx-search-alt me-1" />
                            Search
                        </Button>
                        <Button
                            type="button"
                            color="success"
                            className="btn-rounded  mb-2 me-2"
                            onClick={() => {
                                history.push("/role/create")
                            }}
                        >
                            <i className="mdi mdi-plus me-1" />
                            Add New Role
                        </Button>
                    </FormGroup>
                </Col>
            </Row>
        </React.Fragment>
    )
}

SearchRole.propTypes = {
    onSearch: PropTypes.func.isRequired,
    onError: PropTypes.func,
}

export default SearchRole