import PropTypes from "prop-types";
import {Card, CardBody} from "reactstrap";
import React from "react";
import {htmlDecode} from "../../../../helpers/utils";
import css from "./AuditProduct.module.scss"

const AuditProduct = ({auditProduct}) => {
    const product = auditProduct?.product ?? {}
    const mapFieldNameProduct = (fieldName) => {
        const listFieldName = {
            product: "Product",
            isCanceled: "Canceled",
            requested: {
                quantity: "Requested quantity",
                unit: "Requested unit"
            },
            export: {
                quantity: "Export quantity",
                price: "Export price",
                priceUnit: "Export unit"
            },
            import: {
                quantity: "Import quantity",
                price: "Import price",
                priceUnit: "Import unit"
            }
        }

        return listFieldName[fieldName]
    }
    const getTitle = () => {
        const newValue = product?.new ?? {}
        const oldValue = product?.old ?? {}
        if(Object.keys(newValue).length) {
            const productName = newValue?.name ?? {}
            return <div className={`${css.auditProduct} ${css.title}`}>
                <p>{productName?.VN ?? ""}</p>
                <p>{productName?.EN ?? ""}</p>
            </div>
        }
        if(Object.keys(oldValue).length) {
            const productName = oldValue?.name ?? {}
            return <div className={`${css.auditProduct} ${css.title}`}>
                <p>{productName?.VN ?? ""}</p>
                <p>{productName?.EN ?? ""}</p>
            </div>
        }

        return  <div className={`${css.auditProduct} ${css.title}`}>
            <p>Product N/A</p>
            <p>Product N/A</p>
        </div>
    }
    const getProductName = (product) => {
        const productName = product?.name ?? {}
        return <div className={css.auditProduct}>
            <p>{productName?.VN ?? "N/A"}</p>
            <p>{productName?.EN ?? "N/A"}</p>
        </div>
    }

    const renderProduct = () => {
        const output = []

        for (const key in auditProduct) {
            const value = auditProduct[key]
            const label = mapFieldNameProduct(key)
            if(typeof value === "object" && value !== null && value.hasOwnProperty("old") === false && value.hasOwnProperty("new") === false) {
                for (const subKey in value) {
                    const subValue = value[subKey]
                    const subLabel = label[subKey]
                    output.push(<tr key={`log_batch_key_${Math.floor(Math.random() * 100)}_${subKey}`}>
                        <th scope="row">{subLabel}</th>
                        <td className="text-danger">{htmlDecode(subValue?.old ?? "")}</td>
                        <td className="text-success">{htmlDecode(subValue?.new ?? "")}</td>
                    </tr>)
                }
            }
            else {
                if(key === "product") {
                    const oldId = value?.old?.id ?? null
                    const newId = value?.new?.id ?? null
                    if(oldId === newId) {
                        continue
                    }
                    output.push(<tr key={`log_batch_key_${Math.floor(Math.random() * 100)}_${key}`}>
                        <th scope="row">{label}</th>
                        <td className="text-danger">{htmlDecode(getProductName(value?.old) ?? "")}</td>
                        <td className="text-success">{htmlDecode(getProductName(value?.new) ?? "")}</td>
                    </tr>)
                }
                else {
                    output.push(<tr key={`log_batch_key_${Math.floor(Math.random() * 100)}_${key}`}>
                        <th scope="row">{label}</th>
                        <td className="text-danger">{htmlDecode(value?.old ?? "")}</td>
                        <td className="text-success">{htmlDecode(value?.new ?? "")}</td>
                    </tr>)
                }
            }
        }

        return output
    }

    return <Card style={{border: "1px solid #eee", borderRadius: "5px"}}>
        <CardBody>
            <div>
                <div className="mb-b2 text-primary"><i className="mdi mdi-webpack" />
                    {getTitle()}
                </div>
                <div className="table-responsive mt-2" style={{maxWidth: "100%"}}>
                    <table className="table align-middle table-nowrap table">
                        <thead>
                        <tr>
                            <th scope="col" style={{width: "200px"}}>Field Name</th>
                            <th scope="col">Old Value</th>
                            <th scope="col">New Value</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            renderProduct()
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </CardBody>
    </Card>
}

AuditProduct.propTypes = {
    auditProduct: PropTypes.object.isRequired
}

export default AuditProduct