import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap"
import { canAccessCreate } from "./Permission"

const SearchDepartment = (props) => {
    const { onSearch, onError, onCreate } = props

    const page = useRef(1);
    const menuIsOpen = useRef(false)

    const [formSearch, setDataForm] = useState({
        name: "",
        code: "",
    })

    return (
        <React.Fragment>
            <Row>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Name :</Label>
                        <Input
                            name="name"
                            type="text"
                            value={formSearch.name || ""}
                            onChange={(e) => setDataForm({...formSearch, name: e.target.value})}
                            autoComplete="off"
                        />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Code :</Label>
                        <Input
                            name="code"
                            type="text"
                            value={formSearch.code || ""}
                            onChange={(e) => setDataForm({...formSearch, code: e.target.value})}
                            autoComplete="off"
                        />
                    </FormGroup>
                </div>
            </Row>
            <Row className="mb-2">
                <Col>
                    <div>
                        <Button
                            type="button"
                            color="info"
                            className="btn-rounded  mb-2 me-2"
                            onClick={(e) => {
                                onSearch(formSearch)
                            }}
                        >
                            <i className="bx bx-search-alt me-1" />
                            Search
                        </Button>
                        {canAccessCreate() ? <Button
                            type="button"
                            color="success"
                            className="btn-rounded  mb-2 me-2"
                            onClick={onCreate}
                        >
                            <i className="mdi mdi-plus me-1" />
                            Add New Department
                        </Button> : null}
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

SearchDepartment.propTypes = {
    onSearch: PropTypes.func.isRequired,
    onError: PropTypes.func,
    onCreate: PropTypes.func
}

export default SearchDepartment