import CustomPagination from "components/ultils/CustomPagination"
import React, { useEffect, useRef, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { MetaTags } from "react-meta-tags"
import { Link, withRouter } from "react-router-dom"
import { Badge, Card, CardBody, Col, Container, Row, Spinner, Toast, ToastBody, ToastHeader, UncontrolledTooltip } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { reqDeletePartner, reqGetListPartner } from "helpers/fakebackend_helper"
import DeleteModal from "components/Common/DeleteModal"
import SearchPartner from "./SearchPartner"
import EditPartner from "./EditPartner"
import moment from "moment"
import { dateToString, htmlDecode } from "helpers/utils"
import { canAccessDelete, canAccessDetail, canAccessEdit } from "./Permission"

const PartnerManagerment = (props) => {
    const refPagination = useRef()
    let FLAG_FIRT_LOAD = useRef(false)
    const [partnerListConf, setDepartmentListConf] = useState({
        page: 1,
        limit: 20,
        search: {
            providerName: "",
            bankAccountNumber: "",
            phoneNumber: "",
            description: "",
        }
    });

    const [partners, setDepartments] = useState({
        data: [],
        totalItems: 0
    })

    const [partner, setPartner] = useState({})

    const partnerTableColumn = [
        {
            dataField: "providerName",
            text: "Provider Name",
            formatter: (rowContent, row) => {
                return htmlDecode(rowContent)
            }
        },
        // {
        //     dataField: "purchaseDate",
        //     text: "Purchase Date",
        //     formatter: (rowConten, row) => {
        //         return dateToString(row.purchaseDate)
        //     }
        // },
        // {
        //     dataField: "warranty",
        //     text: "Warranty"
        // },
        // {
        //     dataField: "bankAccountNumber",
        //     text: "Bank Account Number",
        //     formatter: (rowContent, row) => {
        //         return htmlDecode(rowContent)
        //     }
        // },
        {
            dataField: "cardName",
            text: "Card Name",
            formatter: (rowContent, row) => {
                return htmlDecode(rowContent)
            }
        },
        {
            dataField: "phoneNumber",
            text: "Phone number",
            formatter: (rowContent, row) => {
                if (row.phoneNumber && row.phoneNumber2) {
                    return htmlDecode(`${row.phoneNumber} / ${row.phoneNumber2}`)
                }
                if (row.phoneNumber) {
                    return htmlDecode(`${row.phoneNumber}`)
                }
                if (row.phoneNumber2) {
                    return htmlDecode(`${row.phoneNumber2}`)
                }
            }
        },
        {
            dataField: "description",
            text: "Description",
            formatter: (rowContent, row) => {
                return htmlDecode(rowContent)
            }
        },
        {
            dataField: "action",
            isDummyField: true,
            text: "Action",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <>
                    <div className="d-flex gap-3">
                        {canAccessDetail() ? <Link
                            to={`/asset-partner/detail/${row._id}`}
                            className="text-primary"
                        >
                            <i className="mdi mdi-eye font-size-18" id={`edittooltip${row._id}`} />
                            <UncontrolledTooltip placement="top" target={`edittooltip${row._id}`}>Deatil</UncontrolledTooltip>
                        </Link> : null}

                        {canAccessEdit() ? <Link
                            to="#"
                            className="text-success"
                            onClick={() => onClickEdit(row)}
                        >
                            <i className="mdi mdi-pencil font-size-18" id={`edittooltip${row._id}`} />
                            <UncontrolledTooltip placement="top" target={`edittooltip${row._id}`}>Edit</UncontrolledTooltip>
                        </Link>: null}
                        {canAccessDelete() ? <Link
                            to="#"
                            className="text-danger"
                            onClick={() => onDelete(row)}
                        >
                            <i className="mdi mdi-delete font-size-18" id={`deletetooltip${row._id}`} />
                            <UncontrolledTooltip placement="top" target={`deletetooltip${row._id}`}>Delete</UncontrolledTooltip>
                        </Link> : null}
                    </div>
                </>
            ),
        },
    ]

    const [toast, setToast] = useState({
        message: "",
        isOpen: false
    })

    const autoCloseToast = (message) => {
        setToast({
            message: message,
            isOpen: true
        })
        setTimeout(() => {
            setToast({
                message: "",
                isOpen: false
            })
        }, 5000);
    }

    const pageOptions = {
        sizePerPage: partnerListConf.limit,
        totalSize: partners.totalItems, // replace later with size(partners),
        custom: true,
    };

    const onChangePageNumber = (pageNumber) => {
        setDepartmentListConf({
            ...partnerListConf,
            page: parseInt(pageNumber)
        })
    }

    const onSearch = (data) => {
        setDepartmentListConf({
            ...partnerListConf,
            search: {
                ...partnerListConf.search,
                ...data
            },
            page: 1
        })

        refPagination.current.refreshCurrentPage()
    }

    const onSearchError = (message) => {
        autoCloseToast(message)
    }

    const getListPartners = async () => {
        try {
            const filter = {
                params: {
                    page: partnerListConf.page,
                    providerName: partnerListConf.search.providerName || "",
                    bankAccountNumber: partnerListConf.search.bankAccountNumber || "",
                    phoneNumber: partnerListConf.search.phoneNumber || "",
                    description: partnerListConf.search.description || "",
                }
            }

            const result = await reqGetListPartner(filter)
            FLAG_FIRT_LOAD.current = true

            setDepartments({
                ...partners,
                data: result.items,
                totalItems: result.totalItems
            })

        } catch (error) {
            autoCloseToast(error.message || error)
        }
    }

    useEffect(async () => {
        await getListPartners()
    }, [])

    useEffect(async () => {
        if (FLAG_FIRT_LOAD.current) {
            await getListPartners()
        }
    }, [partnerListConf])

    //Create / Edit partner
    const [modalEdit, setModalEdit] = useState(false)
    const toggleModal = () => {
        setModalEdit(!modalEdit)
    }
    const onClickEdit = (partner) => {
        console.log(partner)
        setPartner(partner)
        setModalEdit(true)
    }

    const onSuccessEdit = async () => {
        await getListPartners()
    }

    const onCreateClick = () => {
        setPartner({})
        setModalEdit(true)
    }

    //delete
    const [deleteModal, setDeleteModal] = useState(false)
    const onDelete = async (partner) => {
        setPartner(partner)
        setDeleteModal(true)
    }

    const handleDelete = async () => {
        try {
            await reqDeletePartner(partner?._id)
            await getListPartners()
            setDeleteModal(false)
        } catch (error) {
            autoCloseToast(error.message || error)
            setDeleteModal(false)
        }
    }

    return (
        <React.Fragment>
            <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: "1005" }}>
                <Toast isOpen={toast.isOpen}>
                    <ToastHeader icon={<Spinner type="grow" size="sm" color="danger" />} toggle={() => {
                        setToast({ ...toast, isOpen: !toast.isOpen })
                    }}>Error</ToastHeader>
                    <ToastBody>{toast.message}</ToastBody>
                </Toast>
            </div>
            {deleteModal ? <DeleteModal show={deleteModal} onDeleteClick={handleDelete} onCloseClick={() => {
                setDeleteModal(false)
                setPartner({})
            }}
            /> : null}
            {modalEdit ? <EditPartner isShow={modalEdit} partner={partner} onSuccess={onSuccessEdit} toggle={toggleModal} /> : null}
            <div className="page-content">
                <MetaTags>
                    <title>Partner Managerment</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Partner Managerment" breadcrumbItem="" />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField="_id"
                                        columns={partnerTableColumn}
                                        data={partners.data}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField="_id"
                                                data={partners.data}
                                                columns={partnerTableColumn}
                                                bootstrap4
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <SearchPartner onSearch={onSearch} onError={onSearchError} onCreate={onCreateClick} />
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        keyField="id"
                                                                        responsive
                                                                        basseted={false}
                                                                        striped={false}
                                                                        classes={
                                                                            "table align-middle table-nowrap table-check"
                                                                        }
                                                                        headerWrapperClasses={"table-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>

                                                            </Col>
                                                        </Row>
                                                        <div className="pagination pagination-rounded justify-content-end mb-2">
                                                            <CustomPagination ref={refPagination} onChangePage={onChangePageNumber} sizeOfPerPage={pageOptions.sizePerPage} totalItem={pageOptions.totalSize} />
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(PartnerManagerment)