import { ceil, throttle } from "lodash";
import ReactSelect from "react-select";

import React, { forwardRef, useImperativeHandle, useState, useRef, useEffect } from "react"
import * as PropTypes from "prop-types"
import { FormFeedback, Input } from "reactstrap";
import { reqGetListPartnerOptions } from "helpers/fakebackend_helper";

const SelectPartner = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {

        }
    })

    const [otherErrorMessage, setOtherErrorMessage] = useState("")

    const {onBlur, onChange, isClear} = props
    const isClearable = isClear ?? false

    const [optionPartner, setOptionPartner] = useState({
        isLoading: false,
        options: [],
        textSearch: "",
        totalItems: 0
    })

    const menuIsOpen = useRef(false)
    const PAGE = useRef(1);
    const [textSearch, changeTextSearch] = useState("")

    const getAssetTypes = async (textSearch = "") => {
        if (!optionPartner.isLoading && menuIsOpen.current) {
            setOptionPartner({
                ...optionPartner,
                isLoading: true
            })
            const config = {
                params: {
                    name: textSearch,
                    page: PAGE.current
                }
            }

            const options = PAGE.current > 1 ? optionPartner.options : []
            let totalItems = 0
            try {
                const res = await reqGetListPartnerOptions(config)
                totalItems = res.totalItems || 0
                res?.items.map((val) => {
                    const { _id: value, providerName } = val
                    options.push({ value, label: providerName })
                })
            } catch (error) {
                setOtherErrorMessage(error.message || error)
            }

            setOptionPartner({
                ...optionPartner,
                options,
                isLoading: false,
                totalItems
            })
        }
    }

    const throttled = useRef(throttle(async (newValue) => {
        PAGE.current = 1
        await getAssetTypes(newValue)
    }, 1000))

    useEffect(() => {
        throttled.current(textSearch)
    }, [textSearch])

    const handleOnScrollToBottom = async () => {
        if (!optionPartner.isLoading) {
            const { totalItems } = optionPartner
            const LIMIT = 20
            const totalPage = ceil(totalItems / LIMIT)
            const newPageNumber = (PAGE.current < totalPage) ? (PAGE + 1) : PAGE.current
            if (newPageNumber != PAGE.current) {
                PAGE.current = newPageNumber
                await getAssetTypes()
            }
        }
    }

    return (
        <React.Fragment>
            <ReactSelect
                blurInputOnSelect={false}
                isLoading={optionPartner.isLoading}
                onInputChange={(text) => {
                    changeTextSearch(text)
                }}
                onChange={(option) => {
                    typeof onChange === "function" ? onChange(option) : null
                }}
                onMenuOpen={async () => {
                    menuIsOpen.current = true
                    await getAssetTypes()
                }}
                options={optionPartner.options}
                name="type"
                onBlur={(e) => {
                    menuIsOpen.current = false
                    typeof onBlur === "function" ? onBlur(e) : null
                }}
                value={props.value}
                onMenuScrollToBottom={async () => {
                    await handleOnScrollToBottom()
                }}
                isClearable={isClearable}
            />
            <Input type="hidden" invalid={!!otherErrorMessage} />
            <FormFeedback>{otherErrorMessage}</FormFeedback>
        </React.Fragment>
    )

})

SelectPartner.propTypes = {
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.any,
    isClear: PropTypes.bool
}

export default SelectPartner