import CustomPagination from "components/ultils/CustomPagination"
import React, { useEffect, useRef, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { MetaTags } from "react-meta-tags"
import { Link, withRouter } from "react-router-dom"
import { Badge, Card, CardBody, Col, Container, Row, Spinner, Toast, ToastBody, ToastHeader, UncontrolledTooltip } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import EditEmployee from "./EditEmployee"
import SearchEmployee from "./SearchEmployee"
import { reqDeleteEmployee, reqGetListEmployees } from "helpers/fakebackend_helper"
import DeleteModal from "components/Common/DeleteModal"
import { canAccessDelete, canAccessDetail, canAccessEdit } from "./Permission"
import { htmlDecode } from "helpers/utils"

const EmployeeManagerment = (props) => {
    const refPagination = useRef()
    let FLAG_FIRT_LOAD = useRef(false)
    const [employeeListConf, setEmployeeListConf] = useState({
        page: 1,
        limit: 20,
        search: {
            code: "",
            fullname: "",
            status: "",
        }
    });

    const [employees, setEmployees] = useState({
        data: [],
        totalItems: 0
    })

    const [employee, setEmployee] = useState({})

    const employeeTableColumn = [
        {
            dataField: "code",
            text: "Code",
            formatter: (rowContent, row) => {
                return htmlDecode(rowContent)
            }
        },
        {
            dataField: "idNumber",
            text: "ID Number",
            formatter: (rowContent, row) => {
                return htmlDecode(rowContent)
            }
        },
        {
            dataField: "firstName",
            text: "Firts Name",
            formatter: (rowContent, row) => {
                return htmlDecode(rowContent)
            }
        },
        {
            dataField: "lastName",
            text: "Last Name",
            formatter: (rowContent, row) => {
                return htmlDecode(rowContent)
            }
        },
        {
            dataField: "status",
            text: "Status",
            formatter: (rowContent, row) => {
                const status = row.status === true || row.status === "true" ? true : false
                const className = status ? "success" : "primary";
                const label = status ? "Active" : "Inactive";
                return (
                    <Badge
                        className={"font-size-12 badge-soft-" + className}
                        color={className}
                        pill
                    >{label}</Badge>)
            }
        },
        {
            dataField: "passportOrIDNumber",
            text: "Passport or ID Card Number",
            formatter: (rowContent, row) => {
                return htmlDecode(rowContent)
            }
        },
        {
            dataField: "action",
            isDummyField: true,
            text: "Action",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <>
                    <div className="d-flex gap-3">
                        {canAccessDetail() ? <Link
                            to={`/employee/detail/${row._id}`}
                            className="text-primary"
                        >
                            <i className="mdi mdi-eye font-size-18" id={`detailtooltip${row._id}`} />
                            <UncontrolledTooltip placement="top" target={`detailtooltip${row._id}`}>Deatil</UncontrolledTooltip>
                        </Link> : null}

                        {canAccessEdit() ? <Link
                            to="#"
                            className="text-success"
                            onClick={() => onClickEdit(row)}
                        >
                            <i className="mdi mdi-pencil font-size-18" id={`edittooltip${row._id}`} />
                            <UncontrolledTooltip placement="top" target={`edittooltip${row._id}`}>Edit</UncontrolledTooltip>
                        </Link> : null}
                        {canAccessDelete() ? <Link
                            to="#"
                            className="text-danger"
                            onClick={() => onDelete(row)}
                        >
                            <i className="mdi mdi-delete font-size-18" id={`deletetooltip${row._id}`} />
                            <UncontrolledTooltip placement="top" target={`deletetooltip${row._id}`}>Delete</UncontrolledTooltip>
                        </Link> : null}
                    </div>
                </>
            ),
        },
    ]

    const [toast, setToast] = useState({
        message: "",
        isOpen: false
    })

    const autoCloseToast = (message) => {
        setToast({
            message: message,
            isOpen: true
        })
        setTimeout(() => {
            setToast({
                message: "",
                isOpen: false
            })
        }, 5000);
    }

    const pageOptions = {
        sizePerPage: employeeListConf.limit,
        totalSize: employees.totalItems, // replace later with size(employees),
        custom: true,
    };

    const onChangePageNumber = (pageNumber) => {
        setEmployeeListConf({
            ...employeeListConf,
            page: parseInt(pageNumber)
        })
    }

    const onSearch = (data) => {
        setEmployeeListConf({
            ...employeeListConf,
            search: {
                ...employeeListConf.search,
                ...data
            },
            page: 1
        })

        refPagination.current.refreshCurrentPage()
    }

    const onSearchError = (message) => {
        autoCloseToast(message)
    }

    const getListEmployees = async () => {
        try {

            let params = {page: employeeListConf.page}

            for (const searchParam in employeeListConf.search) {
                if (Object.hasOwnProperty.call(employeeListConf.search, searchParam)) {
                    const param = employeeListConf.search[searchParam];
                    if(param !== "" && param !== null) {
                        params[searchParam] = param
                    }
                }
            }

            const filter = {params}

            const result = await reqGetListEmployees(filter)
            FLAG_FIRT_LOAD.current = true

            setEmployees({
                ...employees,
                data: result.items,
                totalItems: result.totalItems
            })

        } catch (error) {
            autoCloseToast(error.message || error)
        }
    }

    useEffect(async () => {
        await getListEmployees()
    }, [])

    useEffect(async () => {
        if (FLAG_FIRT_LOAD.current) {
            await getListEmployees()
        }
    }, [employeeListConf])

    //Create / Edit employee
    const [modalEdit, setModalEdit] = useState(false)
    const toggleModal = () => {
        setModalEdit(!modalEdit)
    }
    const onClickEdit = (employee) => {
        setEmployee(employee)
        setModalEdit(true)
    }

    const onSuccessEdit = async () => {
        await getListEmployees()
    }

    const onCreateClick = () => {
        setEmployee({})
        setModalEdit(true)
    }

    //delete employee
    const [deleteModal, setDeleteModal] = useState(false)
    const onDelete = (employee) => {
        setEmployee(employee)
        setDeleteModal(true)
    }

    const handleDelete = async () => {
        try {
            await reqDeleteEmployee(employee?._id)
            await getListEmployees()
            setDeleteModal(false)
        } catch (error) {
            autoCloseToast(error.message || error)
            setDeleteModal(false)
        }
    }

    return (
        <React.Fragment>
            <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: "1005" }}>
                <Toast isOpen={toast.isOpen}>
                    <ToastHeader icon={<Spinner type="grow" size="sm" color="danger" />} toggle={() => {
                        setToast({ ...toast, isOpen: !toast.isOpen })
                    }}>Error</ToastHeader>
                    <ToastBody>{toast.message}</ToastBody>
                </Toast>
            </div>
            {deleteModal ? <DeleteModal show={deleteModal} onDeleteClick={handleDelete} onCloseClick={() => {
                setDeleteModal(false)
                setDepartment({})
            }}
            /> : null}
            {modalEdit ? <EditEmployee isShow={modalEdit} employee={employee} onSuccess={onSuccessEdit} toggle={toggleModal} /> : null}
            <div className="page-content">
                <MetaTags>
                    <title>Employees Managerment</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Employees Managerment" breadcrumbItem="" />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField="_id"
                                        columns={employeeTableColumn}
                                        data={employees.data}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField="_id"
                                                data={employees.data}
                                                columns={employeeTableColumn}
                                                bootstrap4
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <SearchEmployee onSearch={onSearch} onError={onSearchError} onCreate={onCreateClick} />
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        keyField="id"
                                                                        responsive
                                                                        basseted={false}
                                                                        striped={false}
                                                                        classes={
                                                                            "table align-middle table-nowrap table-check"
                                                                        }
                                                                        headerWrapperClasses={"table-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>

                                                            </Col>
                                                        </Row>
                                                        <div className="pagination pagination-rounded justify-content-end mb-2">
                                                            <CustomPagination ref={refPagination} onChangePage={onChangePageNumber} sizeOfPerPage={pageOptions.sizePerPage} totalItem={pageOptions.totalSize} />
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(EmployeeManagerment)