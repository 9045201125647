import CustomPagination from "components/ultils/CustomPagination"
import React, { useEffect, useMemo, useRef, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationProvider,PaginationListStandalone } from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { MetaTags } from "react-meta-tags"
import { Link, withRouter } from "react-router-dom"
import { Badge, Card, CardBody, Col, Container, Row, Spinner, Toast, ToastBody, ToastHeader, UncontrolledTooltip, FormGroup } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { reqDeleteUser, reqGetListProduct } from "helpers/fakebackend_helper"
import SearchProduct from "./SearchProduct"
import EditProduct from "./EditProduct"
import EditPrice from "./EditPrice"
import AddStocks from "./AddStocks"
import ChangePassword from "./ChangePasswordUser"
import DeleteModal from "components/Common/DeleteModal"
import { htmlDecode } from "helpers/utils"  
import Maintenance from "components/Common/Maintenance";
import CustomSearch from "components/Common/CustomSearch";

const ProductManagement = (props) => {
    const [products, setProducts] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [isError, setIsError] = useState([]);
    const [params, setParams] = useState({
        params: {
          page: 1,
          limit: 10,
          SKU: "",
          PRODUCTNAME: "",
          BARCODE: "",
        },
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [productdetails, setProductDetails] = useState([]);

    useEffect(() => {
        const fetch = async () => {
            const data = await reqGetListProduct(params);
          if (data?.status === "fail") {
            setIsError(data);
          } else {
            // setProducts(data?.data?.paginatedResults);
            // console.log(data.data.paginatedResults)
            setProducts( 
              data?.data?.paginatedResults
            );
            let metadata = data?.data?.metadata;
            setPageOptions({
              sizePerPage: metadata?.limit,
              totalSize: metadata?.total,
              custom: true,
            });
            setIsError([]);
          }
        };
        fetch();
      }, [currentPage, params, refresh]);

      const Colums = [
        {
            text: "id",
            dataField: "_id",
            hidden: true,
        },
        {
            dataField: "PRODUCTNAME.VN",
            text: "Tên Sản Phẩm",
            formatter: (rowContent, row) => htmlDecode(rowContent)
        },
        {
            dataField: "SKU",
            text: "SKU"
        },
        {
            dataField: "BARCODE",
            text: "Bar Code",
            formatter: (rowContent, row) => htmlDecode(rowContent)
        },
        {
            dataField: "QUANTITY",
            text: "Quantity",
            sort: true,
            formatter: (rowContent, row) => {
                // console.log(rowContent)
                return row.QUANTITY > 0 ? row.QUANTITY : 0
            }
        },
        {
            dataField: "ENTRYPRICE",
            text: "Entry Price",
            formatter: (rowContent, row) => htmlDecode(rowContent)
        },
        {
            dataField: "PRICE",
            text: "Price",
            formatter: (rowContent, row) => htmlDecode(rowContent)
        },
        {
            dataField: "STATUS",
            text: "Status",
            formatter: (rowContent, row) => {
                const status = row.STATUS === true || row.STATUS === "true" ? true : false
                const className = status ? "success" : "primary";
                const label = status ? "Active" : "Inactive";
                return (
                    <Badge
                        className={"font-size-12 badge-soft-" + className}
                        color={className}
                        pill
                    >{label}</Badge>)
            }
        },
        {
            dataField: "action",
            isDummyField: true,
            text: "Actions",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <>
                    <div className="d-flex gap-3">
                        <Link
                            to="#"
                            className="text-success"
                            onClick={() => onClickEdit(row)}
                        >
                            <i className="mdi mdi-pencil font-size-18" id={`edittooltip${row._id}`} />
                            <UncontrolledTooltip placement="top" target={`edittooltip${row._id}`}>Edit</UncontrolledTooltip>
                        </Link>

                        {/* <Link
                            to="#"
                            className="text-danger"
                            onClick={() => onDelete(row)}
                        >
                            <i className="mdi mdi-delete font-size-18" id={`deletetooltip${row._id}`} />
                            <UncontrolledTooltip placement="top" target={`deletetooltip${row._id}`}>Delete</UncontrolledTooltip>
                        </Link> */}

                        <Link
                            to="#"
                            className="text-warning"
                            onClick={() => onClickEditPrice(row)}
                        >
                            <i className="mdi mdi-notebook-edit font-size-18" id={`editpricetooltip${row._id}`} />
                            <UncontrolledTooltip placement="top" target={`editpricetooltip${row._id}`}>Edit Price</UncontrolledTooltip>
                        </Link>

                        <Link
                            to="#"
                            className="text-info"
                            onClick={() => onClickAddStocks(row)}
                        >
                            <i className="mdi mdi-cart font-size-18" id={`addstockstooltip${row._id}`} />
                            <UncontrolledTooltip placement="top" target={`addstockstooltip${row._id}`}>Add Stocks</UncontrolledTooltip>
                        </Link>
                    </div>
                </>
            ),
        },
    ]


    const [pageOptions, setPageOptions] = useState({
        page: currentPage,
        sizePerPage: 10,
        totalSize: 10, // replace later with size(Order),
        custom: true,
    });

    const onTableChange = (type, { page, sizePerPage }) => {
        setParams(prevState => ({
          params: {
            ...prevState.params,
            page: page,
          },
        })); 
        setCurrentPage(page);
    };  
    
    const [searchTimeout, setSearchTimeout] = useState();
    const searchTable = data => {
      // console.log("resut: ", data)
      setParams(prevState => ({
        params: {
            ...prevState.params,
            limit: data.limit,
            SKU: data.SKU,
            PRODUCTNAME: data.PRODUCTNAME,
            BARCODE: data.BARCODE
        },
        }));
        setCurrentPage(1);
    };
 

 

    //For Action Buttons
    //Edit Price
    const [modalEditPrice, setModalEditPrice] = useState(false)
    const toggleModalEditPrice = () => {
        setModalEditPrice(!modalEditPrice)
        setRefresh(!refresh);
    }
    const onClickEditPrice = (products) => { 
        setProductDetails(products)
        setModalEditPrice(true)
    }

    const onSuccessEditPrice = () => { 
      setRefresh(!refresh);
    }

    const onCloseClick = async () => {
        setModalEditPrice(false)
    }

    const onUpdateClick = () => {
      setProductDetails({})
        setModalEditPrice(true)
    }

    //onClickAddStocks
    //Add Stocks
    const [modalAddStocks, setModalAddStocks] = useState(false)
    const toggleModalAddStocks = () => {
        setModalAddStocks(!modalAddStocks)
        setRefresh(!refresh);
    }
    const onClickAddStocks = (products) => {
      setProductDetails(products)
      setModalAddStocks(true)
    }

    const onSuccessAddStocks = async () => {
      await reqGetListProduct(params)
    }

    const onCloseClickAddStocks = async () => {
        setModalAddStocks(false)
    }

    const onAddStocksClick = () => {
      setProductDetails({})
        setModalAddStocks(true)
    }

    //Create / Edit user
    const [modalEdit, setModalEdit] = useState(false)
    const toggleModal = () => {
        setModalEdit(!modalEdit)
    }
    const onClickEdit = (user) => {
      setProductDetails({
            ...user,
            userName: htmlDecode(user.userName),
            firstName: htmlDecode(user.firstName),
            lastName:htmlDecode(user.lastName),
            email: htmlDecode(user.email)
        })
        setModalEdit(true)
    }

    const onSuccessEdit = async () => {
        //console.log('da vao onsucess')
      await getProducts()
    }

    const getProducts = async ()=> {
        const data = await reqGetListProduct(params);
        console.log(data);
        if (data?.status === "fail") {
            setIsError(data);
        } else {
            setProducts(
                data?.data?.paginatedResults
            );
            let metadata = data?.data?.metadata;
            setPageOptions({
                sizePerPage: metadata?.limit,
                totalSize: metadata?.total,
                custom: true,
            });
            setIsError([]);
        }
    }

    const onCreateClick = () => {
      setProductDetails({})
      setModalEdit(true)
    }
 
    // Table
    let productTable = (
        <PaginationProvider
          pagination={paginationFactory(pageOptions || [])}
          keyField="_id"
          columns={Colums || []}
          data={products || []}
        >
          {({ paginationProps, paginationTableProps }) => (
            <React.Fragment>
              <div className="table-responsive">
                <BootstrapTable
                  {...paginationTableProps}
                  remote
                  keyField="_id"
                  columns={Colums || []}
                  data={products || []}
                  responsive
                  bordered={false}
                  striped={false}
                  bootstrap4
                  classes={"table align-middle table-nowrap"}
                  headerWrapperClasses={"table-light"}
                  onTableChange={onTableChange}
                  noDataIndication={() => {
                    return loading;
                  }}
                />
              </div>
              <div className="pagination pagination-rounded justify-content-end mb-2">
                <PaginationListStandalone {...paginationProps} />
              </div>
            </React.Fragment>
          )}
        </PaginationProvider>
    );

    let loading = <Spinner className="loader">Loading ...</Spinner>;
    // console.log("Products: ", products)
    return (
        <React.Fragment>
          <div className="page-content">
          {modalEdit ? <EditProduct isShow={modalEdit} product={productdetails} onSuccess={onSuccessEdit} toggle={toggleModal} /> : null}
          {modalEditPrice ? <EditPrice isShow={modalEditPrice} products={productdetails} toggle={toggleModalEditPrice} onClose={onCloseClick}/> : null}
          {modalAddStocks ? <AddStocks isShow={modalAddStocks} products={productdetails} onSuccess={onSuccessAddStocks} toggle={toggleModalAddStocks} onClose={onCloseClickAddStocks}/> : null}
            <MetaTags>
              <title> Product Management </title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs
                title="Product Management"
                breadcrumbItem="Product"
              /> 
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <Row className="mb-4">
                        <div className="col-xl col-sm-6">
                          <FormGroup className="mt-3 mb-0">
                            {/* <CustomSearch
                              placeholder="Search by (Product name, SKU or Barcode)"
                              searchTable={searchTable}
                            /> */}
                             <SearchProduct onSearch={searchTable} onCreate={onCreateClick} />
                          </FormGroup>
                        </div> 
                      </Row>
                      {/* {productTable} */}
                      {isError?.status === "fail" ? (<Maintenance errorMsg={isError?.data?.Message || isError?.mess} />) : (productTable)}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      );
    
}

export default withRouter(ProductManagement)