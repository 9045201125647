import React from "react";
import {Card, CardBody, Modal, ModalBody, ModalHeader} from "reactstrap";
import PropTypes from "prop-types";
import {dateTimeToString, htmlDecode} from "../../../helpers/utils";
import AuditProduct from "./AuditProduct";
import moment from "moment";

const Audit = ({isShow, toggle, batch}) => {
    const audits = batch?.audit ?? []
    const _audits = audits.sort((record1, record2) => {
        const date1 = record1?.dateCreated ?? null
        const date2 = record2?.dateCreated ?? null

        const moment1 = moment(date1).unix()
        const moment2 = moment(date2).unix()

        return moment2 - moment1
    })
    const mapFieldName = (fieldName) => {
        const listFieldName = {
            status: "Status",
            shippingFee: "Shipping fee",
            shippingFeeUnit: "Shipping fee unit",
            partner: "Partner",
        }

        return listFieldName[fieldName] ?? "N/A"
    }

    const renderBatch = (logBatch = {}) => {
        const output = []

        for (const logBatchKey in logBatch) {
            const value = logBatch[logBatchKey]
            const label = mapFieldName(logBatchKey)
            output.push(<tr key={`log_batch_key_${Math.floor(Math.random() * 100)}_${logBatchKey}`}>
                <th scope="row">{label}</th>
                <td className="text-danger">{htmlDecode(value?.old ?? "")}</td>
                <td className="text-success">{htmlDecode(value?.new ?? "")}</td>
            </tr>)
        }

        return output
    }
    const renderAudit = (audit, key) => {
        let modifiedBy = audit?.userModified ? audit.userModified : {}
        modifiedBy = `${modifiedBy?.firstName} ${modifiedBy?.lastName}(${modifiedBy?.userName})`
        const time = audit?.dateCreated ? dateTimeToString(audit.dateCreated) : ""
        const logs = audit.logs ?? {}
        const logProducts = logs?.products ?? []
        const logBatch = logs?.batch ?? {}
        return (
            <React.Fragment key={`block_audit_${key}`}>
                <Card style={{border: "1px solid #ababab", borderRadius: "5px"}}>
                    <CardBody>
                        <div>
                            <p className="mb-b2 text-primary"><i className="mdi mdi-account" /> {modifiedBy}</p>
                            <p className="mb-b4 text-danger"><i className="mdi mdi-clock" /> {time}</p>
                            <div className="table-responsive" style={{maxWidth: "100%"}}>
                                <table className="table align-middle table-nowrap table">
                                    <thead>
                                    <tr>
                                        <th scope="col" style={{width: "200px"}}>Field Name</th>
                                        <th scope="col">Old Value</th>
                                        <th scope="col">New Value</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        renderBatch(logBatch)
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {
                            logProducts.map((logProduct, index) => {
                                return <AuditProduct auditProduct={logProduct} key={`log_audit_product_${index}`} />
                            })
                        }
                    </CardBody>
                </Card>
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <Modal isOpen={isShow} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle} tag="h4">Audit Batch</ModalHeader>
                <ModalBody>
                    {
                        _audits.map((audit, key) => renderAudit(audit, key))
                    }
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

Audit.propTypes = {
    isShow: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    batch: PropTypes.object
}

export default Audit