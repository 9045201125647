import CustomPagination from "components/ultils/CustomPagination"
import React, { useEffect, useMemo, useRef, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { MetaTags } from "react-meta-tags"
import { Link, withRouter } from "react-router-dom"
import { Badge, Card, CardBody, Col, Container, Row, Spinner, Toast, ToastBody, ToastHeader, UncontrolledTooltip } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { reqDeleteUser, reqGetListUsers } from "helpers/fakebackend_helper"
import SearchUser from "./SearchUser"
import EditUser from "./EditUser"
import ChangePassword from "./ChangePasswordUser"
import DeleteModal from "components/Common/DeleteModal"
import { htmlDecode } from "helpers/utils"

const EmployeeManagerment = (props) => {
    const refPagination = useRef()
    let FLAG_FIRT_LOAD = useRef(false)
    const [userListConf, setEmployeeListConf] = useState({
        page: 1,
        limit: 20,
        search: {
            userName: "",
            firstName: "",
            lastName: "",
            email: "",
            status: "",
        }
    });

    const [users, setUsers] = useState({
        data: [],
        totalItems: 0
    })

    const [user, setUser] = useState({})

    const employeeTableColumn = [
        {
            dataField: "userName",
            text: "Username",
            formatter: (rowContent, row) => htmlDecode(rowContent)
        },
        {
            dataField: "isAdmin",
            text: "Is System admin",
            formatter: (rowContent, row) => row?.isAdmin ? "Yes" : "No  "
        },
        {
            dataField: "firstName",
            text: "First Name",
            formatter: (rowContent, row) => htmlDecode(rowContent)
        },
        {
            dataField: "lastName",
            text: "Last Name",
            formatter: (rowContent, row) => htmlDecode(rowContent)
        },
        {
            dataField: "email",
            text: "Email",
            formatter: (rowContent, row) => htmlDecode(rowContent)
        },
        {
            dataField: "status",
            text: "Status",
            formatter: (rowContent, row) => {
                const status = row.status === true || row.status === "true" ? true : false
                const className = status ? "success" : "primary";
                const label = status ? "Active" : "Inactive";
                return (
                    <Badge
                        className={"font-size-12 badge-soft-" + className}
                        color={className}
                        pill
                    >{label}</Badge>)
            }
        },
        {
            dataField: "action",
            isDummyField: true,
            text: "Action",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <>
                    <div className="d-flex gap-3">
                        <Link
                            to="#"
                            className="text-success"
                            onClick={() => onClickEdit(row)}
                        >
                            <i className="mdi mdi-pencil font-size-18" id={`edittooltip${row._id}`} />
                            <UncontrolledTooltip placement="top" target={`edittooltip${row._id}`}>Edit</UncontrolledTooltip>
                        </Link>
                        <Link
                            to="#"
                            className="text-info"
                            onClick={() => onClickChangePassword(row)}
                        >
                            <i className="mdi mdi-key font-size-18" id={`changePasstooltip${row._id}`} />
                            <UncontrolledTooltip placement="top" target={`changePasstooltip${row._id}`}>Change Password</UncontrolledTooltip>
                        </Link>
                        <Link
                            to="#"
                            className="text-danger"
                            onClick={() => onDelete(row)}
                        >
                            <i className="mdi mdi-delete font-size-18" id={`deletetooltip${row._id}`} />
                            <UncontrolledTooltip placement="top" target={`deletetooltip${row._id}`}>Delete</UncontrolledTooltip>
                        </Link>
                    </div>
                </>
            ),
        },
    ]

    const [toast, setToast] = useState({
        message: "",
        isOpen: false
    })

    const autoCloseToast = (message) => {
        setToast({
            message: message,
            isOpen: true
        })
        setTimeout(() => {
            toastSpinerColler.current = "danger"
            setToast({
                message: "",
                isOpen: false
            })
        }, 5000);
    }

    const pageOptions = {
        sizePerPage: userListConf.limit,
        totalSize: users.totalItems, // replace later with size(users),
        custom: true,
    };

    const onChangePageNumber = (pageNumber) => {
        setEmployeeListConf({
            ...userListConf,
            page: parseInt(pageNumber)
        })
    }

    const onSearch = (data) => {
        setEmployeeListConf({
            ...userListConf,
            search: {
                ...userListConf.search,
                ...data
            },
            page: 1
        })

        refPagination.current.refreshCurrentPage()
    }

    const getListUsers = async () => {
        try {
            const filter = {
                params: {
                    page: userListConf.page,
                    firstName: userListConf.search.firstName || "",
                    lastName: userListConf.search.lastName || "",
                    userName: userListConf.search.userName || "",
                    status: userListConf.search.status || "",
                    email: userListConf.search.email || "",
                }
            }

            const result = await reqGetListUsers(filter)
            FLAG_FIRT_LOAD.current = true

            setUsers({
                ...users,
                data: result.items,
                totalItems: result.totalItems
            })

        } catch (error) {
            autoCloseToast(error.message || error)
        }
    }

    useMemo(async () => {
        await getListUsers()
    }, [])

    useEffect(async () => {
        if (FLAG_FIRT_LOAD.current) {
            await getListUsers()
        }
    }, [userListConf])

    //Create / Edit user
    const [modalEdit, setModalEdit] = useState(false)
    const toggleModal = () => {
        setModalEdit(!modalEdit)
    }
    const onClickEdit = (user) => {
        setUser({
            ...user,
            userName: htmlDecode(user.userName),
            firstName: htmlDecode(user.firstName),
            lastName:htmlDecode(user.lastName),
            email: htmlDecode(user.email)
        })
        setModalEdit(true)
    }

    const onSuccessEdit = async () => {
        await getListUsers()
    }

    const onCreateClick = () => {
        setUser({})
        setModalEdit(true)
    }

    //delete user
    const [deleteModal, setDeleteModal] = useState(false)
    const onDelete = (user) => {
        setUser({
            ...user,
            userName: htmlDecode(user.userName),
            firstName: htmlDecode(user.firstName),
            lastName:htmlDecode(user.lastName),
            email: htmlDecode(user.email)
        })
        setDeleteModal(true)
    }

    const handleDelete = async () => {
        try {
            await reqDeleteUser(user.userName)
            await getListUsers()
            setDeleteModal(false)
        } catch (error) {
            autoCloseToast(error.message || error)
            setDeleteModal(false)
        }
    }

    //change password
    const [modalChangePass, setModalChangePass] = useState(false)
    const toggleModalChangePass = () => setModalChangePass(!modalChangePass)

    const onClickChangePassword = (user) => {
        setUser(user)
        setModalChangePass(true)
    }
    const toastSpinerColler = useRef("danger")

    const onSuccessChangePass = (message) => {
        toastSpinerColler.current = "success"
        autoCloseToast(message)
    }

    return (
        <React.Fragment>
            <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: "1005" }}>
                <Toast isOpen={toast.isOpen}>
                    <ToastHeader icon={<Spinner type="grow" size="sm" color={toastSpinerColler.current} />} toggle={() => {
                        setToast({ ...toast, isOpen: !toast.isOpen })
                    }}>{toastSpinerColler.current === "danger" ? "Error" : "Message"}</ToastHeader>
                    <ToastBody>{toast.message}</ToastBody>
                </Toast>
            </div>
            {deleteModal ? <DeleteModal show={deleteModal} onDeleteClick={handleDelete} onCloseClick={() => {
                setDeleteModal(false)
                setDepartment({})
                }}
            /> : null}
            {modalEdit ? <EditUser isShow={modalEdit} user={user} onSuccess={onSuccessEdit} toggle={toggleModal} /> : null}
            {modalChangePass ? <ChangePassword isShow={modalChangePass} onSuccess={onSuccessChangePass} toggle={toggleModalChangePass} user={user} /> : null}
            <div className="page-content">
                <MetaTags>
                    <title>Users Managerment</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Users Managerment" breadcrumbItem="" />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField="_id"
                                        columns={employeeTableColumn}
                                        data={users.data}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField="_id"
                                                data={users.data}
                                                columns={employeeTableColumn}
                                                bootstrap4
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <SearchUser onSearch={onSearch} onCreate={onCreateClick} />
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        keyField="id"
                                                                        responsive
                                                                        basseted={false}
                                                                        striped={false}
                                                                        classes={
                                                                            "table align-middle table-nowrap table-check"
                                                                        }
                                                                        headerWrapperClasses={"table-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>

                                                            </Col>
                                                        </Row>
                                                        <div className="pagination pagination-rounded justify-content-end mb-2">
                                                            <CustomPagination ref={refPagination} onChangePage={onChangePageNumber} sizeOfPerPage={pageOptions.sizePerPage} totalItem={pageOptions.totalSize} />
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(EmployeeManagerment)