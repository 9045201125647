import PropTypes from "prop-types"
import React, { useState } from "react"
import { Button, FormGroup, Input, Label, Row } from "reactstrap"
import { canAccessCreate } from "./Permission"

const SearchPaymentTypes = (props) => {
    const { onCreate, onSearch } = props
    const [formSearch, setDataForm] = useState({
        name: "",
    })

    return (
        <React.Fragment>
            <Row>
                <div className="col-xl-4 col-sm-6 mb-2">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Payment type name :</Label>
                        <Input
                            name="name"
                            type="text"
                            value={formSearch.name}
                            autoComplete="off"
                            onChange={(e) => {
                                setDataForm({
                                    ...formSearch,
                                    name: e.target.value
                                })
                            }}
                        />
                    </FormGroup>
                </div>
                <div className="col-xl-4 col-sm-6 mb-2">
                    <div className="mb-0" style={{ marginTop: "2.6rem" }}>
                        <Button
                            type="button"
                            color="info"
                            className="btn-rounded  mb-2 me-2"
                            onClick={(e) => {
                                onSearch(formSearch)
                            }}
                        >
                            <i className="bx bx-search-alt me-1" />
                            Search
                        </Button>
                        {canAccessCreate() ? <Button
                            type="button"
                            color="success"
                            className="btn-rounded  mb-2 me-2"
                            onClick={onCreate}
                        >
                            <i className="mdi mdi-plus me-1" />
                            Add New Payment type
                        </Button> : null}
                    </div>
                </div>
            </Row>
        </React.Fragment>
    )
}

SearchPaymentTypes.propTypes = {
    onSearch: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
}

export default SearchPaymentTypes