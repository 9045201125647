import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react"
import { Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import { useFormik } from "formik";
import * as Yup from "yup";
import { reqCreateAssetType, reqUpdateAssetType, reqUpdateDepartment } from "helpers/fakebackend_helper";
import { htmlDecode } from "helpers/utils";

const EditAssetType = (props) => {
    const { onSuccess, assetType, isShow, toggle } = props
    const [otherErrorMessage, setOtherErrorMessage] = useState("")
    const isEdit = useRef(false)
    const [assetTypeInfo, setAssetTypeInfo] = useState({
        id: "",
        name: "",
    })

    const handleUpdateState = () => {
        const assetTypeId = assetType._id || ""
        isEdit.current = (assetTypeId !== null && assetTypeId !== "")

        setAssetTypeInfo({
            id: assetType._id || "",
            name: htmlDecode(assetType.name),
        })
    }

    useEffect(() => {
        handleUpdateState()
    }, [assetType])

    useEffect(() => {
        handleUpdateState()
    }, [])

    const validationInitValue = {
        id: (assetTypeInfo && assetTypeInfo.id) || '',
        name: (assetTypeInfo && assetTypeInfo.name) || '',
    }

    const validationRule = {
        name: Yup.string().required("Name is required"),
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: validationInitValue,
        validationSchema: Yup.object(validationRule),
        onSubmit: async (values) => {
            let data = {
                id: values.id,
                name: values.name,
            };

            try {
                if(isEdit.current){
                    await reqUpdateAssetType(data)
                }
                else{
                    await reqCreateAssetType(data)
                }
                await onSuccess()
                validation.resetForm();
                toggle();
            } catch (error) {
                return setOtherErrorMessage(error.message || error)
            }
        },
    });

    const onToggle = () => {
        toggle()
    }

    return (
        <React.Fragment>
            <Modal isOpen={isShow} toggle={onToggle}>
                <ModalHeader toggle={onToggle} tag="h4">{isEdit.current ? "Edit Asset Type" : "Create Asset Type"}</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row form>
                            <Col className="col-12">
                                <div className="mb-3">
                                    {otherErrorMessage ? (<p className="alert alert-danger">{otherErrorMessage}</p>) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Name</Label>
                                    <Input
                                        name="name"
                                        type="text"
                                        value={validation.values.name || ""}
                                        invalid={validation.errors.name ? true : false}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setAssetTypeInfo({
                                                ...assetTypeInfo,
                                                name: e.target.value
                                            })
                                        }}
                                    />
                                    {validation.errors.name ? (
                                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn btn-success save-user"
                                    >
                                        {isEdit.current ? "Update" : "Create"}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

EditAssetType.propTypes = {
    assetType: PropTypes.object.isRequired,
    onSuccess: PropTypes.func,
    isShow: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired
}

export default EditAssetType