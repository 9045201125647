import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import ReactSelect from "react-select"
import { Col, Form, FormFeedback, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import * as Yup from "yup";
import { useFormik } from "formik";
import { reqAddAsset, reqUpdateAsset } from "helpers/fakebackend_helper";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import SelectAssetType from "./components/SelectAssetTypes";
import SelectPartner from "./components/SelectPartner";

const AssetEdit = (props) => {
    const { isShow, toggle, isEdit, asset, onSuccess } = props

    const [assetInfo, setAssetInfo] = useState({ ...asset })

    useEffect(() => {
        setAssetInfo({ ...props.asset })
    }, [props.asset])

    const statusOptions = [
        { value: "true", label: "Available" },
        { value: "false", label: "Unavailable" }
    ]

    const [otherErrorMessage, setOtherErrorMessage] = useState("")
    const validationInitValue = {
        name: (assetInfo && assetInfo.name) || '',
        code: (assetInfo && assetInfo.code) || '',
        available: (assetInfo && assetInfo.available) || 'true',
        description: (assetInfo && assetInfo.description) || '',
        price: (assetInfo && assetInfo.price) || '',
        type: (assetInfo && assetInfo.type) || '',
        partner: (assetInfo && assetInfo.partner) || '',
        purchaseDate: (assetInfo && assetInfo.purchaseDate) || '',
        warranty: (assetInfo && assetInfo.warranty) || '',
    }

    const validationRule = {
        name: Yup.string().required("Please Enter asset name"),
        code: Yup.string().required("Please Enter asset code(serial)"),
        available: Yup.string().required("Please choose asset status"),
        price: Yup.number().required("Please enter asset price"),
        type: Yup.string().required("Please enter type"),
        partner: Yup.string().required("Please enter partner"),
        purchaseDate: Yup.date().notRequired(),
        warranty: Yup.number().notRequired(),
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: validationInitValue,
        validationSchema: Yup.object(validationRule),
        onSubmit: async (values) => {
            let data = {
                name: values.name,
                code: values.code,
                available: values.available,
                description: values.description,
                price: values.price,
                type: values.type,
                purchaseDate: values.purchaseDate,
                warranty: values.warranty,
                partner: values.partner,
            };

            if (isEdit) {
                data.id = assetInfo ? assetInfo.id : ""
            }

            try {
                if (isEdit) {
                    await reqUpdateAsset(data)
                }
                else {
                    await reqAddAsset(data)
                }
                await onSuccess()
                validation.resetForm();
            } catch (error) {
                setOtherErrorMessage(error.message || error)
            }
        },
    });

    return (
        <React.Fragment>
            <Modal isOpen={isShow} toggle={toggle}>
                <ModalHeader toggle={toggle} tag="h4">
                    {!!isEdit ? "Edit Asset" : "Add Asset"}
                </ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row form>
                            <Col className="col-12">
                                <div className="mb-3">
                                    {otherErrorMessage ? (<p className="alert alert-danger">{otherErrorMessage}</p>) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Asset name</Label>
                                    <Input
                                        name="name"
                                        type="text"
                                        onChange={(e) => {
                                            validation.handleChange(e)
                                            setAssetInfo({
                                                ...assetInfo,
                                                name: e.target.value
                                            })
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name || ""}
                                        invalid={
                                            validation.touched.name && validation.errors.name ? true : false
                                        }
                                    />
                                    {validation.touched.name && validation.errors.name ? (
                                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Asset code(Serial)</Label>
                                    <Input
                                        name="code"
                                        type="text"
                                        onChange={(e) => {
                                            validation.handleChange(e)
                                            setAssetInfo({
                                                ...assetInfo,
                                                code: e.target.value
                                            })
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.code || ""}
                                        invalid={
                                            validation.touched.code && validation.errors.code ? true : false
                                        }
                                    />
                                    {validation.touched.code && validation.errors.code ? (
                                        <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Price</Label>
                                    <Input
                                        name="price"
                                        type="numeric"
                                        onChange={(e) => {
                                            validation.handleChange(e)
                                            setAssetInfo({
                                                ...assetInfo,
                                                price: e.target.value
                                            })
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.price || ''}
                                        invalid={
                                            validation.touched.price && validation.errors.price ? true : false
                                        }
                                    />
                                    {validation.touched.price && validation.errors.price ? (
                                        <FormFeedback type="invalid">{validation.errors.price}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Type</Label>
                                    <SelectAssetType value={assetInfo.typeObject} onChange={(option) => {
                                        setAssetInfo({
                                            ...assetInfo,
                                            type: option?.value,
                                            typeObject: {value: option?.value, label: option?.label}
                                        })
                                        validation.validateField("type")
                                    }}/>
                                    {validation.touched.type && validation.errors.type ? (
                                        <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Partner</Label>
                                    <SelectPartner value={assetInfo.partnerObject} onChange={(option) => {
                                        setAssetInfo({
                                            ...assetInfo,
                                            partner: option?.value,
                                            partnerObject: {value: option?.value, label: option?.label}
                                        })
                                        validation.validateField("partner")
                                    }}/>
                                    {validation.touched.partner && validation.errors.partner ? (
                                        <FormFeedback partner="invalid">{validation.errors.partner}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Status</Label>
                                    <ReactSelect
                                        defaultValue="true"
                                        options={statusOptions}
                                        onChange={(option) => {
                                            setAssetInfo({
                                                ...assetInfo,
                                                available: option.value
                                            })

                                            validation.validateField("available")
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={statusOptions.filter(option => option.value === assetInfo.available)}
                                    />

                                    {validation.touched.available && validation.errors.available ? (
                                        <FormFeedback type="invalid">{validation.errors.available}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Purchase Date</Label>
                                    <InputGroup>
                                        <Flatpickr
                                            className="form-control d-block"
                                            placeholder="YYYY-MM-DD"
                                            options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                            }}
                                            onChange={(date, dateString) => {
                                                setAssetInfo({
                                                    ...assetInfo,
                                                    purchaseDate: dateString
                                                })
                                                validation.validateField("purchaseDate")
                                            }}
                                            name="purchaseDate"
                                            onBlur={validation.handleBlur}
                                            value={assetInfo.purchaseDate}
                                        />
                                    </InputGroup>
                                    <Input type="hidden" invalid={validation.touched.purchaseDate && validation.errors.purchaseDate ? true : false} />
                                    {validation.touched.purchaseDate && validation.errors.purchaseDate ? (
                                        <FormFeedback type="invalid">{validation.errors.purchaseDate}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Warranty</Label>
                                    <Input
                                        name="warranty"
                                        type="numeric"
                                        onChange={(e) => {
                                            validation.handleChange(e)
                                            setAssetInfo({
                                                ...assetInfo,
                                                warranty: e.target.value
                                            })
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.warranty || ''}
                                        invalid={
                                            validation.touched.warranty && validation.errors.warranty ? true : false
                                        }
                                    />
                                    {validation.touched.warranty && validation.errors.warranty ? (
                                        <FormFeedback type="invalid">{validation.errors.warranty}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Description</Label>
                                    <Input
                                        name="description"
                                        type="textarea"
                                        onChange={(e) => {
                                            validation.handleChange(e)
                                            setAssetInfo({
                                                ...assetInfo,
                                                description: e.target.value
                                            })
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.description || ""}
                                        rows="3"
                                        invalid={
                                            validation.touched.description && validation.errors.description ? true : false
                                        }
                                    />
                                    {validation.touched.description && validation.errors.description ? (
                                        <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn btn-success save-user"
                                    >
                                        Save
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

AssetEdit.propTypes = {
    asset: PropTypes.object.isRequired,
    isShow: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    isEdit: PropTypes.bool.isRequired,
}

export default AssetEdit