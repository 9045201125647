import React, { useState, useMemo } from "react";
import { withRouter, useParams } from "react-router-dom";

import Breadcrumbs from "components/Common/Breadcrumb";
import { MetaTags } from "react-meta-tags";
import { Badge, Card, CardBody, CardTitle, Col, Container, Input, Label, Row } from "reactstrap";
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { reqGetDetailEmployee } from "helpers/fakebackend_helper";
import { dateToString, htmlDecode } from "helpers/utils";

const EmployeeDetail = props => {
  const { id: employeeId } = useParams()
  const [employee, setEmployee] = useState({})
  const [messageError, setMessageError] = useState("")
  const getEmployee = async () => {
    try {
      const res = await reqGetDetailEmployee(employeeId)
      setEmployee(res)
    }
    catch (error) {
      setMessageError(error.message || error)
    }
  }

  useMemo(async () => {
    await getEmployee()
  }, [])

  const historyColumn = () => [
    {
      dataField: "assetId",
      text: "Asset",
      formatter: (rowConten, row) => {
        if (row.assetId?.code) {
          return `#${htmlDecode(row.assetId?.code)} - ${htmlDecode(row.assetId?.name)}`
        }
        return htmlDecode(row.assetId?.name)
      }
    },
    {
      dataField: "returned",
      text: "Status",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        const status = row.returned === true || row.returned === "true"
        const className = status ? "success" : "primary";
        const label = status ? "Returned" : "Using";
        return (
          <Badge
            className={"font-size-12 badge-soft-" + className}
            color={className}
            pill
          >{label}</Badge>
        )
      },
    },
    {
      dataField: "startUsingDate",
      text: "Start using date",
      formatter: (rowConten, row) => {
        return dateToString(row.startUsingDate)
      }
    },
    {
      dataField: "returnedDate",
      text: "Return date",
      formatter: (rowConten, row) => {
        return dateToString(row.returnedDate)
      }
    },
  ];

  const pageOptions = {
    sizePerPage: 20,
    totalSize: employee.histories?.length || 0, // replace later with size(assets),
    custom: true,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Employee Detail</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Employee Detail" breadcrumbItem={"#" + htmlDecode(employee.code) || ""} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {messageError ? (<Row>
                    <Col><p className="alert alert-danger">{messageError}</p></Col>
                  </Row>) : null}
                  <Row>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">Code</Label>
                        <Input
                          type="text"
                          disabled={true}
                          value={htmlDecode(employee.code) || ""}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">First Name</Label>
                        <Input
                          type="text"
                          disabled={true}
                          value={htmlDecode(employee.firstName) || ""}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">Last Name</Label>
                        <Input
                          type="text"
                          disabled={true}
                          value={htmlDecode(employee.lastName) || ""}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">Middle Name</Label>
                        <Input
                          type="text"
                          disabled={true}
                          value={htmlDecode(employee.middleName) || ""}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">ID Number</Label>
                        <Input
                          type="text"
                          disabled={true}
                          value={htmlDecode(employee.idNumber) || ""}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">Passport Or ID Card Number</Label>
                        <Input
                          type="text"
                          disabled={true}
                          value={htmlDecode(employee.passportOrIDNumber) || ""}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">BIO Number</Label>
                        <Input
                          type="text"
                          disabled={true}
                          value={htmlDecode(employee.bioNumber) || ""}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">Status</Label>
                        <Input
                          type="text"
                          disabled={true}
                          value={employee.status === "true" || employee.status === true ? "Active" : "Inactive"}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">Department</Label>
                        <Input
                          type="text"
                          disabled={true}
                          value={`${employee.departmentId?.code ? "#" + htmlDecode(employee.departmentId.code) + " - " : ""}${htmlDecode(employee.departmentId?.name)}`}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">Site</Label>
                        <Input
                          type="text"
                          disabled={true}
                          value={htmlDecode(employee.site) || ""}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="_id"
            columns={historyColumn()}
            data={employee.histories || []}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="_id"
                data={employee.histories || []}
                columns={historyColumn()}
                bootstrap4
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col xl="12">
                            <CardTitle className="mb-3">Asset Histories</CardTitle>
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField="_id"
                                responsive
                                basseted={false}
                                striped={false}
                                defaultSorted={[]}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>

                          </Col>
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EmployeeDetail);

