const KEYWORD_ENTRY_PRICE = "ENTRY_PRICE"

const isValidFormula = (formula = "") => {
    const entryPrice = "100"

    let tmp_formula = formula.toString().trim()
    tmp_formula = tmp_formula.replaceAll(KEYWORD_ENTRY_PRICE, entryPrice)

    try {
        eval(tmp_formula) //convert to a calculation expression
        return true
    }
    catch (e) {
        return false
    }
}

const suggestPrice = (formula = "", entryPrice = 0) => {
    if(isValidFormula(formula)) {
        entryPrice = entryPrice ?? 0
        entryPrice = Number(entryPrice)
        entryPrice = entryPrice.toString()

        let tmp_formula = formula
        tmp_formula = tmp_formula.replaceAll(KEYWORD_ENTRY_PRICE, entryPrice)

        try {
            return eval(tmp_formula)
        }
        catch (e) {
            return false
        }
    }

    return false
}

module.exports = {
    suggestPrice,
    isValidFormula,
    KEYWORD_ENTRY_PRICE
}