import PropTypes from "prop-types"
import React, {useState} from "react"
import {Button, Col, FormGroup, Input, Label, Row} from "reactstrap"
import {canAccessCreate} from "../Permission"
import ReactSelect from "react-select";
import {BATCH_STATUS, BATCH_STATUS_CODE} from "../constant";
import SelectPartner from "../../AssetManagerment/components/SelectPartner";

const Search = (props) => {
    const {onSearch, onCreate} = props

    const [formSearch, setDataForm] = useState({
        code: "",
        status: [],
        partner: null,
    })

    const optionsStatus = [
        {value: BATCH_STATUS_CODE.pending, label: BATCH_STATUS.pending},
        {value: BATCH_STATUS_CODE.approved, label: BATCH_STATUS.approved},
        {value: BATCH_STATUS_CODE.canceled, label: BATCH_STATUS.canceled},
        {value: BATCH_STATUS_CODE.buying, label: BATCH_STATUS.buying},
        {value: BATCH_STATUS_CODE.shipping, label: BATCH_STATUS.shipping},
        {value: BATCH_STATUS_CODE.done, label: BATCH_STATUS.done},
    ]

    return (
        <React.Fragment>
            <Row>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Code :</Label>
                        <Input
                            name="code"
                            type="text"
                            value={formSearch.code || ""}
                            onChange={(e) => setDataForm({...formSearch, code: e.target.value})}
                            autoComplete="off"
                        />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Status: </Label>
                        <ReactSelect options={optionsStatus} isMulti={true} isClearable={true}
                                     onChange={(options) => {
                                         setDataForm({
                                             ...formSearch,
                                             status: options ? options.map(option => option.value) : options
                                         })
                                     }}
                                     value={optionsStatus.filter(option => formSearch.status.includes(option.value))}
                        />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Partner :</Label>
                        <SelectPartner value={formSearch.partner} isClear={true}
                                       onChange={(option) => setDataForm({...formSearch, partner: option})}
                        />
                    </FormGroup>
                </div>
            </Row>
            <Row className="mb-2">
                <Col>
                    <div>
                        <Button
                            type="button"
                            color="info"
                            className="btn-rounded  mb-2 me-2"
                            onClick={() => {
                                onSearch(formSearch)
                            }}
                        >
                            <i className="bx bx-search-alt me-1"/>
                            Search
                        </Button>
                        {canAccessCreate() ? <Button
                            type="button"
                            color="success"
                            className="btn-rounded  mb-2 me-2"
                            onClick={onCreate}
                        >
                            <i className="mdi mdi-plus me-1"/>
                            Add New Batch
                        </Button> : null}
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

Search.propTypes = {
    onSearch: PropTypes.func.isRequired,
    onError: PropTypes.func,
    onCreate: PropTypes.func
}

export default Search