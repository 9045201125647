import {getAuthUser, getConfigPermissionModule, getUserInfo} from "helpers/Authentication"

export const getRoles = () => {
    const authUser = getAuthUser()
    return authUser?.roles ?? []
}

const canAccessToAction = (action, actionCode) => {
    const _actionCode = action?.actionCode ?? ""
    const allow = action?.allow ?? false

    return _actionCode === actionCode && allow === true
}

export const onlyAminAccess = (module) => {
    const modules = getConfigPermissionModule()["moduleOnlyAdmin"] ?? []
    return modules.indexOf(module) > -1
}

const canAccessModule = (module, moduleCode, actionCode) => {
    const _moduleCode = module?.moduleCode ?? ""

    if (moduleCode === _moduleCode) {
        const actions = module?.actions ?? []

        for (let index = 0; index < actions.length; index++) {
            const action = actions[index];
            if (canAccessToAction(action, actionCode)) {
                return true
            }
        }
    }

    return false
}

const handleAccessRole = (role, moduleCode, actionCode) => {
    const roleModules = role?.roleModules ?? []

    for (let index = 0; index < roleModules.length; index++) {
        const module = roleModules[index];
        if (canAccessModule(module, moduleCode, actionCode)) {
            return true
        }
    }

    return false
}

export const isAdmin = () => {
    const userInfo = getUserInfo()
    return userInfo?.isAdmin ?? false
}

export const canAccess = (moduleCode, actionCode) => {
    if(isAdmin()) {
        return true
    }

    if(onlyAminAccess(moduleCode)){
        return false
    }

    const roles = getRoles()
    for (let index = 0; index < roles.length; index++) {
        const role = roles[index];
        if (handleAccessRole(role, moduleCode, actionCode)) {
            return true
        }
    }

    return false
}

const getModuleCode = () => {
    return getConfigPermissionModule()["moduleCode"] ?? []
}

const getActionCode = () => {
    return getConfigPermissionModule()["actionCode"] ?? []
}

export const getCodeModuleAsset = () => {
    return getModuleCode()["ASSET"] ?? ""
}

export const getCodeModuleAssetHistory = () => {
    return getModuleCode()["ASSET_HISTORY"] ?? ""
}

export const getCodeModuleAssetType = () => {
    return getModuleCode()["ASSET_TYPE"] ?? ""
}

export const getCodeModuleDepartment = () => {
    return getModuleCode()["DEPARTMENT"] ?? ""
}

export const getCodeModulePayment = () => {
    return getModuleCode()["PAYMENT"] ?? ""
}

export const getCodeModulePaymentType = () => {
    return getModuleCode()["PAYMENT_TYPE"] ?? ""
}

export const getCodeModuleEmployee = () => {
    return getModuleCode()["EMPLOYEE"] ?? ""
}

export const getCodeModulePartner = () => {
    return getModuleCode()["PARTNER"] ?? ""
}

export const getCodeModuleRole = () => {
    return getModuleCode()["ROLE"] ?? ""
}

export const getCodeModuleUser = () => {
    return getModuleCode()["USER"] ?? ""
}

export const getCodeModuleWorkingTimes = () => {
    return getModuleCode()["WORKING_TIME"] ?? ""
}

export const getCodeModuleImport_Category = () => {
    return getModuleCode()["IMPORT_CATEGORY"] ?? ""
}

export const getCodeModuleBATCH = () => {
    return getModuleCode()["BATCH"] ?? ""
}

export const getCodeModulePayout = () => {
    return getModuleCode()["PAYOUT"] ?? ""
}

export const getCodeModuleGame = () => {
    return getModuleCode()["GAMING_PAYOUT"] ?? ""
}

// ACTIONS
export const getCodeActionList = () => {
    return getActionCode()["LIST"] ?? ""
}

export const getCodeActionCreate = () => {
    return getActionCode()["CREATE"] ?? ""
}

export const getCodeActionEdit = () => {
    return getActionCode()["EDIT"] ?? ""
}

export const getCodeActionDetail = () => {
    return getActionCode()["DETAIL"] ?? ""
}

export const getCodeActionDelete = () => {
    return getActionCode()["DELETE"] ?? ""
}

export const canAccessToModule = (moduleCode) => {
    const canAccessList = canAccess(moduleCode, getCodeActionList())
    const canAccessCreate = canAccess(moduleCode, getCodeActionCreate())
    const canAccessEdit = canAccess(moduleCode, getCodeActionEdit())
    const canAccessDetail = canAccess(moduleCode, getCodeActionDetail())
    const canAccessDelete = canAccess(moduleCode, getCodeActionDelete())

    return (canAccessList || canAccessCreate || canAccessEdit || canAccessDetail || canAccessDelete)
}