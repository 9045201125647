export const BATCH_STATUS = {
    pending: "Pending",
    approved: "Approved",
    canceled: "Canceled",
    buying: "Buying",
    shipping: "Shipping",
    done: "Done"
}

export const BATCH_STATUS_CODE = {
    pending: 0,
    approved: 1,
    buying: 2,
    shipping: 3,
    done: 4,
    canceled: 99,
}

export const BATCH_PRODUCT_UNIT = {
    pc: "PC",
    pack: "PACK",
    box: "BOX",
}

export const TEMPLATE_NEW_PRODUCT = {
    isNewRow: true,
    isCanceled: false,
    canRevertCancel: false,
    product: {
        _id: ""
    },
    requested: {
        quantity: "",
        unit: ""
    },
    export: {
        quantity: "",
        price: "",
        note: "",
        priceUnit: ""
    },
    import: {
        quantity: "",
        price: "",
        note: "",
        priceUnit: "PESO"
    }
}