import CustomPagination from "components/ultils/CustomPagination"
import React, { useEffect, useRef, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { MetaTags } from "react-meta-tags"
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Spinner, Toast, ToastBody, ToastHeader, UncontrolledTooltip } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { reqDeleteAssetType, reqDeletePaymentType, reqGetListPaymentType } from "helpers/fakebackend_helper"
import DeleteModal from "components/Common/DeleteModal"
import EditPaymentType from "./EditPaymentType"
import SearchPaymentTypes from "./SearchPaymentType"
import { canAccessCreate, canAccessDelete } from "./Permission"

const PaymentTypesManagerment = (props) => {
  const refPagination = useRef()
  let FLAG_FIRT_LOAD = useRef(false)
  const [paymentTypesListConf, setPaymentTypesListConf] = useState({
    page: 1,
    limit: 20,
    search: {
      name: "",
    }
  });

  const [paymentTypes, setPaymentTypes] = useState({
    data: [],
    totalItems: 0
  })

  const [paymentType, setPaymentType] = useState({
    id: "",
    name: ""
  })

  const paymentTypeColumn = [
    {
      dataField: "name",
      text: "Name"
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3">
            {canAccessCreate() ? <Link
              to="#"
              className="text-success"
              onClick={() => onClickEdit(row)}
            >
              <i className="mdi mdi-pencil font-size-18" id={`edittooltip${row._id}`} />
              <UncontrolledTooltip placement="top" target={`edittooltip${row._id}`}>Edit</UncontrolledTooltip>
            </Link> : null}
            {canAccessDelete() ? <Link
              to="#"
              className="text-danger"
              onClick={() => onDelete(row)}
            >
              <i className="mdi mdi-delete font-size-18" id={`deletetooltip${row._id}`} />
              <UncontrolledTooltip placement="top" target={`deletetooltip${row._id}`}>Delete</UncontrolledTooltip>
            </Link> : null}
          </div>
        </>
      ),
    },
  ]

  const [toast, setToast] = useState({
    message: "",
    isOpen: false
  })

  const autoCloseToast = (message) => {
    setToast({
      message: message,
      isOpen: true
    })
    setTimeout(() => {
      setToast({
        message: "",
        isOpen: false
      })
    }, 5000);
  }

  const pageOptions = {
    sizePerPage: paymentTypesListConf.limit,
    totalSize: paymentTypes.totalItems, // replace later with size(paymentTypes),
    custom: true,
  };

  const onChangePageNumber = (pageNumber) => {
    setPaymentTypesListConf({
      ...paymentTypesListConf,
      page: parseInt(pageNumber)
    })
  }

  const onSearch = (data) => {
    setPaymentTypesListConf({
      ...paymentTypesListConf,
      search: {
        ...paymentTypesListConf.search,
        ...data
      },
      page: 1
    })

    refPagination.current.refreshCurrentPage()
  }

  const onSearchError = (message) => {
    autoCloseToast(message)
  }

  const getListPaymentTypes = async () => {
    try {
      const filter = {
        params: {
          page: paymentTypesListConf.page,
          name: paymentTypesListConf.search.name || "",
        }
      }

      const result = await reqGetListPaymentType(filter)
      FLAG_FIRT_LOAD.current = true

      setPaymentTypes({
        ...paymentTypes,
        data: result.items,
        totalItems: result.totalItems
      })

    } catch (error) {
      autoCloseToast(error.message || error)
    }
  }

  useEffect(async () => {
    await getListPaymentTypes()
  }, [])

  useEffect(async () => {
    if (FLAG_FIRT_LOAD.current) {
      await getListPaymentTypes()
    }
  }, [paymentTypesListConf])

  //Create / Edit paymentType
  const [modalEdit, setModalEdit] = useState(false)
  const toggleModal = () => {
    setModalEdit(!modalEdit)
  }
  const onClickEdit = (paymentType) => {
    setPaymentType(paymentType)
    setModalEdit(true)
  }

  const onSuccessEdit = async () => {
    await getListPaymentTypes()
  }

  const onCreateClick = () => {
    setPaymentType({})
    setModalEdit(true)
  }

  //delete
  const [deleteModal, setDeleteModal] = useState(false)
  const onDelete = async (paymentType) => {
    setPaymentType(paymentType)
    setDeleteModal(true)
  }

  const handleDelete = async () => {
    try {
      await reqDeletePaymentType(paymentType?._id)
      await getListPaymentTypes()
      setDeleteModal(false)
    } catch (error) {
      autoCloseToast(error.message || error)
      setDeleteModal(false)
    }
  }

  return (
    <React.Fragment>
      <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: "1005" }}>
        <Toast isOpen={toast.isOpen}>
          <ToastHeader icon={<Spinner type="grow" size="sm" color="danger" />} toggle={() => {
            setToast({ ...toast, isOpen: !toast.isOpen })
          }}>Error</ToastHeader>
          <ToastBody>{toast.message}</ToastBody>
        </Toast>
      </div>
      {deleteModal ? <DeleteModal show={deleteModal} onDeleteClick={handleDelete} onCloseClick={() => {
        setDeleteModal(false)
        setPaymentType({})
      }}
      /> : null}
      {modalEdit ? <EditPaymentType isShow={modalEdit} paymentType={paymentType} onSuccess={onSuccessEdit} toggle={toggleModal} /> : null}
      <div className="page-content">
        <MetaTags>
          <title>Payment types Managerment</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Payment types Managerment" breadcrumbItem="" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="_id"
                    columns={paymentTypeColumn}
                    data={paymentTypes.data}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={paymentTypes.data}
                        columns={paymentTypeColumn}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <SearchPaymentTypes onSearch={onSearch} onError={onSearchError} onCreate={onCreateClick} />
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    basseted={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>

                              </Col>
                            </Row>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <CustomPagination ref={refPagination} onChangePage={onChangePageNumber} sizeOfPerPage={pageOptions.sizePerPage} totalItem={pageOptions.totalSize} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(PaymentTypesManagerment)