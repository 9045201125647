import PropTypes from "prop-types"
import React, { useState, useEffect, useRef, useMemo } from "react"
import { Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import { useFormik } from "formik";
import * as Yup from "yup";
import {  reqCreateUser, reqUpdateUser } from "helpers/fakebackend_helper";
import ReactSelect from "react-select";
import SelectRoles from "./coponents/SelectRoles";

const EditUser = (props) => {
    const { onSuccess, user, isShow, toggle } = props
    const [otherErrorMessage, setOtherErrorMessage] = useState("")
    const isEdit = useRef(false)

    const [userInfo, setUserInfo] = useState({
        id: "",
        userName: "",
        firstName: "",
        lastName: "",
        email: "",
        status: "true",
        password: "",
        confirm_password: "",
        isAdmin: false,
        roles: []
    })

    const handleUpdateState = () => {
        const userId = user._id || ""
        const status = user.status ?? "true"
        isEdit.current = (userId !== null && userId !== "")
        const _roles = user.roles ?? []
        const roles = _roles.map(role => {
            return {label: role.roleName, value: role._id} 
        })

        setUserInfo({
            id: userId || "",
            userName: user.userName || "",
            firstName: user.firstName || "",
            lastName: user.lastName || "",
            email: user.email || "",
            status: (status === "true" || status === true) ? "true" : "false",
            isAdmin: (user && user.isAdmin) || false,
            roles: roles,
        })
    }

    useEffect(() => {
        handleUpdateState()
    }, [user])

    useMemo(() => {
        handleUpdateState()
    }, [])

    let validationInitValue = {
        id: (userInfo && userInfo.id) || '',
        userName: (userInfo && userInfo.userName) || '',
        firstName: (userInfo && userInfo.firstName) || '',
        lastName: (userInfo && userInfo.lastName) || '',
        email: (userInfo && userInfo.email) || '',
        status: (userInfo && userInfo.status) || '',
        isAdmin: (userInfo && userInfo.isAdmin) || false,
        roles: (userInfo && userInfo.roles) || [],
    }

    let validationRule = {
        userName: Yup.string().required("Username is required"),
        email: Yup.string().required("Email is required").email("Email is invalid"),
        status: Yup.string().required("Status required"),
        lastName: Yup.string().required("Last name required"),
        isAdmin: Yup.boolean().required("Is System Admin is required"),
        roles: Yup.array().of(
            Yup.object().shape({
                value: Yup.string().required(),
                label: Yup.string().required()
            })
        ).notRequired(),
    }

    if(!isEdit.current){
        validationRule.password = Yup.string().required("Password is required")
        .min(6, "Password length must be on range 6 to 256 character")
        .max(255, "Password length must be on range 6 to 256 character")
        .oneOf([Yup.ref('confirm_password'), null], "Password confirm do not match")

        validationInitValue.password = (userInfo && userInfo.password) || ''
        validationInitValue.confirm_password = (userInfo && userInfo.confirm_password) || ''
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: validationInitValue,
        validationSchema: Yup.object(validationRule),
        onSubmit: async (values) => {
            const roles = values.roles ?? []
            
            let data = {
                id: values.id,
                userName: values.userName,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                status: values.status,
                isAdmin: values.isAdmin,
                roles: roles.map(role => role.value),
                password: values.password || "",
                confirm_password: values.confirm_password || "",
            };

            try {
                if (isEdit.current) {
                    await reqUpdateUser(data)
                }
                else {
                    await reqCreateUser(data)
                }
                await onSuccess()
                validation.resetForm();
                toggle();
            } catch (error) {
                return setOtherErrorMessage(error.message || error)
            }
        },
    });

    const onToggle = () => {
        toggle()
    }

    const passwordElement = () => {
        if (isEdit.current) {
            return null
        }

        return (
            <React.Fragment>
                <div className="mb-3">
                    <Label className="form-label">Password</Label>
                    <Input
                        name="password"
                        type="password"
                        value={validation.values.password || ""}
                        autoComplete="off"
                        onChange={(e) => {
                            setUserInfo({
                                ...userInfo,
                                password: e.target.value
                            })
                        }}
                        invalid={validation.errors.password ? true : false}
                    />
                    {validation.errors.password ? (
                        <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                    ) : null}
                </div>
                <div className="mb-3">
                    <Label className="form-label">Confirm Password</Label>
                    <Input
                        name="confirm_password"
                        type="password"
                        value={validation.values.confirm_password || ""}
                        autoComplete="off"
                        onChange={(e) => {
                            setUserInfo({
                                ...userInfo,
                                confirm_password: e.target.value
                            })
                        }}
                    />
                </div>
            </React.Fragment>
        )
    }

    //Status
    const optionsStatus = [
        { value: "true", label: "Active" },
        { value: "false", label: "Inactive" },
    ]
    
    const systemAminOption = [
        { value: false, label: "No" },
        { value: true, label: "Yes" },
    ]

    return (
        <React.Fragment>
            <Modal isOpen={isShow} toggle={onToggle}>
                <ModalHeader toggle={onToggle} tag="h4">{isEdit.current ? "Edit user" : "Create Employee"}</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row form>
                            <Col className="col-12">
                                <div className="mb-3">
                                    {otherErrorMessage ? (<p className="alert alert-danger">{otherErrorMessage}</p>) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Username</Label>
                                    <Input
                                        name="userName"
                                        type="text"
                                        value={validation.values.userName || ""}
                                        invalid={validation.errors.userName && validation.touched.userName ? true : false}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setUserInfo({
                                                ...userInfo,
                                                userName: e.target.value
                                            })
                                        }}
                                        disabled={isEdit.current}
                                    />
                                    {validation.errors.userName ? (
                                        <FormFeedback type="invalid">{validation.errors.userName}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">First Name</Label>
                                    <Input
                                        name="firstName"
                                        type="text"
                                        value={validation.values.firstName || ""}
                                        invalid={validation.errors.firstName ? true : false}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setUserInfo({
                                                ...userInfo,
                                                firstName: e.target.value
                                            })
                                        }}
                                    />
                                    {validation.errors.firstName ? (
                                        <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Last Name</Label>
                                    <Input
                                        name="lastName"
                                        type="text"
                                        value={validation.values.lastName || ""}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setUserInfo({
                                                ...userInfo,
                                                lastName: e.target.value
                                            })
                                        }}
                                        invalid={validation.errors.lastName ? true : false}
                                    />
                                    {validation.errors.lastName ? (
                                        <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Email</Label>
                                    <Input
                                        name="email"
                                        type="text"
                                        value={validation.values.email || ""}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setUserInfo({
                                                ...userInfo,
                                                email: e.target.value
                                            })
                                        }}
                                        invalid={validation.errors.email ? true : false}
                                    />
                                    {validation.errors.email ? (
                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Status</Label>
                                    <ReactSelect
                                        options={optionsStatus}
                                        onChange={(option) => {
                                            setUserInfo({
                                                ...userInfo,
                                                status: option.value
                                            })

                                            validation.validateField("status")
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={optionsStatus.filter(option => {
                                            const status = userInfo.status
                                            return status === option.value
                                        })}
                                    />

                                    <Input type="hidden" invalid={validation.errors.status ? true : false} />
                                    {validation.touched.status && validation.errors.status ? (
                                        <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Is System admin</Label>
                                    <ReactSelect
                                        options={systemAminOption}
                                        onChange={(option) => {
                                            setUserInfo({
                                                ...userInfo,
                                                isAdmin: option.value
                                            })

                                            validation.validateField("isAdmin")
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={systemAminOption.filter(option => {
                                            const isAdmin = userInfo.isAdmin
                                            return isAdmin === option.value
                                        })}
                                    />

                                    <Input type="hidden" invalid={validation.errors.isAdmin ? true : false} />
                                    {validation.touched.isAdmin && validation.errors.isAdmin ? (
                                        <FormFeedback type="invalid">{validation.errors.isAdmin}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Roles</Label>
                                    <SelectRoles onChange={(options) => {
                                            setUserInfo({
                                                ...userInfo,
                                                roles: options
                                            })
                                        }}
                                        value={validation.values.roles}
                                    />

                                    <Input type="hidden" invalid={validation.errors.roles ? true : false} />
                                    {validation.touched.roles && validation.errors.roles ? (
                                        <FormFeedback type="invalid">{validation.errors.roles}</FormFeedback>
                                    ) : null}
                                </div>
                                {passwordElement()}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn btn-success save-user"
                                    >
                                        {isEdit.current ? "Update" : "Create"}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

EditUser.propTypes = {
    user: PropTypes.object.isRequired,
    onSuccess: PropTypes.func,
    isShow: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired
}

export default EditUser