import React, { useState, useMemo } from "react";
import { withRouter, Link, useLocation, useParams } from "react-router-dom";

import Breadcrumbs from "components/Common/Breadcrumb";
import { reqGetDatailAsset, reqGetDetailPartner } from "helpers/fakebackend_helper";
import { MetaTags } from "react-meta-tags";
import { Badge, Card, CardBody, CardTitle, Col, Container, Input, Label, Row } from "reactstrap";
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { dateToString, htmlDecode } from "helpers/utils";

const DetailPartner = props => {
    const { id: partnerId } = useParams()
    const [partner, setPartner] = useState({})
    const [messageError, setMessageError] = useState("")
    const getDetailPartner = async () => {
        try {
            const res = await reqGetDetailPartner(partnerId)
            setPartner(res)
        }
        catch (error) {
            setMessageError(error.message || error)
        }
    }

    useMemo(async () => {
        await getDetailPartner()
    }, [])

    const assetColumn = () => [
        {
            dataField: "name",
            text: "Partner Name",
        },
        {
            dataField: "code",
            text: "Partner code(serial)"
        },
        {
            dataField: "type",
            text: "Type",
            formatter: (rowContent, row) => {
                return row?.type?.name
            }
        },
        {
            dataField: "available",
            text: "Status",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => {
                const className = row.available ? "success" : "primary";
                const label = row.available ? "Available" : "Unavaiable";
                return (
                    <Badge
                        className={"font-size-12 badge-soft-" + className}
                        color={className}
                        pill
                    >{label}</Badge>
                )
            },
        },
        {
            dataField: "price",
            text: "Price"
        },
        {
            dataField: "purchaseDate",
            text: "Purchase date",
            formatter: (rowContent, row) => {
                return dateToString(row.purchaseDate)
            }
        },
        {
            dataField: "warranty",
            text: "Warranty"
        },
    ];

    const pageOptions = {
        sizePerPage: 20,
        totalSize: partner.partners?.length || 0, // replace later with size(assets),
        custom: true,
    };


    const renderNotes = () => {
        const notes = partner.notes ?? []

        if (!notes.length) {
            return (<React.Fragment>
                <Row className="mb-3">
                    <Col className="col-md-4">
                        <Label className="form-label">Date of note</Label>
                        <Input
                            type="text"
                            disabled={true}
                        />
                    </Col>
                    <Col className="col-md-8">
                        <Label className="form-label">Note</Label>
                        <Input
                            type="textarea"
                            disabled={true}
                        />
                    </Col>
                </Row>
            </React.Fragment>)
        }

        return notes.map((note, index) => {
            return (<React.Fragment key={`partner-note-${index}`}>
                <Row className="mb-3">
                    <Col className="col-md-4">
                        {index === 0 ? <Label className="form-label">Date of note</Label> : null}
                        <Input
                            type="text"
                            disabled={true}
                            value={dateToString(note.date)}
                        />
                    </Col>
                    <Col className="col-md-8">
                        {index === 0 ? <Label className="form-label">Note</Label> : null}
                        <Input
                            type="textarea"
                            disabled={true}
                            value={note.note || ""}
                        />
                    </Col>
                </Row>
            </React.Fragment>)
        })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Partner Detail</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Partner Detail" breadcrumbItem={"#" + partner.providerName || ""} />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    {messageError ? (<Row>
                                        <Col><p className="alert alert-danger">{messageError}</p></Col>
                                    </Row>) : null}
                                    <Row>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label className="form-label">Provider name</Label>
                                                <Input
                                                    type="text"
                                                    disabled={true}
                                                    value={htmlDecode(partner.providerName) || ""}
                                                />
                                            </div>
                                        </Col>
                                        {/* <Col md="6">
                                            <div className="mb-3">
                                                <Label className="form-label">Purchase Date</Label>
                                                <Input
                                                    type="text"
                                                    disabled={true}
                                                    value={dateToString(partner.purchaseDate)}
                                                />
                                            </div>
                                        </Col> */}
                                        {/* <Col md="6">
                                            <div className="mb-3">
                                                <Label className="form-label">Warranty</Label>
                                                <Input
                                                    type="text"
                                                    disabled={true}
                                                    value={htmlDecode(partner.warranty) || ""}
                                                />
                                            </div>
                                        </Col> */}
                                        {/* <Col md="6">
                                            <div className="mb-3">
                                                <Label className="form-label">Bank Account Number</Label>
                                                <Input
                                                    type="text"
                                                    disabled={true}
                                                    value={htmlDecode(partner.bankAccountNumber) || ""}
                                                />
                                            </div>
                                        </Col> */}
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label className="form-label">Card Name</Label>
                                                <Input
                                                    type="text"
                                                    disabled={true}
                                                    value={htmlDecode(partner.cardName) || ""}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label className="form-label">Bank Name</Label>
                                                <Input
                                                    type="text"
                                                    disabled={true}
                                                    value={htmlDecode(partner.bankName) || ""}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label className="form-label">Address</Label>
                                                <Input
                                                    type="text"
                                                    disabled={true}
                                                    value={htmlDecode(partner.address) || ""}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label className="form-label">Facebook Link</Label>
                                                <Input
                                                    type="text"
                                                    disabled={true}
                                                    value={htmlDecode(partner.facebookLink) || ""}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label className="form-label">Phone Number</Label>
                                                <Input
                                                    type="text"
                                                    disabled={true}
                                                    value={htmlDecode(partner.phoneNumber) || ""}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label className="form-label">Phone Number 2</Label>
                                                <Input
                                                    type="text"
                                                    disabled={true}
                                                    value={htmlDecode(partner.phoneNumber2) || ""}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Label className="form-label">Description</Label>
                                                <Input
                                                    type="textarea"
                                                    disabled={true}
                                                    value={htmlDecode(partner.description) || ""}
                                                    rows="3"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <Label className="form-label">Notes</Label>
                                            {renderNotes()}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="_id"
                        columns={assetColumn()}
                        data={partner.assets || []}
                    >
                        {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                                keyField="_id"
                                data={partner.assets || []}
                                columns={assetColumn()}
                                bootstrap4
                                search
                            >
                                {toolkitProps => (
                                    <React.Fragment>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col xl="12">
                                                        <CardTitle className="mb-3">Assets</CardTitle>
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                keyField="_id"
                                                                responsive
                                                                basseted={false}
                                                                striped={false}
                                                                defaultSorted={[]}
                                                                classes={
                                                                    "table align-middle table-nowrap table-check"
                                                                }
                                                                headerWrapperClasses={"table-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />
                                                        </div>

                                                    </Col>
                                                </Row>
                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                                        <PaginationListStandalone
                                                            {...paginationProps}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </React.Fragment>
                                )}
                            </ToolkitProvider>
                        )}
                    </PaginationProvider>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(DetailPartner);

