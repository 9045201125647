import PropType from "prop-types"
import React, { useState } from "react"
import { Button } from "reactstrap"

const ButtonExport = (props) => {
    const { onClick } = props
    const [isLoading, setIsLoading] = useState(false)

    const handleClick = async (e) => {
        setIsLoading(true)
        await onClick(e)
        setIsLoading(false)
    }

    return (
        <React.Fragment>
            <Button
                type="button"
                color="warning"
                className="btn-rounded mb-2 me-2"
                onClick={async (e) => {
                    await handleClick(e)
                }}
                disabled={isLoading}
            >
                {isLoading ? <i className="mdi mdi-spin mdi-loading me-1" /> : <i className="mdi mdi-plus me-1" />}
                Export
            </Button>
        </React.Fragment>
    )
}

ButtonExport.propTypes = {
    onClick: PropType.func.isRequired
}

export default ButtonExport