import PropTypes from "prop-types"
import React, { useState } from "react"
import ReactSelect from "react-select"
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap"

const SearchProduct = (props) => {
    const {onCreate, onSearch} = props
    const [formSearch, setDataForm] = useState({
        PRODUCTNAME: "",
        SKU: "",
        BARCODE: "",
        limit: 10,
        status: ""
    })
    const statusOptions = [
        { value: "", label: "Choose status" },
        { value: "true", label: "Active" },
        { value: "false", label: "Inactive" }
    ]
    const limitOptions = [
        { value: "20", label: "Choose status" },
        { value: "50", label: "50" },
        { value: "100", label: "100" },
        { value: "200", label: "200" }
    ]

    return (
        <React.Fragment>
            <Row>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>PRODUCT NAME :</Label>
                        <Input
                            name="PRODUCTNAME"
                            type="text"
                            value={formSearch.PRODUCTNAME}
                            autoComplete="off"
                            onChange={(e) => {
                                setDataForm({
                                    ...formSearch,
                                    PRODUCTNAME: e.target.value
                                })
                            }}
                        />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>SKU :</Label>
                        <Input
                            name="SKU"
                            type="text"
                            value={formSearch.SKU}
                            autoComplete="off"
                            onChange={(e) => {
                                setDataForm({
                                    ...formSearch,
                                    SKU: e.target.value
                                })
                            }}
                        />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>BARCODE :</Label>
                        <Input
                            name="lastName"
                            type="text"
                            value={formSearch.BARCODE}
                            autoComplete="off"
                            onChange={(e) => {
                                setDataForm({
                                    ...formSearch,
                                    BARCODE: e.target.value
                                })
                            }}
                        />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>LIMIT :</Label>
                        <ReactSelect
                            options={limitOptions}
                            onChange={(option) => {
                                setDataForm({
                                    ...formSearch,
                                    limit: option.value
                                })
                            }}
                            value={limitOptions.filter(option => option.value === formSearch.limit)}
                        />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Status :</Label>
                        <ReactSelect
                            options={statusOptions}
                            onChange={(option) => {
                                setDataForm({
                                    ...formSearch,
                                    status: option.value
                                })
                            }}
                            value={statusOptions.filter(option => option.value === formSearch.status)}
                        />
                    </FormGroup>
                </div>
            </Row>
            <Row className="mb-2">
                <Col>
                    <div>
                        <Button
                            type="button"
                            color="info"
                            className="btn-rounded  mb-2 me-2"
                            onClick={(e) => {
                                onSearch(formSearch)
                            }}
                        >
                            <i className="bx bx-search-alt me-1" />
                            Search
                        </Button>
                        <Button
                            type="button"
                            color="success"
                            className="btn-rounded  mb-2 me-2"
                            onClick={onCreate}
                        >
                            <i className="mdi mdi-plus me-1" />
                            Add New
                        </Button>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

SearchProduct.propTypes = {
    onSearch: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
}

export default SearchProduct