import css from "./Product.module.scss";
import {Button, Col, FormFeedback, Input, Label, Row} from "reactstrap";
import SelectProduct from "../../ProductManagerment/coponents/SelectProduct";
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import PropTypes from "prop-types";
import {BATCH_PRODUCT_UNIT, BATCH_STATUS_CODE, TEMPLATE_NEW_PRODUCT} from "../constant";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactSelect from "react-select";
import {getExchangeRate} from "../../../helpers/utils";

const Product = forwardRef(({product, onDelete, onRevertCancel, onCancel, onChange, validate, status, shippingFeePerProduct, units, exchangeRate}, ref) => {
    useImperativeHandle(ref, () => {
        return {}
    })
    const isCanceled = product?.isCanceled === true || parseInt(product?.isCanceled) === 1
    const [isCollapsed, setIsCollapsed] = useState(true)
    const collapseRef = useRef(null)

    const onCollapse = () => {
        if (isCollapsed === true && collapseRef.current) {
            collapseRef.current.style.maxHeight = collapseRef.current.scrollHeight + "px";
        } else {
            collapseRef.current.style.maxHeight = null
        }

        setIsCollapsed(!isCollapsed)
    }

    const onSelectProduct = (selectedProduct) => {
        let _product = {...product}
        _product.product = {
            _id: selectedProduct.value,
            PRODUCTNAME: selectedProduct.label
        }
        onChange(_product)
    }

    useEffect(() => {
        if (product?.isNewRow === true && isCollapsed === true) {
            onCollapse()
        }
    }, [product])

    useEffect(() => {
        if (collapseRef.current && Object.keys(validate).length) {
            collapseRef.current.style.maxHeight = collapseRef.current.scrollHeight + "px";
        }
    }, [validate])

    const getValueProduct = () => {
        const _product = product?.product
        return !_product?._id ? null : [{value: _product?._id, label: _product?.PRODUCTNAME ?? {}}]
    }

    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    const calcLack = (product) => {
        const _status = product?.isCanceled ?? false
        const quantityImport = product?.import?.quantity ?? ""
        const quantityExport = product?.export?.quantity ?? ""

        if(_status === true && status !== BATCH_STATUS_CODE.done) {
            return  ""
        }

        if (!isNaN(quantityExport) && !isNaN(quantityImport) && quantityImport !== "" && quantityExport !== "") {
            return Number(quantityExport) - Number(quantityImport)
        } else if (quantityImport === "" && quantityExport === "") {
            return ""
        }

        return "N/A"
    }

    const calcImportPrice = (product) => {
        const importPrice = product?.export?.price ?? 0
        const importQuantity = product?.import?.quantity
        const importPriceUnit = product?.export?.priceUnit ?? ""
        const _exchangeRate = getExchangeRate(units, exchangeRate, importPriceUnit)
        if(isNaN(importQuantity) || importQuantity === null || typeof importQuantity === "undefined" || status !== BATCH_STATUS_CODE.done) {
            return ""
        }

        const result = (Number(importPrice) * _exchangeRate) + shippingFeePerProduct
        if(!isNaN(result)) {
            if(Number.isInteger(result)) {
                return result.toString()
            }

            return result.toFixed(2).toString()
        }
        return "N/A"
    }

    const productUnitOptions = [
        {value: BATCH_PRODUCT_UNIT.pc, label: BATCH_PRODUCT_UNIT.pc},
        {value: BATCH_PRODUCT_UNIT.pack, label: BATCH_PRODUCT_UNIT.pack},
        {value: BATCH_PRODUCT_UNIT.box, label: BATCH_PRODUCT_UNIT.box},
    ]

    return <div className={css.productWrapper}>
        <SweetAlert
            title="Are you sure?"
            show={showConfirmDelete}
            type="danger"
            onCancel={() => {
                setShowConfirmDelete(!showConfirmDelete)
            }}
            showCancel={true}
            confirmBtnText="Yes" onConfirm={() => {
            if (typeof onChange === "function") {
                setShowConfirmDelete(!showConfirmDelete)
                onDelete()
            }
        }}
            cancelBtnCssClass="btn btn-secondary"
        >Delete this product.</SweetAlert>
        <Row>
            <Col md="5">
                <Label className="form-label">Product</Label>
                <SelectProduct value={getValueProduct()} onChange={onSelectProduct} isDisabled={isCanceled}/>
                <Input
                    type="hidden"
                    disabled={true}
                    invalid={!!validate?.product?._id}
                />
                {validate?.product?._id ? <FormFeedback type="invalid">{validate?.product?._id}</FormFeedback> : null}
            </Col>
            <Col md="4">
                <Row>
                    <Col md={9}>
                        <Label className="form-label">Requested Quantity</Label>
                        <Input type="number" value={product.requested.quantity} disabled={isCanceled} onChange={(e) => {
                            onChange({
                                ...product,
                                requested: {
                                    ...product.requested,
                                    quantity: e.target.value
                                }
                            })
                        }}
                               invalid={!!validate?.requested?.quantity}
                        />
                        {validate?.requested?.quantity ?
                            <FormFeedback type="invalid">{validate?.requested?.quantity}</FormFeedback> : null}
                    </Col>
                    <Col md={3}>
                        <Label className="form-label">Unit</Label>
                        <ReactSelect
                            onChange={(option) => {
                                onChange({
                                    ...product,
                                    requested: {
                                        ...product.requested,
                                        unit: option?.value ?? ""
                                    }
                                })
                            }}
                            value={productUnitOptions.filter(option => product.requested.unit === option.value)}
                            options={productUnitOptions}
                            isDisabled={isCanceled}
                        />
                        <Input type="hidden" invalid={!!validate?.requested?.unit}/>
                        {validate?.requested?.unit ?
                            <FormFeedback type="invalid">{validate?.requested?.unit}</FormFeedback> : null}
                    </Col>
                </Row>
            </Col>
            <Col md="3">
                <Label className="form-label">Action</Label>
                <div className={css.groupBtnAction}>
                    {
                        product?.canRevertCancel ?
                            <Button onClick={onRevertCancel} color="success">Undo Cancel</Button> :
                            <Button onClick={onCancel}>Cancel</Button>
                    }
                    {
                        product?.isNewRow ? <Button onClick={() => {
                            setShowConfirmDelete(!showConfirmDelete)
                        }} color="danger">Delete</Button> : null
                    }
                    <Button onClick={onCollapse} color="info">{isCollapsed ? "Expand" : "Collapse"}</Button>
                </div>
            </Col>
        </Row>
        <div className={isCollapsed ? css.moreInfo : `${css.moreInfo} ${css.active}`} ref={collapseRef}>
            <Row className="mt-2">
                <Col md="5">
                    <Row>
                        <Col sm="12">
                            <Label className="form-label fw-bold text text-info">Export</Label>
                        </Col>
                        <Col md={2}>
                            <Label className="form-label text text-info">Quantity</Label>
                            <Input
                                type="number"
                                name="exportQuantity"
                                disabled={isCanceled}
                                value={product?.export?.quantity ?? ""}
                                onChange={(e) => {
                                    onChange({
                                        ...product,
                                        export: {
                                            ...product.export,
                                            quantity: e.target.value
                                        }
                                    })
                                }}
                                invalid={!!validate?.export?.quantity}
                            />
                            {validate?.export?.quantity ?
                                <FormFeedback type="invalid">{validate?.export?.quantity}</FormFeedback> : null}
                        </Col>
                        <Col md={6}>
                            <Row className={css.row5}>
                                <Col md={8} className={css.col5}>
                                    <Label className="form-label text text-info">Price</Label>
                                    <Input
                                        type="number"
                                        name="exportPrice"
                                        disabled={isCanceled}
                                        value={product?.export?.price ?? ""}
                                        onChange={(e) => {
                                            onChange({
                                                ...product,
                                                export: {
                                                    ...product.export,
                                                    price: e.target.value
                                                }
                                            })
                                        }}
                                        invalid={!!validate?.export?.price}
                                    />
                                    {validate?.export?.price ?
                                        <FormFeedback type="invalid">{validate?.export?.price}</FormFeedback> : null}
                                </Col>
                                <Col md={4} className={css.col5}>
                                    <Label className={`form-label ${css.transparent}`}>Unit</Label>
                                    <ReactSelect
                                        value={units.filter(unit => unit.value === product?.export?.priceUnit)}
                                        onChange={(option) => {
                                            onChange({
                                                ...product,
                                                export: {
                                                    ...product.export,
                                                    priceUnit: option?.value
                                                }
                                            })
                                        }}
                                        isDisabled={isCanceled}
                                        options={units}/>
                                    <Input
                                        type="hidden"
                                        disabled={true}
                                        invalid={!!validate?.export?.priceUnit}
                                    />
                                    {validate?.export?.priceUnit ? <FormFeedback
                                        type="invalid">{validate?.export?.priceUnit}</FormFeedback> : null}
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                            <Label className="form-label text text-info">Note</Label>
                            <Input
                                type="textarea"
                                name="exportNote"
                                disabled={isCanceled}
                                value={product?.export?.note ?? ""}
                                onChange={(e) => {
                                    onChange({
                                        ...product,
                                        export: {
                                            ...product.export,
                                            note: e.target.value
                                        }
                                    })
                                }}
                                invalid={!!validate?.export?.note}
                            />
                            {validate?.export?.note ?
                                <FormFeedback type="invalid">{validate?.export?.note}</FormFeedback> : null}
                        </Col>
                    </Row>
                </Col>
                <Col md="7">
                    <Label className="form-label fw-bold text text-success">Import</Label>
                    <Row>
                        <Col md={4}>
                            <Row className={css.row5}>
                                <Col md={6} className={css.col5}>
                                    <Label className="form-label text text-success">Quantity</Label>
                                    <Input
                                        type="number"
                                        name="importQuantity"
                                        disabled={isCanceled}
                                        value={product?.import?.quantity ?? ""}
                                        onChange={(e) => {
                                            onChange({
                                                ...product,
                                                import: {
                                                    ...product.import,
                                                    quantity: e.target.value
                                                }
                                            })
                                        }}
                                        invalid={!!validate?.import?.quantity}
                                    />
                                    {validate?.import?.quantity ?
                                        <FormFeedback type="invalid">{validate?.import?.quantity}</FormFeedback> : null}
                                </Col>
                                <Col md={6} className={css.col5}>
                                    <Label className="form-label text text-success">Lack</Label>
                                    <Input
                                        type="text"
                                        disabled={true}
                                        value={calcLack(product)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={5}>
                            <Row className={css.row5}>
                                <Col md={8} className={css.col5}>
                                    <Label className="form-label text text-success">Price</Label>
                                    <Input
                                        type="number"
                                        name="importPrice"
                                        disabled={true}
                                        value={calcImportPrice(product)}
                                        />
                                </Col>
                                <Col md={4} className={css.col5}>
                                    <Label className={`form-label ${css.transparent}`}>Unit</Label>
                                    <ReactSelect
                                        isDisabled={true}
                                        value={units.filter(unit => unit.value === (product?.import?.priceUnit || "PESO"))}
                                        options={units}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={3}>
                            <Label className="form-label text text-success">Note</Label>
                            <Input
                                type="textarea"
                                name="importNote"
                                disabled={isCanceled}
                                value={product?.import?.note ?? ""}
                                onChange={(e) => {
                                    onChange({
                                        ...product,
                                        import: {
                                            ...product.import,
                                            note: e.target.value
                                        }
                                    })
                                }}
                                invalid={!!validate?.import?.note}
                            />
                            {validate?.import?.note ?
                                <FormFeedback type="invalid">{validate?.import?.note}</FormFeedback> : null}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    </div>
})

Product.propTypes = {
    product: PropTypes.object.isRequired,
    validate: PropTypes.object,
    onDelete: PropTypes.func,
    onRevertCancel: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    status: PropTypes.number.isRequired,
    units: PropTypes.arrayOf(PropTypes.object).isRequired,
    shippingFeePerProduct: PropTypes.number.isRequired,
    exchangeRate: PropTypes.object.isRequired,
}

Product.defaultProps = {
    product: TEMPLATE_NEW_PRODUCT,
    validate: {},
    onDelete: () => {
    },
    onRevertCancel: () => {
    },
    onCancel: () => {
    },
    onChange: () => {
    },
    shippingFeePerProduct: 0,
    status: BATCH_STATUS_CODE.pending,
    units: [],
    exchangeRate: {}
}

Product.displayName = "Product"

export default Product