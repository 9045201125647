import PropTypes from "prop-types"
import React from "react"
import {Modal, ModalBody, ModalHeader, Row} from "reactstrap"
import {dateTimeToString} from "../../../helpers/utils";
import moment from "moment";

const DetailWorkingTimes = (props) => {
    const {toggle, isShow, workingTimes} = props
    const renderRow = (element, index) => {
        const id = element?._id ?? ""
        const {in: login, out: logout} = element
        const loginTime = login?.time ? moment.utc(login.time) : ""
        const logoutTime = logout?.time ? moment.utc(logout.time) : ""

        return (
            <tr key={`${id}_${index}`}>
                <td>{index + 1}</td>
                <td>{dateTimeToString(loginTime)}</td>
                <td>{dateTimeToString(logoutTime)}</td>
            </tr>
        )
    }
    const renderRows = () => {
        return workingTimes.map((element, index) => {
            return renderRow(element, index)
        })
    }

    if (!isShow) {
        return null
    }

    return (
        <React.Fragment>
            <Modal isOpen={isShow} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle} tag="h4">Logs</ModalHeader>
                <ModalBody>
                    <table
                        className="table table-bordered table align-middle table-nowrap">
                        <thead className="table-light">
                        <tr>
                            <th tabIndex="0">Index</th>
                            <th tabIndex="0">Login</th>
                            <th tabIndex="0">Logout</th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderRows()}
                        </tbody>
                    </table>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

DetailWorkingTimes.propTypes = {
    isShow: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    workingTimes: PropTypes.arrayOf(
        PropTypes.shape({
            in: PropTypes.shape({
                time: PropTypes.string,
                isOpenDoor: PropTypes.number,
            }),
            out: PropTypes.shape({
                time: PropTypes.string,
                isOpenDoor: PropTypes.number,
            })
        })
    ).isRequired,
}

export default DetailWorkingTimes