import React from "react";
import { PropTypes } from "prop-types";
import { Row, Col } from "reactstrap";

import maintenance from "assets/images/maintenance.svg"

const Maintenance = (props) => {
    return (
        <React.Fragment>
            <Row className="justify-content-center">
                <Col sm={4}>
                    <div className="maintenance-img">
                    <img
                        src={maintenance}
                        alt=""
                        className="img-fluid mx-auto d-block"
                    />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="text-center">
                    <h3 className="mt-5"> {props.errorMsg} </h3>
                    <p>Please try again later.</p>
                </Col>
            </Row>
      </React.Fragment>
    )
}

Maintenance.propTypes = {
    errorMsg: PropTypes.string,
  };

export default Maintenance