import PropTypes from "prop-types"
import React, {useState, useEffect, useRef} from "react"
import {Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap"
import {useFormik} from "formik";
import * as Yup from "yup";
import {reqCreateCategory, reqUpdateCategory} from "helpers/fakebackend_helper";
import {htmlDecode} from "helpers/utils";
import ReactSelect from "react-select";
import {isValidFormula, KEYWORD_ENTRY_PRICE, suggestPrice} from "../../../helper";
import "./style.scss"
const EditCategory = (props) => {
    const {onSuccess, category, isShow, toggle} = props
    const [otherErrorMessage, setOtherErrorMessage] = useState("")
    const isEdit = useRef(false)
    const [categoryInfo, setCategoryInfo] = useState({
        id: "",
        name: {
            VN: "",
            EN: "",
            CN: "",
            TH: ""
        },
        description: {
            VN: "",
            EN: "",
            CN: "",
            TH: ""
        },
        isActive: false,
        formula: "",
    })

    const handleUpdateState = () => {
        const categoryId = category._id || ""
        isEdit.current = (categoryId !== null && categoryId !== "")
        setCategoryInfo({
            id: categoryId,
            name: category?.name ?? {VN: "", EN: "", CN: "", TH: ""},
            description: category?.description ?? {VN: "", EN: "", CN: "", TH: ""},
            isActive: category?.isActive ?? false,
            formula: htmlDecode(category?.formula) ?? ""
        })
    }

    useEffect(() => {
        handleUpdateState()
    }, [category])

    useEffect(() => {
        handleUpdateState()
    }, [])

    const validationInitValue = {
        id: (categoryInfo && categoryInfo.id) || '',
        name: categoryInfo?.name ?? {VN: "", EN: "", CN: "", TH: ""},
        description: categoryInfo?.description ?? {VN: "", EN: "", CN: "", TH: ""},
        isActive: categoryInfo?.isActive ?? false,
        formula: htmlDecode(categoryInfo?.formula) ?? ""
    }

    const validationRule = {
        name: Yup.object().shape({
            VN: Yup.string().required("Category name VN is required"),
            EN: Yup.string().required("Category name EN is required"),
            CN: Yup.string().required("Category name CN is required"),
            TH: Yup.string().required("Category name TH is required"),
        }),
        description: Yup.object().shape({
            VN: Yup.string(),
            EN: Yup.string(),
            CN: Yup.string(),
            TH: Yup.string(),
        }),
        isActive: Yup.boolean().required("Active is required"),
        formula: Yup.string().required("Formula is required").test("customValidateFormula", "Formula invalid", isValidFormula),
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: validationInitValue,
        validationSchema: Yup.object(validationRule),
        onSubmit: async (values) => {
            let data = {
                id: values.id,
                name: values.name,
                description: values.description,
                isActive: values.isActive,
                formula: values.formula,
            };

            try {
                if (isEdit.current) {
                    await reqUpdateCategory(data)
                } else {
                    await reqCreateCategory(data)
                }
                await onSuccess()
                validation.resetForm();
                toggle();
            } catch (error) {
                return setOtherErrorMessage(error.message || error)
            }
        },
    });

    const onToggle = () => {
        toggle()
    }

    const activeOptions = [
        {value: true, label: "Active"},
        {value: false, label: "In Active"},
    ]

    const getSuggestPrice = () => {
        const suggest = suggestPrice(validation.values.formula, 100)
        if(suggest !== false) {
            return suggest
        }

        return "Formula invalid"
    }

    return (
        <Modal isOpen={isShow} toggle={onToggle} size="lg">
            <ModalHeader toggle={onToggle} tag="h4">{isEdit.current ? "Edit Partner" : "Create Partner"}</ModalHeader>
            <ModalBody>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <Row form>
                        <Col className="col-12">
                            <div className="mb-3">
                                {otherErrorMessage ? (<p className="alert alert-danger">{otherErrorMessage}</p>) : null}
                            </div>
                        </Col>
                        <Row className="mb-3">
                            <Col className="col-md-6">
                                <Label className="form-label">Name (VN)</Label>
                                <Input
                                    name="name.vn"
                                    type="text"
                                    value={validation.values.name.VN || ""}
                                    invalid={!!validation.errors.name?.VN}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        let names = categoryInfo?.name ?? {}
                                        names.VN = e.target.value
                                        setCategoryInfo({
                                            ...categoryInfo,
                                            name: names
                                        })
                                    }}
                                />
                                {validation.errors.name?.VN ? (
                                    <FormFeedback type="invalid">{validation.errors.name.VN}</FormFeedback>
                                ) : null}
                            </Col>
                            <Col className="col-md-6">
                                <Label className="form-label">Description (VN)</Label>
                                <Input
                                    name="description.vn"
                                    type="textarea"
                                    value={validation.values.description.VN || ""}
                                    invalid={!!validation.errors.description?.VN}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        let descriptions = categoryInfo?.description ?? {}
                                        descriptions.VN = e.target.value
                                        setCategoryInfo({
                                            ...categoryInfo,
                                            description: descriptions
                                        })
                                    }}
                                />
                                {validation.errors.description?.VN ? (
                                    <FormFeedback type="invalid">{validation.errors.description.VN}</FormFeedback>
                                ) : null}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col className="col-md-6">
                                <Label className="form-label">Name (EN)</Label>
                                <Input
                                    name="name.vn"
                                    type="text"
                                    value={validation.values.name.EN || ""}
                                    invalid={!!validation.errors.name?.EN}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        let names = categoryInfo?.name ?? {}
                                        names.EN = e.target.value
                                        setCategoryInfo({
                                            ...categoryInfo,
                                            name: names
                                        })
                                    }}
                                />
                                {validation.errors.name?.EN ? (
                                    <FormFeedback type="invalid">{validation.errors.name.EN}</FormFeedback>
                                ) : null}
                            </Col>
                            <Col className="col-md-6">
                                <Label className="form-label">Description (EN)</Label>
                                <Input
                                    name="description.vn"
                                    type="textarea"
                                    value={validation.values.description.EN || ""}
                                    invalid={!!validation.errors.description?.EN}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        let descriptions = categoryInfo?.description ?? {}
                                        descriptions.EN = e.target.value
                                        setCategoryInfo({
                                            ...categoryInfo,
                                            description: descriptions
                                        })
                                    }}
                                />
                                {validation.errors.description?.EN ? (
                                    <FormFeedback type="invalid">{validation.errors.description.EN}</FormFeedback>
                                ) : null}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col className="col-md-6">
                                <Label className="form-label">Name (CN)</Label>
                                <Input
                                    name="name.vn"
                                    type="text"
                                    value={validation.values.name.CN || ""}
                                    invalid={!!validation.errors.name?.CN}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        let names = categoryInfo?.name ?? {}
                                        names.CN = e.target.value
                                        setCategoryInfo({
                                            ...categoryInfo,
                                            name: names
                                        })
                                    }}
                                />
                                {validation.errors.name?.CN ? (
                                    <FormFeedback type="invalid">{validation.errors.name.CN}</FormFeedback>
                                ) : null}
                            </Col>
                            <Col className="col-md-6">
                                <Label className="form-label">Description (CN)</Label>
                                <Input
                                    name="description.vn"
                                    type="textarea"
                                    value={validation.values.description.CN || ""}
                                    invalid={!!validation.errors.description?.CN}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        let descriptions = categoryInfo?.description ?? {}
                                        descriptions.CN = e.target.value
                                        setCategoryInfo({
                                            ...categoryInfo,
                                            description: descriptions
                                        })
                                    }}
                                />
                                {validation.errors.description?.CN ? (
                                    <FormFeedback type="invalid">{validation.errors.description.CN}</FormFeedback>
                                ) : null}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col className="col-md-6">
                                <Label className="form-label">Name (TH)</Label>
                                <Input
                                    name="name.vn"
                                    type="text"
                                    value={validation.values.name.TH || ""}
                                    invalid={!!validation.errors.name?.TH}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        let names = categoryInfo?.name ?? {}
                                        names.TH = e.target.value
                                        setCategoryInfo({
                                            ...categoryInfo,
                                            name: names
                                        })
                                    }}
                                />
                                {validation.errors.name?.TH ? (
                                    <FormFeedback type="invalid">{validation.errors.name.TH}</FormFeedback>
                                ) : null}
                            </Col>
                            <Col className="col-md-6">
                                <Label className="form-label">Description (TH)</Label>
                                <Input
                                    name="description.vn"
                                    type="textarea"
                                    value={validation.values.description.TH || ""}
                                    invalid={!!validation.errors.description?.TH}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        let descriptions = categoryInfo?.description ?? {}
                                        descriptions.TH = e.target.value
                                        setCategoryInfo({
                                            ...categoryInfo,
                                            description: descriptions
                                        })
                                    }}
                                />
                                {validation.errors.description?.TH ? (
                                    <FormFeedback type="invalid">{validation.errors.description.TH}</FormFeedback>
                                ) : null}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col className="col-md-6">
                                <Label className="form-label">Active</Label>
                                <ReactSelect name="active" onChange={(option) => setCategoryInfo({
                                    ...categoryInfo,
                                    isActive: option?.value ?? false
                                })} value={activeOptions.filter(option => option.value === categoryInfo.isActive)}
                                             options={activeOptions}/>
                                {validation.errors.isActive ? (
                                    <FormFeedback type="invalid">{validation.errors.isActive}</FormFeedback>
                                ) : null}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col className="col-md-12">
                                <Label className="form-label">Formula</Label>
                                <Input
                                    name="formula"
                                    type="textarea"
                                    value={validation.values.formula || ""}
                                    invalid={!!validation.errors.formula}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        const formula = e.target.value ?? ""
                                        setCategoryInfo({
                                            ...categoryInfo,
                                            formula: formula.toString().toUpperCase()
                                        })
                                    }}
                                />
                                {validation.errors.formula ? (
                                    <FormFeedback type="invalid">{validation.errors.formula}</FormFeedback>
                                ) : null}

                                <div className="formula-description">
                                    <div className="formula-list-keyword">
                                        <p className="formula-list-keyword-title">List Keyword: </p>
                                        <p className="formula-keyword">
                                            <span>{KEYWORD_ENTRY_PRICE}: </span>
                                            <span>Entry price</span>
                                        </p>
                                    </div>
                                    <div className="formula-example">
                                        <p className="formula-example-title">Example</p>
                                        <p className="formula-input-title">Input: </p>
                                        <span className="formula-input"><span>ENTRY_PRICE</span>: 100</span>
                                    </div>
                                    <p className="formula"><span>Formula:</span> {validation.values.formula}</p>
                                    <p className="formula-output"><span>Output:</span> {getSuggestPrice()}</p>
                                </div>
                            </Col>
                        </Row>
                    </Row>
                    <Row>
                        <Col>
                            <div className="text-end">
                                <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                >
                                    {isEdit.current ? "Update" : "Create"}
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    )
}

EditCategory.propTypes = {
    category: PropTypes.object.isRequired,
    onSuccess: PropTypes.func,
    isShow: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired
}

export default EditCategory