import axios from "axios";
import { getUserToken, removeAuthUser } from "./Authentication";
import { reqRefreshToken } from "./fakebackend_helper";

//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL;

const axiosApi = axios.create({
  baseURL: API_URL,
});

//handle request error invalid
const isInvalid = (res) => {
  if (res.status === 405 && res.hasOwnProperty("error")) {
    const { error } = res
    const messages = []

    for (const req_param in error) {
      if (Object.hasOwnProperty.call(error, req_param)) {
        const message = error[req_param];
        message ? messages.push(message) : ""
      }
    }

    if (messages.length) {
      throw messages.join("<br>")
    }
  }

  return false
}

//handle request internal server error
const isInternalServerError = (res) => {
  if (res.status === 500) {
    throw "Something went wrong!"
  }
}

const handleError = async (error) => {
  const { response, config } = error
  if (error.config && response && response.status === 401) {
    const { data } = response
    const { error: reqError } = data
    if (reqError.hasOwnProperty("token") && reqError["token"] === "Token expired" && config.url !== "/auth/refreshToken") {
      await reqRefreshToken()
      error.config.headers.token = getUserToken()
      return await axios.request(error.config);
    }
    else if(reqError.hasOwnProperty("token") && reqError["token"] === "Token invalid"){
      removeAuthUser()
      return window.location = "/login"
    }
    else if(reqError.hasOwnProperty("token") && reqError["token"] === "Access denied"){
      return window.location = "/error/401"
    }
  }

  if(response && response.status === 405){
    const { data } = response
    const { error: reqError } = data
    const messages = []
    for (const key in reqError) {
      if (Object.hasOwnProperty.call(reqError, key)) {
        const message = reqError[key];
        messages.push(message)
      }
    }
    
    return Promise.reject(messages.join("<br>"))
  }

  return Promise.reject(error)
}

axiosApi.interceptors.response.use(
  response => {
    isInvalid(response)
    isInternalServerError(response)

    return response
  },
  handleError
);

const pushTokenToHeader = (config ={}) => {
  let newHeader = config.headers || {}
  newHeader.token = getUserToken()

  return newHeader
}

export async function get(url, config = {}) {
  return axiosApi.get(url, { ...config, headers: pushTokenToHeader(config) })
                .then(response => response)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config, headers: pushTokenToHeader(config) })
    .then(response => response)
}

export async function postFormData(url, data, config = {}) {
  return axiosApi
      .post(url, data, { ...config, headers: pushTokenToHeader(config) })
      .then(response => response)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config, headers: pushTokenToHeader(config) })
    .then(response => response)
}

export async function del(url, config = {}) {
  return axiosApi
    .delete(url, { ...config, headers: pushTokenToHeader(config) })
    .then(response => response)
}
