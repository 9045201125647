import CustomPagination from "components/ultils/CustomPagination"
import React, { useEffect, useRef, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { MetaTags } from "react-meta-tags"
import { Link, withRouter } from "react-router-dom"
import { Badge, Card, CardBody, Col, Container, Row, Spinner, Toast, ToastBody, ToastHeader, UncontrolledTooltip } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { reqDeleteDepartment, reqGetListDepartment, reqGetListHistories } from "helpers/fakebackend_helper"
import SearchDepartment from "./SearchDepartment"
import EditDepartment from "./EditDepartment"
import DeleteModal from "components/Common/DeleteModal"
import { canAccessDelete, canAccessDetail, canAccessEdit } from "./Permission"
import { htmlDecode } from "helpers/utils"

const DepartmentManagerment = (props) => {
  const refPagination = useRef()
  let FLAG_FIRT_LOAD = useRef(false)
  const [departmentListConf, setDepartmentListConf] = useState({
    page: 1,
    limit: 20,
    search: {
      code: "",
      name: "",
    }
  });

  const [departments, setDepartments] = useState({
    data: [],
    totalItems: 0
  })

  const [department, setDepartment] = useState({})

  const historyTableColumn = [
    {
      dataField: "code",
      text: "Code",
      formatter: (rowContent, row) => {
        return htmlDecode(rowContent)
      }
    },
    {
      dataField: "name",
      text: "Name",
      formatter: (rowContent, row) => {
        return htmlDecode(rowContent)
      }
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3">
            {canAccessDetail() ? <Link
              to={`/department/detail/${row._id}`}
              className="text-primary"
            >
              <i className="mdi mdi-eye font-size-18" id={`edittooltip${row._id}`} />
              <UncontrolledTooltip placement="top" target={`edittooltip${row._id}`}>Deatil</UncontrolledTooltip>
            </Link> : null}

            {canAccessEdit() ? <Link
              to="#"
              className="text-success"
              onClick={() => onClickEdit(row)}
            >
              <i className="mdi mdi-pencil font-size-18" id={`edittooltip${row._id}`} />
              <UncontrolledTooltip placement="top" target={`edittooltip${row._id}`}>Edit</UncontrolledTooltip>
            </Link> : null}
            {canAccessDelete() ? <Link
              to="#"
              className="text-danger"
              onClick={() => onDelete(row)}
            >
              <i className="mdi mdi-delete font-size-18" id={`deletetooltip${row._id}`} />
              <UncontrolledTooltip placement="top" target={`deletetooltip${row._id}`}>Delete</UncontrolledTooltip>
            </Link> : null}
          </div>
        </>
      ),
    },
  ]

  const [toast, setToast] = useState({
    message: "",
    isOpen: false
  })

  const autoCloseToast = (message) => {
    setToast({
      message: message,
      isOpen: true
    })
    setTimeout(() => {
      setToast({
        message: "",
        isOpen: false
      })
    }, 5000);
  }

  const pageOptions = {
    sizePerPage: departmentListConf.limit,
    totalSize: departments.totalItems, // replace later with size(departments),
    custom: true,
  };

  const onChangePageNumber = (pageNumber) => {
    setDepartmentListConf({
      ...departmentListConf,
      page: parseInt(pageNumber)
    })
  }

  const onSearch = (data) => {
    setDepartmentListConf({
      ...departmentListConf,
      search: {
        ...departmentListConf.search,
        ...data
      },
      page: 1
    })

    refPagination.current.refreshCurrentPage()
  }

  const onSearchError = (message) => {
    autoCloseToast(message)
  }

  const getListDepartments = async () => {
    try {
      const filter = {
        params: {
          page: departmentListConf.page,
          name: departmentListConf.search.name || "",
          code: departmentListConf.search.code || "",
        }
      }

      const result = await reqGetListDepartment(filter)
      FLAG_FIRT_LOAD.current = true

      setDepartments({
        ...departments,
        data: result.items,
        totalItems: result.totalItems
      })

    } catch (error) {
      autoCloseToast(error.message || error)
    }
  }

  useEffect(async () => {
    await getListDepartments()
  }, [])

  useEffect(async () => {
    if (FLAG_FIRT_LOAD.current) {
      await getListDepartments()
    }
  }, [departmentListConf])

  //Create / Edit department
  const [modalEdit, setModalEdit] = useState(false)
  const toggleModal = () => {
    setModalEdit(!modalEdit)
  }
  const onClickEdit = (department) => {
    setDepartment(department)
    setModalEdit(true)
  }

  const onSuccessEdit = async () => {
    await getListDepartments()
  }

  const onCreateClick = () => {
    setDepartment({})
    setModalEdit(true)
  }

  //delete
  const [deleteModal, setDeleteModal] = useState(false)
  const onDelete = async (department) => {
    setDepartment(department)
    setDeleteModal(true)
  }

  const handleDelete = async () => {
    try {
      await reqDeleteDepartment(department?._id)
      await getListDepartments()
      setDeleteModal(false)
    } catch (error) {
      autoCloseToast(error.message || error)
      setDeleteModal(false)
    }
  }

  return (
    <React.Fragment>
      <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: "1005" }}>
        <Toast isOpen={toast.isOpen}>
          <ToastHeader icon={<Spinner type="grow" size="sm" color="danger" />} toggle={() => {
            setToast({ ...toast, isOpen: !toast.isOpen })
          }}>Error</ToastHeader>
          <ToastBody>{toast.message}</ToastBody>
        </Toast>
      </div>
      {deleteModal ? <DeleteModal show={deleteModal} onDeleteClick={handleDelete} onCloseClick={() => {
        setDeleteModal(false)
        setDepartment({})
      }}
      /> : null}
      {modalEdit ? <EditDepartment isShow={modalEdit} department={department} onSuccess={onSuccessEdit} toggle={toggleModal} /> : null}
      <div className="page-content">
        <MetaTags>
          <title>Department Managerment</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Department Managerment" breadcrumbItem="" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="_id"
                    columns={historyTableColumn}
                    data={departments.data}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={departments.data}
                        columns={historyTableColumn}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <SearchDepartment onSearch={onSearch} onError={onSearchError} onCreate={onCreateClick} />
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    basseted={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>

                              </Col>
                            </Row>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <CustomPagination ref={refPagination} onChangePage={onChangePageNumber} sizeOfPerPage={pageOptions.sizePerPage} totalItem={pageOptions.totalSize} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(DepartmentManagerment)