import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import ReactSelect from "react-select"
import { Col, Form, FormFeedback, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import * as Yup from "yup";
import { useFormik } from "formik";
import { reqCreatePayment, reqUpdatePayment } from "helpers/fakebackend_helper";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import SelectPartner from "pages/AssetManagerment/components/SelectPartner";
import SelectPaymentType from "./component/SelectPaymentType";
import { dateToDB, htmlDecode } from "helpers/utils";

const EditPayment = (props) => {
    const { isShow, toggle, payment, onSuccess } = props
    const isEdit = useRef(false)
    const [paymentInfo, setPaymentInfo] = useState({})
    

    useEffect(() => {
        const type = props.payment?.type ?? {}
        const typeObject = type?._id ? { value: type._id, label: type.name } : null

        const partner = props.payment?.partner ?? {}
        const partnerObject = partner?._id ? { value: partner._id, label: partner.providerName } : null
        const paymentId = props.payment?._id ?? ""
        isEdit.current = paymentId !== ""
        setPaymentInfo({
            ...props.payment,
            type: type?._id || "",
            partner: partner?._id || "",
            typeObject,
            partnerObject,
            currency: props.payment?.currency ?? "PESO"
        })
    }, [props.payment])

    const resovledOptions = [
        { value: 0, label: "No" },
        { value: 1, label: "Yes" }
    ]

    const CURRENCY_OPTIONS = [
        {value: "PESO", label: "PESO"},
        {value: "VND", label: "VND"},
        {value: "USD", label: "USD"},
    ]
    const [otherErrorMessage, setOtherErrorMessage] = useState("")
    const validationInitValue = {
        name: (paymentInfo && paymentInfo.name) || '',
        type: (paymentInfo && paymentInfo.type) || '',
        paymentDate: (paymentInfo && paymentInfo.paymentDate) || '',
        accountReceived: (paymentInfo && paymentInfo.accountReceived) || '',
        paymentAccount: (paymentInfo && paymentInfo.paymentAccount) || '',
        transactionAddress: (paymentInfo && paymentInfo.transactionAddress) || '',
        note: (paymentInfo && paymentInfo.note) || '',
        resovled: (paymentInfo && paymentInfo.resovled) || 0,
        partner: (paymentInfo && paymentInfo.partner) || '',
        amount: (paymentInfo && paymentInfo.amount) || '',
        registedPhone: (paymentInfo && paymentInfo.registedPhone) || '',
        registedEmail: (paymentInfo && paymentInfo.registedEmail) || '',
        repeatType: (paymentInfo && paymentInfo.repeatType) || 'month',
        repeatNumber: (paymentInfo && paymentInfo.repeatNumber) || 1,
        currency: (paymentInfo && paymentInfo.currency) || "PESO",
    }

    const validationRule = {
        name: Yup.string().required("Please Enter Payment Name"),
        type: Yup.string().required("Please Choose Payment Type"),
        paymentDate: Yup.date().required("Please Choose Payment Date"),
        accountReceived: Yup.string().required("Please Enter Account Received"),
        paymentAccount: Yup.string().required("Please Enter Payment Account"),
        transactionAddress: Yup.string().notRequired(),
        resovled: Yup.number().required("Please Choose Task Status"),
        partner: Yup.string().notRequired(),
        amount: Yup.number().required("Please Enter Amount"),
        currency: Yup.string().oneOf(CURRENCY_OPTIONS.map(option => option.value)).required("Please Choose Currency"),
        registedPhone: Yup.string().notRequired(),
        registedEmail: Yup.string().email("Email invalid").notRequired(),
        repeatType: Yup.string().required("Please Choose Repeat Type"),
        repeatNumber: Yup.number().min(1).required("Please Enter Repeat"),
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: validationInitValue,
        validationSchema: Yup.object(validationRule),
        onSubmit: async (values) => {
            console.log(validation.errors)
            let data = {
                name: values.name,
                type: values.type,
                paymentDate: dateToDB(values.paymentDate),
                accountReceived: values.accountReceived,
                paymentAccount: values.paymentAccount,
                transactionAddress: values.transactionAddress,
                note: values.note,
                resovled: values.resovled,
                partner: values.partner,
                amount: values.amount,
                registedPhone: values.registedPhone,
                registedEmail: values.registedEmail,
                repeatType: values.repeatType,
                repeatNumber: values.repeatNumber,
                currency: values.currency
            };

            if (isEdit.current) {
                data.id = paymentInfo ? paymentInfo._id : ""
            }

            try {
                if (isEdit.current) {
                    await reqUpdatePayment(data)
                }
                else {
                    await reqCreatePayment(data)
                }
                validation.resetForm();
                await onSuccess()
            } catch (error) {
                setOtherErrorMessage(error.message || error)
            }
        },
    });

    return (
        <React.Fragment>
            <Modal isOpen={isShow} toggle={toggle}>
                <ModalHeader toggle={toggle} tag="h4">
                    {!!isEdit ? "Edit Asset" : "Add Asset"}
                </ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row form>
                            <Col className="col-12">
                                <div className="mb-3">
                                    {otherErrorMessage ? (<p className="alert alert-danger">{otherErrorMessage}</p>) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Payment name</Label>
                                    <Input
                                        name="name"
                                        type="text"
                                        onChange={(e) => {
                                            validation.handleChange(e)
                                            setPaymentInfo({
                                                ...paymentInfo,
                                                name: e.target.value
                                            })
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={htmlDecode(validation.values.name) || ""}
                                        invalid={
                                            validation.touched.name && validation.errors.name ? true : false
                                        }
                                    />
                                    {validation.touched.name && validation.errors.name ? (
                                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Type</Label>
                                    <SelectPaymentType value={paymentInfo?.typeObject} onChange={(option) => {
                                        const type = option ?? {}
                                        const typeObject = type?.value ? type : null
                                        setPaymentInfo({
                                            ...paymentInfo,
                                            type: option?.value ?? "",
                                            typeObject
                                        })
                                        validation.validateField("type")
                                    }} />
                                    <Input type="hidden" invalid={validation.touched.type && validation.errors.type} />
                                    {validation.errors.type && validation.touched.type ? (
                                        <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Partner</Label>
                                    <SelectPartner value={paymentInfo.partnerObject} onChange={(option) => {
                                        const partner = option ?? {}
                                        const partnerObject = partner?.value ? partner : null
                                        setPaymentInfo({
                                            ...paymentInfo,
                                            partner: option?.value ?? "",
                                            partnerObject
                                        })
                                        validation.validateField("partner")
                                    }} />
                                    <Input type="hidden" invalid={validation.touched.partner && validation.errors.partner} />
                                    {validation.errors.partner ? (
                                        <FormFeedback partner="invalid">{validation.errors.partner}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Resovled</Label>
                                    <ReactSelect
                                        defaultValue="true"
                                        options={resovledOptions}
                                        onChange={(option) => {
                                            setPaymentInfo({
                                                ...paymentInfo,
                                                resovled: option.value
                                            })

                                            validation.validateField("resovled")
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={resovledOptions.filter(option => option.value === paymentInfo.resovled)}
                                    />

                                    <Input type="hidden" invalid={validation.touched.resovled && validation.errors.resovled} />
                                    {validation.touched.resovled && validation.errors.resovled ? (
                                        <FormFeedback type="invalid">{validation.errors.resovled}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Payment Date</Label>
                                    <InputGroup>
                                        <Flatpickr
                                            className="form-control d-block"
                                            placeholder="YYYY-MM-DD"
                                            options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                            }}
                                            onChange={(date, dateString) => {
                                                setPaymentInfo({
                                                    ...paymentInfo,
                                                    paymentDate: dateString
                                                })
                                                validation.validateField("paymentDate")
                                            }}
                                            name="paymentDate"
                                            onBlur={validation.handleBlur}
                                            value={paymentInfo.paymentDate}
                                        />
                                    </InputGroup>
                                    <Input type="hidden" invalid={validation.touched.paymentDate && validation.errors.paymentDate ? true : false} />
                                    {validation.touched.paymentDate && validation.errors.paymentDate ? (
                                        <FormFeedback type="invalid">{validation.errors.paymentDate}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Amount</Label>
                                    <Input
                                        name="amount"
                                        type="number"
                                        onChange={(e) => {
                                            validation.handleChange(e)
                                            setPaymentInfo({
                                                ...paymentInfo,
                                                amount: e.target.value
                                            })
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.amount || ""}
                                        invalid={
                                            validation.touched.amount && validation.errors.amount ? true : false
                                        }
                                    />
                                    {validation.touched.amount && validation.errors.amount ? (
                                        <FormFeedback type="invalid">{validation.errors.amount}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Currency</Label>
                                    <ReactSelect
                                        defaultValue="true"
                                        options={CURRENCY_OPTIONS}
                                        onChange={(option) => {
                                            setPaymentInfo({
                                                ...paymentInfo,
                                                currency: option.value
                                            })

                                            validation.validateField("currency")
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={CURRENCY_OPTIONS.filter(option => option.value === paymentInfo.currency)}
                                    />

                                    <Input type="hidden" invalid={validation.touched.currency && validation.errors.currency} />
                                    {validation.touched.currency && validation.errors.currency ? (
                                        <FormFeedback type="invalid">{validation.errors.currency}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Repeat Every</Label>
                                    <Row>
                                        <Col sm="6">
                                            <Input
                                                value={validation.values.repeatNumber || ""}
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    setPaymentInfo({
                                                        ...paymentInfo,
                                                        repeatNumber: value
                                                    })
                                                }}
                                                placeholder=""
                                                type="number"
                                                min="1"
                                                invalid={validation.touched.repeatNumber && validation.errors.repeatNumber}
                                            />
                                            {validation.touched.repeatNumber && validation.errors.repeatNumber ? (
                                                <FormFeedback type="invalid">{validation.errors.repeatNumber}</FormFeedback>
                                            ) : null}
                                        </Col>
                                        <Col sm="6" style={{ paddingLeft: "0" }}>
                                            <select value={validation.values.repeatType} className="form-select" onChange={(e) => {
                                                setPaymentInfo({
                                                    ...paymentInfo,
                                                    repeatType: e.target.value
                                                })
                                            }}
                                            >
                                                <option value="month">Month</option>
                                                <option value="precious">Precious</option>
                                            </select>
                                            <Input
                                                type="hidden"
                                                invalid={validation.touched.repeatType && validation.errors.repeatType}
                                            />
                                            {validation.touched.repeatType && validation.errors.repeatType ? (
                                                <FormFeedback type="invalid">{validation.errors.repeatType}</FormFeedback>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Account Received</Label>
                                    <Input
                                        name="accountReceived"
                                        type="text"
                                        onChange={(e) => {
                                            validation.handleChange(e)
                                            setPaymentInfo({
                                                ...paymentInfo,
                                                accountReceived: e.target.value
                                            })
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={htmlDecode(validation.values.accountReceived) || ""}
                                        invalid={
                                            validation.touched.accountReceived && validation.errors.accountReceived ? true : false
                                        }
                                    />
                                    {validation.touched.accountReceived && validation.errors.accountReceived ? (
                                        <FormFeedback type="invalid">{validation.errors.accountReceived}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Account Payment</Label>
                                    <Input
                                        name="paymentAccount"
                                        type="text"
                                        onChange={(e) => {
                                            validation.handleChange(e)
                                            setPaymentInfo({
                                                ...paymentInfo,
                                                paymentAccount: e.target.value
                                            })
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={htmlDecode(validation.values.paymentAccount) || ""}
                                        invalid={
                                            validation.touched.paymentAccount && validation.errors.paymentAccount ? true : false
                                        }
                                    />
                                    {validation.touched.paymentAccount && validation.errors.paymentAccount ? (
                                        <FormFeedback type="invalid">{validation.errors.paymentAccount}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Transaction Address</Label>
                                    <Input
                                        name="transactionAddress"
                                        type="textarea"
                                        onChange={(e) => {
                                            validation.handleChange(e)
                                            setPaymentInfo({
                                                ...paymentInfo,
                                                transactionAddress: e.target.value
                                            })
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={htmlDecode(validation.values.transactionAddress) || ""}
                                        invalid={validation.touched.transactionAddress && validation.errors.transactionAddress}
                                    />
                                    {validation.touched.transactionAddress && validation.errors.transactionAddress ? (
                                        <FormFeedback type="invalid">{validation.errors.transactionAddress}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Registed Phone</Label>
                                    <Input
                                        name="registedPhone"
                                        type="text"
                                        onChange={(e) => {
                                            validation.handleChange(e)
                                            setPaymentInfo({
                                                ...paymentInfo,
                                                registedPhone: e.target.value
                                            })
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={htmlDecode(validation.values.registedPhone) || ""}
                                        invalid={
                                            validation.touched.registedPhone && validation.errors.registedPhone ? true : false
                                        }
                                    />
                                    {validation.touched.registedPhone && validation.errors.registedPhone ? (
                                        <FormFeedback type="invalid">{validation.errors.registedPhone}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Registed Email</Label>
                                    <Input
                                        name="registedEmail"
                                        type="email"
                                        onChange={(e) => {
                                            validation.handleChange(e)
                                            setPaymentInfo({
                                                ...paymentInfo,
                                                registedEmail: e.target.value
                                            })
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={htmlDecode(validation.values.registedEmail) || ""}
                                        invalid={
                                            validation.touched.registedEmail && validation.errors.registedEmail ? true : false
                                        }
                                    />
                                    {validation.touched.registedEmail && validation.errors.registedEmail ? (
                                        <FormFeedback type="invalid">{validation.errors.registedEmail}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Note</Label>
                                    <Input
                                        name="note"
                                        type="textarea"
                                        onChange={(e) => {
                                            validation.handleChange(e)
                                            setPaymentInfo({
                                                ...paymentInfo,
                                                note: e.target.value
                                            })
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={htmlDecode(validation.values.note) || ""}
                                        rows="3"
                                        invalid={
                                            validation.touched.note && validation.errors.note ? true : false
                                        }
                                    />
                                    {validation.touched.note && validation.errors.note ? (
                                        <FormFeedback type="invalid">{validation.errors.note}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn btn-success save-user"
                                    >
                                        Save
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

EditPayment.propTypes = {
    payment: PropTypes.object.isRequired,
    isShow: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
}

export default EditPayment