import CustomPagination from "components/ultils/CustomPagination"
import React, { useEffect, useRef, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { MetaTags } from "react-meta-tags"
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Spinner, Toast, ToastBody, ToastHeader, UncontrolledTooltip } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { reqDeletePayment, reqGetListPayment } from "helpers/fakebackend_helper"
import DeleteModal from "components/Common/DeleteModal"
import SearchPayment from "./SearchPayment"
import EditPayment from "./EditPayment"
import { dateToString, rebuildConfigSearchParams, htmlDecode } from "helpers/utils"
import AuditPayment from "./Audit"
import { canAccessDelete, canAccessEdit, canAccessList } from "./Permission"

const PaymentManagerment = (props) => {
  const refPagination = useRef()
  let FLAG_FIRT_LOAD = useRef(false)

  const [paymentListConf, setPaymentListConf] = useState({
    page: 1,
    limit: 20,
    search: {
      name: "",
      type: "",
      paymentDate: "",
      paymentDateType: "",
      resovled: "",
      missTime: ""
    }
  });

  const [payments, setPayments] = useState({
    data: [],
    totalItems: 0
  })

  const [payment, setPayment] = useState({
    id: "",
    name: "",
    type: "",
    paymentDate: "",
    accountReceived: "",
    paymentAccount: "",
    transactionAddress: "",
    note: "",
    resovled: 0,
    missTime: 0,
    partner: "",
    audit: [],
  })

  const missTimeStyleRow = (contentRow, row) => {
    if (row.missTime === 1) {
      return (
        <React.Fragment>
          <div style={{ color: "red" }}>
            {htmlDecode(contentRow)}
          </div>
        </React.Fragment>
      )
    }

    return htmlDecode(contentRow)
  }

  const onClickAudit = (payment) => {
    setPayment(payment)
    toggleAudit()
  }

  const paymentTypeColumn = [
    {
      dataField: "name",
      text: "Name",
      formatter: (rowContent, row) => {
        const label = missTimeStyleRow(rowContent, row)
        if(canAccessEdit()) {
          return <Link
              to="#"
              onClick={() => onClickEdit(row)}
          >{rowContent}</Link>
        }
        return label
      }
    },
    {
      dataField: "type",
      text: "Type",
      formatter: (rowContent, row) => {
        return missTimeStyleRow(row?.type?.name ?? "", row)
      }
    },
    {
      dataField: "partner",
      text: "Partner",
      formatter: (rowContent, row) => {
        return missTimeStyleRow(row?.partner?.providerName ?? "", row)
      }
    },
    {
      dataField: "paymentDate",
      text: "Payment Date",
      formatter: (rowContent, row) => {
        return missTimeStyleRow(dateToString(rowContent), row)
      }
    },
    {
      dataField: "resovledDate",
      text: "Last Payment Date",
      formatter: (rowContent, row) => {
        return missTimeStyleRow(dateToString(rowContent), row)
      }
    },
    {
      dataField: "amount",
      text: "Amount",
      formatter: (rowContent, row) => {
        rowContent = rowContent ?? ""
        return missTimeStyleRow(`${rowContent?.toLocaleString('en-US')} ${row?.currency ?? ""}`, row)
      }
    },
    {
      dataField: "resovled",
      text: "Resovled",
      formatter: (rowContent, row) => {
        return missTimeStyleRow(row.resovled === 1 ? "Yes" : "No", row)
      }
    },
    {
      dataField: "missTime",
      text: "Miss Time",
      formatter: (rowContent, row) => {
        return missTimeStyleRow(row.missTime === 1 ? "Yes" : "No", row)
      }
    },
    {
      dataField: "repeat",
      isDummyField: true,
      text: "Repeat",
      formatter: (rowContent, row) => {
        const type = row.repeatType ?? "month"
        const number = row.repeatNumber ?? 1
        const content = `Every ${number} ${type}`
        return missTimeStyleRow(content, row)
      }
    },
    {
      dataField: "accountReceived",
      text: "Account Received",
      formatter: (rowContent, row) => {
        return missTimeStyleRow(rowContent, row)
      }
    },
    {
      dataField: "paymentAccount",
      text: "Payment Account",
      formatter: (rowContent, row) => {
        return missTimeStyleRow(rowContent, row)
      }
    },
    {
      dataField: "transactionAddress",
      text: "Where / Payment method",
      formatter: (rowContent, row) => {
        return missTimeStyleRow(rowContent, row)
      }
    },
    {
      dataField: "registedPhone",
      text: "Registed Phone",
      formatter: (rowContent, row) => {
        return missTimeStyleRow(rowContent, row)
      }
    },
    {
      dataField: "registedEmail",
      text: "Registed Email",
      formatter: (rowContent, row) => {
        return missTimeStyleRow(rowContent, row)
      }
    },
    {
      dataField: "note",
      text: "Note",
      formatter: (rowContent, row) => {
        return missTimeStyleRow(rowContent, row)
      }
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3">
            {canAccessEdit() ? <Link
              to="#"
              className="text-success"
              onClick={() => onClickEdit(row)}
            >
              <i className="mdi mdi-pencil font-size-18" id={`edittooltip${row._id}`} />
              <UncontrolledTooltip placement="top" target={`edittooltip${row._id}`}>Edit</UncontrolledTooltip>
            </Link> : null}
            {canAccessList() ? <Link
              to="#"
              className="text-primary"
              onClick={() => onClickAudit(row)}
            >
              <i className="mdi mdi-clock-fast font-size-18" id={`audit_tooltip${row._id}`} />
              <UncontrolledTooltip placement="top" target={`audit_tooltip${row._id}`}>Audit history</UncontrolledTooltip>
            </Link> : null}
            {canAccessDelete() ? <Link
              to="#"
              className="text-danger"
              onClick={() => onDelete(row)}
            >
              <i className="mdi mdi-delete font-size-18" id={`deletetooltip${row._id}`} />
              <UncontrolledTooltip placement="top" target={`deletetooltip${row._id}`}>Delete</UncontrolledTooltip>
            </Link> : null}
          </div>
        </>
      ),
    },
  ]

  const [toast, setToast] = useState({
    message: "",
    isOpen: false
  })

  const autoCloseToast = (message) => {
    setToast({
      message: message,
      isOpen: true
    })
    setTimeout(() => {
      setToast({
        message: "",
        isOpen: false
      })
    }, 5000);
  }

  const pageOptions = {
    sizePerPage: paymentListConf.limit,
    totalSize: payments.totalItems, // replace later with size(payments),
    custom: true,
  };

  const onChangePageNumber = (pageNumber) => {
    setPaymentListConf({
      ...paymentListConf,
      page: parseInt(pageNumber)
    })
  }

  const onSearch = (data) => {
    setPaymentListConf({
      ...paymentListConf,
      search: {
        ...paymentListConf.search,
        ...data
      },
      page: 1
    })

    refPagination.current.refreshCurrentPage()
  }

  const onSearchError = (message) => {
    autoCloseToast(message)
  }

  const getListPayments = async () => {
    try {
      const filter = {
        params: rebuildConfigSearchParams({
          page: paymentListConf.page,
          name: paymentListConf.search.name || "",
          type: paymentListConf.search.type || "",
          paymentDate: paymentListConf.search.paymentDate || "",
          paymentDateType: paymentListConf.search.paymentDateType || "",
          resovled: paymentListConf.search.resovled || "",
          missTime: paymentListConf.search.missTime || "",
        })
      }

      const result = await reqGetListPayment(filter)
      FLAG_FIRT_LOAD.current = true

      setPayments({
        ...payments,
        data: result.items,
        totalItems: result.totalItems
      })

    } catch (error) {
      autoCloseToast(error.message || error)
    }
  }

  useEffect(async () => {
    await getListPayments()
  }, [])

  useEffect(async () => {
    if (FLAG_FIRT_LOAD.current) {
      await getListPayments()
    }
  }, [paymentListConf])

  //Create / Edit payment
  const [modalEdit, setModalEdit] = useState(false)
  const toggleModal = () => {
    setModalEdit(!modalEdit)
  }
  const onClickEdit = (payment) => {
    setPayment(payment)
    setModalEdit(true)
  }

  const onSuccessEdit = async () => {
    setModalEdit(false)
    await getListPayments()
  }

  const onCreateClick = () => {
    setPayment({})
    setModalEdit(true)
  }

  //delete
  const [deleteModal, setDeleteModal] = useState(false)
  const onDelete = async (payment) => {
    setPayment(payment)
    setDeleteModal(true)
  }

  const handleDelete = async () => {
    try {
      await reqDeletePayment(payment?._id)
      await getListPayments()
      setDeleteModal(false)
    } catch (error) {
      autoCloseToast(error.message || error)
      setDeleteModal(false)
    }
  }

  //Audit
  const [showAudit, setShowAudit] = useState(false)
  const toggleAudit = () => {
    setShowAudit(!showAudit)
  }

  return (
    <React.Fragment>
      <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: "1005" }}>
        <Toast isOpen={toast.isOpen}>
          <ToastHeader icon={<Spinner type="grow" size="sm" color="danger" />} toggle={() => {
            setToast({ ...toast, isOpen: !toast.isOpen })
          }}>Error</ToastHeader>
          <ToastBody>{toast.message}</ToastBody>
        </Toast>
      </div>
      {deleteModal ? <DeleteModal show={deleteModal} onDeleteClick={handleDelete} onCloseClick={() => {
        setDeleteModal(false)
        setPayment({})
      }}
      /> : null}
      {modalEdit ? <EditPayment isShow={modalEdit} payment={payment} onSuccess={onSuccessEdit} toggle={toggleModal} /> : null}
      {showAudit ? <AuditPayment isShow={showAudit} payment={payment} toggle={toggleAudit} /> : null}
      <div className="page-content">
        <MetaTags>
          <title>Payment Managerment</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Payment Managerment" breadcrumbItem="" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="_id"
                    columns={paymentTypeColumn}
                    data={payments.data}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={payments.data}
                        columns={paymentTypeColumn}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <SearchPayment onSearch={onSearch} onError={onSearchError} onCreate={onCreateClick} />
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    basseted={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>

                              </Col>
                            </Row>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <CustomPagination ref={refPagination} onChangePage={onChangePageNumber} sizeOfPerPage={pageOptions.sizePerPage} totalItem={pageOptions.totalSize} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(PaymentManagerment)