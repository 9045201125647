import ButtonExport from "components/ultils/ButtonExport"
import PropTypes from "prop-types"
import React, { useState } from "react"
import ReactSelect from "react-select"
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap"
import SelectAssetType from "./components/SelectAssetTypes"
import { canAccessCreate, canAccessList } from "./Permission"

const SearchAsset = (props) => {
    const { onCreate, onSeach, onExport } = props
    const [formSearch, setDataForm] = useState({
        name: "",
        code: "",
        available: "",
        type: "",
        typeObject: null
    })
    const statusOptions = [
        { value: "", label: "Choose status" },
        { value: "true", label: "Available" },
        { value: "false", label: "Unavailable" }
    ]

    const renderButtonCreate = () => {
        if(!canAccessCreate()){
            return null
        }

        return (<Button
            type="button"
            color="success"
            className="btn-rounded  mb-2 me-2"
            onClick={onCreate}
        >
            <i className="mdi mdi-plus me-1" />
            Add New Asset
        </Button>)
    }
    
    const renderButtonExport = () => {
        if(!canAccessList()){
            return null
        }

        return (<ButtonExport onClick={async (e) => {
            await onExport()
        }} />)
    }
 
    return (
        <React.Fragment>
            <Row>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Asset name :</Label>
                        <Input
                            name="name"
                            type="text"
                            value={formSearch.name}
                            autoComplete="off"
                            onChange={(e) => {
                                setDataForm({
                                    ...formSearch,
                                    name: e.target.value
                                })
                            }}
                        />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Asset code :</Label>
                        <Input
                            name="code"
                            type="text"
                            value={formSearch.code}
                            autoComplete="off"
                            onChange={(e) => {
                                setDataForm({
                                    ...formSearch,
                                    code: e.target.value
                                })
                            }}
                        />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Asset Status :</Label>
                        <ReactSelect
                            options={statusOptions}
                            onChange={(option) => {
                                setDataForm({
                                    ...formSearch,
                                    available: option.value
                                })
                            }}
                            value={statusOptions.filter(option => option.value === formSearch.available)}
                        />
                    </FormGroup>
                </div>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-3 mb-0">
                        <Label>Asset Type :</Label>
                        <SelectAssetType isClear={true} value={formSearch.typeObject} onChange={(option) => {
                            setDataForm({
                                ...formSearch,
                                type: option ? option?.value : "",
                                typeObject: option ? { value: option?.value, label: option?.label } : null
                            })
                        }} />
                    </FormGroup>
                </div>
            </Row>
            <Row className="mb-2">
                <Col>
                    <div>
                        <Button
                            type="button"
                            color="info"
                            className="btn-rounded  mb-2 me-2"
                            onClick={(e) => {
                                onSeach(formSearch)
                            }}
                        >
                            <i className="bx bx-search-alt me-1" />
                            Search
                        </Button>
                        {renderButtonCreate()}
                        {renderButtonExport()}
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

SearchAsset.propTypes = {
    onSeach: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onExport: PropTypes.func
}

export default SearchAsset