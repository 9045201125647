import PropTypes from "prop-types"
import React, { useState, useEffect, useRef, useMemo } from "react"
import { Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, ModalFooter, Row, Spinner, Button } from "reactstrap"
import { useFormik } from "formik";
import * as Yup from "yup";
import { reqUpdateProduct } from "helpers/fakebackend_helper";
import {suggestPrice} from "./helper";
import ReactSelect from "react-select"; 
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Swal from "sweetalert2";
const EdiProducts = (props) => {
    
    const { products = {}, isShow, toggle, onClose } = props
    const [isLoading, setIsLoading] = useState(false);

    const swalSuccess = data => {
        Swal.fire({
          title: "Success",
          text: data?.mess,
          icon: "success",
          confirmButtonText: "Okay",
        }).then(res => {
          if (res.isConfirmed) {
            onToggle();
          }
        });
      };
    
      const swalError = data => {
        Swal.fire({
          title: "Error",
          text: data?.data || data?.mess,
          icon: "error",
          confirmButtonText: "Okay",
        }).then(res => {
          if (res?.isConfirmed) {
            console.log(data?.mess);
          }
        });
      };
     
    // console.log(products)
    const [otherErrorMessage, setOtherErrorMessage] = useState("")
    const isEdit = useRef(false)

    const [productInfo, setProductInfo] = useState({
        id: "",
        userName: "",
        sku: "",
        entryPrice: "",
        suggestPrice: "",
        regularPrice: "",
        website: "",
        branch: "",
        gccategory: ""
    })

    const handleUpdateState = () => {
        const productId = products._id || "" 
        isEdit.current = (productId !== null && productId !== "") 
        let GCCat = (products.GROUPCATEGORY.length == 0) ? "" : products.GROUPCATEGORY[0].GName.VN
        let SuggestPrice = (products.GROUPCATEGORY.length == 0) ? "" : products.GROUPCATEGORY[0].Formula
        
        setProductInfo({
            id: productId || "",
            userName: localStorage.getItem("user") || "",
            sku: products.SKU || "",
            entryPrice: products.ENTRYPRICE || "",
            regularPrice: products.REGULARPRICE || "",
            suggestPrice: SuggestPrice || "",
            website: products.website || "",
            branch: products.branch || "",
            gccategory: GCCat || ""
        })
    }

    const getSuggestPrice = () => {
        let Formula = (products.GROUPCATEGORY.length == 0) ? "" : products.GROUPCATEGORY[0].Formula
        const suggest = suggestPrice(Formula, products.ENTRYPRICE)
        if(suggest !== false) {
            return suggest
        }

        return "Formula invalid"
    }

    useEffect(() => {
        handleUpdateState()
    }, [products])

    useMemo(() => {
        handleUpdateState()
    }, [])

    let validationInitValue = {
        id: (productInfo && productInfo.id) || '',
        userName: (productInfo && productInfo.userName) || '',
        sku: (productInfo && productInfo.sku) || '',
        entryPrice: (productInfo && productInfo.entryPrice) || '',
        regularPrice: (productInfo && productInfo.regularPrice) || '',
        suggestPrice: (productInfo && productInfo.suggestPrice) || '', 
        website: (productInfo && productInfo.website) || '',
        branch: (productInfo && productInfo.branch) || '',
        gccategory: (productInfo && productInfo.gccategory) || ''
    }

    let validationRule = {  
        entryPrice: Yup.string().required("Entry Price is required"),
        regularPrice: Yup.string().required("Price is required"),
        website: Yup.string().required("Website is required"),
        branch: Yup.string().required("Branch is required"),
        gccategory: Yup.string().required("Group Category is required")
       
    }

    const updateProduct = async values => {
        const data = await reqUpdateProduct(values) 
        if (data?.status == "fail") {
          swalError(data);
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        } else {
          swalSuccess(data);
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        }
      };
 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: validationInitValue,
        validationSchema: Yup.object(validationRule),
        onSubmit: async (values) => { 
            
            let data = {
                id: values.id,
                website: values.website,
                username: values.userName,
                branchid: values.branch, 
                sku: values.sku,
                entryPrice: values.entryPrice,
                regprice: values.regularPrice,  
                gccategory: values.gccategory
            }; 
            try { 
                setIsLoading(true);
                await updateProduct(data)  
                validation.resetForm();
                toggle();
            } catch (error) {
                return setOtherErrorMessage(error.message || error)
            }
        },
    });

    const onToggle = () => {
        toggle()
        validation.resetForm();
    }
  
    //Selection
    let websiteOption = [
        { value: "VMART", label: "VMART" },
        { value: "ANNAM", label: "ANNAM" },
    ]

    let BranchOption = []

    if(productInfo.website == "VMART"){
        BranchOption = [
            { value: "5f7ff944bb947b79c6410a19", label: "Yakal Vmart" },
            { value: "60640d7a258389420901e239", label: "Sherwood Vmart" }
        ]
    }else if(productInfo.website == "ANNAM"){
        BranchOption = [
            { value: "61a86499586bcd26f54b8a67", label: "Yakal Annam" }
        ]
    }
     
    const [state, setState] = useState({
        value: '',
        copied: false,
    })
  
    return (
        <React.Fragment>
            <Modal isOpen={isShow} toggle={onToggle}>
                <ModalHeader toggle={onToggle} tag="h4">Edit Regular Price</ModalHeader> 
                    <ModalBody>
                        <Form 
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        > 
                            <Row>
                                <Col className="col-12">
                                    <div className="mb-3">
                                        {otherErrorMessage ? (<p className="alert alert-danger">{otherErrorMessage}</p>) : null}
                                    </div>
                                    <div className="mb-3">
                                        <Label className="form-label">Website</Label>
                                        <ReactSelect
                                            options={websiteOption}
                                            onChange={(option) => {
                                                setProductInfo({
                                                    ...productInfo,
                                                    website: option.value
                                                })

                                                validation.validateField("website")
                                            }}
                                            onBlur={validation.handleBlur}
                                            value={websiteOption.filter(option => {
                                                const website = productInfo.website
                                                return website === option.value
                                            })}
                                        />

                                        <Input type="hidden" invalid={validation.errors.website ? true : false} />
                                        {validation.touched.website && validation.errors.website ? (
                                            <FormFeedback type="invalid">{validation.errors.website}</FormFeedback>
                                        ) : null}
                                    </div>
                                    <div className="mb-3">
                                        <Label className="form-label">Branches</Label>
                                        <ReactSelect
                                            options={BranchOption}
                                            onChange={(option) => {
                                                setProductInfo({
                                                    ...productInfo,
                                                    branch: option.value
                                                })

                                                validation.validateField("branch")
                                            }}
                                            onBlur={validation.handleBlur}
                                            value={BranchOption.filter(option => {
                                                const branch = productInfo.branch
                                                return branch === option.value
                                            })}
                                        />

                                        <Input type="hidden" invalid={validation.errors.branch ? true : false} />
                                        {validation.touched.branch && validation.errors.branch ? (
                                            <FormFeedback type="invalid">{validation.errors.branch}</FormFeedback>
                                        ) : null}
                                    </div>
                                    <div className="mb-3">
                                        <Label className="form-label">SKU</Label>
                                        <Input
                                            name="sku"
                                            type="text"
                                            value={validation.values.sku || ""}
                                            invalid={validation.errors.sku && validation.touched.sku ? true : false}
                                            autoComplete="off"
                                            onChange={(e) => {
                                                setProductInfo({
                                                    ...productInfo,
                                                    sku: e.target.value
                                                })
                                            }}
                                            disabled={isEdit.current}
                                        />
                                        {validation.errors.sku ? (
                                            <FormFeedback type="invalid">{validation.errors.sku}</FormFeedback>
                                        ) : null}
                                    </div> 
                                    <div className="mb-3">
                                        <Label className="form-label">Group Category</Label>
                                        <Input
                                            name="gccategory"
                                            type="text"
                                            value={validation.values.gccategory || ""}
                                            invalid={validation.errors.gccategory && validation.touched.gccategory ? true : false}
                                            autoComplete="off"
                                            onChange={(e) => {
                                                setProductInfo({
                                                    ...productInfo,
                                                    gccategory: e.target.value
                                                })
                                            }}
                                            disabled={isEdit.current}
                                        />
                                        {validation.errors.gccategory ? (
                                            <FormFeedback type="invalid">{validation.errors.gccategory}</FormFeedback>
                                        ) : null}
                                    </div>
                                    <div className="mb-3">
                                        <Label className="form-label">Suggested Price</Label> 
                                        <Input
                                            name="suggestPrice"
                                            type="number"
                                            value={getSuggestPrice() || ""}
                                            invalid={validation.errors.suggestPrice && validation.touched.suggestPrice ? true : false}
                                            autoComplete="off"
                                            onChange={(e, {target: {value}} ) => {
                                                setProductInfo({
                                                    ...productInfo,
                                                    suggestPrice: e.target.value
                                                })
                                                // setState({value, copied: false})
                                            }}
                                            disabled={isEdit.current}
                                        />
                                        {validation.errors.suggestPrice ? (
                                            <FormFeedback type="invalid">{validation.errors.suggestPrice}</FormFeedback>
                                        ) : null}
                                        <CopyToClipboard text={getSuggestPrice()}
                                            onCopy={() => setState({copied: true})}
                                        >
                                            <span className="mdi mdi-clipboard-edit-outline font-size-15"> - Click here to copy</span>
                                        </CopyToClipboard>
                                    </div>
                                    <div className="mb-3">
                                        <Label className="form-label">Entry Price</Label>
                                        <Input
                                            name="entryPrice"
                                            type="text"
                                            value={validation.values.entryPrice || ""}
                                            invalid={validation.errors.entryPrice && validation.touched.entryPrice ? true : false}
                                            autoComplete="off"
                                            onChange={(e) => {
                                                setProductInfo({
                                                    ...productInfo,
                                                    entryPrice: e.target.value
                                                })
                                            }}
                                            disabled={isEdit.current}
                                        />
                                        {validation.errors.entryPrice ? (
                                            <FormFeedback type="invalid">{validation.errors.entryPrice}</FormFeedback>
                                        ) : null}
                                    </div>
                                    <div className="mb-3">
                                        <Label className="form-label">Regular Price</Label>
                                        <Input
                                            name="regularPrice"
                                            type="number"
                                            value={validation.values.regularPrice || ""}
                                            invalid={validation.errors.regularPrice && validation.touched.regularPrice ? true : false}
                                            autoComplete="off"
                                            onChange={(e) => {
                                                setProductInfo({
                                                    ...productInfo,
                                                    regularPrice: e.target.value
                                                })
                                            }}
                                        />
                                        {validation.errors.regularPrice ? (
                                            <FormFeedback type="invalid">{validation.errors.regularPrice}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>  
                            <Row>
                                <Col>
                                    <div className="text-start">
                                        <Button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={onClose}
                                        >
                                            Cancel {"  "}
                                            <i className="bx bx-window-close me-2"></i>
                                        </Button> 
                                    </div>
                                </Col>
                                <Col>
                                    <div className="text-end">  
                                        <Button type="submit" color="success" className="btn">
                                            Save {"  "}
                                            <i className="bx bx-save me-2"></i>
                                            {isLoading && <Spinner size="sm" />}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody> 
            </Modal>
        </React.Fragment>
    )
}

EdiProducts.propTypes = {
    products: PropTypes.any, 
    isShow: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
}

export default EdiProducts