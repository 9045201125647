import {Spinner, Toast, ToastBody, ToastHeader} from "reactstrap";
import React, {useRef} from "react";
import PropTypes from "prop-types";

const CustomToast = ({isOpen, onToggle, title, message, color, closeAfterMilSec}) => {
    color = color ?? "danger"
    const timeout = useRef(null)
    if(closeAfterMilSec && typeof closeAfterMilSec === "number" && typeof onToggle === "function" && isOpen) {
        if(timeout.current) {
            clearTimeout(timeout.current)
            timeout.current = null
        }

        timeout.current = setTimeout(() => {
            onToggle()
        }, closeAfterMilSec)
    }

    if(!isOpen) {
        return null
    }

    return (
        <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: "1005" }}>
            <Toast isOpen={isOpen}>
                <ToastHeader icon={<Spinner type="grow" size="sm" color={color} />} toggle={onToggle}>{title}</ToastHeader>
                <ToastBody>{message}</ToastBody>
            </Toast>
        </div>
    )
}

CustomToast.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    color: PropTypes.string,
    closeAfterMilSec: PropTypes.number
}

CustomToast.defaultProps = {
    isOpen: false,
    onToggle: () => {},
    title: "",
    message: "",
    color: "danger",
    closeAfterMilSec: null
}

export default CustomToast