import CustomPagination from "components/ultils/CustomPagination"
import React, { useEffect, useRef, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { MetaTags } from "react-meta-tags"
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Spinner, Toast, ToastBody, ToastHeader, UncontrolledTooltip } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { reqDeleteAssetType, reqDeleteDepartment, reqGetListAssetType } from "helpers/fakebackend_helper"
import SearchAssetTypes from "./SearchAssetTypes"
import DeleteModal from "components/Common/DeleteModal"
import EditAssetType from "./EditAssetType"
import { canAccessDelete, canAccessDetail, canAccessEdit } from "./Permission"
import { htmlDecode } from "helpers/utils"

const AssetTypesManagerment = (props) => {
  const refPagination = useRef()
  let FLAG_FIRT_LOAD = useRef(false)
  const [assetTypeListConf, setAssetTypeListConf] = useState({
    page: 1,
    limit: 20,
    search: {
      name: "",
    }
  });

  const [departments, setAssetTypes] = useState({
    data: [],
    totalItems: 0
  })

  const [assetType, setAssetType] = useState({
    id: "",
    name: ""
  })

  const assetTypeTableColumn = [
    {
      dataField: "name",
      text: "Name",
      formatter: (rowContent, row) => {
        return htmlDecode(rowContent)
      }
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3">
            {canAccessDetail() ? <Link
              to={`/asset-type/detail/${row._id}`}
              className="text-warning"
            >
              <i className="mdi mdi-eye font-size-18" id={`detailtooltip${row._id}`} />
              <UncontrolledTooltip placement="top" target={`detailtooltip${row._id}`}>
                View Detail
              </UncontrolledTooltip>
            </Link>: null}
            
            {canAccessEdit() ? <Link
              to="#"
              className="text-success"
              onClick={() => onClickEdit(row)}
            >
              <i className="mdi mdi-pencil font-size-18" id={`edittooltip${row._id}`} />
              <UncontrolledTooltip placement="top" target={`edittooltip${row._id}`}>Edit</UncontrolledTooltip>
            </Link> : null}
            {canAccessDelete() ? <Link
              to="#"
              className="text-danger"
              onClick={() => onDelete(row)}
            >
              <i className="mdi mdi-delete font-size-18" id={`deletetooltip${row._id}`} />
              <UncontrolledTooltip placement="top" target={`deletetooltip${row._id}`}>Delete</UncontrolledTooltip>
            </Link> : null}
          </div>
        </>
      ),
    },
  ]

  const [toast, setToast] = useState({
    message: "",
    isOpen: false
  })

  const autoCloseToast = (message) => {
    setToast({
      message: message,
      isOpen: true
    })
    setTimeout(() => {
      setToast({
        message: "",
        isOpen: false
      })
    }, 5000);
  }

  const pageOptions = {
    sizePerPage: assetTypeListConf.limit,
    totalSize: departments.totalItems, // replace later with size(departments),
    custom: true,
  };

  const onChangePageNumber = (pageNumber) => {
    setAssetTypeListConf({
      ...assetTypeListConf,
      page: parseInt(pageNumber)
    })
  }

  const onSearch = (data) => {
    setAssetTypeListConf({
      ...assetTypeListConf,
      search: {
        ...assetTypeListConf.search,
        ...data
      },
      page: 1
    })

    refPagination.current.refreshCurrentPage()
  }

  const onSearchError = (message) => {
    autoCloseToast(message)
  }

  const getListAssetType = async () => {
    try {
      const filter = {
        params: {
          page: assetTypeListConf.page,
          name: assetTypeListConf.search.name || "",
        }
      }

      const result = await reqGetListAssetType(filter)
      FLAG_FIRT_LOAD.current = true

      setAssetTypes({
        ...departments,
        data: result.items,
        totalItems: result.totalItems
      })

    } catch (error) {
      autoCloseToast(error.message || error)
    }
  }

  useEffect(async () => {
    await getListAssetType()
  }, [])

  useEffect(async () => {
    if (FLAG_FIRT_LOAD.current) {
      await getListAssetType()
    }
  }, [assetTypeListConf])

  //Create / Edit assetType
  const [modalEdit, setModalEdit] = useState(false)
  const toggleModal = () => {
    setModalEdit(!modalEdit)
  }
  const onClickEdit = (assetType) => {
    setAssetType(assetType)
    setModalEdit(true)
  }

  const onSuccessEdit = async () => {
    await getListAssetType()
  }

  const onCreateClick = () => {
    setAssetType({})
    setModalEdit(true)
  }

  //delete
  const [deleteModal, setDeleteModal] = useState(false)
  const onDelete = async (assetType) => {
    setAssetType(assetType)
    setDeleteModal(true)
  }

  const handleDelete = async () => {
    try {
      await reqDeleteAssetType(assetType?._id)
      await getListAssetType()
      setDeleteModal(false)
    } catch (error) {
      autoCloseToast(error.message || error)
      setDeleteModal(false)
    }
  }

  return (
    <React.Fragment>
      <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: "1005" }}>
        <Toast isOpen={toast.isOpen}>
          <ToastHeader icon={<Spinner type="grow" size="sm" color="danger" />} toggle={() => {
            setToast({ ...toast, isOpen: !toast.isOpen })
          }}>Error</ToastHeader>
          <ToastBody>{toast.message}</ToastBody>
        </Toast>
      </div>
      {deleteModal ? <DeleteModal show={deleteModal} onDeleteClick={handleDelete} onCloseClick={() => {
        setDeleteModal(false)
        setAssetType({})
      }}
      /> : null}
      {modalEdit ? <EditAssetType isShow={modalEdit} assetType={assetType} onSuccess={onSuccessEdit} toggle={toggleModal} /> : null}
      <div className="page-content">
        <MetaTags>
          <title>Asset Type Managerment</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Asset Type Managerment" breadcrumbItem="" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="_id"
                    columns={assetTypeTableColumn}
                    data={departments.data}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={departments.data}
                        columns={assetTypeTableColumn}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <SearchAssetTypes onSearch={onSearch} onError={onSearchError} onCreate={onCreateClick} />
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    basseted={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>

                              </Col>
                            </Row>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <CustomPagination ref={refPagination} onChangePage={onChangePageNumber} sizeOfPerPage={pageOptions.sizePerPage} totalItem={pageOptions.totalSize} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AssetTypesManagerment)